import { createSlice } from '@reduxjs/toolkit';

interface LayoutType{
    darkMode: boolean
}

export const layoutSlice = createSlice({
    name: "layout",
    initialState: {
        darkMode: false
    } as LayoutType ,
    reducers: {
        setDarkmode: (state, action) => {
            state.darkMode = action.payload
        },
    }
})

export const { setDarkmode } = layoutSlice.actions

export default layoutSlice.reducer