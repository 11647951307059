import { FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react"
import { cnpjMask, cnpjMaskToNumber } from "masks/cnpjMask"
import { phoneMask, phoneMaskToNumber } from "masks/phoneMask";
import InputMask from 'react-input-mask';
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { useNavigate } from "react-router-dom";
import apiCeps from "services/apiCeps";

import consultarCNPJ from 'consultar-cnpj'
import dayjs from "dayjs";
import { ClientType, IBGEMunicipio } from "types/app";
import { formatToCurrency, replaceSpecialChars } from "helpers/conversions";
import { StylesConfig } from "react-select";
import AsyncSelect from 'react-select/async'
import { toastErrorMsg } from "components/toasts/toastError";

const selectStyle: StylesConfig = {
    singleValue: (styles, { isDisabled }) => ({
        ...styles,
        color: isDisabled ? "rgb(112 126 174 / 1)" : undefined
    }),
    input: (styles, { isDisabled }) => ({
        ...styles,
        paddingLeft: 2,
        paddingRight: 2,
        height: 42,

    }),
    option: (styles, { isSelected, isFocused, isDisabled}) => ({
        ...styles,
        background: isSelected ? "#2d394d" : isFocused ? "#edf2f7" : styles.background,
        ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
            ? "#cbd5e0" : undefined,
        },
    }),
    control: (styles, {isDisabled}) => ({
        ...styles,
        borderColor: "#a3aed0",
        borderRadius: 0,
        background: isDisabled ? "#f8f9fa" : "white",
        cursor: isDisabled ? "default" : "text"
    })
}

const NewClient = () => {
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const user = useAppSelector((state) => state.user.user)

    const [cnpj, setCnpj] = useState('')
    const [razao, setRazao] = useState('')
    const [phone, setPhone] = useState('')
    const [ie, setIE] = useState('')
    const [address, setAddress] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [number, setNumber] = useState('')
    const [complement, setComplement] = useState('')
    const [cep, setCep] = useState('')
    const [district, setDistrict] = useState('')
    const [situacao, setSituacao] = useState('')
    const [inicio, setInicio] = useState(dayjs())
    const [capital, setCapital] = useState(0)
    const [tipo, setTipo] = useState('')
    const [atividadePr, setAtividadePr] = useState<ClientType["atividadePrimaria"]>(null)
    const [atividadesSec, setAtividadesSec] = useState<ClientType["atividadesSecundarias"]>([])
    const [socios, setSocios] = useState<ClientType["socios"]>([])
    const [codigoCidade, setCodigoCidade] = useState('')
	const [abreviacaoEstado, setAbreviacaoEstado] = useState('')

	const [loadingCidades, setLoadingCidades] = useState(true)
    const [cidades, setCidades] = useState<{
        codigo_municipio: string,
        nome_municipio: string
    }[]>([])

    const handleLoadCidades = () => {
		setLoadingCidades(true)
		api.get<IBGEMunicipio[]>("https://servicodados.ibge.gov.br/api/v1/localidades/municipios").then((res)=> {
			if(res){
				if(res.data){
					setCidades(res.data.map(c=> {
						return {
                            codigo_municipio: c.id.toString(),
                            nome_municipio: c.nome
                        }
					}))
				}
			}
			setLoadingCidades(false)
		}).catch(err=>{
			toastErrorMsg("Erro ao carregar cidades IBGE", false)
			setLoadingCidades(false)
		})
    }

    const navigate = useNavigate()

    const getCNPJ = async (cnpj: any) => {
        try{
            const empresa = await consultarCNPJ(cnpj)
            if(empresa){
                setRazao(empresa.razao_social)
                setCep(empresa.estabelecimento.cep)
                setNumber(empresa.estabelecimento.numero)
                setComplement(empresa.estabelecimento.complemento)
                setAtividadePr(empresa.estabelecimento.atividade_principal)
                setAtividadesSec(empresa.estabelecimento.atividades_secundarias)
                setSocios(empresa.socios)
                setTipo(empresa.estabelecimento.tipo)
                setCapital(parseFloat(empresa.capital_social))
                const inicioArray = empresa.estabelecimento.data_inicio_atividade.split('-')
                const dataInicio = {
                    ano: inicioArray[0] ? inicioArray[0] : '0',
                    mes: inicioArray[1] ? inicioArray[1] : '0',
                    dia: inicioArray[2] ? inicioArray[2] : '0'
                }
                setInicio(dayjs().year(parseInt(dataInicio.ano)).month(parseInt(dataInicio.mes)).day(parseInt(dataInicio.dia)))
                setSituacao(empresa.estabelecimento.situacao_cadastral)

                if(empresa.estabelecimento.inscricoes_estaduais.length && empresa.estabelecimento.inscricoes_estaduais){
                    let activeIE = false
                    let selectedIE = null
                    empresa.estabelecimento.inscricoes_estaduais.map(_ie=>{
                        if(_ie.ativo == true && !activeIE){
                            activeIE = true
                            selectedIE = _ie.inscricao_estadual
                        }
                    })

                    if(activeIE){
                        setIE(empresa.estabelecimento.inscricoes_estaduais[0].inscricao_estadual);
                    }else{
                        setIE("Isento")
                    }
                }else{
                    setIE("Isento")
                }

                if(empresa.estabelecimento.cep){
                    setAddress("Carregando...")
                    setState("Carregando...")
                    setCity("Carregando...")
                    setDistrict('Carregando...')

                    apiCeps.get("/cep", {
                        params: {
                            cep: empresa.estabelecimento.cep
                        }
                    }).then(res=>{
                        if(res.data){
                            if(res.data.rua && res.data.rua != ""){
                                setAddress(res.data.rua)
                            }else{
                                setAddress(empresa.estabelecimento.logradouro)
                            }

                            if(res.data.estados.nome && res.data.estados.nome != ""){
                                setState(res.data.estados.nome)
                            }else{

                            }

                            if(res.data.estados.abreviacao && res.data.estados.abreviacao != ""){
                                setAbreviacaoEstado(res.data.estados.abreviacao)
                            }else{

                            }

                            if(res.data.cidades.nome && res.data.cidades.nome != ""){
                                setCity(res.data.cidades.nome)
                            }else{

                            }

                            if(res.data.bairro && res.data.bairro != ""){
                                setDistrict(res.data.bairro)
                            }else{
                                setDistrict(empresa.estabelecimento.bairro)
                            }
                        }else{
                            setAddress("Não encontrado")
                            setState("Não encontrado")
                            setCity("Não encontrado")
                        }
                    })
                }
            }
        }catch(error: any){
            toast("CNPJ não encontrado na base do Governo", {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        }
    }

    useEffect(() => {
        if(cnpj){
            if(cnpj.length >= 18){
                // console.log(cnpj.length)
                getCNPJ(cnpj)
            }
        }
    }, [cnpj])

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const _cnpj = cnpjMaskToNumber(cnpj)
        const _phone = phoneMaskToNumber(phone)

        if(_cnpj.trim() == ""){
            toast("Preencha o campo de CNPJ", {
                theme: darkmode ? "dark" : "light",
                type: "error"
            })
            return
        }
        if(razao.trim() == ""){
            toast("Preencha o campo de Razão Social", {
                theme: darkmode ? "dark" : "light",
                type: "error"
            })
            return
        }
        if(address.trim() == ""){
            toast("Preencha o campo de Endereço", {
                theme: darkmode ? "dark" : "light",
                type: "error"
            })
            return
        }

        api.post('/client/new', {
            name: razao,
            address,
            state,
            cep: cep.split('-').join(''),
            number,
            city,
			uf: abreviacaoEstado,
            complement,
            ie,
            phone: _phone,
            cnpj: _cnpj,
            district,


            atividadePrimaria: atividadePr,
            atividadesSecundarias: atividadesSec,
            socios,
            tipo,
            capital,
            situacao,
            inicio,
			codigo_municipio: codigoCidade
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Cliente cadastrado com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            navigate('/admin/clients')
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })

    }

    const ajusta = (v: any) => {
        const digitos = !v ? '' : v.replace(/[^\d]/g, '');
        if (!digitos || digitos.length < 10) return v;
        const corte = digitos.length === 10 ? 6 : 7;
        const max = digitos.length > 11 ? 11 : digitos.length;
        return `(${digitos.substring(0, 2)}) ${digitos.substring(2, corte)}-${digitos.substring(corte, max)}`;
    }

    const maskBuilder = (v: any) => {
        if (!v || v.length == 0) return '';
        const a = ajusta(v);
        return (a.length >= 6 && a[5] === '9') ? '(99) 99999-9999' : '(99) 9999-9999';
    }

    const handlePhoneChange = (value: any) => {
        setPhone(ajusta(value));
    }

    const handleCepChange = (val: any) => {
        setCep(val)
    }

    const cepBuilder = (val: any) => {
        return '99999-999'
    }

    useEffect(()=>{
        setAddress('')
        setState('')
        setCity('')
        setDistrict('')
        if(cep){
            if(cep.length >= 9){
                setAddress("Carregando...")
                setState("Carregando...")
                setCity("Carregando...")
                setDistrict('Carregando...')

                apiCeps.get("/cep", {
                    params: {
                        cep: cep.split('-').join('')
                    }
                }).then(res=>{
                    if(res.data){
                        setAddress(res.data.rua)
                        setState(res.data.estados.nome)
                        setCity(res.data.cidades.nome)
                        setDistrict(res.data.bairro)
                    }else{
                        setAddress("Não encontrado")
                        setState("Não encontrado")
                        setCity("Não encontrado")
                    }
                })
            }
        }
    }, [cep])

    return(
        <>
            <form className="grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6 mt-10 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">
            	<FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">CNPJ<b className="text-red-500">*</b></FormLabel>
                    <Input value={cnpjMask(cnpj)} onChange={e=> setCnpj(e.target.value)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="CNPJ da empresa" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Inscrição Estadual</FormLabel>
                    <Input value={ie} onChange={e=> setIE(e.target.value)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="IE da empresa" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full 2xl:col-span-3">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Razão Social<b className="text-red-500">*</b></FormLabel>
                    <Input value={razao} onChange={e=> setRazao(e.target.value)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="Razão social da empresa" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Situação Cadastral<b className="text-red-500">*</b></FormLabel>
                    <Input value={situacao} onChange={e=> setSituacao(e.target.value)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="Ativa ou Inativa" />
                </FormControl>


                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">CEP<b className="text-red-500">*</b></FormLabel>
                    <InputMask mask={cepBuilder(cep)} value={cep} onChange={e=> {handleCepChange(e.target.value);}} maskChar={null} type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="CEP da empresa" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full 2xl:col-span-2">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Rua<b className="text-red-500">*</b></FormLabel>
                    <Input value={address} onChange={e=> setAddress(e.target.value)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="Rua do estabelecimento" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Número<b className="text-red-500">*</b></FormLabel>
                    <Input value={number} onChange={e=> setNumber(e.target.value)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="0" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full 2xl:col-span-2">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Complemento</FormLabel>
                    <Input value={complement} onChange={e=> setComplement(e.target.value)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="Complemento" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full 2xl:col-span-2">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Bairro<b className="text-red-500">*</b></FormLabel>
                    <Input value={district} onChange={e=> setDistrict(e.target.value)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="Bairro do estabelecimento" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full 2xl:col-span-2">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Cidade<b className="text-red-500">*</b></FormLabel>
                    <Input value={city} disabled onChange={e=> setCity(e.target.value)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="Cidade do estabelecimento" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Estado<b className="text-red-500">*</b></FormLabel>
                    <Input value={state} disabled onChange={e=> setState(e.target.value)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="Estado do estabelecimento" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">UF<b className="text-red-500">*</b></FormLabel>
                    <Input value={abreviacaoEstado} disabled onChange={e=> setAbreviacaoEstado(e.target.value)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="Estado do estabelecimento" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Início das Atividades</FormLabel>
                    <Input value={inicio?.format("YYYY-MM-DD")} style={{
                        colorScheme: darkmode ? "dark" : "light"
                    }} onChange={e => setInicio(dayjs(e.target.value).isValid() ? dayjs(e.target.value) : dayjs())} disabled required type="date" className="h-[50px] disabled:bg-transparent border border-gray-600 p-2 px-2" placeholder="" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Capital Social</FormLabel>
                    <Input disabled value={formatToCurrency(capital)} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="R$ 00,00" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Tipo</FormLabel>
                    <Input value={tipo} disabled type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="" />
                </FormControl>

				<FormControl className="flex flex-col gap-1 lg:flex w-full xl:col-span-1 2xl:col-span-2">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Cidade NFE</FormLabel>

                    <AsyncSelect
                        defaultOptions={ cidades.map((item) => { let _item: any = {}; _item.value = item.codigo_municipio; _item.label = item.nome_municipio; return _item; }).slice(0, 9) }
						isLoading={loadingCidades ? true : undefined}
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = cidades
									.filter((item) =>
										replaceSpecialChars(
											item.nome_municipio.toLowerCase()
										).includes(replaceSpecialChars(inputstring.toLowerCase()))
									)
									.map((item) => {
										let _item: any = {};
										_item.value = item.codigo_municipio;
										_item.label = item.nome_municipio;
										return _item;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: codigoCidade?.toString(),
							label: cidades?.find(
								(c) =>
									c.codigo_municipio.toString() ==
									codigoCidade?.toString()
							)?.nome_municipio,
						}}
						menuPlacement="auto"
                        onChange={(e: any)=> {
                            setCodigoCidade(e.value)
                        }}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</FormControl>
                {/* <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Data de Cadastro</FormLabel>
                    <Input disabled value={complement} onChange={e=> {}} required type="text" className="h-[50px] border border-gray-600 p-2 px-2" placeholder="" />
                </FormControl> */}

                <FormControl className="flex flex-col gap-1 w-full 2xl:col-span-3">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Atividades</FormLabel>
                    <Textarea placeholder={
`Primária:
    -
Secundárias:
    -
                    `} value={ atividadePr ?
`Primária:
    - ${atividadePr?.descricao ? atividadePr.descricao : ""}
Secundárias:
${atividadesSec.map(atividade=>
`   - ${atividade.descricao}
`).join('')}` : ''} h={240} minH={240} maxH={240} resize={"none"} className="h-[50px] border border-gray-600 p-2 px-2" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full 2xl:col-span-3">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Quadro Societário</FormLabel>
                    <Textarea placeholder={
`Sócio 1:
    -
Sócio 2:
    -
                    `} value={socios.map(socio=>(
`${socio.nome}: ${socio.qualificacao_socio.descricao}
`)).join("")} h={240} minH={240} maxH={240} resize={"none"} className="h-[50px] border border-gray-600 p-2 px-2" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 hidden 2xl:flex w-full 2xl:col-span-1">
                </FormControl>

                <button type="button" onClick={()=> navigate('/admin/clients')} className="linear lg:col-span-2 h-10 mt-5 w-full md:w-fit ml-auto flex items-center justify-center rounded-xl bg-red-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-darkred-400 dark:text-white dark:hover:bg-darkred-300 dark:active:bg-darkred-200">
                    Cancelar
                </button>
                <button type="button" onClick={handleSubmit} className="linear mr-[100%] lg:col-span-2 h-10 mt-5 w-full md:w-fit ml-auto flex items-center justify-center rounded-xl bg-green-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
                    Salvar
                </button>
            </form>
        </>
    )
}

export default NewClient