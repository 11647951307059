import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { logoffUser, setUser } from "redux/reducers/userSlice";
import { setDarkmode } from "redux/reducers/layoutSlice";
import api from "services/api";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";

export default function SignIn() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const user = useAppSelector((state) => state.user.user)
  const darkmode = useAppSelector((state) => state.layout.darkMode)
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const handleLogin = async (e: any) => {
    e.preventDefault();
    api.post('/auth/login', {
      username,
      password
    }).then(response=> {
      if(response){
        dispatch(setUser({
		  id: response.data.id,
          name: response.data.name,
          admin: response.data.admin,
          token: response.data.token,
          photo: response.data.photo,
          permissions: response.data.permissions
        }))

        dispatch(setDarkmode(response.data.darkTheme));

        navigate("/admin/bi/vendas")
      }else{
        toast("Erro ao se comunicar com o backend", {
          type: "error",
          theme: darkmode ? "dark" : "light"
        })
      }
    }).catch((error: ErrorData)=> {
      if(error.response){
        toast(error.response.data.error, {
          type: "error",
          theme: darkmode ? "dark" : "light"
        })
      }else{
        toast("Erro ao se comunicar com o backend", {
          type: "error",
          theme: darkmode ? "dark" : "light"
        })
      }
    })
    // if(username === "admin"){
    //   dispatch(setUser({
    //     name: username,
    //     admin: true
    //   }))
    // }else{
    //   dispatch(setUser({
    //     name: username,
    //     admin: false
    //   }))
    // }
    // navigate("/admin/default")
  }

  useEffect(()=>{
    if(user){
      api.get('/auth/check', {
        headers: {
          token: user?.token
        }
      }).catch(()=>{
        dispatch(logoffUser())
      }).then(()=>{
        navigate('/admin/bi/vendas')
      })
    }
  }, [])

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Entrar
        </h4>
        <p className="mb-9 ml-1 text-sm text-gray-600">
          Entre com seu usuário e senha para acessar a plataforma
        </p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div> */}
        {/* <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        <form onSubmit={handleLogin}>
          {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            labelExtra="!font-bold"
            label="Usuário*"
            placeholder="usuario.proit"
            id="username"
            type="text"
            value={username}
            onChange={e=> setUsername(e.target.value)}
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            labelExtra="!font-bold !text-sm"
            label="Senha*"
            value={password}
            onChange={e=> setPassword(e.target.value)}
            placeholder="******"
            id="password"
            type="password"
          />
          <button onClick={handleLogin} type="submit" className="linear mt-2 w-full rounded-xl bg-orange-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Entrar
          </button>
        </form>
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          {/* <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Mantenha-me conectado
            </p>
          </div> */}
          {/* <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a> */}
        </div>
          {/* <div className="mt-4">
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              Not registered yet?
            </span>
            <a
              href=" "
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            >
              Create an account
            </a>
          </div> */}
      </div>
    </div>
  );
}
