import { Tooltip } from "@chakra-ui/tooltip";
import CustomInputV2 from "components/custom-input-2";
import SideMenu from "components/sidemenu";
import dayjs from "dayjs";
import { replaceSpecialChars } from "helpers/conversions";
import { cnpjMask } from "masks/cnpjMask";
import { useEffect, useState } from "react";
import ReactSelect from 'react-select'
import { StylesConfig } from "react-select";
import AsyncSelect from 'react-select/async'
import api from "services/api";
import avatar from "assets/img/avatars/default-profile.png";
import { SaleSheetType, SelectType, SelectTypePhoto } from "types/app";
import { distribuidores, fabricantes, faturamentos, formasDePagamento, segmentos, status_pedidos, statusPedido, tiposProdutos } from "views/sheets/planilhaVendas";
import TooltipHorizon from "components/tooltip";

const referenteSelect = [
	{value: 0, label: "Fornecedor"},
	{value: 1, label: "Cliente"},
	{value: 2, label: "Imposto"},
]

const metodosPagamentoSelect = [
	{value: 1, label: "Boleto"},
	{value: 2, label: "PIX"},
	{value: 3, label: "TED"},
]

interface IPosVendasSidemenu {
	isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    loading: boolean;
    editData: (key: string, value: any) => void;
    resetData: () => void;
    handleSave: () => void;
	data: SaleSheetType;
	clients: SelectType[];
	funcs: {id: string, label: string}[];
	funcNames: SelectTypePhoto[];
	transportadoras: SelectType[];
}
	//2 { visible: undefined, minWidth: 180, name: 'pre_vendas_sheet', header: 'PRÉ VENDAS', defaultFlex: 1, width: 180, maxWidth: 250, editable: false, style: showOverflowColumn, render: (props: {value: string, data: any, cellProps: any})=> {
	// 		const {value, data, cellProps} = props

	// 		const funcs = data.pre_vendas
	// 		let funcsArray = []
	// 		if(typeof funcs == "object"){
	// 			funcsArray = funcs.map((v: any)=> v.toString())
	// 		}
	// 		return(
	// 			// <FuncMultiSelector className="w-full" val={value} disabled openEditor={cellProps} defaultValue={data["participantes"]} clients={funcNames} />
	// 			<div className="flex items-center gap-2">
	// 				{funcsArray.map((id: any) => {
	// 					const client = funcNames.find(client=> client.value == id)
	// 					const photo = client?.photo
	// 					const name = client?.content
	// 					return(
	// 						<Tooltip label={name?name:"Não identificado"} className="rounded px-2 py-1 text-xs" bg={"#000000e0"} color={"white"} rounded={"base"} placement="top">
	// 							<img
	// 								className="h-[25px] w-[25px] rounded-full cursor-pointer object-cover"
	// 								src={photo ? api.defaults.baseURL + "/uploads/" + photo : avatar}
	// 								alt={name ? name : "User"}
	// 							/>
	// 						</Tooltip>
	// 						// <span className="bg-brand-600 text-white rounded-full text-xs px-1">{id}</span>
	// 					)
	// 				})}
	// 			</div>
	// 		)
	// 	}, renderEditor: (editorProps: any) => {
	// 		const { value, onChange, onComplete, cellProps } = editorProps
	// 		const { data } = cellProps
	// 		return(
	// 			<FuncMultiSelector className="w-full" val={value} setVal={(val)=>{
	// 				data["pre_vendas"] = val
	// 				// onChange(val)
	// 			}} defaultValue={data["pre_vendas"]} clients={funcNames} onBlur={onComplete}/>
	// 		)
	// 	}},
	//1	{ visible: undefined, minWidth: 180, name: 'participantes_sheet', header: 'PARTICIPANTES', defaultFlex: 1, width: 180, maxWidth: 250, editable: false, style: showOverflowColumn, render: (props: {value: string, data: any, cellProps: any})=> {
	// 		const {value, data, cellProps} = props

	// 		const funcs = data.participantes
	// 		let funcsArray = []
	// 		if(typeof funcs == "object"){
	// 			funcsArray = funcs.map((v: any)=> v.toString())
	// 		}
	// 		return(
	// 			// <FuncMultiSelector className="w-full" val={value} disabled openEditor={cellProps} defaultValue={data["participantes"]} clients={funcNames} />
	// 			<div className="flex items-center gap-2">
	// 				{funcsArray.map((id: any) => {
	// 					const client = funcNames.find(client=> client.value == id)
	// 					const photo = client?.photo
	// 					const name = client?.content
	// 					return(
	// 						<Tooltip label={name?name:"Não identificado"} className="rounded px-2 py-1 text-xs" bg={"#000000e0"} color={"white"} rounded={"base"} placement="top">
	// 							<img
	// 								className="h-[25px] w-[25px] rounded-full cursor-pointer object-cover"
	// 								src={photo ? api.defaults.baseURL + "/uploads/" + photo : avatar}
	// 								alt={name ? name : "User"}
	// 							/>
	// 						</Tooltip>
	// 						// <span className="bg-brand-600 text-white rounded-full text-xs px-1">{id}</span>
	// 					)
	// 				})}
	// 			</div>
	// 		)
	// 	}, renderEditor: (editorProps: any) => {
	// 		const { value, onChange, onComplete, cellProps } = editorProps
	// 		const { data } = cellProps
	// 		return(
	// 			<FuncMultiSelector className="w-full" val={value} setVal={(val)=>{
	// 				data["participantes"] = val
	// 				// onChange(val)
	// 			}} defaultValue={data["participantes"]} clients={funcNames} onBlur={onComplete}/>
	// 		)
	// 	}},
	// ];

const PosVendasSidemenu = ({
	isOpen,
    setIsOpen,
    title,
    loading,
    editData,
    resetData,
    handleSave,
    data,
	clients,
	funcs,
	funcNames,
	transportadoras
}: IPosVendasSidemenu) => {

	const [arquitetos, setArquitetos] = useState([])
	const [preVendas, setPreVendas] = useState([])
	const [participantes, setParticipantes] = useState([])

	useEffect(()=> {
		const funcs = data.arquitetos
		let funcsArray = []
		if(typeof funcs == "object"){
			funcsArray = funcs.map((v: any)=> v.toString())
		}

		setArquitetos(funcsArray)
	}, [data.arquitetos])
	useEffect(()=> {
		const funcs = data.pre_vendas
		let funcsArray = []
		if(typeof funcs == "object"){
			funcsArray = funcs.map((v: any)=> v.toString())
		}

		setPreVendas(funcsArray)
	}, [data.pre_vendas])
	useEffect(()=> {
		const funcs = data.participantes
		let funcsArray = []
		if(typeof funcs == "object"){
			funcsArray = funcs.map((v: any)=> v.toString())
		}

		setParticipantes(funcsArray)
	}, [data.participantes])

	const selectStyle: StylesConfig = {
		singleValue: (styles, { isDisabled }) => ({
			...styles,
			color: isDisabled ? "rgb(112 126 174 / 1)" : undefined
		}),
		input: (styles, { isDisabled }) => ({
			...styles,
			paddingLeft: 2,
			paddingRight: 2,
			height: 42,

		}),
		option: (styles, { isSelected, isFocused, isDisabled}) => ({
			...styles,
			background: isSelected ? "#2d394d" : isFocused ? "#edf2f7" : styles.background,
			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
				? "#cbd5e0" : undefined,
			},
		}),
		control: (styles, {isDisabled}) => ({
			...styles,
			borderColor: "#a3aed0",
			borderRadius: 0,
			background: isDisabled ? "#f8f9fa" : "white",
			cursor: isDisabled ? "default" : "text"
		})
	}

	return (
		<SideMenu
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={title}
			loading={loading}
			onConfirm={() => {
				handleSave();
			}}
			onClose={() => {
				resetData();
			}}
		>
			<div className="flex flex-col gap-5">
				<div className="grid grid-cols-3 gap-2">

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">
							Data do pedido
						</h2>
						<input
							type="date"
							className="h-[50px] border border-gray-600 px-2 text-gray-700"
							defaultValue={dayjs(
								data.data
							).format(`YYYY-MM-DD`)}
							disabled
							onBlur={(e) => {
								if (
									e.target.value &&
									dayjs(e.target.value).isValid()
								)
									editData(
										`data`,
										dayjs(e.target.value).toISOString()
									);
								else
									editData("data", null)
							}}
						/>
					</div>
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">
							Previsão de entrega
						</h2>
						<input
							type="date"
							className="h-[50px] border border-gray-600 px-2 cursor-text"
							defaultValue={dayjs(
								data.previsao_entrega
							).format(`YYYY-MM-DD`)}
							onBlur={(e) => {
								if (
									e.target.value &&
									dayjs(e.target.value).isValid()
								)
									editData(
										`previsao_entrega`,
										dayjs(e.target.value).toISOString()
									);
								else
									editData("previsao_entrega", null)
							}}
						/>
					</div>
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">
							Data de entrega
						</h2>
						<input
							type="date"
							className="h-[50px] border border-gray-600 px-2 cursor-text"
							defaultValue={dayjs(
								data.data_entrega
							).format(`YYYY-MM-DD`)}
							onBlur={(e) => {
								if (
									e.target.value &&
									dayjs(e.target.value).isValid()
								)
									editData(
										`data_entrega`,
										dayjs(e.target.value).toISOString()
									);
								else
									editData("data_entrega", null)
							}}
						/>
					</div>
				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">
						Transportadora
					</h2>

					<AsyncSelect
						defaultOptions
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = transportadoras
									.filter((transportadora) =>
										replaceSpecialChars(
											transportadora.content.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((transportadora) => {
										let _transportadora: any = {};
										_transportadora.value = transportadora.value;
										_transportadora.label = transportadora.content;
										return _transportadora;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.transportadora?.toString(),
							label: transportadoras?.find(
								(c) =>
									c.value.toString() ==
									data.transportadora?.toString()
							)?.content,
						}}
						onChange={(e: any) => {
							editData("transportadora", e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Vendedor</h2>

					<AsyncSelect
						defaultOptions
						cacheOptions
						menuPlacement="auto"
						isDisabled
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = funcs
									.filter((func) =>
										replaceSpecialChars(
											func.label.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((func) => {
										let _func: any = {};
										_func.value = func.id;
										_func.label = func.label;
										return _func;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.userId,
							label: funcs?.find(
								(c) =>
									c.id.toString() ==
									data.userId?.toString()
							)?.label,
						}}
						onChange={(e: any) => {
							editData("userId", e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Cliente</h2>

					<AsyncSelect
						defaultOptions
						cacheOptions
						menuPlacement="auto"
						isDisabled
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = clients
									.filter((cliente) =>
										replaceSpecialChars(
											cliente.content.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((cliente) => {
										let _cliente: any = {};
										_cliente.value = cliente.value;
										_cliente.label = cliente.content;
										return _cliente;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.clientId,
							label: clients?.find(
								(c) =>
									c.value.toString() ==
									data.clientId?.toString()
							)?.content,
						}}
						onChange={(e: any) => {
							editData("clientId", e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">CNPJ</h2>

					<input
						className="h-[50px] border border-gray-600 p-2 px-2 text-gray-700"
						defaultValue={data.client?.cnpj ? cnpjMask(data.client?.cnpj) : "Não identificado"}
						disabled
					/>
				</div>
				<div className="grid grid-cols-2 gap-2">
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Segmento</h2>

						<input
							className="h-[50px] border border-gray-600 p-2 px-2 text-gray-700"
							defaultValue={segmentos.find(s=> s.id == data.segmento)?.label || "Não identificado"}
							disabled
						/>
					</div>
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Tipo de produto</h2>

						<input
							className="h-[50px] border border-gray-600 p-2 px-2 text-gray-700"
							defaultValue={tiposProdutos.find(s=> s.id == data.tipo_produto)?.label || "Não identificado"}
							disabled
						/>
					</div>
				</div>

				<div className="grid grid-cols-2 gap-2">
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Fabricante</h2>

						<AsyncSelect
							defaultOptions
							isDisabled
							cacheOptions
							menuPlacement="auto"
							loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = fabricantes
										.filter((item) =>
											replaceSpecialChars(
												item.label.toLowerCase()
											).includes(inputstring.toLowerCase())
										)
										.map((item) => {
											let _item: any = {};
											_item.value = item.id;
											_item.label = item.label;
											return _item;
										})
										.slice(0, 9);

									setTimeout(() => {
										resolve(options);
									}, 250);
								});
							}}
							value={{
								value: data.fabricante,
								label: fabricantes?.find(
									(c) =>
										c.id.toString() ==
										data.fabricante?.toString()
								)?.label,
							}}
							onChange={(e: any) => {
								editData("fabricante", e.value);
							}}
							noOptionsMessage={() => "Sem dados"}
							placeholder={"Selecione um"}
							styles={selectStyle}
						/>
					</div>
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Distribuidor</h2>

						<AsyncSelect
							defaultOptions
							cacheOptions
							isDisabled
							menuPlacement="auto"
							loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = distribuidores
										.filter((item) =>
											replaceSpecialChars(
												item.label.toLowerCase()
											).includes(inputstring.toLowerCase())
										)
										.map((item) => {
											let _item: any = {};
											_item.value = item.id;
											_item.label = item.label;
											return _item;
										})
										.slice(0, 9);

									setTimeout(() => {
										resolve(options);
									}, 250);
								});
							}}
							value={{
								value: data.distribuidor,
								label: distribuidores?.find(
									(c) =>
										c.id.toString() ==
										data.distribuidor?.toString()
								)?.label,
							}}
							onChange={(e: any) => {
								editData("distribuidor", e.value);
							}}
							noOptionsMessage={() => "Sem dados"}
							placeholder={"Selecione um"}
							styles={selectStyle}
						/>
					</div>
				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Número de cotação</h2>

					<input
						className="h-[50px] border border-gray-600 p-2 px-2"
						defaultValue={data.n_cotacao}
						onBlur={e=> editData("n_cotacao", e.target.value)}
					/>
				</div>

				<div className="grid grid-cols-2 gap-2">
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Número do pedido distribuidor</h2>

						<input
							className="h-[50px] border border-gray-600 p-2 px-2"
							defaultValue={data.n_pedido}
							onBlur={e=> editData("n_pedido", e.target.value)}
						/>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">
							Data de envio ao distribuidor
						</h2>
						{dayjs(data.data_envio_distribuidor).isValid() ?
							<input
								type="date"
								className="h-[50px] border border-gray-600 px-2 text-gray-700"
								defaultValue={dayjs(
									data.data_envio_distribuidor
								).format(`YYYY-MM-DD`)}
								disabled
								onBlur={(e) => {
									if (
										e.target.value &&
										dayjs(e.target.value).isValid()
									)
										editData(
											`data_envio_distribuidor`,
											dayjs(e.target.value).toISOString()
										);
								}}
							/>
						:
							<input disabled className="h-[50px] border border-gray-600 px-2 text-gray-700" value={"Aguardando envio"} />
						}
					</div>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">
						Descrição
					</h2>
					<textarea
						disabled
						className="h-[80px] min-h-[80px] border border-gray-600 p-2 px-2 text-gray-700"
						defaultValue={data.descricao_produto}
						onBlur={(e) => {
							editData(`descricao_produto`, e.target.value);
						}}
					/>
				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Valor de venda</h2>
					<CustomInputV2
						type="currency"
						totalWidth={500}
						disabled
						value={data.valor_venda}
						setValue={(e) => {
							editData("valor_venda", e);
						}}
						containerClass="h-[50px] px-2 border border-gray-600 w-full"
						inputClass="w-full outline-none h-[50px]"
					/>
				</div>

				<div className="grid grid-cols-2 gap-2 gap-y-5">
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Forma de pagamento</h2>

						<input
							className="h-[50px] border border-gray-600 p-2 px-2 text-gray-700"
							defaultValue={formasDePagamento.find(f=> f.id == data.forma_pagamento)?.label || "Não identificado"}
							disabled
						/>
					</div>
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Faturamento</h2>

						<input
							className="h-[50px] border border-gray-600 p-2 px-2 text-gray-700"
							defaultValue={faturamentos.find(f=> f.id == data.faturamento)?.label || "Não identificado"}
							disabled
						/>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">
							Status do pedido
						</h2>

						<AsyncSelect
							defaultOptions
							cacheOptions
							menuPlacement="auto"
							loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = status_pedidos
										.filter((item) =>
											replaceSpecialChars(
												item.label.toLowerCase()
											).includes(inputstring.toLowerCase())
										)
										.map((item) => {
											let _item: any = {};
											_item.value = item.id;
											_item.label = item.label;
											return _item;
										})
										.slice(0, 9);

									setTimeout(() => {
										resolve(options);
									}, 250);
								});
							}}
							value={{
								value: data.status_pedido,
								label: status_pedidos?.find(
									(c) =>
										c.id.toString() ==
										data.status_pedido?.toString()
								)?.label,
							}}
							onChange={(e: any) => {
								editData("status_pedido", e.value);
							}}
							noOptionsMessage={() => "Sem dados"}
							placeholder={"Selecione um"}
							styles={selectStyle}
						/>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">NFs Faturamento</h2>

						<input
							className="h-[50px] border border-gray-600 p-2 px-2"
							defaultValue={data.nfs_faturamento}
							onBlur={(e) => {
								editData(`nfs_faturamento`, e.target.value);
							}}
						/>
					</div>
				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Link da oportunidade</h2>

					<input
						className="h-[50px] border border-gray-600 p-2 px-2"
						defaultValue={data.link_oportunindade}
						onBlur={(e) => {
							editData(`link_oportunindade`, e.target.value);
						}}
					/>
				</div>


				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Arquitetos</h2>

						<div className="flex items-center gap-2">
							{arquitetos.length > 0 ? arquitetos.map((id: any) => {
								const func = funcNames.find(f=> f.value == id)
								const photo = func?.photo
								const name = func?.content
								return(
									<div key={id} className="w-[120px] h-[120px] rounded border hover:border-gray-600 p-2 flex flex-col gap-1">
										<img
											className="h-[60px] w-full cursor-pointer object-cover"
											src={photo ? api.defaults.baseURL + "/uploads/" + photo : avatar}
											alt={name ? name : "User"}
										/>
										<span className="w-full text-md text-center">{name}</span>
									</div>
								)
							}) : <span>Não há arquiteto(s) nessa negociação</span>}
						</div>

				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Pré-vendas</h2>

						<div className="flex items-center gap-2">
							{preVendas.length > 0 ? preVendas.map((id: any) => {
								const func = funcNames.find(f=> f.value == id)
								const photo = func?.photo
								const name = func?.content
								return(
									<div key={id} className="w-[120px] h-[120px] rounded border hover:border-gray-600 p-2 flex flex-col gap-1">
										<img
											className="h-[60px] w-full cursor-pointer object-cover"
											src={photo ? api.defaults.baseURL + "/uploads/" + photo : avatar}
											alt={name ? name : "User"}
										/>
										<span className="w-full text-md text-center">{name}</span>
									</div>
								)
							}) : <span>Não há pré-vendas nessa negociação</span>}
						</div>

				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Participantes</h2>

						<div className="flex items-center gap-2">
							{participantes.length > 0 ? participantes.map((id: any) => {
								const func = funcNames.find(f=> f.value == id)
								const photo = func?.photo
								const name = func?.content
								return(
									<div key={id} className="w-[120px] h-[120px] rounded border hover:border-gray-600 p-2 flex flex-col gap-1">
										<img
											className="h-[60px] w-full cursor-pointer object-cover"
											src={photo ? api.defaults.baseURL + "/uploads/" + photo : avatar}
											alt={name ? name : "User"}
										/>
										<span className="w-full text-md text-center">{name}</span>
									</div>
								)
							}) : <span>Não há participante(s) nessa negociação</span>}
						</div>

				</div>

			</div>
		</SideMenu>
	);
};

export default PosVendasSidemenu;
