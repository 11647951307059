import { ChakraProvider, FormControl, FormLabel, Input, Select } from "@chakra-ui/react"
import Switch from "components/switch";
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { useNavigate, useParams } from "react-router-dom";
import { GruposPermissoes, Role, UserType } from "types/app";
import { toastError } from "components/toasts/toastError";
import permissionsArray from "helpers/permissions";

interface EditGrupoType extends GruposPermissoes {
	permissions?: {[key: string]: boolean}
}

const EditGrupo = () => {
    const { id } = useParams()

    const [data, setData] = useState<EditGrupoType>({
		nome: "",
		permissoes: [],
		permissions: {}
    })

    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const user = useAppSelector((state) => state.user.user)

    const navigate = useNavigate()

    const handleSubmit = (e: any) => {
        e.preventDefault();

        api.put('/users/grupos/update/'+id, {
            nome: data.nome,
			permissoes: data.permissoes
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Grupo editado com sucesso!", {
                type: "success",
                theme: "light"
            })
            navigate('/admin/users')
        }).catch((error: ErrorData) => {
            toastError(error, false)
        })

    }

    useEffect(()=> {
        api.get("/users/grupos/show/"+id, {
            headers: {
                token: user?.token
            }
        }).then(res=> {
			const gData: EditGrupoType = res.data
			gData.permissions = {}

			gData.permissoes.map((p)=>{
				gData.permissions[p] = true
			})
            setData(res.data)
        }).catch((error: ErrorData)=> {
            toast(error.response.data.error, {
                type: "error",
                theme: "light"
            })
        })
    }, [])

	useEffect(()=> {
		let _newPerms: string[] = []
		Object.entries(data.permissions).map((val, index) => {
			const [perm] = val
			_newPerms.push(perm)
		})

		setData({
			...data,
			permissoes: _newPerms
		})
	}, [data.permissions])

    return(
        <>

			<div className="flex justify-between mt-5">
				<h1 className="text-2xl font-bold dark:text-white w-fit flex-none">Editar Grupo</h1>
			</div>
            <form className="grid grid-cols-2 gap-5 mt-5">
				<div className="flex flex-col">
					<FormControl className="flex flex-col gap-1 w-full">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Nome do grupo</FormLabel>
						<Input value={data.nome} onChange={e=> setData({...data, nome: e.target.value})} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Grupo" />
					</FormControl>

					<button type="button" onClick={handleSubmit} className="linear h-10 mt-5 w-full md:w-30p xl:w-20p ml-auto flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-darkorange-400 dark:text-white dark:hover:bg-darkorange-300 dark:active:bg-darkorange-200">
						Salvar
					</button>
				</div>

				<ChakraProvider>
					<div className="flex flex-col gap-1 mt-5">
						{permissionsArray.map(perm=> {
							if(perm.req){
								if(data.permissions[perm.req]){
									return (
										<div key={`permissao-${perm.id}`} className="flex items-center gap-3">
											<Switch checked={data.permissions[perm.perm] ? true : false} onChange={e=> {
												const _data = {...data, permissions: {
													...data.permissions
												}}
												if(e.target.checked){
													_data.permissions[perm.perm] = true
												}else{
													delete _data.permissions[perm.perm]
												}
												setData(_data)
											}} id="switch1" />
											<label
												htmlFor="checkbox1"
												className="text-base font-medium text-navy-700 dark:text-white"
											>
												{perm.text}
											</label>
										</div>
									)
								}
							}else{
								return (
									<div key={perm.perm} className="flex items-center gap-3">
										<Switch checked={data.permissions[perm.perm] ? true : false} onChange={e=> {
											const _data = {...data, permissions: {
												...data.permissions
											}}
											if(e.target.checked){
												_data.permissions[perm.perm] = true
											}else{
												delete _data.permissions[perm.perm]
											}
											setData(_data)
										}} id="switch1" />
										<label
											htmlFor="checkbox1"
											className="text-base font-medium text-navy-700 dark:text-white"
										>
											{perm.text}
										</label>
									</div>
								)
							}
						})}
					</div>
				</ChakraProvider>
            </form>
        </>
    )
}

export default EditGrupo