import Card from "components/card";
import { useEffect, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toastError } from "components/toasts/toastError";
import { ErrorData } from "types/fetch";
import dayjs from "dayjs";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { formatToCurrency } from "helpers/conversions";
import { ChakraProvider, Skeleton } from "@chakra-ui/react";

interface ChartType {
	name: string;
	data: number[];
}

const DetalhamentosDespesa = ({ from, to, selectedFuncs, selectedOperacoes, pageLoading }: {
	from?: Date,
	to?: Date,
	searchDate?: Date,
	selectedFuncs: any,
	selectedOperacoes: any,
	pageLoading?: boolean
}) => {
	const user = useAppSelector((selector) => selector.user.user);
	const darkmode = useAppSelector((selector) => selector.layout.darkMode);
	const [loading, setLoading] = useState(true)
	const [chartOptions, setChartOptions] = useState<any>({
		chart: {
			type: "bar",
			height: 350,
			stacked: true,
		},
		plotOptions: {
			bar: {
				horizontal: false,
				borderRadius: 10,
				dataLabels: {
					total: {
						enabled: false,
						style: {
							fontSize: "12px",
							fontWeight: 400,
						},
					},
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			show: true,
			width: 2,
			colors: ["transparent"],
		},
		xaxis: {
			categories: [
				"Jan",
				"Fev",
				"Mar",
				"Abr",
				"Mai",
				"Jun",
				"Jul",
				"Ago",
				"Set",
				"Out",
				"Nov",
				"Dez",
			],
		},
		yaxis: {
			labels: {
				formatter: (val: number) => {
					const formatted = formatToCurrency(val);
					return formatted;
				},
			},
		},
		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (val: number) {
					const formatted = formatToCurrency(val);
					return formatted;
				},
			},
		},
		fill: {
			opacity: 1,
			// colors: ["#00E396", "#e30000"]
		},
	});

	const [chartData, setChartData] = useState<ChartType[]>([
		{ name: "Fixa", data: [900, 250, 300, 600, 900, 150, 350] },
		{ name: "Variável", data: [400, 800, 700, 600, 1500, 10, 0] },
	]);

	const getData = () => {
		setLoading(true)
		api.get("/financeiro/dashboard/detalhamentoDespesas", {
			params: {
				start: from,
				end: to,
				funcs: JSON.stringify(selectedFuncs),
				operacoes: JSON.stringify(selectedOperacoes)
			},
			headers: {
				token: user?.token
			}
		}).then(res => {
			if(res){
				if(res.data){
					setChartData(res.data)
				}
			}
			setLoading(false)
		}).catch(err=>{
			toastError(err, false)
		})
	}

	useEffect(()=> {
		getData()
	}, [from, to, selectedFuncs, selectedOperacoes])

	return (
		<Card extra="flex flex-col bg-white !w-full rounded-3xl py-6 px-2 text-center !shadow-shadow-500">
			<ChakraProvider>
				<Skeleton isLoaded={!pageLoading} startColor="white" endColor="gray.300" className="py-6 px-2 !rounded-3xl">
					<div className="mb-auto flex items-center justify-between px-6">
						<h4 className="text-md font-bold text-navy-700 dark:text-white">
							Detalhamento das Despesas
						</h4>
					</div>

					<div className="md:mt-16 lg:mt-0">
						<div className="h-[250px] w-full xl:h-[350px]">
							<Chart
								options={chartOptions}
								series={chartData as any}
								type="bar"
								width="100%"
								height="100%"
							/>
						</div>
					</div>
				</Skeleton>
			</ChakraProvider>
		</Card>
	);
};

export default DetalhamentosDespesa;
