import { Action, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { OrderType } from 'types/app';

interface OrderSliceType extends OrderType{
    order: {
        categoryId: number,
        categoryName?: string,
        subcategoryId: number,
        subcategoryName?: string,
        itemId: number,
        itemName?: string,
        paramId: number,
        paramName?: string
        itemQtd?: number,
        paramQtd?: number,
        itemType?: string,
        clientName?: string,
    }[],
}

export const orderSlice = createSlice({
    name: "layout",
    initialState: {
        order: [],
        clientId: null,
        validUntil: dayjs().add(1, "day").toISOString(),
		estimatedTime1: "",
		paymentMethod1: "",
        executive: -1
    } as OrderSliceType ,
    reducers: {
        resetOrder: (state, action) => {
            state.order = []
            state.clientId = 0
            state.clientName = ""
            state.validUntil = dayjs().add(1, "day").toISOString()
			state.estimatedTime1 = ""
			state.paymentMethod1 = ""
            state.executive = -1
        },
        removeItem: (state, action) => {
            if(!state.order){
                return
            }
            if(!state.order[action.payload]){
                return
            }

            state.order = state.order.filter((val, index)=> index != action.payload)
        },
        addItem: (state, action) => {
            if (!state.order){
                state.order = []
            }
            state.order.push({
                itemName: "Novo Item",
                categoryId: 0,
                subcategoryId: 0,
                itemId: 0,
                paramId: 0,
                itemQtd: 0,
                paramQtd: 0,
            })
        },
        addItemCategory: (state, action) => {
            if (!state.order){
                state.order = []
            }
            state.order.push({
                itemName: "Novo Item",
                categoryId: action.payload.categoryId,
                subcategoryId: 0,
                itemId: 0,
                paramId: 0,
                itemQtd: 0,
                paramQtd: 0,
            })
        },

        selectCategory: (state, action) => {
            state.order[action.payload.itemIndex].subcategoryId = 0
            state.order[action.payload.itemIndex].subcategoryName = ""
            state.order[action.payload.itemIndex].itemId = 0
            state.order[action.payload.itemIndex].itemType = undefined
            state.order[action.payload.itemIndex].itemQtd = 0
            state.order[action.payload.itemIndex].itemName = ""
            state.order[action.payload.itemIndex].paramId = 0
            state.order[action.payload.itemIndex].paramQtd = 0
            state.order[action.payload.itemIndex].paramName = ""

            state.order[action.payload.itemIndex].categoryId = action.payload.categoryId
            state.order[action.payload.itemIndex].categoryName = action.payload.categoryName
        },
        selectSubcategory: (state, action) => {
            state.order[action.payload.itemIndex].itemId = 0
            state.order[action.payload.itemIndex].itemQtd = 0
            state.order[action.payload.itemIndex].itemType = undefined
            state.order[action.payload.itemIndex].itemName = ""
            state.order[action.payload.itemIndex].paramId = 0
            state.order[action.payload.itemIndex].paramQtd = 0
            state.order[action.payload.itemIndex].paramName = ""

            state.order[action.payload.itemIndex].subcategoryId = action.payload.subcategoryId
            state.order[action.payload.itemIndex].subcategoryName = action.payload.subcategoryName
        },
        selectItem: (state, action) => {
            state.order[action.payload.itemIndex].paramId = 0
            state.order[action.payload.itemIndex].paramQtd = 0
            state.order[action.payload.itemIndex].paramName = ""

            state.order[action.payload.itemIndex].itemQtd = 0
            state.order[action.payload.itemIndex].itemId = action.payload.itemId
            state.order[action.payload.itemIndex].itemType = action.payload.itemType
            state.order[action.payload.itemIndex].itemName = action.payload.itemName
        },
        selectParam: (state, action) => {
            state.order[action.payload.itemIndex].paramQtd = 0
            state.order[action.payload.itemIndex].paramId = action.payload.paramId
            state.order[action.payload.itemIndex].paramName = action.payload.paramName
        },
        selectParamQtd: (state, action) => {
            state.order[action.payload.itemIndex].paramQtd = action.payload.qtd
        },
        selectItemQtd: (state, action) => {
            state.order[action.payload.itemIndex].itemQtd = action.payload.qtd
        },
        selectClient: (state, action) => {
            state.clientId = action.payload
        },
        setDate: (state, action) => {
            state.validUntil = action.payload
        },
        setClientName: (state, action) => {
            state.clientName = action.payload
        },
        setEstimatedTime1: (state, action) => {
            state.estimatedTime1 = action.payload
        },
        setPaymentMethod1: (state, action) => {
            state.paymentMethod1 = action.payload
        },
        setExecutive: (state, action) => {
            state.executive = action.payload
        }
    }
})

export const {
    setExecutive,
    addItem,
    addItemCategory,
    removeItem,
    resetOrder,
    selectCategory ,
    selectSubcategory,
    selectItem,
    selectItemQtd,
    selectParam,
    selectParamQtd,
    selectClient,
    setDate,
    setClientName,
	setEstimatedTime1,
	setPaymentMethod1
} = orderSlice.actions

export default orderSlice.reducer