import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Button, ChakraProvider, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useDisclosure } from "@chakra-ui/react";
import Card from "components/card";
import CustomInput from "components/custom-input";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import ExpensesTable from "./ExpensesTable";
import { ClientType, FuncExpensesType, SelectType } from "types/app";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toastError } from "components/toasts/toastError";
import ExpensesDropdown from "./ExpensesDropdown";
import { BsPerson } from "react-icons/bs";
import Dropdown from "components/dropdown";
import Calendar from "react-calendar";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import dayjs, { Dayjs } from "dayjs";
import { IoAdd } from "react-icons/io5";
import { PiFloppyDisk, PiFloppyDiskBold, PiFloppyDiskFill } from "react-icons/pi";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import ClientsAutocomplete from "views/orcamentos/components/AutoComplete";
import Switch from "components/switch";
import { months } from "helpers/months";

interface CardType{
    id: string,
    maxH?: number,
    minH?: number,
    searchDate?: Date
}

const ExpensesCard = ({id, maxH, minH, searchDate}: CardType) => {
    const user = useAppSelector(selector => selector.user.user)
    const darkmode = useAppSelector(selector => selector.layout.darkMode)

    const [data, setData] = useState<FuncExpensesType[]>([])

    const { isOpen: isOpenNewExpense, onOpen: onOpenNewExpense, onClose: onCloseNewExpense } = useDisclosure()
    const { isOpen: isOpenEditExpense, onOpen: onOpenEditExpense, onClose: onCloseEditExpense } = useDisclosure()


    const [newExpenseData, setNewExpenseData] = useState<FuncExpensesType>({
        date: dayjs().toDate(),
        description: "",
		toSalary: false,
        value: 0,
        isRecurrent: false
    })

    const [editExpenseData, setEditExpenseData] = useState<FuncExpensesType>({
        id: 0,
        date: dayjs().toDate(),
		toSalary: false,
        description: "",
        value: 0,
        isRecurrent: false
    })

    const loadData = () => {
        api.get('/funcexpenses/list/'+id, {
            headers: {
                token: user?.token
            },
            params: {
                date: dayjs(searchDate).toISOString()
            }
        }).then(res=>{
            setData(res.data)
        }).catch(err=> {
            toastError(err, darkmode)
        })
    }

    useEffect(()=> {
        loadData()
    }, [searchDate])

    const resetValues = () => {
        setNewExpenseData({
            date: dayjs().toDate(),
            description: "",
			toSalary: false,
            value: 0,
            isRecurrent: false
        })

        setEditExpenseData({
            id: 0,
            date: dayjs().toDate(),
			toSalary: false,
            description: "",
            value: 0,
            isRecurrent: false
        })
    }

    const handleCancelCreateExpense = () => {
        onCloseNewExpense()
        resetValues()
    }

    const handleCancelEditExpense = () => {
        onCloseEditExpense()
        resetValues()
    }

    const setNewExpenseDate = (date: Date) => {
        setNewExpenseData({
            ...newExpenseData,
            date: date
        })
    }

    const handleCreateExpense = () => {
        api.post('/funcexpenses/new', {
            funcId: parseInt(id),
            description: newExpenseData.description,
            date: dayjs(newExpenseData.date).toISOString(),
            isRecurrent: newExpenseData.isRecurrent,
			toSalary: newExpenseData.toSalary,
            value: newExpenseData.value
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Comissão criada com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            handleCancelCreateExpense()
            loadData()
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const handleEditExpense = () => {
        api.put('/funcexpenses/edit/'+editExpenseData.id, {
            description: editExpenseData.description,
            date: dayjs(editExpenseData.date).toISOString(),
            isRecurrent: editExpenseData.isRecurrent,
			toSalary: editExpenseData.toSalary,
            value: editExpenseData.value,
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Despesa editado com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            handleCancelEditExpense()
            loadData()
        }).catch((error: ErrorData) => {
            if(error.response){
                toast(error.response.data.error, {
                    type: "error",
                    theme: darkmode ? "dark" : "light"
                })
            }else{
                toast("Erro indefinido", {
                    type: "error",
                    theme: darkmode ? "dark" : "light"
                })
            }
        })
    }

    const editData = (key: keyof typeof editExpenseData, value: any) => {
        setEditExpenseData({
            ...editExpenseData,
            [key]: value
        })
    }

    // useEffect(()=> {
    //     console.log(editExpenseData)
    // }, [editExpenseData])

    return(
        <>

            <ChakraProvider>
                <Modal isOpen={isOpenNewExpense} onClose={handleCancelCreateExpense} isCentered>
                    <ModalOverlay />
                    <ModalContent className="dark:bg-navy-800">
                        <ModalHeader className="dark:text-white">Registrar novo despesa</ModalHeader>
                        <ModalCloseButton className="dark:text-white" />
                        <ModalBody className="dark:text-white flex flex-col">
                            <div className="mt-3 flex items-center gap-3">
                                <Switch checked={newExpenseData.isRecurrent ? true : false} onChange={e=> {
                                    const _data = {...newExpenseData, isRecurrent: !newExpenseData.isRecurrent}

                                    setNewExpenseData(_data)
                                }} id="switch1" />
                                <label
                                    htmlFor="checkbox1"
                                    className="text-base font-medium text-navy-700 dark:text-white"
                                >
                                    Despesa recorrente?
                                </label>
                            </div>
                            <div className="mt-3 flex items-center gap-3">
                                <Switch checked={newExpenseData.toSalary ? true : false} onChange={e=> {
                                    const _data = {...newExpenseData, toSalary: !newExpenseData.toSalary}

                                    setNewExpenseData(_data)
                                }} id="switch1" />
                                <label
                                    htmlFor="checkbox1"
                                    className="text-base font-medium text-navy-700 dark:text-white"
                                >
                                    Adicionar ao Salário?
                                </label>
                            </div>
                            {!newExpenseData.isRecurrent &&
                                <FormControl className="w-full mt-2">
                                    <FormLabel className="!font-bold !text-sm !pl-2 dark:text-white">Data</FormLabel>
                                    <Dropdown
                                        button={
                                        <button
                                            type="button"
                                            className={`no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2`}
                                        >
                                            {newExpenseData.date ? dayjs(newExpenseData.date).format("DD/MM/YYYY") : "Clique para definir"}
                                        </button>
                                        }
                                        extraClassesWrapper="w-full h-10"
                                        extraClassesButton="w-full h-10"
                                        animation={"origin-top-right transition-all duration-300 ease-in-out"}
                                        classNames={`top-11 right-0 w-max `}
                                        children={
                                        <div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                            <Calendar
                                                value={newExpenseData.date}
                                                onClickDay={e=> setNewExpenseDate(e)}
                                                prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
                                                nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
                                                view={"month"}
                                            />
                                        </div>
                                        }
                                    />
                                </FormControl>
                            }

                            <FormControl className="w-full mt-2">
                                <FormLabel className="!font-bold !text-sm !pl-2 dark:text-white">Descrição</FormLabel>
                                <Textarea h={100} minH={100} maxH={100} resize={"block"} value={newExpenseData.description} onChange={e=>{
                                    setNewExpenseData({
                                        ...newExpenseData,
                                        description: e.target.value
                                    })
                                }} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
                            </FormControl>

                            <FormControl className="w-full mt-2">
                                <FormLabel className="!font-bold !text-sm !pl-2 dark:text-white">Valor</FormLabel>
                                <CustomInput containerClass="w-50" type="currency" inputClass="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" value={newExpenseData.value} setValue={(val: any)=>{
                                    setNewExpenseData({
                                        ...newExpenseData,
                                        value: val
                                    })
                                }} />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='red' mr={3} onClick={handleCancelCreateExpense}>
                                Cancelar
                            </Button>
                            <Button colorScheme='blue' mr={3} onClick={handleCreateExpense}>
                                Criar
                            </Button>
                            {/* <Button onClick={handleGeneratePDF} colorScheme="green" variant='solid'>Gerar</Button> */}
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Modal isOpen={isOpenEditExpense} onClose={handleCancelEditExpense} isCentered>
                    <ModalOverlay />
                    <ModalContent className="dark:bg-navy-800">
                        <ModalHeader className="dark:text-white">Editar despesa</ModalHeader>
                        <ModalCloseButton className="dark:text-white" />
                        <ModalBody className="dark:text-white flex flex-col">


                        	<div className="mt-3 flex items-center gap-3">
                                <Switch checked={editExpenseData.isRecurrent ? true : false} onChange={e=> {
                                    const _data = {...editExpenseData, isRecurrent: !editExpenseData.isRecurrent}

                                    setEditExpenseData(_data)
                                }} id="switch1" />
                                <label
                                    htmlFor="checkbox1"
                                    className="text-base font-medium text-navy-700 dark:text-white"
                                >
                                    Despesa recorrente?
                                </label>
                            </div>
                            <div className="mt-3 flex items-center gap-3">
                                <Switch checked={editExpenseData.toSalary ? true : false} onChange={e=> {
                                    const _data = {...editExpenseData, toSalary: !editExpenseData.toSalary}

                                    setEditExpenseData(_data)
                                }} id="switch1" />
                                <label
                                    htmlFor="checkbox1"
                                    className="text-base font-medium text-navy-700 dark:text-white"
                                >
                                    Adicionar ao Salário?
                                </label>
                            </div>
                            {!editExpenseData.isRecurrent &&
                                <FormControl className="w-full">
                                    <FormLabel className="!font-bold !text-sm !pl-2 dark:text-white">Data</FormLabel>
                                    <Dropdown
                                        button={
                                        <button
                                            type="button"
                                            className={`no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2`}
                                        >
                                            {editExpenseData.date ? dayjs(editExpenseData.date).format("DD/MM/YYYY") : "Clique para definir"}
                                        </button>
                                        }
                                        extraClassesWrapper="w-full h-10"
                                        extraClassesButton="w-full h-10"
                                        animation={"origin-top-right transition-all duration-300 ease-in-out"}
                                        classNames={`top-11 right-0 w-max `}
                                        children={
                                        <div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                            <Calendar
                                                value={dayjs(editExpenseData.date).toDate()}
                                                onClickDay={e=> editData("date", e)}
                                                prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
                                                nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
                                                view={"month"}
                                            />
                                        </div>
                                        }
                                    />
                                </FormControl>
                            }

                            <FormControl className="w-full mt-2">
                                <FormLabel className="!font-bold !text-sm !pl-2 dark:text-white">Descrição</FormLabel>
                                <Textarea h={100} minH={100} maxH={100} resize={"block"} value={editExpenseData.description} onChange={e=>{
                                    editData("description", e.target.value)
                                }} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
                            </FormControl>

                            <FormControl className="w-full mt-2">
                                <FormLabel className="!font-bold !text-sm !pl-2 dark:text-white">Valor</FormLabel>
                                <CustomInput containerClass="w-50" type="currency" inputClass="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" value={editExpenseData.value} setValue={(val: any)=>{
                                    editData("value", val)
                                }} />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='red' mr={3} onClick={handleCancelEditExpense}>
                                Cancelar
                            </Button>
                            <Button colorScheme='blue' mr={3} onClick={handleEditExpense}>
                                Salvar
                            </Button>
                            {/* <Button onClick={handleGeneratePDF} colorScheme="green" variant='solid'>Gerar</Button> */}
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ChakraProvider>

			<Accordion allowToggle>
				<AccordionItem>
					<AccordionButton className="flex items-center justify-between">
						<h2 className="text-lg font-bold text-navy-700 dark:text-white">
							Despesas
						</h2>
        				<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<Card extra="" noShadow>
							<div className="mb-auto text-start flex flex-col relative">
								<p className="mt-2 text-sm font-medium leading-4 text-gray-600">
									Mês referência: {months[dayjs(searchDate).month()]} de {dayjs(searchDate).year()}
								</p>
								<ExpensesDropdown className="!absolute top-0 right-0" menus={[
									{
										action: ()=> {
											onOpenNewExpense()
										},
										icon: <IoAdd />,
										text: "Adicionar Despesa",
										extraButtonClasses:"ml-auto text-white rounded !font-bold !hover:font-bold hover:text-white bg-blue-500 p-2"
									},
								]}/>
							</div>
							<div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap">

								<div className="h-full w-full mt-5">
									<ExpensesTable setEditExpense={setEditExpenseData} onOpenExpense={onOpenEditExpense} minH={minH} maxH={maxH} tableData={data} reloadTable={loadData} title="" loading={false}/>
								</div>
							</div>
						</Card>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
        </>
    )

}

export default ExpensesCard