import React from "react";
import Card from "components/card";
import { MdCancel, MdCheck } from "react-icons/md";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Tooltip } from "@chakra-ui/tooltip";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toast } from "react-toastify";
import { IoMdExit } from "react-icons/io";
import { ErrorData } from "types/fetch";

type RowObj = {
  id: number;
  clientName: string;
  date: string;
  price: number;
  funcName: string;
  awaitingApproval: boolean;
  discount: number,
  pretendDiscount: number,
  originalValue: number
};

const columnHelper = createColumnHelper<RowObj>();

const percentOption = {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

// const columns = columnsDataCheck;
export default function PendingDiscountsTable(props: { tableData: RowObj[], title?: string, refresh: () => void, loading?: boolean }) {
  const { tableData } = props;
  const user = useAppSelector(state=> state.user.user)
  const darkmode = useAppSelector(state=> state.layout.darkMode)
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [defaultData, setDefaultData] = React.useState([])
  const navigate = useNavigate()

  const handleAccept = (data: CellContext<RowObj, any>) => {
    api.put('/orders/approve/'+data.row.original.id, {

    }, {
      headers: {
        token: user?.token
      }
    }).then(()=> {
      props.refresh()
      toast("Desconto do orçamento "+data.row.original.id+" aprovado com sucesso", {
          type: "success",
          autoClose: 1000,
          theme: darkmode ? "dark" : "light"
      })
    }).catch((error: ErrorData) => {
      props.refresh()
      toast(error.response.data.error, {
          type: "error",
          theme: darkmode ? "dark" : "light"
      })
    })
  }

  const handleDeny = (data: CellContext<RowObj, any>) => {
    api.put('/orders/deny/'+data.row.original.id, {

    }, {
      headers: {
        token: user?.token
      }
    }).then(()=> {
      props.refresh()
      toast("Desconto do orçamento "+data.row.original.id+" reprovado", {
          type: "success",
          autoClose: 1000,
          theme: darkmode ? "dark" : "light"
      })
    }).catch((error: ErrorData) => {
      props.refresh()
      toast(error.response.data.error, {
          type: "error",
          theme: darkmode ? "dark" : "light"
      })
    })
  }

  const columns = [
    columnHelper.accessor("clientName", {
      id: "clientName",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">CLIENTE</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("funcName", {
      id: "funcName",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">CRIADO POR</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          DATA
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {dayjs(info.getValue()).format("DD/MM/YYYY HH:mm")}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("originalValue", {
      id: "originalValue",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          VALOR INICIAL
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {Intl.NumberFormat("BR", {
              currency: "BRL",
              style: "currency",

            }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("price", {
      id: "price",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          VALOR ATUAL
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {Intl.NumberFormat("BR", {
              currency: "BRL",
              style: "currency",

            }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor(null, {
      id: "pretendValue",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          VALOR PRETENDIDO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {Intl.NumberFormat("BR", {
              currency: "BRL",
              style: "currency",

            }).format(info.row.original.originalValue * (1 - (info.row.original.pretendDiscount / 100)))}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("pretendDiscount", {
      id: "pretendDiscount",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ATUAL/PRETENDIDO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {Intl.NumberFormat('pt-br', percentOption).format(info.row.original.discount / 100)} / {Intl.NumberFormat('pt-br', percentOption).format(info.row.original.pretendDiscount / 100)}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor(null, {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">AÇÃO</p>
      ),
      cell: (info) => (
        <p className="flex items-center gap-2 text-xs text-navy-700 dark:text-white">
          <Tooltip label="Aprovar" className="rounded" bg={"#000000e0"} color={"white"} rounded={"base"} p={5} placement="top">
            <button onClick={() => handleAccept(info)} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                <MdCheck />
            </button>
          </Tooltip>
          <Tooltip label="Negar" className="rounded" bg={"#000000e0"} color={"white"} rounded={"base"} p={5} placement="top">
            <button onClick={() => handleDeny(info)} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                <MdCancel />
            </button>
          </Tooltip>
        </p>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(()=>{
    setData(()=> [...tableData])
  }, [tableData])
  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          {props.title}
        </div>
      </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Não há nenhuma cotação aguardando aprovação...</h1> }
      </div>
    </Card>
  );
}
