const permissionsArray = [
	{id: 1, perm: "arquiteto_geral", text: "Permissão de Arquiteto Geral"},
	{id: 2, perm: "powerbi", text: "Acesso aos links de Power BI"},
	{id: 3, perm: "sizingehomolog", text: "Acesso aos links de Sizing e Homologação"},
	{id: 4, perm: "backoffice", text: "Acesso aos links de BackOffice"},
	{id: 5, perm: "crm_menu", text: "Acesso ao módulo de CRM"},
	{id: 13, perm: "pos_venda", text: "Acesso ao módulo de Pós Venda"},
	{id: 6, perm: "glpi_menu", text: "Acesso ao módulo do GLPI"},
	{id: 7, perm: "contracts_menu", text: "Acesso ao módulo de Contratos"},
	{id: 8, perm: "planilhas_menu", text: "Acesso ao módulo de Planilhas"},
	{id: 14, perm: "financeiro_menu", text: "Acesso ao módulo de Financeiro"},
	{id: 15, perm: "financeiro_admin", text: "Acesso de administrador ao módulo de Financeiro", req: "financeiro_menu"},
	{id: 9, perm: "planilha_rdv", text: "Acesso as planilhas de RDV", req: "financeiro_menu"},
	{id: 10, perm: "planilha_vendas", text: "Acesso as planilhas de Vendas", req: "planilhas_menu"},
	{id: 11, perm: "planilhas_arquiteto", text: "Permissão de Arquiteto no Módulo de Planilhas"},
	{id: 12, perm: "acesso_bi", text: "Permissão de acesso de gerente nas planilhas do BI"},
]

export default permissionsArray