import React, { useEffect, useState } from "react";
import Card from "components/card";
import { MdArrowLeft, MdArrowRight, MdDelete, MdEdit } from "react-icons/md";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Tooltip } from "@chakra-ui/tooltip";
import { useAppSelector } from "redux/hooks";
import { TicketsType } from "types/app";
import dayjs from "dayjs";

interface UserInTicketType {
  ["requesters"]: string[],
  ["techs"]: string[],
  ticket_id: number,
  ticket_status?: string,
  ticket_name?: string,
  created_date?: string,
  last_update?: string,
}

const maxPerPage = 25;
const columnHelper = createColumnHelper<UserInTicketType>();

// const columns = columnsDataCheck;
export default function TicketsTable(props: { tableData: UserInTicketType[], title?: string }) {
  const { tableData } = props;
  const darkmode = useAppSelector(state => state.layout.darkMode)
  const user = useAppSelector(state => state.user.user)
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [defaultData, setDefaultData] = React.useState([])
  const [openedImage, setOpenedImage] = React.useState(null)

  const [page, setPage] = useState(1)
  const [maxPages, setMaxPages] = useState(0)

  const columns = [
    columnHelper.accessor("ticket_id", {
      id: "ticket_id",
      header: () => (
        <p className="text-xs font-bold text-gray-600 dark:text-white">
          ID
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("created_date", {
      id: "created_date",
      header: () => (
        <p className="text-xs font-bold text-gray-600 dark:text-white">
          CRIAÇÃO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {dayjs(info.getValue()).add(3, "h").format("DD/MM/YYYY HH:mm")}
            {/* {info.row.original.created_date} */}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("ticket_name", {
      id: "ticket_name",
      header: () => (
        <p className="text-xs font-bold text-gray-600 dark:text-white">
          TÍTULO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("techs", {
      id: "tech",
      header: () => (
        <p className="text-xs font-bold text-gray-600 dark:text-white">TECNICOS</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white flex items-center gap-2">
          {info.getValue().map(tecnico=>(
            <span className="bg-blue-700 rounded-full p-1 px-2 text-white">{tecnico}</span>
          ))}
        </p>
      ),
    }),
    columnHelper.accessor("requesters", {
      id: "requesters",
      header: () => (
        <p className="text-xs font-bold text-gray-600 dark:text-white">
          SOLICITANTES
        </p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white flex items-center gap-1">
          {info.getValue().map(solicitante=>(
            <span className="bg-green-700 rounded-full p-1 px-2 text-white">{solicitante}</span>
          ))}
        </p>
      ),
    }),
    columnHelper.accessor("ticket_status", {
      id: "ticket_status",
      header: () => (
        <p className="text-xs font-bold text-gray-600 dark:text-white">
          STATUS
        </p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white flex items-center gap-2">
          {info.getValue()}
        </p>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,

    },
    onSortingChange: e=> {
      setSorting(e)
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: false,
  });

  React.useEffect(()=>{
    if(tableData){
      const _newData = [...tableData]
      setData(()=> _newData)
    }
  }, [tableData, page])

  return (
    <>
      <div className={`${openedImage ? 'w-[400px] md:w-[500px] h-[400px]' : 'w-0 h-0'} object-cover transition-all overflow-hidden duration-200 fixed center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[200] bg-white dark:bg-navy-900 border rounded dark:border-navy-800`}>
        <div className="h-[30px] flex">
          <button onClick={()=> setOpenedImage(null)} className="ml-auto mt-2 mr-2 text-lg dark:text-white">X</button>
        </div>
        <div className="h-[90%] w-full p-5">
          <img
            className="rounded h-full w-full"
            style={{
              objectFit: "scale-down"
            }}
            src={openedImage}
            alt={"User"}
          />
        </div>
      </div>
      <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
        <div className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {props.title}
          </div>
        </div>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="!border-px !border-gray-400">
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                      >
                        <div className="items-center gap-5 text-xs text-gray-200 flex">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "↓",
                            desc: "↑",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table
                .getRowModel()
                .rows
                .map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[150px] border-white/0 py-3  pr-4"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="md:flex items-center gap-2 ml-auto md:mr-0 mr-auto text-center">
          <button
            className="w-[40px] h-[40px] cursor-pointer linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className="w-[40px] h-[40px] cursor-pointer linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className="w-[40px] h-[40px] cursor-pointer linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="w-[40px] h-[40px] cursor-pointer linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className="md:ml-5 flex items-center gap-1 justify-center">
            <div>Página</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} de{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="flex items-center gap-1">
            | Ir para a página:
            <input
              type="number"
              style={{
                colorScheme: darkmode ? "dark" : "light"
              }}
              value={table.getState().pagination.pageIndex + 1}
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0

                if(page + 1 >= table.getPageCount()){
                  table.setPageIndex(table.getPageCount() - 1)
                }else if(page + 1 <= 0){
                  table.setPageIndex(0)
                }
                else{
                  table.setPageIndex(page)
                }
              }}
              className="h-[40px] w-[60px] cursor-pointer linear rounded bg-lightPrimary px-2 py-2 text-xs font-medium text-gray-900 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            className="md:ml-5 h-[40px] cursor-pointer linear rounded bg-lightPrimary px-2 py-2 text-xs font-medium text-gray-900 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[5, 10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize} linhas
              </option>
            ))}
          </select>
        </div>

        {/* <div className="mt-2 gap-6 flex items-center justify-center">
          <button disabled={page <= 1} onClick={() => setPage(page - 1)} className="linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
            <MdArrowLeft />
          </button>
          <span className="text-gray-900 dark:text-gray-200">
              {page}
          </span>
          <button disabled={page >= maxPages} onClick={() => setPage(page + 1)}  className="linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
            <MdArrowRight />
          </button>
        </div> */}
      </Card>
    </>
  );
}
