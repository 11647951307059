import React from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import { MdArrowCircleLeft, MdArrowLeft, MdArrowRight, MdCancel, MdCheckCircle, MdDelete, MdEdit, MdOutlineError } from "react-icons/md";

import {
	CellContext,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@chakra-ui/tooltip";
import { ContactType, UserType } from "types/app";
import api from "services/api";
import avatar from "assets/img/avatars/default-profile.png";
import Swal from "sweetalert2";
import { useAppSelector } from "redux/hooks";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import { Button, ChakraProvider, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import ReactInputMask from "react-input-mask";
import Switch from "components/switch";
import dayjs from "dayjs";
import { phoneMask, phoneMaskBuilder } from "masks/phoneMask";

const columnHelper = createColumnHelper<ContactType>();

// const columns = columnsDataCheck;
export default function ContactsTable(props: { handleEdit: (id: number) => void, tableData: ContactType[], reloadTable?: () => void, title?: string, loading?: boolean }) {
	const { tableData } = props;
	const darkmode = useAppSelector(state => state.layout.darkMode)
	const user = useAppSelector(state => state.user.user)
	const [sorting, setSorting] = React.useState<SortingState>([]);
	const [defaultData, setDefaultData] = React.useState([])
	const [openedImage, setOpenedImage] = React.useState(null)

	const [contactData, setContactData] = React.useState<ContactType>({
		id: 0,
		name: "",
		birthdate: dayjs(),
		clientId: null,
		createdAt: "",
		department: "",
		email: "",
		linkedin: "",
		role: "",
		whatsapp: null,
		assinaturaPadrao: false,
		testemunhaPadrao: false,
	})

	const navigate = useNavigate()

	const { isOpen: isOpenContact, onOpen: onOpenContact, onClose: onCloseContact } = useDisclosure();

	const resetData = () => {
		setContactData({
			id: 0,
			name: "",
			birthdate: dayjs(),
			clientId: null,
			createdAt: "",
			department: "",
			email: "",
			linkedin: "",
			role: "",
			whatsapp: null,
			assinaturaPadrao: false,
			testemunhaPadrao: false,
		})
	}

    const handleEditContact = () => {
        api.put('/contacts/edit/'+contactData.id, {
            ...contactData,
        }, {
            headers: {
                token: user?.token
            }
        }).then(() => {
            toast("Contato editado com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
			onCloseContact()
			props.reloadTable()
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

	const handleChangeInput = (key: keyof (ContactType), val: React.ChangeEvent<HTMLInputElement>) => {
		setContactData({ ...contactData, [key]: val.target.value })
	}
	const handleChangeValue = (key: keyof (ContactType), val: any) => {
		setContactData({ ...contactData, [key]: val })
	}

	const handleEdit = (data: CellContext<ContactType, any>) => {
		// console.log(data.row)
		resetData()
		api.get('/contacts/show/'+data.row.original.id, {
            headers: {
                token: user?.token
            }
        }).then((res: {data: ContactType})=> {
            setContactData(res.data)
			onOpenContact()
        }).catch((error) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
	}

	const handleDelete = (data: CellContext<ContactType, any>) => {
		Swal.fire({
			title: "Remover Contato",
			text: "Você realmente deseja remover o contato " + data.row.original.name + "?",
			icon: "question",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
			background: darkmode ? "rgb(11 20 55)" : "#fff",
			color: darkmode ? "#fff" : "#222",
		}).then(response => {
			if (response.isConfirmed) {
				api.delete('/contacts/delete/' + data.row.original.id, {
					headers: {
						token: user?.token
					}
				}).then(() => {
					props.reloadTable()
					toast("Contato removido com sucesso!", {
						type: "success",
						theme: darkmode ? "dark" : "light"
					})
				}).catch((error: ErrorData) => {
					// console.log(error)
					toast(error.response.data.error, {
						type: "error",
						theme: darkmode ? "dark" : "light"
					})
				})
			}
		})
	}

	const columns = [
		columnHelper.accessor("name", {
			id: "name",
			header: () => (
				<p className="text-sm font-bold text-gray-900 dark:text-white">NOME</p>
			),
			cell: (info) => (
				<p className="text-xs text-navy-700 dark:text-white">
					{info.getValue()}
				</p>
			),
		}),
		columnHelper.accessor("email", {
			id: "email",
			header: () => (
				<p className="text-sm font-bold text-gray-900 dark:text-white">
					Empresa
				</p>
			),
			cell: (info) => (
				<div className="flex items-center">
					<p className="text-xs text-navy-700 dark:text-white">
						{info.getValue() ? info.getValue() : "Sem dados"}
					</p>
				</div>
			),
		}),
		columnHelper.accessor("whatsapp", {
			id: "whatsapp",
			header: () => (
				<p className="text-sm font-bold text-gray-900 dark:text-white">
					Whatsapp
				</p>
			),
			cell: (info) => (
				<div className="flex items-center">
					<p className="text-xs text-navy-700 dark:text-white">
						{info.getValue() ? info.getValue() : "Sem dados"}
					</p>
				</div>
			),
		}),
		columnHelper.accessor("role", {
			id: "role",
			header: () => (
				<p className="text-sm font-bold text-gray-900 dark:text-white">
					CARGO
				</p>
			),
			cell: (info) => (
				<div className="flex items-center">
					<p className="text-xs text-navy-700 dark:text-white">
						{info.getValue() ? info.getValue() : "Sem cargo"}
					</p>
				</div>
			),
		}),
		columnHelper.accessor(null, {
			id: "action",
			header: () => (
				<p className="text-sm font-bold text-gray-900 dark:text-white">AÇÃO</p>
			),
			cell: (info) => (
				<p className="flex items-center gap-2 text-xs text-navy-700 dark:text-white">
					<Tooltip label="Editar" className="rounded" bg={"#000000e0"} color={"white"} rounded={"base"} p={5} placement="top">
						<button onClick={() => handleEdit(info)} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
							<MdEdit />
						</button>
					</Tooltip>
					<Tooltip label="Remover" className="rounded" bg={"#000000e0"} color={"white"} rounded={"base"} p={5} placement="top">
						<button onClick={() => handleDelete(info)} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
							<MdDelete />
						</button>
					</Tooltip>
				</p>
			),
		}),
	]; // eslint-disable-next-line
	const [data, setData] = React.useState(() => [...defaultData]);
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true,
	});

	React.useEffect(() => {
		setData(() => [...tableData])
	}, [tableData])
	return (
		<>

			<ChakraProvider>
				<Modal isOpen={isOpenContact} onClose={onCloseContact}>
					<ModalOverlay />
					<ModalContent className="dark:bg-navy-800">
						<ModalHeader className="dark:text-white">Editar Contato</ModalHeader>
						<ModalCloseButton className="dark:text-white" />
						<ModalBody className="dark:text-white">
							<FormControl className="w-full">
								<FormLabel className="text-gray-600 dark:text-white">Nome</FormLabel>
								<input value={contactData.name} onChange={e => handleChangeInput("name", e)} type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Nome do Contato" />
							</FormControl>
							<FormControl className="w-full mt-2">
								<FormLabel className="text-gray-600 dark:text-white">Email</FormLabel>
								<input value={contactData.email} onChange={e => handleChangeInput("email", e)} type="email" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="email@dominio.com" />
							</FormControl>

							<FormControl className="w-full mt-2">
								<FormLabel className="text-gray-600 dark:text-white">Telefone</FormLabel>
								<ReactInputMask mask={phoneMaskBuilder(contactData.whatsapp)} value={contactData.whatsapp} onChange={e => { handleChangeValue("whatsapp", phoneMask(e.target.value)) }} maskChar={null} type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="(99) 99999-9999" />
							</FormControl>

							<FormControl className="w-full mt-2">
								<FormLabel className="text-gray-600 dark:text-white">LinkedIn</FormLabel>
								<input value={contactData.linkedin} onChange={e => handleChangeInput("linkedin", e)} type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="https://br.linkedin.com/in/usuario" />
							</FormControl>
							<FormControl className="w-full mt-2">
								<FormLabel className="text-gray-600 dark:text-white">Data de Nascimento</FormLabel>
								<input value={dayjs(contactData.birthdate).format("YYYY-MM-DD")} style={{
									colorScheme: darkmode ? "dark" : "light",
								}} onChange={e => handleChangeInput("birthdate", e)} type="date" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
							</FormControl>
							<FormControl className="w-full mt-2">
								<FormLabel className="text-gray-600 dark:text-white">Cargo</FormLabel>
								<input value={contactData.role} onChange={e => handleChangeInput("role", e)} type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Cargo do Contato" />
							</FormControl>
							<FormControl className="w-full mt-2">
								<FormLabel className="text-gray-600 dark:text-white">Departamento</FormLabel>
								<input value={contactData.department} onChange={e => handleChangeInput("department", e)} type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Departamento de Trabalho" />
							</FormControl>

							<div className="mt-3 flex items-center gap-3">
								<Switch checked={contactData.assinaturaPadrao ? true : false} onChange={() => {
									setContactData({
										...contactData,
										["assinaturaPadrao"]: !contactData.assinaturaPadrao
									})
								}} id="switch1" />
								<label
									htmlFor="checkbox1"
									className="text-base font-medium text-navy-700 dark:text-white"
								>
									Por padrão, assina todos os documentos
								</label>
							</div>
							<div className="mt-3 flex items-center gap-3">
								<Switch checked={contactData.testemunhaPadrao ? true : false} onChange={() => {
									setContactData({
										...contactData,
										["testemunhaPadrao"]: !contactData.testemunhaPadrao
									})
								}} id="switch1" />
								<label
									htmlFor="checkbox1"
									className="text-base font-medium text-navy-700 dark:text-white"
								>
									Por padrão, é a testemunha de todos os documentos
								</label>
							</div>
						</ModalBody>

						<ModalFooter>
							<Button colorScheme='red' mr={3} onClick={onCloseContact}>
								Fechar
							</Button>
							<Button onClick={handleEditContact} colorScheme="green" variant='solid'>Salvar</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</ChakraProvider>
			<div className={`${openedImage ? 'w-[400px] md:w-[500px] h-[400px]' : 'w-0 h-0'} object-cover transition-all overflow-hidden duration-200 fixed center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[200] bg-white dark:bg-navy-900 border rounded dark:border-navy-800`}>
				<div className="h-[30px] flex">
					<button onClick={() => setOpenedImage(null)} className="ml-auto mt-2 mr-2 text-lg dark:text-white">X</button>
				</div>
				<div className="h-[90%] w-full p-5">
					<img
						className="rounded h-full w-full"
						style={{
							objectFit: "scale-down"
						}}
						src={openedImage}
						alt={"User"}
					/>
				</div>
			</div>
			<Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
				<div className="relative flex items-center justify-between pt-4">
					<div className="text-xl font-bold text-gray-900 dark:text-white">
						{props.title}
					</div>
				</div>

				<div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
					<table className="w-full">
						<thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<tr key={headerGroup.id} className="!border-px !border-gray-400">
									{headerGroup.headers.map((header) => {
										return (
											<th
												key={header.id}
												colSpan={header.colSpan}
												className="border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
											>
												<div className="items-center justify-between text-xs text-gray-200">
													{flexRender(
														header.column.columnDef.header,
														header.getContext()
													)}
													{{
														asc: "",
														desc: "",
													}[header.column.getIsSorted() as string] ?? null}
												</div>
											</th>
										);
									})}
								</tr>
							))}
						</thead>
						<tbody>
							{table
								.getRowModel()
								.rows
								.map((row) => {
									return (
										<tr key={row.id}>
											{row.getVisibleCells().map((cell) => {
												return (
													<td
														key={cell.id}
														className="min-w-[150px] border-white/0 py-3  pr-4"
													>
														{flexRender(
															cell.column.columnDef.cell,
															cell.getContext()
														)}
													</td>
												);
											})}
										</tr>
									);
								})}
						</tbody>
					</table>

					{props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Não há nenhum usuário cadastrado na plataforma...</h1>}
				</div>
			</Card>
		</>
	);
}
