import { ChakraProvider, SkeletonText } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

function DashboardContratosButtonWidget({icon, title, subtitle, loading, clickAction, highlighted}: {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  loading?: boolean;
  clickAction?: ()=> void;
  highlighted?: boolean;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <div onClick={clickAction} className={`!border-orange-500 border-b-[0px] ${highlighted ? "border-b-[5px]" : "mb-[5px]" } !z-5 relative flex flex-col rounded-[20px] bg-white shadow-lg shadow-shadow-600 !flex-row flex-grow items-center rounded-[20px] ${clickAction ? "cursor-pointer" : ""}`}>
		<div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
			<div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
				<span onClick={() => setOpen(false)} className="flex items-center text-orange-500 dark:text-white">
					{icon}
				</span>
			</div>
		</div>

		<div className="h-50 ml-2 flex w-auto flex-col justify-center">
			<p className="font-dm text-xs font-medium text-gray-600">{title}</p>
			<ChakraProvider>
				<SkeletonText isLoaded={!loading} p={0} noOfLines={1} skeletonHeight={5}>
					<h4 className="text-md font-bold text-navy-700 dark:text-white">
						{subtitle}
					</h4>
				</SkeletonText>
			</ChakraProvider>
		</div>
	</div>
  );
}

export default DashboardContratosButtonWidget;
