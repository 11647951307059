export const scopeTypes = {
  planejamento: "Planejamento",
  instalacaoFisica: "InstalacaoFisica",
  instalacaoLogica: "InstalacaoLogica",
  homologacao: "Homologacao",
  finalizacao: "Finalizacao",
}

export const scopeNames = {
    Planejamento: "Planejamento",
    InstalacaoFisica: "Instalação Física",
    InstalacaoLogica: "Instalação Lógica",
    Homologacao: "Homologação",
    Finalizacao: "Finalização",
}