import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { useNavigate, useParams } from "react-router-dom";
import { Role, UserType } from "types/app";


const EditRole = () => {
    const { id } = useParams()

    const [data, setData] = useState<Role>({
        id: 0,
        name: "",
        perc: 0,
    })
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const user = useAppSelector((state) => state.user.user)

    const navigate = useNavigate()

    useEffect(()=> {
        api.get("/role/"+id, {
            headers: {
                token: user?.token
            }
        }).then(res=> {
            setData(res.data)
        }).catch((error: ErrorData)=> {
            toast(error.response.data.error, {
                type: "error",
                theme: "light"
            })
        })
    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault();

        api.put('/role/'+id, {
            name: data.name,
            perc: data.perc
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Cargo editado com sucesso!", {
                type: "success",
                theme: "light"
            })
            navigate('/admin/users')
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: "light"
            })
        })

    }

    return(
        <>
            <form className="grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 mt-10 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Nome do cargo</FormLabel>
                    <Input value={data.name} onChange={e=> setData({...data, name: e.target.value})} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Cargo" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Percentual de acréscimo</FormLabel>
                    <Input value={data.perc} onChange={e=> setData({...data, perc: e.target.valueAsNumber})} type="number" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Acréscimo" />
                </FormControl>

                <button type="button" onClick={handleSubmit} className="linear h-10 mt-5 w-full md:w-30p xl:w-20p ml-auto flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-darkorange-400 dark:text-white dark:hover:bg-darkorange-300 dark:active:bg-darkorange-200">
                    Salvar
                </button>
            </form>
        </>
    )
}

export default EditRole