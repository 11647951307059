import React from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import { MdAdd, MdArrowCircleLeft, MdArrowLeft, MdArrowRight, MdCancel, MdCheckCircle, MdDelete, MdEdit, MdOutlineError, MdSave } from "react-icons/md";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@chakra-ui/tooltip";
import { ItemParam } from "types/app";
import Swal from "sweetalert2";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { toast } from "react-toastify";
import TooltipHorizon from "components/tooltip";
import MoneyInput from "components/fields/MoneyInput";

const columnHelper = createColumnHelper<ItemParam>();

// const columns = columnsDataCheck;
export default function ParamsTable(props: { tableData: ItemParam[], title?: string, page: number, loading?: boolean, refresh: () => void, itemId: number }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const darkmode = useAppSelector((state) => state.layout.darkMode)
  const user = useAppSelector((state) => state.user.user)
  const [defaultData, setDefaultData] = React.useState([])
  const navigate = useNavigate()

  const handleSave = (data: CellContext<ItemParam, any>) => {
    api.put("/orders/param/"+data.row.original.id, {
      name: data.row.original.name,
      price: data.row.original.price
    }, {
      headers: {
        token: user?.token
      }
    }).then(()=> {
      props.refresh();
      toast("Parâmetro editado com sucesso", {
          type: "success",
          theme: darkmode ? "dark" : "light"
      })
    }).catch((error: ErrorData)=>{
      toast(error.response.data.error, {
          type: "error",
          theme: darkmode ? "dark" : "light"
      })
    })
  }

  const handleAddParam = () => {
    api.post('/orders/param', {
      itemId: props.itemId
    }, {
      headers: {
        token: user?.token
      }
    }).then(res=> {
      props.refresh();
      toast("Parâmetro adicionado com sucesso", {
          type: "success",
          theme: darkmode ? "dark" : "light"
      })
    }).catch((error: ErrorData)=>{
        toast(error.response.data.error, {
            type: "error",
            theme: darkmode ? "dark" : "light"
        })
    })
  }

  const handleDelete = (data: CellContext<ItemParam, any>) => {
    Swal.fire({
      title: "Remover Parâmetro",
      text: "Você realmente deseja remover a página "+data.row.original.name+"?",
      icon: "question",
      confirmButtonText: "Sim",
      showCancelButton: true,
      cancelButtonText: "Não",
      background: darkmode ? "rgb(11 20 55)" : "#fff",
      color: darkmode ? "#fff" : "#222",
    }).then(response=> {
      if(response.isConfirmed){
        api.delete('/orders/param/'+data.row.original.id, {
          headers: {
            token: user?.token
          }
        }).then(()=>{
          props.refresh()
          toast("Parâmetro removido com sucesso!", {
              type: "success",
              theme: darkmode ? "dark" : "light"
          })
        }).catch((error: ErrorData)=>{
          toast(error.response.data.error, {
              type: "error",
              theme: darkmode ? "dark" : "light"
          })
      })
      }
    })
  }

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">NOME</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
            <input
                type={"text"}
                key={"NAME-"+info.row.original.id}
                name={"NAME-"+info.row.original.id}
                defaultValue={info.row.original.name}
                onChange={e=> info.row.original.name = e.target.value}
                placeholder={"Nome do parâmetro"}
                className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-xs text-navy-700 dark:text-white outline-none border-gray-300 "
                }`}
            />
        </p>
      ),
    }),
    columnHelper.accessor("price", {
      id: "price",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Preço
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            <MoneyInput
                variant="auth"
				extra=""
                inputClasses="text-xs text-navy-700 dark:text-white"
                placeholder="Valor do item"
                id="itemValue"
                defaultValue={info.getValue()}
                onChange={(e, name, values)=> info.row.original.price = values.float}
            />
          </p>
        </div>
      ),
    }),
    columnHelper.accessor(null, {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">AÇÃO</p>
      ),
      cell: (info) => (
        <p className="flex items-center gap-2 text-xs text-navy-700 dark:text-white">
          <Tooltip label="Salvar" className="rounded" bg={"#000000e0"} color={"white"} rounded={"base"} p={5} placement="top">
            <button onClick={()=> handleSave(info)} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                <MdSave />
            </button>
          </Tooltip>
          <Tooltip label="Remover" className="rounded" bg={"#000000e0"} color={"white"} rounded={"base"} p={5} placement="top">
            <button onClick={()=> handleDelete(info)} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                <MdDelete />
            </button>
          </Tooltip>
        </p>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(()=>{
    setData(()=> [...tableData])
  }, [tableData])
  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto shadow-xl shadow-shadow-600 border border-gray-200"} noShadow>
      <div className="relative flex items-center justify-between pt-4">
        <div className="flex w-full text-xl font-bold text-navy-700 dark:text-white">
          {props.title}
          <div className="ml-auto mt-2">
            <TooltipHorizon
                content={<span>Adicionar novo parâmetro</span>}
                trigger={<button type="button" onClick={()=> handleAddParam()} className="rounded-full bg-blue-500 dark:bg-orange-800 text-white p-2">
                    <MdAdd className="" size={12}/>
                </button>}
                extra=""
                placement="top"
            />
          </div>
        </div>
      </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>

        {props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Esse item não possui parâmetros...</h1> }
      </div>
    </Card>
  );
}
