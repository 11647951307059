import { MdDone, MdNewLabel } from "react-icons/md"
import ButtonWidget from "./components/ButtonWidget"
import { BsClockFill, BsTicketFill } from "react-icons/bs"
import { HiDocument } from "react-icons/hi"
import { IoClose } from "react-icons/io5"
import { useEffect, useState } from "react"
import { TicketsType } from "types/app"
import api from "services/api"
import { toastError } from "components/toasts/toastError"
import { useAppSelector } from "redux/hooks"
import TicketsTable from "./components/TicketsTable"
import RankingTecnicos from "./components/RankingTecnicos"
import SelectMasterDate from "./components/SelectMasterDate"
import dayjs from "dayjs"


const TIMEZONE = -3;
const GLPITickets = () => {
    const [ticketsData, setTicketsData] = useState<TicketsType>()
    const user = useAppSelector(selector => selector.user.user)
    const darkmode = useAppSelector(selector => selector.layout.darkMode)

    const [searchDate, setSearchDate] = useState(dayjs().startOf("month").toDate())
    const [dateMode, setDateMode] = useState("mensal")

    const getTicketsData = () => {
        api.get('/glpi/tickets', {
            headers: {
                token: user?.token
            },
            params: {
                start_date: dateMode == "mensal" ? dayjs(searchDate).startOf('month').subtract(-TIMEZONE,'h') : dayjs(searchDate).startOf("year").subtract(-TIMEZONE, 'h'),
                end_date: dateMode == "mensal" ? dayjs(searchDate).endOf('month').subtract(-TIMEZONE,'h') : dayjs(searchDate).endOf("year").subtract(-TIMEZONE, 'h'),
            }
        }).then(res=> {
            setTicketsData(res.data)
        }).catch(error => {
            toastError(error, darkmode)
        })
    }

    useEffect(()=> {
        getTicketsData();
    }, [searchDate])

    return(
        <div className="mt-2">
            <div className="ml-auto w-fit mb-3">
                <SelectMasterDate searchDate={searchDate} setSearchDate={setSearchDate} dateMode={dateMode} setDateMode={setDateMode} />
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5">
                <ButtonWidget
                    icon={<MdNewLabel className="h-7 w-7" />}
                    title={"Chamados Novos"}
                    subtitle={ticketsData?.tickets_info.novos ? ticketsData.tickets_info.novos.toString() : '0'}
                />
                <ButtonWidget
                    icon={<BsTicketFill className="h-7 w-7" />}
                    title={"Chamados em Andamento e Atribuídos"}
                    subtitle={ticketsData?.tickets_info.andamento_atribuidos ? ticketsData.tickets_info.andamento_atribuidos.toString() : '0'}
                />
                <ButtonWidget
                    icon={<HiDocument className="h-7 w-7" />}
                    title={"Chamados em Andamento e Planejados"}
                    subtitle={ticketsData?.tickets_info.andamento_planejados ? ticketsData.tickets_info.andamento_planejados.toString() : '0'}
                />
                <ButtonWidget
                    icon={<BsClockFill className="h-7 w-7" />}
                    title={"Chamados Pendentes"}
                    subtitle={ticketsData?.tickets_info.pendentes ? ticketsData.tickets_info.pendentes.toString() : '0'}
                />
                <ButtonWidget
                    icon={<MdDone className="h-7 w-7" />}
                    title={"Chamados Solucionados"}
                    subtitle={ticketsData?.tickets_info.solucionados ? ticketsData.tickets_info.solucionados.toString() : '0'}
                />
                <ButtonWidget
                    icon={<IoClose className="h-7 w-7" />}
                    title={"Chamados Finalizados"}
                    subtitle={ticketsData?.tickets_info.fechados ? ticketsData.tickets_info.fechados.toString() : '0'}
                />
            </div>
            <div className="mt-5">
                <RankingTecnicos date={''} className={""} rankingData={ticketsData?.ranking} />
            </div>
            <div className="mt-5">
                <TicketsTable title="Lista de Chamados" tableData={ticketsData?.users_in_tickets} />
            </div>
        </div>
    )
}

export default GLPITickets