import React, { useEffect, useState } from "react";

function ButtonWidget({icon, title, subtitle}: {
  icon: JSX.Element;
  title: string;
  subtitle: string;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none !flex-row flex-grow items-center rounded-[20px]">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
            <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span onClick={() => setOpen(false)} className="cursor-pointer flex items-center text-orange-500 dark:text-white">
                    {icon}
                </span>
            </div>
        </div>

        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {subtitle}
        </h4>
        </div>
    </div>
  );
}

export default ButtonWidget;
