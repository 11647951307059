import Card from "components/card";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, FormControl } from "@chakra-ui/react";
import CustomInputV3 from "components/custom-input-3";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import { PiFloppyDiskBold } from "react-icons/pi";
import { useState } from "react";
import { Operacao } from "types/app";

interface OperacoesCardType{
    id: string,
    maxH?: number,
    minH?: number,
	operacoes: Operacao[],
	userOperacoes: number[],
	setUserOperacoes: (e: number[]) => void
}

const OperacoesCard = ({id, maxH, minH, operacoes, userOperacoes, setUserOperacoes}: OperacoesCardType) => {
    const user = useAppSelector(selector => selector.user.user)
    const darkmode = useAppSelector(selector => selector.layout.darkMode)
	const [selectedOperacoes, setSelectedOperacoes] = useState<number[]>([])

	const handleSave = () => {
		api.post('/funcionarios/operacoes/' + id, {
			operacoes: userOperacoes
		}, {
			headers: {
				token: user?.token
			}
		}).then(() => {
			toast("Dados salvos com sucesso!", {
				type: "success",
				theme: darkmode ? "dark" : "light"
			})

		}).catch((error: ErrorData) => {
			toast(error.response.data.error, {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		})
	}

	const clickOperacao = (id: number) => {
		let _selectedOperacoes = [...selectedOperacoes]
		if(selectedOperacoes.includes(id)){
			_selectedOperacoes = _selectedOperacoes.filter(item => item!== id)
		}else{
			_selectedOperacoes.push(id)
		}
		setSelectedOperacoes(_selectedOperacoes)
	}

	const switchOperacoes = (ativar: boolean) => {
		let _userOperacoes: number[] = []
		if(userOperacoes){
			_userOperacoes = userOperacoes
		}
		if(ativar){
			selectedOperacoes.map(item=>{
				if(!_userOperacoes.includes(item)){
					_userOperacoes.push(item)
                }
			})
			setUserOperacoes(_userOperacoes)
			setSelectedOperacoes([])
		}else{
			console.log(selectedOperacoes)
			console.log(_userOperacoes)
			selectedOperacoes.map(item=>{
                if(_userOperacoes.includes(item)){
                    _userOperacoes = _userOperacoes.filter(uOp => uOp != item)
                }
            })
            setUserOperacoes(_userOperacoes)
            setSelectedOperacoes([])
		}
	}
    return(
        <>
			<Accordion allowToggle>
				<AccordionItem>
					<AccordionButton className="flex items-center justify-between">
						<h2 className="text-lg font-bold text-navy-700 dark:text-white">
							Operações
						</h2>
        				<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<Card extra="relative" noShadow>
							<div className="flex">
								<div className="mb-auto text-start flex flex-col relative">
									<p className="mt-2 text-sm font-medium leading-4 text-gray-600">
										Coloque do lado direito as operações desejadas para o funcionário
									</p>
								</div>
								<button onClick={handleSave} type="button" className="ml-auto flex items-center justify-center cursor-pointer text-lg w-[40px] min-w-[40px] min-h-[40px] h-[40px] rounded-full p-2 bg-orange-500 text-white dark:bg-orange-500">
									<PiFloppyDiskBold />
								</button>
							</div>
							<div className="flex gap-5 mt-2">
								<div className="border border-gray-500 rounded w-full" style={{
									height: maxH ? maxH : undefined,
									minHeight: maxH ? maxH : undefined,
								}}>
									<div className="h-5 flex justify-center bg-orange-500 text-white text-sm">Operações disponíveis</div>
									<div className="list-none flex flex-col overflow-auto h-full">
										{operacoes.filter(op=> !userOperacoes?.includes(op.id)).sort((a, b)=> {
											if(a.name > b.name){
                                                return 1
											}
											if(a.name < b.name){
                                                return -1
                                            }
											return 0
										}).map((operacao)=>(
											<span className={`px-1 text-sm cursor-pointer select-none ${selectedOperacoes?.includes(operacao.id) ? 'bg-orange-200' : ''}`} onClick={()=> clickOperacao(operacao.id)}>{operacao.name ? operacao.name.toUpperCase() : ''}</span>
										))}
									</div>
								</div>
								<div className="flex flex-col gap-2 items-center justify-center" style={{
									height: maxH ? maxH : undefined,
									minHeight: maxH ? maxH : undefined,
								}}>
									<button className="bg-orange-500 text-white h-10 text-xl font-bold w-[50px] rounded" onClick={()=> switchOperacoes(true)}>{'>'}</button>
									<button className="bg-orange-500 text-white h-10 text-xl font-bold w-[50px] rounded" onClick={() => switchOperacoes(false)}>{'<'}</button>
								</div>
								<div className="border border-gray-500 rounded w-full" style={{
									height: maxH ? maxH : undefined,
									minHeight: maxH ? maxH : undefined,
								}}>
									<div className="h-5 flex justify-center bg-orange-500 text-white text-sm">Operações designadas</div>
									<div className="list-none flex flex-col overflow-auto h-full">
										{operacoes.filter(op=> userOperacoes?.includes(op.id)).sort((a, b)=> {
											if(a.name > b.name){
                                                return 1
											}
											if(a.name < b.name){
                                                return -1
                                            }
											return 0
										}).map((operacao)=>(
											<span className={`px-1 text-sm cursor-pointer select-none ${selectedOperacoes?.includes(operacao.id) ? 'bg-orange-200' : ''}`} onClick={()=> clickOperacao(operacao.id)}>{operacao.name ? operacao.name.toUpperCase() : ''}</span>
										))}
									</div>
								</div>
							</div>
						</Card>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
        </>
    )

}

export default OperacoesCard