import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { resetOrder } from "redux/reducers/orderSlice"
import api from "services/api"
import { Role, UserType } from "types/app"
import { ErrorData } from "types/fetch"

interface IDPrice {
    id: number,
    price: number
}

const Cart = ({}) => {
    const order = useAppSelector((state) => state.order)
    const user = useAppSelector((state) => state.user.user)
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const dispatch = useAppDispatch()
    const [itemsPrice, setItemsPrice] = useState<IDPrice[]>()
    const [paramsPrice, setParamsPrice] = useState<IDPrice[]>()
    const [userAdd, setUserAdd] = useState(0)
	const [canGenerate, setCanGenerate] = useState(true)
    const navigate = useNavigate()

    const getItemsPrice = () => {
        api.get('/orders/items/prices', {
            headers: {
                token: user?.token
            }
        }).then((res: {data: IDPrice[]})=> {
            setItemsPrice(res.data)
        })
    }
    const getParamsPrice = () => {
        api.get('/orders/params/prices', {
            headers: {
                token: user?.token
            }
        }).then((res: {data: IDPrice[]})=> {
            setParamsPrice(res.data)
        })
    }
    const getUserAdd = () => {
        api.get("/users/self", {
            headers: {
                token: user?.token
            }
        }).then((res: {data: UserType})=> {
            if(res.data.role){
                setUserAdd(res.data.role.perc)
            }
        })
    }

    useEffect(()=> {
        getItemsPrice()
        getParamsPrice()
        getUserAdd()
    }, [])

    const calcCartPrice = (type: "item" | "param", id: number, qtd: number) => {
        if(type == "item"){
            const itemValue = itemsPrice?.find(item=>item.id == id)?.price || 0
            return itemValue * qtd * (1+(userAdd/100))
        }else{
            const paramValue = paramsPrice?.find(item=>item.id == id)?.price || 0
            return paramValue * qtd * (1+(userAdd/100))
        }
    }

    const calcTotalPrice = (): number => {
        var price = 0
        order.order.map(item=>{
            const type: "item" | "param" = item.paramName ? item.paramName != "" ? "param" : "item" : "item"
            if(type == "item") {
                const itemValue = itemsPrice?.find(_item=> _item.id == item.itemId)?.price || 0
                const totalValue = itemValue * item.itemQtd * (1+(userAdd/100))
                price += totalValue
            }else{
                const paramValue = paramsPrice?.find(_item=> _item.id == item.paramId)?.price || 0
                const totalValue = paramValue * item.paramQtd * (1+(userAdd/100))
                price += totalValue
            }
        })

        return price
    }

    const handleResetOrder = () => {
        dispatch(resetOrder({}))
    }

    const generateOrder = () => {
		setCanGenerate(false)
		if(order.estimatedTime1 == ""){
			toast("Preencha o Prazo de Entrega", {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
			setCanGenerate(true)
			return;
		}
		if(order.paymentMethod1 == ""){
			setCanGenerate(true)
			toast("Preencha a Condição de Pagamento", {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
			return;
		}
        api.post('/orders/new', {
            validUntil: order.validUntil,
            clientId: order.clientId,
			paymentMethod1: order.paymentMethod1,
			estimatedTime1: order.estimatedTime1,
            cart: order.order,
            executive: order.executive
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Orçamento gerado com sucesso", {
                type: "success",
                autoClose: 1000,
                theme: darkmode ? "dark" : "light"
            })
            dispatch(resetOrder({}))
            navigate("/admin/orcamentos")
        }).catch((error: ErrorData) => {
			setCanGenerate(true)
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    return(
        <div className="gap-5 flex shadow-2xl flex-col items-center w-[94%] mx-auto md:mx-0 lg:w-80p lg:ml-auto 3xl:w-60p justify-center mt-2 h-fit w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-4 border border-gray-200">
        {/* <div className="gap-5 flex flex-col items-center w-[94%] mx-auto md:mx-0 lg:w-[35%] lg:ml-auto 3xl:w-30p md:w-40p md:fixed md:right-[10px] md:top-[150px] lg:top-[200px] justify-center mt-2 h-fit w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2"> */}
            <h1 className="mt-5 font-bold text-navy-900 dark:text-white text-3xl text-center">Carrinho</h1>
            {/* <div className=" md:max-h-[20vh] overflow-y-auto mt-5 w-full flex flex-col gap-2"> */}
            <div className="mt-5 w-full flex flex-col gap-2">
                {order.order.filter(item=> item.itemQtd > 0 || item.paramQtd > 0).map((item, index)=>(
                    <div className="flex gap-5 justify-between" key={"CART-"+index}>
                        <span className="font-bold dark:text-white">{item.paramName ? item.paramName != "" ? item.paramQtd : item.itemQtd : item.itemQtd}x {item.categoryName ? item.categoryName : ""} {(item.paramName != null && item.paramName != undefined && item.paramName != "") ? item.paramName : item.itemName ? item.itemName : ""}</span>
                        <span className="font-bold dark:text-white">{Intl.NumberFormat("pt-br", {currency: "BRL", style: "currency"}).format(calcCartPrice((item.paramName != null && item.paramName != undefined) && item.paramName != "" ? "param" : "item", (item.paramName != null && item.paramName != undefined) && item.paramName != "" ? item.paramId : item.itemId, (item.paramName != null && item.paramName != undefined) && item.paramName != "" ? item.paramQtd : item.itemQtd))}</span>
                    </div>
                ))}
            </div>

            {/* TOTAL */}

            {order.order?.length > 0 &&
                <>
                    <div className="flex justify-between w-full">
                        <span className="mt-5 text-center font-bold dark:text-white">Total</span>
                        <span className="mt-5 text-center font-bold dark:text-white">{Intl.NumberFormat("pt-br", {currency: "BRL", style: "currency"}).format(calcTotalPrice())}</span>
                    </div>
                    {/* BUTTONS */}
                    <div className="mt-0 flex gap-2 flex-col column justify-center items-center">
                        <button disabled={!canGenerate} onClick={generateOrder} className="linear rounded-xl bg-orange-500 text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-navy-600 dark:text-white dark:hover:bg-navy-500 dark:active:bg-navy-700 p-2">Gerar orçamento</button>
                        <button onClick={handleResetOrder} className="linear rounded-xl bg-blue-500 text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-gray-900 dark:text-white dark:hover:bg-gray-700 dark:active:bg-gray-800 p-2">Reiniciar</button>
                    </div>
                </>
            }
        </div>
    )
}

export default Cart