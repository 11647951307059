import React, { useEffect, useRef, useState } from 'react'

interface CustomInputType{
    inputClass?: string,
    suffixClass?: string,
    containerClass?: string,
    value: number,
    setValue: React.Dispatch<React.SetStateAction<number>>,
    suffix?: string,
    max?: number,
    type?: "currency" | "normal" | "distance" | "number",
    totalWidth?: number,
    prefix?: string,
	disabled?: boolean,
	onBlur?: any
}
const CustomInputV2 = ({inputClass, suffixClass, value, setValue, suffix, prefix, max, containerClass, type, totalWidth, onBlur, disabled}: CustomInputType) => {
    const [formattedValue, setFormattedValue] = useState('0.00')
    const wrapperRef = useRef<HTMLDivElement>(null)

    const maskDistance = (val: string) => {
        let maskedVal = ''
        maskedVal = val.toString()
            // .replace(/\D/g, '')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
        return maskedVal
    }

    useEffect(()=> {
        if(type == 'currency'){
            setFormattedValue("R$ 0,00")
        }else if(type == "distance"){
            setFormattedValue("000")
        }
    }, [type])

    useEffect(()=> {
        if(value == undefined) return;
        if(type){
            if(type == "currency"){
                setFormattedValue(Intl.NumberFormat("pt-BR", {style: "currency", currency: "BRL"}).format(parseFloat((value || 0).toFixed(2))))
            }
            else if (type == "distance") {
                setFormattedValue(maskDistance((value || 0).toFixed(2)))
            }
            else{
                setFormattedValue(value.toFixed(2))
            }
        }else{
            // setFormattedValue(`${(value/100).toFixed(2)}`)
            setFormattedValue(value.toFixed(2))
        }
    }, [value, type])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        var newVal = e.target.value
        var canChange = true
        if(type == "currency"){
            newVal = newVal.replace(/\./gi, '').replace(/,/gi, '').replace(/[a-zA-Z$]/gi, '').replace(/%/gi, '')
        }
        if(type == "distance"){
            newVal = newVal.replace(/\./gi, '').replace(/,/gi, '').replace(/[a-zA-Z$]/gi, '').replace(/%/gi, '')
        }
        var toFloat = parseFloat(newVal.replace(/\./gi, '').replace(/,/gi, '.').replace(/[a-zA-Z$]/gi, '').replace(/%/gi, ''))
        if(max){
            if(toFloat > max*100){
                canChange = false
            }
        }

        if(isNaN(toFloat)){
            canChange = false
        }

        if(canChange){
            // if(type){
            //     if(type == "currency"){
            //         setFormattedValue(Intl.NumberFormat("pt-BR", {style: "currency", currency: "BRL"}).format(parseFloat((toFloat/100).toFixed(2))))
            //     }
            //     else{
            //         setFormattedValue(`${(toFloat/100).toFixed(2)}`)
            //     }
            // }else{
            //     setFormattedValue(`${(toFloat/100).toFixed(2)}`)
            // }
            setValue(parseFloat((toFloat/100).toFixed(2)))
        }
    }

    return(
        <>
            <div ref={wrapperRef} className={`group flex items-center w-fit h-fit ${containerClass ? containerClass : ''}`}>
                {prefix &&
                    <div className={` w-[40px] h-[40px] flex items-center justify-center text-sm cursor-default select-none ${suffixClass ? suffixClass : ''}`}>
                        {prefix}
                    </div>
                }
                <input disabled={disabled} value={formattedValue} onChange={handleChange} onBlur={onBlur} style={{
                    width: totalWidth ? totalWidth - 45 : 200
                }} className={`h-[40px] ${inputClass ? inputClass : ''} disabled:text-gray-700`} />
                {suffix &&
                    <div className={`w-[40px] [h-40px] mr-[5px] flex items-center justify-center text-xs cursor-default select-none ${suffixClass ? suffixClass : ''}`}>
                        {suffix}
                    </div>
                }
            </div>
        </>
    )
}

export default CustomInputV2