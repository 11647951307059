import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Button, ChakraProvider, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useDisclosure } from "@chakra-ui/react";
import Card from "components/card";
import CustomInput from "components/custom-input";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import RDVTable from "./RDVTable";
import { ClientType, UserRDVType, SelectType, CityType } from "types/app";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toastError } from "components/toasts/toastError";
import RDVDropdown from "./RDVDropdown";
import { BsPerson } from "react-icons/bs";
import Dropdown from "components/dropdown";
import Calendar from "react-calendar";
import { MdCheck, MdChevronLeft, MdChevronRight } from "react-icons/md";
import dayjs, { Dayjs } from "dayjs";
import { IoAdd } from "react-icons/io5";
import { PiFloppyDisk, PiFloppyDiskBold, PiFloppyDiskFill } from "react-icons/pi";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import ClientsAutocomplete from "views/orcamentos/components/AutoComplete";
import Switch from "components/switch";
import { months } from "helpers/months";
import { formatToCurrency } from "helpers/conversions";
import { RDVRowType } from "views/sheets/rdv";
import apiCeps from "services/apiCeps";
import configs from "variables/configs";

interface CardType{
    id: string,
    maxH?: number,
    minH?: number,
    searchDate?: Date
}

const RDVCard = ({id, maxH, minH, searchDate}: CardType) => {
    const user = useAppSelector(selector => selector.user.user)
    const darkmode = useAppSelector(selector => selector.layout.darkMode)

    const [data, setData] = useState<UserRDVType&{value: number}[]>([])

    const { isOpen: isOpenNewRDV, onOpen: onOpenNewRDV, onClose: onCloseNewRDV } = useDisclosure()
    const { isOpen: isOpenEditRDV, onOpen: onOpenEditRDV, onClose: onCloseEditRDV } = useDisclosure()


    const [newRDVData, setNewRDVData] = useState<RDVRowType>({})

    const [editRDVData, setEditRDVData] = useState<RDVRowType&{value?: number}>({})
	const [cities, setCities] = useState<SelectType[]>([])
    const [selectedImage, setSelectedImage] = useState<{
        id?: number | string,
        url?: string,
    }>({})
    
    const getCities = () => {
        apiCeps.get('/cidades').then((res: {data: CityType[]})=>{
            if(res){
                if(res.data){
                    let newCities: SelectType[] = []
                    res.data.map(city=>{
                        let newCity: SelectType = {value: city.id.toString(), content: city.nome}
                        newCities.push(newCity)
                    })
                    setCities([...newCities])
                }
            }
        }).catch(err=>{
            if(err){
                if(err.response){
                    toast(err.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                }
            }
        })
    }

    const loadData = () => {
        getCities()
        api.get('/funcrdv/list/'+id, {
            headers: {
                token: user?.token
            },
            params: {
                date: dayjs(searchDate).toISOString()
            }
        }).then(res=>{
            setData(res.data)
        }).catch(err=> {
            toastError(err, darkmode)
        })
    }

    useEffect(()=> {
        loadData()
    }, [searchDate])

	const payAll = () => {
		api.put('/funcrdv/setAllPending/'+id, {
			status: 1,
			date: dayjs(searchDate).toISOString()
		}, {
			headers: {
				token: user?.token
			}
		}).then(() => {
			toast("RDVs Marcado como Reembolsados!", {
				type: "success",
				theme: darkmode ? "dark" : "light"
			})
			loadData()
		}).catch((error: ErrorData) => {
			toast(error.response.data.error, {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		})
	}

    const resetValues = () => {
        setNewRDVData({
			data: dayjs().startOf("day").toISOString(),
			origem: "-1",
			km: 0,
			pedagio: 0,
			almoco: 0,
			estacionamento: 0,
        })

        setEditRDVData({
			data: dayjs().startOf("day").toISOString(),
			origem: "-1",
			km: 0,
			pedagio: 0,
			almoco: 0,
			estacionamento: 0,
        })
    }

    const handleCancelCreateRDV = () => {
        onCloseNewRDV()
        resetValues()
    }

    const handleCancelEditRDV = () => {
        onCloseEditRDV()
        resetValues()
    }

    // const handleCreateRDV = () => {
    //     api.post('/funcexpenses/new', {
    //         funcId: parseInt(id),
    //         description: newRDVData.description,
    //         date: dayjs(newRDVData.date).toISOString(),
    //         isRecurrent: newRDVData.isRecurrent,
	// 		toSalary: newRDVData.toSalary,
    //         value: newRDVData.value
    //     }, {
    //         headers: {
    //             token: user?.token
    //         }
    //     }).then(()=> {
    //         toast("Comissão criada com sucesso!", {
    //             type: "success",
    //             theme: darkmode ? "dark" : "light"
    //         })
    //         handleCancelCreateRDV()
    //         loadData()
    //     }).catch((error: ErrorData) => {
    //         toast(error.response.data.error, {
    //             type: "error",
    //             theme: darkmode ? "dark" : "light"
    //         })
    //     })
    // }

    const handleEditRDV = () => {
        api.put('/funcexpenses/edit/'+editRDVData.id, editData, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Gasto editado com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            handleCancelEditRDV()
            loadData()
        }).catch((error: ErrorData) => {
            if(error.response){
                toast(error.response.data.error, {
                    type: "error",
                    theme: darkmode ? "dark" : "light"
                })
            }else{
                toast("Erro indefinido", {
                    type: "error",
                    theme: darkmode ? "dark" : "light"
                })
            }
        })
    }

    const editData = (key: keyof typeof editRDVData, value: any) => {
        setEditRDVData({
            ...editRDVData,
            [key]: value
        })
    }

    // useEffect(()=> {
    //     console.log(editRDVData)
    // }, [editRDVData])

    return(
        <>
            
            <div className={`fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-[10000] invisible ${selectedImage.id != undefined ? "!visible" : ""}`}>
                <div className="absolute w-[100%] max-w-[1024px] h-[100%] max-h-[800px] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded overflow-hidden">
                    <button className="z-[2] text-red-500 font-bold absolute text-2xl top-2 right-2 w-[40px] h-[40px] bg-gray-500/50 hover:bg-gray-400/50 rounded" onClick={()=> {
                        setSelectedImage({})
                    }}>X</button>
                    <div className="">
                        <img src={selectedImage.url} alt={`ATTACH-${selectedImage.id}`} className="object-cover absolute top-0 bottom-0 left-0 right-0 h-full w-full z-[1]"/>
                    </div>
                </div>
            </div>
            <ChakraProvider>
                <Modal isOpen={isOpenEditRDV} onClose={handleCancelEditRDV} isCentered>
                    <ModalOverlay />
                    <ModalContent className="dark:bg-navy-800">
                        <ModalHeader className="dark:text-white">Detalhes Do RDV {dayjs(editRDVData.data).format("DD/MM/YYYY")}</ModalHeader>
                        <ModalCloseButton className="dark:text-white" />
                        <ModalBody className="dark:text-white flex flex-col gap-2">
							<p className="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-navy-700 p-2 rounded"><b>Data:</b> {dayjs(editRDVData.data).format("DD/MM/YYYY")}</p>
							<p className="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-navy-700 p-2 rounded"><b>Origem:</b> {editRDVData.origem}</p>
							<p className="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-navy-700 p-2 rounded"><b>Destino:</b> {cities.find(city=> city.value == editRDVData.destino)?.content || "Não encontrado"}</p>
							<p className="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-navy-700 p-2 rounded"><b>Cliente(s):</b> 
                            <div className="flex flex-col gap-1 w-[240px]">
                                {editRDVData?.rdvEmpresas?.map(empresa=> {
                                    return <span className="text-right" key={empresa.empresa.id + empresa.empresa.name}>
                                        {empresa.empresa.name}
                                    </span>
                                }) }
                            </div>
                            
                            </p>
							<p className="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-navy-700 p-2 rounded"><b>Estacionamento:</b>{formatToCurrency(editRDVData.estacionamento)}</p>
							<p className="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-navy-700 p-2 rounded"><b>Almoço:</b> {formatToCurrency(editRDVData.almoco)}</p>
							<p className="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-navy-700 p-2 rounded"><b>Pedágio:</b> {formatToCurrency(editRDVData.pedagio)}</p>
							<p className="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-navy-700 p-2 rounded"><b>Estorno KM Rodado:</b> {formatToCurrency(editRDVData.km * configs.kmValue)}</p>
							<p className="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-navy-700 p-2 rounded"><b>Total:</b> {formatToCurrency(editRDVData.value)}</p>
							<p className="flex items-center justify-between hover:bg-gray-100 dark:hover:bg-navy-700 p-2 rounded"><b>Anexos:</b></p>
                            <div className="flex gap-2">
                                {editRDVData?.rdvUploads?.map(attach => {
                                    return <img key={attach.fileName} src={api.defaults.baseURL + "/uploads/rdv/" + attach.fileName} alt={`Attach ${attach.id}`} className="cursor-pointer w-full h-[100px] object-cover" onClick={()=> {
                                        setSelectedImage({
                                            id: attach.id,
                                            url: api.defaults.baseURL + "/uploads/rdv/" + attach.fileName
                                        })
                                    }} />
                                })}
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='red' mr={3} onClick={handleCancelEditRDV}>
                                Fechar
                            </Button>
                            {/* <Button colorScheme='green' mr={3} onClick={handleEditRDV}>
                                Salvar
                            </Button> */}
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ChakraProvider>

			<Accordion allowToggle>
				<AccordionItem>
					<AccordionButton className="flex items-center justify-between">
						<h2 className="text-lg font-bold text-navy-700 dark:text-white">
							RDVs
						</h2>
        				<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<Card extra="" noShadow>
							<div className="mb-auto text-start flex flex-col relative">
								<p className="mt-2 text-sm font-medium leading-4 text-gray-600">
									Mês referência: {months[dayjs(searchDate).month()]} de {dayjs(searchDate).year()}
								</p>
								<RDVDropdown className="!absolute top-0 right-0" menus={[
									{
										action: ()=> {
											payAll()
										},
										icon: <MdCheck />,
										text: "Marcar todos os Pendentes como Reembolsado",
										extraButtonClasses:"ml-auto text-white rounded !font-bold !hover:font-bold hover:text-white bg-blue-500 p-2"
									},
								]}/>
							</div>
							<div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap">

								<div className="h-full w-full mt-5">
									<RDVTable setEditExpense={setEditRDVData} onOpenExpense={onOpenEditRDV} minH={minH} maxH={maxH} tableData={data} reloadTable={loadData} title="" loading={false}/>
								</div>
							</div>
						</Card>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
        </>
    )

}

export default RDVCard