import SideMenu from "components/sidemenu"
import { useEffect, useState } from "react"
import { NFs } from "types/app"
import { IoAdd } from "react-icons/io5"
import CustomInputV2 from "components/custom-input-2"
import { toastError, toastErrorMsg } from "components/toasts/toastError"
import api from "services/api"
import { useAppSelector } from "redux/hooks"
import { toast } from "react-toastify"
import NfsTable from "./NFsTable"
import dayjs from "dayjs"

interface INfsCard {
	loadData: () => void
    data: NFs[],
	loading: boolean
}

const NFsCard = ({loadData, data, loading}: INfsCard) => {
	const user = useAppSelector(selector=> selector.user.user)
	const [openNew, setOpenNew] = useState(false)

	const [loadingEdit, setLoadingEdit] = useState(true)
	const [editData, setEditData] = useState<NFs>({

	})

	const [openEdit, setOpenEdit] = useState(false)

	const handleLoadInfo = () => {

	}

	const resetEditService = () => {
		setEditData({})
	}

	const handleEditEditData = (key: keyof typeof editData, newVal: any) => {
		setEditData({
			...editData,
            [key]: newVal
		})
	}

	return (
		<>


			<div className="">
				<div className="flex justify-between mb-5">
					<h2 className="text-lg font-bold text-navy-700 dark:text-white">
						Últimas Notas Emitidas
					</h2>
				</div>

				<NfsTable loading={loading} setLoadingEditData={setLoadingEdit} setEditData={setEditData} setOpenEditData={setOpenEdit} reloadTable={loadData} tableData={data} />
			</div>
		</>
	)
}

export default NFsCard