import React, { useEffect, useRef, useState } from 'react'

interface CustomInputType{
    inputClass?: string,
    suffixClass?: string,
    containerClass?: string,
    value?: number,
    setValue: React.Dispatch<React.SetStateAction<number>>,
    suffix?: string,
    max?: number,
    type?: "currency" | "normal",
    prefix?: string,
	disabled?: boolean
}
const CustomInput = ({inputClass, suffixClass, value, setValue, suffix, prefix, max, containerClass, type, disabled}: CustomInputType) => {
    const [formattedValue, setFormattedValue] = useState('0.00')
    const wrapperRef = useRef<HTMLDivElement>(null)

    useEffect(()=> {
        if(value == undefined) return;
        if(type){
            if(type == "currency"){
                setFormattedValue(Intl.NumberFormat("pt-BR", {style: "currency", currency: "BRL"}).format(parseFloat((value || 0).toFixed(2))))
            }
            else{
                setFormattedValue(value.toFixed(2))
            }
        }else{
            // setFormattedValue(`${(value/100).toFixed(2)}`)
            setFormattedValue(value.toFixed(2))
        }
    }, [value, type])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        var newVal = e.target.value
        var canChange = true
        if(type == "currency"){
            newVal = newVal.replace(/\./gi, '').replace(/,/gi, '').replace(/[a-zA-Z$]/gi, '').replace(/%/gi, '')
        }
        var toFloat = parseFloat(newVal.replace(/\./gi, '').replace(/,/gi, '.').replace(/[a-zA-Z$]/gi, '').replace(/%/gi, ''))
        if(max){
            if(toFloat > max*100){
                canChange = false
            }
        }

        if(isNaN(toFloat)){
            canChange = false
        }

        if(canChange){
            // if(type){
            //     if(type == "currency"){
            //         setFormattedValue(Intl.NumberFormat("pt-BR", {style: "currency", currency: "BRL"}).format(parseFloat((toFloat/100).toFixed(2))))
            //     }
            //     else{
            //         setFormattedValue(`${(toFloat/100).toFixed(2)}`)
            //     }
            // }else{
            //     setFormattedValue(`${(toFloat/100).toFixed(2)}`)
            // }
            setValue(parseFloat((toFloat/100).toFixed(2)))
        }
    }

    return(
        <>
            <div ref={wrapperRef} className={`relative w-fit h-fit ${containerClass ? containerClass : ''}`}>
                {prefix &&
                    <div className={`absolute top-0 bottom-0 left-0 w-[20px] flex items-center justify-center text-xl p-5 cursor-default select-none ${suffixClass ? suffixClass : ''}`}>
                        {prefix}
                    </div>
                }
                <input value={formattedValue} onChange={handleChange} className={`w-[200px] h-[40px] ${inputClass ? inputClass : ''}`} disabled={disabled}/>
                {suffix &&
                    <div className={`absolute top-0 bottom-0 right-0 w-[20px] flex items-center justify-center text-xl p-5 cursor-default select-none ${suffixClass ? suffixClass : ''}`}>
                        {suffix}
                    </div>
                }
            </div>
        </>
    )
}

export default CustomInput