import { FormControl } from "@chakra-ui/react"
import Card from "components/card"
import CustomInputV3 from "components/custom-input-3"
import { toastError } from "components/toasts/toastError"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useAppSelector } from "redux/hooks"
import api from "services/api"

interface PageType{
	[key: string]: {
		valueNum: number,
		valueStr: string
	}
}

const ComissoesGlobais = () => {
	const user = useAppSelector(selector=> selector.user.user)
	const darkmode = useAppSelector(selector=> selector.layout.darkMode)
	const [data, setData] = useState<PageType>({})

	useEffect(()=> {
		loadData()
	}, [])

	const loadData = () => {
        api.post('configs/get', {
			configs: "pre_vendas_normal,pre_vendas_meta,arquiteto_normal,arquiteto_meta,participante_normal,participante_meta"
		}, {
            headers: {
                token: user?.token
            }
        }).then((res: {data: PageType})=> {
			console.log(res.data)
			setData(res.data)
        }).catch(err=> {
            toastError(err, darkmode)
        })
	}

	const handleChangeValue = (key: string, valueStr?: string | undefined | null , valueNum?: number | undefined | null) => {
		let _data = data
		if(!_data[key]){
			_data[key] = {
				valueNum: 0,
				valueStr: ''
			}
		}

		if(valueNum != undefined && valueNum != null) _data[key].valueNum = valueNum
		if(valueStr != undefined && valueStr != null) _data[key].valueStr = valueStr

		setData({..._data})
	}

	const handleSave = () => {
        api.post('configs/save', {
			...data
		}, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
			toast("Configurações salvas com sucesso!", {
				type: "success",
				theme: darkmode ? "dark" : "light"
			})
        }).catch(err=> {
            toastError(err, darkmode)
        })
	}

	return(
		<Card extra="mt-5 p-5 relative">
			<div onClick={handleSave} className="absolute top-5 right-5 p-2 px-3 bg-orange-500 text-white text-sm ml-auto rounded-full cursor-pointer hover:bg-orange-600 transition">
				Salvar
			</div>
			<h2 className="text-lg font-bold text-navy-700 dark:text-white">
				Comissões Globais
			</h2>
			<div className="ml-5 flex flex-col gap-2 mt-5">
				<div className="flex flex-col gap-2 mt-5">
					<h3 className="text-md text-navy-700 font-bold">Comissões como Pré Vendas</h3>
					<div className="flex gap-5 ml-10">
						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Comissão sem bater meta:
								</p>

								<FormControl className="w-full flex items-center gap-2 w-full">
									<CustomInputV3
										suffixClass="dark:text-white absolute right-0"
										containerClass="w-full relative"
										inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] !w-full p-2"
										value={data.pre_vendas_normal?.valueNum} setValue={e=> handleChangeValue('pre_vendas_normal', undefined, e)} max={100} suffix="%"/>
								</FormControl>
							</div>
						</FormControl>
						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Comissão com meta batida:
								</p>
								<FormControl className="w-full flex items-center gap-2 w-full">
									<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 !w-full p-2" value={data.pre_vendas_meta?.valueNum} setValue={e=> handleChangeValue('pre_vendas_meta', undefined, e)} max={100} suffix="%"/>
								</FormControl>
							</div>
						</FormControl>
					</div>
				</div>

				<div className="flex flex-col gap-2 mt-5">
					<h3 className="text-md text-navy-700 font-bold">Comissões como Arquiteto</h3>
					<div className="flex gap-5 ml-10">
						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Comissão sem bater meta:
								</p>

								<FormControl className="w-full flex items-center gap-2 w-full">
									<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] !w-full p-2" value={data.arquiteto_normal?.valueNum} setValue={e=> handleChangeValue('arquiteto_normal', undefined, e)} max={100} suffix="%"/>
								</FormControl>
							</div>
						</FormControl>
						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Comissão com meta batida:
								</p>
								<FormControl className="w-full flex items-center gap-2 w-full">
									<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 !w-full p-2" value={data.arquiteto_meta?.valueNum} setValue={e=> handleChangeValue('arquiteto_meta', undefined, e)} max={100} suffix="%"/>
								</FormControl>
							</div>
						</FormControl>
					</div>
				</div>

				<div className="flex flex-col gap-2 mt-5">
					<h3 className="text-md text-navy-700 font-bold">Comissões como Participante</h3>
					<div className="flex gap-5 ml-10">
						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Comissão sem bater meta:
								</p>

								<FormControl className="w-full flex items-center gap-2 w-full">
									<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] !w-full p-2" value={data.participante_normal?.valueNum} setValue={e=> handleChangeValue('participante_normal', undefined, e)} max={100} suffix="%"/>
								</FormControl>
							</div>
						</FormControl>
						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Comissão com meta batida:
								</p>

								<FormControl className="w-full flex items-center gap-2 w-full">
									<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] !w-full p-2" value={data.participante_meta?.valueNum} setValue={e=> handleChangeValue('participante_meta', undefined, e)} max={100} suffix="%"/>
								</FormControl>
							</div>
						</FormControl>
					</div>
				</div>
			</div>
		</Card>
	)
}

export default ComissoesGlobais