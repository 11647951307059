import Card from "components/card";
import { useEffect, useMemo, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toastError } from "components/toasts/toastError";
import { ErrorData } from "types/fetch";
import dayjs from "dayjs";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { formatToCurrency } from "helpers/conversions";
import { ChakraProvider, Skeleton } from "@chakra-ui/react";

interface ChartType{
	name: string,
	data: number[],
}

const VendasXFabricante = ({ from, to, selectedFuncs, selectedOperacoes, fabricantes, pageLoading }: {
	from?: Date,
	to?: Date,
	searchDate?: Date,
	selectedFuncs: any,
	selectedOperacoes: any,
	fabricantes: {id: number, nome: string}[],
	pageLoading?: boolean
}) => {
	const user = useAppSelector(selector => selector.user.user)
	const darkmode = useAppSelector(selector => selector.layout.darkMode)
	const [loading, setLoading] = useState(true)
	const [labels, setLabels] = useState(['Dell', 'Veeam', 'Fortinet'])
	const chartOptions = useMemo<any>(()=> ({
		chart: {
			width: 380,
			type: 'donut',
			toolbar: {
				show: true
			}
		},
		labels: labels,
		plotOptions: {
			pie: {
				startAngle: -90,
				endAngle: 270
			}
		},
		dataLabels: {
			enabled: true
		},
		fill: {
			type: 'gradient',
		},
		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (val: number) {
					const formatted = formatToCurrency(val);
					return formatted;
				},
			},
		},
		responsive: [{
			breakpoint: 480,
			options: {
				chart: {
				width: 200
				},
				legend: {
					position: 'bottom'
				}
			}
		}]
	}), [labels])

	const [chartData, setChartData] = useState<number[]>([2500,10000,12000])



	useEffect(()=> {
		let _new: string[] = []
		fabricantes.map(f=>{
			_new.push(f.nome)
		})
		setLabels(_new)
	}, [fabricantes])

	useEffect(()=> {
		getData()
	}, [from, to, selectedFuncs, selectedOperacoes])

	const getData = () => {
		setLoading(true)
		api.get("/financeiro/dashboard/relatoriovendasfabricante", {
			params: {
				start: from,
				end: to,
				funcs: JSON.stringify(selectedFuncs),
				operacoes: JSON.stringify(selectedOperacoes)
			},
			headers: {
				token: user?.token
			}
		}).then(res => {
			if(res){
				if(res.data){
					setChartData(res.data)
				}
			}
			setLoading(false)
		}).catch(err=>{
			toastError(err, false)
		})
	}


	return (
		<Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center !shadow-shadow-500">
			<ChakraProvider>
				<Skeleton isLoaded={!pageLoading} startColor="white" endColor="gray.300" className="py-6 px-2 !rounded-3xl">
					<div className="mb-auto flex items-center justify-between px-6">
						<h4 className="text-md font-bold text-navy-700 dark:text-white">
							Vendas X Fabricante
						</h4>
					</div>

					<div className="md:mt-16 lg:mt-0">
						<div className="h-[250px] w-full xl:h-[350px]">
							<Chart
								options={chartOptions}
								series={chartData as any}
								type="donut"
								width="100%"
								height="100%"
							/>
						</div>
					</div>
				</Skeleton>
			</ChakraProvider>
		</Card>
	);
};

export default VendasXFabricante;
