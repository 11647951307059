import ReactApexChart from "react-apexcharts";

type ChartState = {
  chartData: any[];
  chartOptions: any;
};

const LineChart = (props: ChartState) => {

  return (
    <ReactApexChart
      options={props.chartOptions}
      series={props.chartData}
      type="line"
      width="100%"
      height="100%"
    />
  );
}

export default LineChart;
