import { Page, Text, View, Document, StyleSheet, Font, Image, Link } from '@react-pdf/renderer';
import Logo from '../../../../assets/img/layout/Logo.png'
import Arial from '../../../../assets/fonts/arial.ttf'
import ArialBold from '../../../../assets/fonts/arial-bold.ttf'

import Facebook from '../../../../assets/img/logos/fb.png'
import XLogo from '../../../../assets/img/logos/x.png'
import Linkedin from '../../../../assets/img/logos/linkedin.png'
import Insta from '../../../../assets/img/logos/insta.png'
import Nuvem from '../../../../assets/img/logos/Nuvem_Preta.png'

import { Html } from 'react-pdf-html';
import { alfabeto } from 'helpers/dictionary';


interface ContractDocumentType {
	name: string
}

Font.register({ family: 'Arial', fonts: [
	{ src: Arial }, // font-style: normal, font-weight: normal
	{ src: ArialBold, fontWeight: "bold" },
]});

const socials: {img: string, link: any, cover?: boolean}[] = [
    {
        img: Facebook,
        link: "https://www.facebook.com/proitcloudsolutions/"
    },
    // {
    //     img: XLogo,
    //     link: undefined
    // },
    {
        img: Linkedin,
        link: "https://br.linkedin.com/company/pro-it-cloud-solutions"
    },
    {
        img: Insta,
        link: "https://www.instagram.com/proitcloudsolutions/",
        cover: true
    },
    {
        img: Nuvem,
        link: "https://www.proit.com.br",
        cover: true
    },
]

const unidades = [
    "São Paulo",
    "Campinas",
    "São José do Rio Preto",
    "Bauru",
]

// Create styles
const styles = StyleSheet.create({
  page: {
	fontFamily: "Arial",
    flexDirection: 'column',
    backgroundColor: '#fff',
	paddingTop: 80,
	paddingBottom: 80,
	paddingLeft: 40,
	paddingRight: 40,
	fontSize: 12
  },
  section: {
    flexGrow: 1,
	textAlign: "justify"
  },
  title: {
	fontSize: 13,
	fontWeight: "bold",
	textAlign: 'center',
	marginBottom: 10
  },
  subtitle: {
	fontSize: 12,
	fontWeight: "bold",
	marginTop: 10,
	marginBottom: 2,
	textTransform: "uppercase"
  },
  text: {
	textAlign: 'justify',
	fontSize: 12,
  },
  bold: {
	fontWeight: "bold"
  },

  header: {
	position: "absolute",
	top: 0,
	left: 0,
	right: 0
  },
  footer: {
	position: "absolute",
	bottom: 0,
	left: 95,
	transform: "translateX(-50%)",
	width: 500,
	height: 85,
	borderTop: "1px solid black",
	paddingTop: 10,
	display: "flex",
	flexDirection: "column",
	alignContent: "center",
	textAlign: "center"
  },

  logo: {
	position: 'absolute',
	width: 95,
	height: 40,
	top: 20,
	right: 40
  },

  address: {
	fontSize: 12
  },
  socialBox: {
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "center",
	gap: 5
  },
  socials: {
	width: 24,
	height: 24
  }
});

const clausulas: {
	name: string,
	text: string,
	paragrafos?: {
		name: string,
		text: string,
		enumType?: "number" | "letter",
		enums?: {
			text: string,
		}[]
	}[],
	enumType?: "number" | "letter",
	enums?: {
		text: string,
	}[]
}[] = [
	{
		name: 'CLÁUSULA PRIMEIRA – OBJETO:',
		text: 'A <b>contratada</b> empresa especialista em consultoria e vendas de soluções e serviços em tecnologia.',
		paragrafos: [
			{name: "Parágrafo Primeiro", text: "A <b>contratada</b> prestará os serviços constantes na Cláusula Segunda deste contrato sem qualquer exclusividade, podendo desempenhar atividades para terceiros em geral."},
			{name: "PARÁGRAFO SEGUNDO", text: "Os serviços serão prestados com total autonomia, liberdade de horário, sem pessoalidade e sem qualquer subordinação ao <b>contratante</b>, que deverá aprovar previamente quaisquer alterações de processos, sistemas e contratações vinculadas à prestação dos serviços. Poderão ser prestados de forma presencial ou remota, mediante a contraprestação ajustada pelas Partes."},
		]
	},
	{
		name: 'CLÁUSULA SEGUNDA – SERVIÇOS:',
		text: 'Os serviços a serem prestados pela <b>contratada</b>, através de seus empregados/prepostos, sob sua única e exclusiva responsabilidade são:',
		enumType: "number",
		enums: [
			{text: "<b>Serviço remoto de implementação de Firewall.</b>"}
		],
		paragrafos: [
			{
				name: "Parágrafo Primeiro",
				text: "Não está incluso nos serviços acima:",
				enumType: "number",
				enums: [
					{text: "Troca de peças dos equipamentos do estabelecimento do <b>contratante</b>, pois o mesmo deverá ser realizado através de solicitação formal da <b>contratante</b> ao respectivo fabricante;"},
					{text: "Suporte técnico de nenhum tipo à equipamentos de proteção elétrica do datacenter (nobreaks, geradores, etc) do estabelecimento;"},
				]
			},
			{
				name: "Parágrafo Primeiro",
				text: "Não está incluso nos serviços acima:",
				enumType: "letter",
				enums: [
					{text: "Troca de peças dos equipamentos do estabelecimento do <b>contratante</b>, pois o mesmo deverá ser realizado através de solicitação formal da <b>contratante</b> ao respectivo fabricante;"},
					{text: "Suporte técnico de nenhum tipo à equipamentos de proteção elétrica do datacenter (nobreaks, geradores, etc) do estabelecimento;"},
				]
			},
		]
	},
]

// Create Document Component
const ContractDocument = ({name} : ContractDocumentType) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header} fixed>
        <Image src={Logo} style={styles.logo}/>
      </View>

	  <View style={styles.section}> {/*  CONTENT  */}
		<Text style={styles.title}>CONTRATO PARTICULAR DE PRESTAÇÃO DE SERVIÇOS PONTUAIS</Text>

		<Text style={styles.text}>
			Pelo presente instrumento particular, de um lado <Text style={styles.bold}>PRO IT CLOUD SOLUTIONS INFORMÁTICA LTDA</Text>, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº
			29.959.965/0001-98, com sede na Avenida Juscelino Kubsticheck de Oliveira, número 5000, Sala 302, Condomínio Iguatemi Business, CEP 15093-340, cidade de
			São José do Rio Preto /SP neste ato representado na forma prevista em seu Contrato Social, por Sérgio Henrique de Amorim Romeiro, doravante denominada
			simplesmente de <Text style={styles.bold}>contratada</Text>.
		</Text>
		<Text style={styles.text}>
			E de outro lado <Text style={styles.bold}>XXXXXXXXXXXXXXXXXX</Text>, pessoa jurídica de direito privado inscrita no CNPJ sob o nº
			XXXXXX, com sede na XXXXXXXXXX, bairro XXXXXXXX, CEP XXXXXXXXXX, cidade de
			XXXXXXXXXXXX, neste ato representada pelo(a) senhor(a) XXXXXXXX doravante denominado <Text style={styles.bold}>contratante</Text>.
			Tem entre si, justo e <Text style={styles.bold}>contratada</Text> o presente, que se regerá pelas seguintes Cláusulas e Condições:
		</Text>
		{clausulas.map(clausula=>(
			<>
				<Text style={styles.subtitle}>{clausula.name}</Text>
				<Html style={styles.text}>{clausula.text}</Html>
				{clausula.paragrafos?.map(paragrafo=>(
					<>
						<Text style={styles.subtitle}>{paragrafo.name}</Text>
						<Html style={styles.text}>{paragrafo.text}</Html>
						{paragrafo.enumType && paragrafo.enumType == "number" ?
							paragrafo.enums?.map((_enum, index) => (
								<View style={{flexDirection: 'row', marginRight: 35, paddingBottom: 5}}>
									<Text style={[styles.text, styles.bold, {paddingLeft: 20, paddingRight: 10}]}>{index+1}</Text>
									<Html style={styles.text}>{_enum?.text}</Html>
								</View>
							))
						:
							paragrafo.enums?.map((_enum, index) => (
								<View style={{flexDirection: 'row', marginRight: 35, paddingBottom: 5}}>
									<Text style={[styles.text, styles.bold, {paddingLeft: 20, paddingRight: 10}]}>{alfabeto[index]})</Text>
									<Html style={styles.text}>{_enum?.text}</Html>
								</View>
							))
						}
					</>
				))}
			</>
		))}
	  </View>

      <View style={styles.footer} fixed>
        <Text style={styles.address}>{unidades.join(" | ")}</Text>
		<View style={styles.socialBox}>
			{socials.map(social=> (
				<Link src={social.link}>
					<Image style={[styles.socials, social.cover ? {padding: 5} : undefined]} src={social.img} />
				</Link>
			))}
		</View>
        {/* <Text style={styles.address}>www.proit.com.br</Text> */}
      </View>
    </Page>
  </Document>
);

export default ContractDocument