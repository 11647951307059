import { useEffect, useState } from "react"
import { MdBarChart, MdChevronLeft, MdChevronRight, MdNumbers } from "react-icons/md"
import { CityType, ClientType, ConfigDataType, EstadoEntregaType, IUserOperacoes, Operacao, SaleSheetType, SelectType, SelectTypePhoto } from "types/app"
import api from "services/api"
import { toast } from "react-toastify"
import { useAppSelector } from "redux/hooks"
import Dropdown from "components/dropdown"
import dayjs from "dayjs"
import Calendar from "react-calendar"
import apiCeps from "services/apiCeps"
import { toastError, toastErrorMsg } from "components/toasts/toastError"
import MultiSelect, { useSelectItems } from "components/multi-select"

import '@inovua/reactdatagrid-community/index.css'

import DashboardFinanceiroButtonWidget from "views/dashboardvendas/components/DashboardVendasButtonWidget"
import VendasTable from "./components/VendaTable"
import VendasSidemenu from "./components/VendasSidemenu"
import { FaMoneyBill, FaMoneyBillWave } from "react-icons/fa"
import Swal from "sweetalert2"
import { generateXlsx } from "helpers/generateXlsx"

interface SheetRowType{
    id: any,
    data: string,
    origem: string
    destino: string
    clientId: number
    km: number
    pedagio: number
    almoco: number
    estacionamento: number
    status: number
}



export const status_pedidos = [
    {id: 1, label: "Aguardando faturamento"},
    {id: 2, label: "Em rota de entrega"},
    {id: 3, label: "Entregue"},
    {id: 4, label: "Devolvido"},
    {id: 5, label: "RMA"},
]

export const distribuidores = [
	{id: 1, label: "ScanSource"},
	{id: 2, label: "Pro It Serviços"},
	{id: 3, label: "Ingram Micro"},
	{id: 4, label: "Ingram Micro Cloud"},
	{id: 5, label: "4Servers"},
	{id: 6, label: "Officer"},
	{id: 7, label: "Agis"},
	{id: 8, label: "SB Serviços"},
	{id: 9, label: "VS Data"},
	{id: 10, label: "TS Plus"},
	{id: 11, label: "ADISTEC"},
]

export const tiposProdutos = [
	{id: 1, label: "Hardware"},
	{id: 2, label: "Software"},
	{id: 3, label: "Serviço"},
	{id: 4, label: "Garantia"},
]

export const fabricantes = [
	{id: 1, label: "APC"},
	{id: 2, label: "Arcserve"},
	{id: 3, label: "Cisco Systems"},
	{id: 4, label: "Crowdstrike"},
	{id: 5, label: "Dell Technologies"},
	{id: 6, label: "Fortinet"},
	{id: 7, label: "HPE"},
	{id: 8, label: "Kaspersky"},
	{id: 9, label: "Microsoft"},
	{id: 10, label: "Sonicwall"},
	{id: 11, label: "Veeam"},
	{id: 12, label: "Vmware"},
	{id: 14, label: "Trend Micro"},
	{id: 15, label: "Lenovo"},
	{id: 16, label: "Zebra"},
	{id: 17, label: "Datalogic"},
	{id: 18, label: "Honeywell"},
	{id: 19, label: "Axis"},
	{id: 13, label: "Outro"},
]

export const formasDePagamento = [
	{id: 1, label: "Antecipado"},
	{id: 2, label: "A vista"},
	{id: 3, label: "Leasing"},
	{id: 4, label: "Mensal"},
	{id: 5, label: "Locação"},
	{id: 24, label: "10 dias"},
	{id: 6, label: "20 dias"},
	{id: 7, label: "28 dias"},
	{id: 8, label: "30 dias"},
	{id: 9, label: "31 dias"},
	{id: 10, label: "32 dias"},
	{id: 11, label: "45 dias"},
	{id: 12, label: "60 dias"},
	{id: 13, label: "30/60 dias"},
	{id: 23, label: "10/30/60 dias"},
	{id: 14, label: "30/60/90 dias"},
	{id: 15, label: "30/60/90/120 dias"},
	{id: 16, label: "10/30/60/90 dias"},
	{id: 17, label: "15/30/60/90 dias"},
	{id: 18, label: "15/30/60/90/120 dias"},
	{id: 19, label: "30/60/90/120/150 dias"},
	{id: 20, label: "10/30/60/90/120 dias"},
	{id: 21, label: "10/30/60/90/120/150 dias"},
	{id: 23, label: "10/30/60/90/120/150/180 dias"},
	{id: 22, label: "30/60/90/120/150/180/210 dias"},
]

export const statusPedido: {[key: number]: string} = {
	[1]: "Aguardando",
	[2]: "Entregue",
	[3]: "Cancelado",
}

export const segmentos = [
	{id: 1, label: "Client"},
	{id: 2, label: "Enterprise"},
	{id: 3, label: "Serviço Pontual"},
]

export const faturamentos = [
	{id: 1, label: "Pro It"},
	{id: 2, label: "Distribuidor"},
]


const VendasSheet = () => {
    const user = useAppSelector((state) => state.user.user)
    const darkmode = useAppSelector((state) => state.layout.darkMode)


    const [sheetData, setSheetData] = useState([])
	const [filteredSheetData, setFilteredSheetData] = useState([])
    const [toDeleteSheet, setToDeleteSheet] = useState<number[]>([])
    const [clients, setClients] = useState<SelectType[]>([])
    const [loadingClients, setLoadingClients] = useState(true)
    const [loadingCnpjs, setLoadingCnpjs] = useState(true)
    const [selectedDate, setSelectedDate] = useState({
		from: dayjs().startOf("month").toDate(),
		to: dayjs().endOf("month").toDate()
	})
    const [canSave, setCanSave] = useState(false)
	const [cnpjs, setCnpjs] = useState<{id: number, cnpj: string}[]>([])

	const [funcNames, setFuncNames] = useState<SelectTypePhoto[]>([])
	const [configs, setConfigs] = useState<ConfigDataType>({})

	const [funcs, setFuncs] = useSelectItems([])

	const [selectFuncs, setSelectFuncs] = useState<{id: string, label: string}[]>([

	])

	const [cities, setCities] = useState<SelectType[]>([])
	const [transportadoras, setTransportadoras] = useState<SelectType[]>([])

	const [selectFilter, setSelectFilter] = useState('')
	const [selectedItems, setSelectedItems] = useSelectItems([])

	const [filters, setFilters] = useState<{[key: string]: {
		items: {
			value: number;
			text: string;
		}[],
		search: string
	}}>({
		["fabricantes"]: {
			items: [],
			search: ""
		},
		["distribuidores"]: {
			items: [],
			search: ""
		},
		["segmentos"]: {
			items: [],
			search: ""
		},
	})

	const [loadingPage, setLoadingPage] = useState(true)
	const [loadingFuncs, setLoadingFuncs] = useState(true)

	const [operacoes, setOperacoes] = useState<Operacao[]>([])

	const [userOperacoes, setUserOperacoes] = useState<IUserOperacoes[]>([])
	const [operacoesFilter, setOperacoesFilter] = useSelectItems([])
	const [operacoesSelectedItems, setOperacoesSelectedItems] = useSelectItems([])
	const [operacoesSelectFilter, setOperacoesSelectFilter] = useState('')

	const [editData, setEditData] = useState<SaleSheetType>({})
	const [openEdit, setOpenEdit] = useState(false)
	const [loadingEdit, setLoadingEdit] = useState(false)

	const [margens, setMargens] = useState({
		bruta: 0,
		liquida: 0
	})

	const [createData, setCreateData] = useState<SaleSheetType>({
	})
	const [openCreate, setOpenCreate] = useState(false)
	const [loadingCreate, setLoadingCreate] = useState(false)

	const [countPedidos, setCountPedidos] = useState({
		atraso: 0,
		pendentes: 0,
		entregues: 0,
		entregasAtrasadas: 0,
		semNPedido: 0
	})

	const [deliverFilter, setDeliverFilter] = useState<EstadoEntregaType>(undefined)
	const [semNPedidoFilter, setSemNPedidoFilter] = useState<boolean>(false)

	const [cardsData, setCardsData] = useState({
		vendasTotais: 0,
		receitaBruta: 0,
		receitaLiquida: 0,
		semNPedido: 0,
	})

	const [selected, setSelected] = useState([]);
	const options = [
	  { value: "1", label: "Jimmy" },
	  { value: "2", label: "Laura" },
	  { value: "3", label: "Tommy" },
	  { value: "4", label: "Jane" },
	  { value: "5", label: "Mike" }
	];

	const handleBlur = (rowId: any, columnKey: any) => {
		// Chamada para atualizar a grade de dados quando o usuário terminar de editar uma célula
		// Implemente a lógica para salvar ou atualizar os dados, se necessário
	};


	const isSuperUser = (): boolean => {
		return (user?.admin || user?.permissions["arquiteto_geral"])
	}

	const resetData = () => {
		setEditData({})
		setMargens({ bruta: 0, liquida: 0})
		setCreateData({})
	}

	useEffect(()=> {
		let _newCards: typeof cardsData = {
			receitaBruta: 0,
			receitaLiquida: 0,
			semNPedido: 0,
			vendasTotais: 0
		}
		sheetData.map((data: {n_pedido?: any, valor_venda?: any, valor_custo?: any, faturamento?: any})=> {
			const valVenda = data["valor_venda"]
			const valCusto = data["valor_custo"]
			const faturamento = data["faturamento"]

			const margem_bruta = (valVenda - valCusto > 0 ? valVenda - valCusto : 0) //IGUAL O DIFF DA MARGEM_BRUTA

			const _taxa = faturamento == 2 ? configs["taxa_distribuidor"] : configs["taxa_proit"]
			const _taxa_normal = configs["taxa_normal"]

			const taxa = _taxa ? (_taxa.valueNum) / 100 : 0
			const taxa_normal = _taxa_normal ? _taxa_normal.valueNum / 100 : 0

			const calc_faturamento = margem_bruta - (margem_bruta * taxa)
			const margem_liquida = calc_faturamento - (calc_faturamento * taxa_normal)

			_newCards.receitaBruta += margem_bruta
			_newCards.receitaLiquida += margem_liquida
			_newCards.vendasTotais += valVenda

			if(data.n_pedido == undefined || data.n_pedido?.toString()?.trim() == ""){
				_newCards.semNPedido += 1
			}

		})

		setCardsData(_newCards)
	}, [sheetData])

	useEffect(()=> {
		setFilteredSheetData([...sheetData])
	}, [sheetData])

    const loadData = () => {
		setLoadingPage(true)
		setMargens({liquida: 0, bruta: 0})
        api.get("/sales/user/index", {
            headers: {
                token: user?.token
            },
            params: {
				from: selectedDate.from.toISOString(),
				to: selectedDate.to.toISOString(),
                // date: selectedDate.toISOString(),
                users: JSON.stringify(selectedItems),
				deliverFilter: deliverFilter,
				operacoes: JSON.stringify(operacoesSelectedItems),
				fabricantes: JSON.stringify(filters["fabricantes"].items),
				segmentos: JSON.stringify(filters["segmentos"].items),
				distribuidores: JSON.stringify(filters["distribuidores"].items),
            }
        }).then((response: {data: any})=>{
            if(response){
                if(response.data){
					const newData = response.data.sort((a: any, b: any)=> a.data > b.data ? 1 : a.data < b.data ? -1 : 0)
                    setSheetData([...newData])
                }
            }
            setCanSave(true)
			setLoadingPage(false)
        }).catch((error) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
			setLoadingPage(false)
            setCanSave(true)
        })
    }

	const loadConfigs = () => {
        api.post('configs/get', {
			configs: "taxa_proit,taxa_distribuidor,taxa_normal"
		}, {
            headers: {
                token: user?.token
            }
        }).then((res: {data: ConfigDataType})=> {
			setConfigs(res.data)
        }).catch(err=> {
            toastError(err, darkmode)
        })
	}

	const getUserOperacoes = () => {
		api.get("/funcionarios/operacoes/all", {
			headers: {
				token: user?.token
			}
		}).then((res: {data: IUserOperacoes[]})=> {
			if(res){
				if(res.data){
					setUserOperacoes(res.data)
				}
			}
		}).catch(err=>{
			toastError(err, false)
		})
	  }

  const getClients = (_search?: string) => {
    setLoadingClients(true)
    api.get("/clients/all/autocomplete2", {
        headers: {
            token: user?.token
        },
        params: {
            search: _search ? _search : ""
        }
    }).then((res: { data: ClientType[] }) => {
        setLoadingClients(false)
        var _newClients: SelectType[] = []
        res.data.map(client => {
            _newClients.push({
                content: client.name ? client.name : "Sem nome",
                value: client.id.toString()
            })
        })

        setClients(_newClients)
    }).catch((error) => {
        setLoadingClients(false)
        toast(error.response.data.error, {
            type: "error",
            theme: darkmode ? "dark" : "light"
        })
    })
  }

  const getCities = () => {
	apiCeps.get('/cidades').then((res: {data: CityType[]})=>{
		if(res){
			if(res.data){
				let newCities: SelectType[] = []
				res.data.map(city=>{
					let newCity: SelectType = {value: city.id.toString(), content: city.nome}
					newCities.push(newCity)
				})
				setCities([...newCities])
			}
		}
	}).catch(err=>{
		if(err){
			if(err.response){
				toast(err.response.data.error, {
					type: "error",
					theme: darkmode ? "dark" : "light"
				})
			}
		}
	})
  }

  const getFuncs = () => {
	setLoadingFuncs(true)
    api.get('/funcionarios/autocomplete2', {
        headers: {
          token: user?.token
        },
    }).then((res: {data: {id: number, name: string, photo: string}[] })=>{
        const newData: SelectTypePhoto[] = []
		const newData2: {value: number, text: string}[] = []
		const newData3: {id: string, label: string}[] = []
		if(res){
			if(res.data){
				res.data.map(i=>{
					const val: SelectTypePhoto = {
						value: i.id.toString(),
						content: i.name,
						photo: i.photo
					}
					newData.push(val)
					newData2.push({
						text: val.content,
						value: parseInt(val.value || '0')
					})

					newData3.push({
						id: val.value || "0",
						label: val.content
					})
				})

				if(user.admin){
					setSelectedItems(newData2.map(f=>{
						const func = f
						return func
					}))
				}else{
					const func = newData2.find(func=> func.value == user.id)
					if(func){
						setSelectedItems([{text: func.text, value: func.value}])
					}
				}

			}
			setLoadingFuncs(false)
		}
		initializeSelectedFunc(newData2)
		setFuncs([...newData2])
		setFuncNames(newData)
		setSelectFuncs(newData3)
    }).catch(err=> {
		setLoadingFuncs(false)
	})
  }

  const getTransportadoras = () => {
    api.get('/empresas/all/autocomplete', {
		params: {
			tipo: "transportadora"
		},
        headers: {
          token: user?.token
        },
    }).then((res: {data: any})=>{
		if(res){
			if(res.data){
				var _newEmpresas: SelectType[] = []
				res.data.map((empresa: {name: string, id: number}) => {
					_newEmpresas.push({
						content: empresa.name ? empresa.name : "Sem nome",
						value: empresa.id.toString()
					})
				})

				setTransportadoras(_newEmpresas)
			}
		}
    }).catch(err=>{
		toastError(err, false)
	})
  }

  const getOperacoes = () => {
	api.get("/operacoes/autocomplete", {
		headers: {
			token: user?.token
		}
	}).then(res=>{
		if(res){
			if(res.data){
				setOperacoes(res.data)

				const newData: {value: number, text: string}[] = []
				if(res){
					if(res.data){
						res.data.map((i: any)=>{
							const val = {
								value: i.id.toString(),
								content: i.name,
							}
							newData.push({
								text: val.content,
								value: parseInt(val.value || '0')
							})
						})

					}
				}
				setOperacoesFilter([...newData])
				setOperacoesSelectedItems([...newData])
			}
		}
	}).catch(err=>{
		toastError(err, false)
	})
}

  const initializeSelectedFunc = (list = funcs) => {
	if(true){
		if(user.admin){
			setSelectedItems(list.map(f=>{
				const func = f
				return func
			}))
		}else{
			const func = list.find(func=> func.value == user.id)
			if(func){
				setSelectedItems([{text: func.text, value: func.value}])
			}
			// const func = list.find(func=> parseInt(func.value) == user.id)
			// if(func){
			// 	setSelectedFunc(parseInt(func.value))
			// }
		}
	}
  }

  const handleEditData = (key: string, data: any) => {
	const newData: any = editData
	newData[key] = data
	setEditData({...newData})
  }

  const handleEditCreateData = (key: string, data: any) => {
	const newData: any = createData
	newData[key] = data
	setCreateData({...newData})
  }

  const resetEditData = () => {
	setEditData({})
  }

  const resetCreateData = () => {
	setCreateData({})
  }

  const getCnpjs = () => {
    setLoadingCnpjs(true)
    api.get("/clients/cnpj/all", {
        headers: {
            token: user?.token
        },
    }).then((res: { data: ClientType[] }) => {
        setLoadingCnpjs(false)
        setCnpjs(res.data)
    }).catch((error) => {
        setLoadingCnpjs(false)
        toast(error.response.data.error, {
            type: "error",
            theme: darkmode ? "dark" : "light"
        })
    })
  }

  const setDefaultFilterValues = () => {
	setFilterItemsValue("segmentos", segmentos.map(v=>{
		return {value: v.id, text: v.label}
	}))
	setFilterItemsValue("distribuidores", distribuidores.map(v=>{
		return {value: v.id, text: v.label}
	}))
	setFilterItemsValue("fabricantes", fabricantes.map(v=>{
		return {value: v.id, text: v.label}
	}))
  }

  const setFilterSearchValue = (key: keyof typeof filters, value: string) => {
	const _filters = filters

	_filters[key] = {
		...filters[key],
		search: value
	}

	setFilters({..._filters})
  }

  const setFilterItemsValue = (key: keyof typeof filters, value: {value: number, text: string}[]) => {
	const _filters = filters

	_filters[key] = {
		...filters[key],
		items: value
	}

	setFilters({..._filters})
  }

  useEffect(()=> {
    getClients()
	getCities()
	getFuncs()
	loadConfigs()
	getOperacoes()
	getCnpjs()
	getTransportadoras()
	getUserOperacoes()
	setDefaultFilterValues()
  }, [])

  useEffect(()=>{
	loadData()
}, [selectedDate, selectedItems, operacoesSelectedItems, filters["segmentos"].items, filters["distribuidores"].items, filters["fabricantes"].items])

    const handleSave = () => {
        if(!canSave) return;

        setCanSave(false)
        api.put("/sales/save/"+editData.id, {
            data: editData,
			margens: margens
        },{
            headers: {
                token: user?.token
            },
        }).then((res: { data: any }) => {

            toast("Dados salvos com sucesso", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            loadData()
			setOpenEdit(false)
            setCanSave(true)
			resetData()
        }).catch((error) => {
            if(error){
                if(error.response){
                    toast(error.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                }
            }
            setCanSave(true)
        })
    }

    const handleCreate = () => {
        if(!canSave) return;

        setCanSave(false)
        api.put("/sales/create", {
            data: createData,
			margens
        },{
            headers: {
                token: user?.token
            },
        }).then((res: { data: any }) => {

            toast("Criado com sucesso", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            loadData()
			setOpenCreate(false)
            setCanSave(true)
			setMargens({liquida: 0, bruta: 0})
        }).catch((error) => {
            if(error){
                if(error.response){
                    toast(error.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                }
            }
            setCanSave(true)
        })
    }

	const changeEntregaFiltro = (tipo: EstadoEntregaType) => {
		if(deliverFilter == tipo){
			setDeliverFilter(undefined)
		}else{
			setDeliverFilter(tipo)
		}
	}

	const loadRowInfo = (id: number | string) => {
		setOpenEdit(true)
		setLoadingEdit(true)
		api.get("/sales/show/"+id, {
			headers: {
				token: user?.token
			}
		}).then(res=> {
			if(res){
				if(res.data){
					setEditData(res.data)
					setLoadingEdit(false)
				}
			}
		}).catch(err=> {
			toastError(err, false)
			setOpenEdit(false)
			setLoadingEdit(false)
		})
	}

	const handleDeleteSale = (id: number) => {
		Swal.fire({
			title: "Remover venda?",
			text: "Você realmente deseja remover essa venda?",
			icon: "question",
			confirmButtonText: "Sim",
			showCancelButton: true,
			cancelButtonText: "Não",
			}).then(response=> {
			if(response.isConfirmed){
				api.delete('/sales/delete/'+id, {
					headers: {
						token: user?.token
					}
				}).then(()=>{
					toast("Venda removida com sucesso!", {
						type: "success",
					})

					loadData()
					setOpenEdit(false)
					setCanSave(true)

				}).catch((error)=>{
					toastError(error, false)
				})
			}
		})
	}

	const handleExport = () => {
		api.get('/sales/export', {
            headers: {
                token: user?.token
            },
			params: {
				from: selectedDate.from.toISOString(),
				to: selectedDate.to.toISOString(),
                users: JSON.stringify(selectedItems),
				operacoes: JSON.stringify(operacoesSelectedItems),
			}
        }).then(res=> {
			const data = res.data
			if(data){
				const {name, sheetData, sheetName} = data
				try{
					generateXlsx(name, sheetName, sheetData)
				}catch(err){
					toastErrorMsg("Falha ao exportar relatório", false)
					console.error(err)
				}
			}
        }).catch(err=> {
            toastError(err, false)
        })
	}

    return (
        <div className="mt-5 gap-2 flex flex-col">
			<div className="flex justify-end">
				{filteredSheetData.length > 0 && <button onClick={handleExport} className="linear flex-none ml-auto flex items-center justify-center rounded bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
					Baixar relatório
				</button>}
			</div>
			<div className="flex justify-between">
				<h1 className="text-2xl font-bold dark:text-white w-fit">Controle de Vendas</h1>
				<div className="flex items-center gap-2 justify-end">
					<MultiSelect showSelected={false} placeholder="Funcionários" selectAllText="Todos"
						classNames={{
							container: '!w-[240px] bg-white rounded-xl',
							box: '!bg-white !text-navy-700 dark:!bg-navy-900 dark:!text-white border border-gay-700',
							items: '!bg-white hover:!bg-opacity-20 !text-gray-900 dark:!bg-navy-700 dark:!text-white',
							select: 'h-full !text-xs p-2 !w-[240px] flex items-center justify-center font-bold !text-black hover:cursor-pointer dark:!bg-navy-700 dark:text-white rounded shadow !bg-white placeholder:text-black',
							selectedItems: "!bg-white ",
							selectAll: "text-gray-700 hover:text-gray-800 dark:text-white hover:dark:text-white !right-5"
						}} filter={selectFilter} setFilter={setSelectFilter} selectedElements={selectedItems} setSelectedElements={setSelectedItems} data={funcs}
					/>

					<MultiSelect showSelected={false} placeholder="Operações" selectAllText="Todas"
						classNames={{
							container: '!w-[240px] bg-white',
							box: '!bg-white !text-navy-700 dark:!bg-navy-900 dark:!text-white border border-gay-700',
							items: '!bg-white hover:!bg-opacity-20 !text-gray-900 dark:!bg-navy-700 dark:!text-white',
							select: 'h-full !text-xs p-2 !w-[240px] flex items-center justify-center font-bold !text-black hover:cursor-pointer dark:!bg-navy-700 dark:text-white rounded shadow !bg-white placeholder:text-black',
							selectedItems: "!bg-white ",
							selectAll: "text-gray-700 hover:text-gray-800 dark:text-white hover:dark:text-white !right-5"
						}} filter={operacoesSelectFilter} setFilter={setOperacoesSelectFilter} selectedElements={operacoesSelectedItems} setSelectedElements={setOperacoesSelectedItems} data={operacoesFilter}
					/>

					<Dropdown
						button={
							<button
								type="button"
								className={`no-arrows text-xs text-white bg-orange-500 hover:bg-orange-600 active:bg-orange-700 rounded w-fit py-[1px] px-2 ml-auto mr-auto`}
							>
								{selectedDate ?
									<>
										{/* <b>{months[dayjs(selectedDate).month()]}</b> de <b>{dayjs(selectedDate).year()}</b> */}
										{<b className="text-nowrap">{dayjs(selectedDate.from).format("DD/MM/YYYY")} - {dayjs(selectedDate.to).format("DD/MM/YYYY")}</b>}
									</>
									: "Clique para definir"
								}
							</button>
						}
						extraClassesWrapper="w-fit h-10"
						extraClassesButton="w-fit h-10"
						animation={"origin-top transition-all duration-300 ease-in-out"}
						classNames={`top-11 right-0 w-max `}
						children={
						<div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
							<Calendar
								value={[selectedDate.from, selectedDate.to]}

								selectRange={true}
								// onClickMonth={e=> setSelectedDate(e)}
								onChange={(e: any)=>{
									const [from, to] = e
									setSelectedDate({
										from,
										to
									})
								}}
								prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
								nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
								view={"month"}
							/>
						</div>
						}
					/>

					<div className="flex items-center gap-5 justify-self-end">
						<button className={`w-[120px] py-2 text-sm text-white bg-blue-500 rounded hover:bg-blue-600 px-4 py-3 font-bold`} onClick={()=> setOpenCreate(true)}>
                            Criar
                        </button>
					</div>
				</div>
			</div>
			<div className="flex items-center gap-5 w-full">
				<div className="flex items-center justify-between gap-2 w-full">
					<div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 justify-center w-full">
						<DashboardFinanceiroButtonWidget
							icon={<MdNumbers className="h-7 w-7" />}
							title={"Sem o nº de pedido"}
							subtitle={cardsData.semNPedido.toString()}
						/>
						<DashboardFinanceiroButtonWidget
							icon={<MdBarChart className="h-7 w-7" />}
							title={"Vendas no Período"}
							subtitle={Intl.NumberFormat("pt-BR", {
								style: "currency",
								currency: "BRL",
							}).format(cardsData.vendasTotais)}
						/>
						<DashboardFinanceiroButtonWidget
							icon={<FaMoneyBillWave className="h-7 w-7" />}
							title={"Receita Bruta no Período"}
							subtitle={Intl.NumberFormat("pt-BR", {
								style: "currency",
								currency: "BRL",
							}).format(cardsData.receitaBruta)}
						/>
						<DashboardFinanceiroButtonWidget
							icon={<FaMoneyBill className="h-7 w-7" />}
							title={"Receita Líquida no Período"}
							subtitle={Intl.NumberFormat("pt-BR", {
								style: "currency",
								currency: "BRL",
							}).format(cardsData.receitaLiquida)}
						/>
					</div>
				</div>
			</div>

			<VendasSidemenu transportadoras={transportadoras} operacoes={operacoes} userOperacoes={userOperacoes}
				configs={configs} cnpjs={cnpjs} funcNames={funcNames} funcs={selectFuncs}
				clients={clients} data={editData} editData={handleEditData}
				handleSave={handleSave} isOpen={openEdit} loading={loadingEdit}
				resetData={resetEditData} setIsOpen={setOpenEdit} title={editData?.client?.name}
				deleteText={isSuperUser ? "Remover pedido" : undefined}
				deleteAction={isSuperUser ? (id)=> {
					handleDeleteSale(id)
				} : undefined}
				margens={margens}
				setMargens={setMargens}
			/>
			<VendasSidemenu transportadoras={transportadoras} margens={margens} setMargens={setMargens} operacoes={operacoes} userOperacoes={userOperacoes} configs={configs} cnpjs={cnpjs} funcNames={funcNames} funcs={selectFuncs} clients={clients} data={createData} editData={handleEditCreateData} handleSave={handleCreate} isOpen={openCreate} loading={loadingCreate} resetData={resetCreateData} setIsOpen={setOpenCreate} title={"Novo pedido"}/>
			<VendasTable userPhotos={funcNames} handleEdit={loadRowInfo} reloadTable={loadData} loading={loadingPage || loadingFuncs} title="" tableData={filteredSheetData} disabledActions={openEdit}/>

        </div>
    )
}

export default VendasSheet