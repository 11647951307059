import { useEffect, useRef, useState } from "react";

interface ClientsAutocompleteType{
  id: string;
  label: string;
  extra: string;
  placeholder: string;
  variant: string;
  state?: string;
  disabled?: boolean;
  onChange?: (clientName: string) => void;
  onSelect?: (clientId: string) => void,
  labelClasses?: string,
  value?: any,
  options: {value: any, content: any}[]
  loading: boolean
  className: string;
  inputClasses?: string
}
const ClientsAutocomplete = ({className, labelClasses, loading, options, onSelect, label, id, extra, placeholder, variant, state, disabled, onChange, value, inputClasses}: ClientsAutocompleteType) => {

    const [selectOpen, setSelectOpen] = useState(false)
    const ref = useRef()

    const openSelect = () => {
        setSelectOpen(true)
    }

    const handleOutsideClick = (e: any) => {
        if(e.target.parentNode != ref.current && e.target != ref.current){
            setSelectOpen(false)
        }
    }

    const handleSelect = (client: {value: any, content: any}) => {
        onChange(client.content)
        onSelect(client.value)
        setSelectOpen(false)
    }

    useEffect(()=> {
        document.addEventListener("mousedown", handleOutsideClick)
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    })
    return(
        <div className={`relative ${className}`}>
            <label
                htmlFor={id}
                className={` ${labelClasses ? labelClasses : `text-sm text-navy-700 dark:text-white ${
                variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
                } `} `}
            >
                {label}
            </label>
            <input
                disabled={disabled}
                id={id}
                value={value}
                onChange={e=> onChange(e.target.value)}
                placeholder={placeholder}
                autoComplete="off"
                onFocus={openSelect}
                onClick={openSelect}
                className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none placeholder-gray-900 dark:placeholder-white text-gray-900 ${
                disabled === true
                    ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:text-white"
                    : state === "error"
                    ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                    : state === "success"
                    ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                    : "border-gray-300 dark:!border-white/10 dark:text-white"
                } ${inputClasses ? inputClasses : ''}`}
            />
            <ul ref={ref} className={`${selectOpen ? '' : 'hidden'} ${loading ? "text-center justify-center" : ""} z-50 flex flex-col h-[150px] list-none overflow-auto w-full absolute top-[90px] left-0 right-0 border border-gray-200 dark:border-gray-900 bg-white dark:bg-navy-900 rounded`}>
                {!loading ? options.map(client=>(
                    <li key={"CLIENTS-"+client.value} onClick={() => handleSelect(client)} className="dark:text-white p-2 hover:bg-gray-50 hover:dark:bg-navy-800 cursor-pointer">{client.content}</li>
                )) : <span className="dark:text-white">
                    Carregando...
                </span>}
            </ul>
        </div>
    )
}

export default ClientsAutocomplete