import { useEffect, useState } from "react"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import { CityType, ClientType, ConfigDataType, EstadoEntregaType, Operacao, SaleSheetType, SelectType, SelectTypePhoto } from "types/app"
import api from "services/api"
import { toast } from "react-toastify"
import { useAppSelector } from "redux/hooks"
import Dropdown from "components/dropdown"
import dayjs from "dayjs"
import Calendar from "react-calendar"
import apiCeps from "services/apiCeps"
import { toastError } from "components/toasts/toastError"
import MultiSelect, { useSelectItems } from "components/multi-select"

import '@inovua/reactdatagrid-community/index.css'
import TooltipHorizon from "components/tooltip"

import DashboardFinanceiroButtonWidget from "views/dashboardvendas/components/DashboardVendasButtonWidget"
import { GrDeliver } from "react-icons/gr"
import { TbClockFilled, TbClockOff } from "react-icons/tb"
import { BsCheck } from "react-icons/bs"
import { HiHashtag } from "react-icons/hi"
import { checkNullString } from "helpers/checkers"
import PosVendaTable from "./components/PosVendaTable"
import PosVendasSidemenu from "./components/PosVendasSidemenu"

interface SheetRowType{
    id: any,
    data: string,
    origem: string
    destino: string
    clientId: number
    km: number
    pedagio: number
    almoco: number
    estacionamento: number
    status: number
}

// const defaultData: SheetRowType[] = [
//     {id: 1, data: dayjs().format('YYYY-MM-DD'), origem: "SJRP", destino: "Bauru", clientId: 0, km: 200, pedagio: 34.5, almoco: 49.90, estacionamento: 10, status: 1},
//     {id: 2, data: dayjs().subtract(76, 'day').format('YYYY-MM-DD'), origem: "BAURU", destino: "Jaci", clientId: 1, km: 120, pedagio: 34.5, almoco: 49.90, estacionamento: 10, status: 0}
// ]


const empresasKey = {
	["Transportadoras"]: "transportadora"
}

const PosVendaSheet = () => {
    const user = useAppSelector((state) => state.user.user)
    const darkmode = useAppSelector((state) => state.layout.darkMode)


    const [sheetData, setSheetData] = useState([])
	const [filteredSheetData, setFilteredSheetData] = useState([])
    const [toDeleteSheet, setToDeleteSheet] = useState<number[]>([])
    const [clients, setClients] = useState<SelectType[]>([])
    const [loadingClients, setLoadingClients] = useState(true)
    const [loadingCnpjs, setLoadingCnpjs] = useState(true)
    const [selectedDate, setSelectedDate] = useState({
		from: dayjs().startOf("month").toDate(),
		to: dayjs().endOf("month").toDate()
	})
    const [canSave, setCanSave] = useState(false)
	const [cnpjs, setCnpjs] = useState<{id: number, cnpj: string}[]>([])

	const [funcNames, setFuncNames] = useState<SelectTypePhoto[]>([])
	const [configs, setConfigs] = useState<ConfigDataType>({})

	const [funcs, setFuncs] = useSelectItems([])

	const [selectFuncs, setSelectFuncs] = useState<{id: string, label: string}[]>([

	])

	const [cities, setCities] = useState<SelectType[]>([])
	const [transportadoras, setTransportadoras] = useState<SelectType[]>([])

	const [selectFilter, setSelectFilter] = useState('')
	const [selectedItems, setSelectedItems] = useSelectItems([])

	const [loadingPage, setLoadingPage] = useState(true)
	const [loadingFuncs, setLoadingFuncs] = useState(true)

	const [operacoes, setOperacoes] = useState<Operacao[]>([])
	const [operacoesFilter, setOperacoesFilter] = useSelectItems([])
	const [operacoesSelectedItems, setOperacoesSelectedItems] = useSelectItems([])
	const [operacoesSelectFilter, setOperacoesSelectFilter] = useState('')

	const [editData, setEditData] = useState<SaleSheetType>({})
	const [openEdit, setOpenEdit] = useState(false)
	const [loadingEdit, setLoadingEdit] = useState(false)

	const [countPedidos, setCountPedidos] = useState({
		atraso: 0,
		pendentes: 0,
		entregues: 0,
		entregasAtrasadas: 0,
		semNPedido: 0
	})

	const [deliverFilter, setDeliverFilter] = useState<EstadoEntregaType>(undefined)
	const [semNPedidoFilter, setSemNPedidoFilter] = useState<boolean>(false)

	const handleBlur = (rowId: any, columnKey: any) => {
		// Chamada para atualizar a grade de dados quando o usuário terminar de editar uma célula
		// Implemente a lógica para salvar ou atualizar os dados, se necessário
	};

	useEffect(()=> {
		let _data = sheetData
		if(deliverFilter !== undefined ){
			_data.map(data=>{
				var mode: EstadoEntregaType = undefined
				if(data.previsao_entrega) {
					if(dayjs(data.previsao_entrega).isValid()){
						if(data.data_entrega && dayjs(data.data_entrega).isValid()){
							if(dayjs(data.data_entrega).toISOString() > dayjs(data.previsao_entrega).toISOString()){
								mode = "entregueAtraso"
							}else{
								mode = "entregue"
							}
						}else if(dayjs(data.previsao_entrega).startOf("day").toISOString() < dayjs().startOf("day").toISOString()){
							mode = "passou"
						}else{
							mode = "normal"
						}
					}
				}

				data.estadoEntrega = mode
			})

			_data = _data.filter(d=> {
				return d.estadoEntrega === deliverFilter
			})
		}


		if(semNPedidoFilter){
			_data = _data.filter(d=> {
				if(checkNullString(d.n_pedido)){
					return d.estadoEntrega === deliverFilter
				}
			})
		}
		setFilteredSheetData(_data)
	}, [sheetData, deliverFilter, semNPedidoFilter])

    const loadData = () => {
		setLoadingPage(true)
        api.get("/sales/user/index", {
            headers: {
                token: user?.token
            },
            params: {
				from: selectedDate.from.toISOString(),
				to: selectedDate.to.toISOString(),
                // date: selectedDate.toISOString(),
                users: JSON.stringify(selectedItems),
				deliverFilter: deliverFilter,
				operacoes: JSON.stringify(operacoesSelectedItems)
            }
        }).then((response: {data: any})=>{
            if(response){
                if(response.data){
					const newData = response.data.sort((a: any, b: any)=> a.data > b.data ? 1 : a.data < b.data ? -1 : 0)
                    setSheetData([...newData])
                }
            }
			setLoadingPage(false)
            setCanSave(true)
        }).catch((error) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
			setLoadingPage(false)
            setCanSave(true)
        })
    }

	const loadConfigs = () => {
        api.post('configs/get', {
			configs: "taxa_proit,taxa_distribuidor,taxa_normal"
		}, {
            headers: {
                token: user?.token
            }
        }).then((res: {data: ConfigDataType})=> {
			setConfigs(res.data)
        }).catch(err=> {
            toastError(err, darkmode)
        })
	}

  const getClients = (_search?: string) => {
    setLoadingClients(true)
    api.get("/clients/all/autocomplete2", {
        headers: {
            token: user?.token
        },
        params: {
            search: _search ? _search : ""
        }
    }).then((res: { data: ClientType[] }) => {
        setLoadingClients(false)
        var _newClients: SelectType[] = []
        res.data.map(client => {
            _newClients.push({
                content: client.name ? client.name : "Sem nome",
                value: client.id.toString()
            })
        })

        setClients(_newClients)
    }).catch((error) => {
        setLoadingClients(false)
        toast(error.response.data.error, {
            type: "error",
            theme: darkmode ? "dark" : "light"
        })
    })
  }

  const getCities = () => {
	apiCeps.get('/cidades').then((res: {data: CityType[]})=>{
		if(res){
			if(res.data){
				let newCities: SelectType[] = []
				res.data.map(city=>{
					let newCity: SelectType = {value: city.id.toString(), content: city.nome}
					newCities.push(newCity)
				})
				setCities([...newCities])
			}
		}
	}).catch(err=>{
		if(err){
			if(err.response){
				toast(err.response.data.error, {
					type: "error",
					theme: darkmode ? "dark" : "light"
				})
			}
		}
	})
  }


  const getFuncs = () => {
	setLoadingFuncs(true)
    api.get('/funcionarios/autocomplete2', {
        headers: {
          token: user?.token
        },
    }).then((res: {data: {id: number, name: string, photo: string}[] })=>{
        const newData: SelectTypePhoto[] = []
		const newData2: {value: number, text: string}[] = []
		const newData3: {id: string, label: string}[] = []
		if(res){
			if(res.data){
				res.data.map(i=>{
					const val: SelectTypePhoto = {
						value: i.id.toString(),
						content: i.name,
						photo: i.photo
					}
					newData.push(val)
					newData2.push({
						text: val.content,
						value: parseInt(val.value || '0')
					})

					newData3.push({
						id: val.value || "0",
						label: val.content
					})
				})

				if(user.admin){
					setSelectedItems(newData2.map(f=>{
						const func = f
						return func
					}))
				}else{
					const func = newData2.find(func=> func.value == user.id)
					if(func){
						setSelectedItems([{text: func.text, value: func.value}])
					}
				}

			}
			setLoadingFuncs(false)
		}
		initializeSelectedFunc(newData2)
		setFuncs([...newData2])
		setFuncNames(newData)
		setSelectFuncs(newData3)
    }).catch(err=> {
		setLoadingFuncs(false)
	})
  }

  const getTransportadoras = () => {
    api.get('/empresas/all/autocomplete', {
		params: {
			tipo: empresasKey["Transportadoras"]
		},
        headers: {
          token: user?.token
        },
    }).then((res: {data: any})=>{
		if(res){
			if(res.data){
				var _newEmpresas: SelectType[] = []
				res.data.map((empresa: {name: string, id: number}) => {
					_newEmpresas.push({
						content: empresa.name ? empresa.name : "Sem nome",
						value: empresa.id.toString()
					})
				})

				setTransportadoras(_newEmpresas)
			}
		}
    }).catch(err=>{
		toastError(err, false)
	})
  }

  const getOperacoes = () => {
	api.get("/operacoes/autocomplete", {
		headers: {
			token: user?.token
		}
	}).then(res=>{
		if(res){
			if(res.data){
				setOperacoes(res.data)

				const newData: {value: number, text: string}[] = []
				if(res){
					if(res.data){
						res.data.map((i: any)=>{
							const val = {
								value: i.id.toString(),
								content: i.name,
							}
							newData.push({
								text: val.content,
								value: parseInt(val.value || '0')
							})
						})

					}
				}
				setOperacoesFilter([...newData])
				setOperacoesSelectedItems([...newData])
			}
		}
	}).catch(err=>{
		toastError(err, false)
	})
}

  const initializeSelectedFunc = (list = funcs) => {
	if(true){
		if(user.admin){
			setSelectedItems(list.map(f=>{
				const func = f
				return func
			}))
		}else{
			const func = list.find(func=> func.value == user.id)
			if(func){
				setSelectedItems([{text: func.text, value: func.value}])
			}
			// const func = list.find(func=> parseInt(func.value) == user.id)
			// if(func){
			// 	setSelectedFunc(parseInt(func.value))
			// }
		}
	}
  }

  const handleEditData = (key: string, data: any) => {
	const newData: any = editData
	newData[key] = data
	setEditData({...newData})
  }

  const resetEditData = () => {
	setEditData({})
  }

  useEffect(()=> {
    getClients()
	getCities()
	getFuncs()
	loadConfigs()
	getOperacoes()
	getTransportadoras()
  }, [])

  useEffect(()=>{
	loadData()
}, [selectedDate, selectedItems, operacoesSelectedItems])

    const handleSave = () => {
        if(!canSave) return;

        setCanSave(false)
        api.put("/sales/save/"+editData.id, {
            data: editData,
        },{
            headers: {
                token: user?.token
            },
        }).then((res: { data: any }) => {

            toast("Dados salvos com sucesso", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            loadData()
			setOpenEdit(false)
            setCanSave(true)
        }).catch((error) => {
            if(error){
                if(error.response){
                    toast(error.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                }
            }
            setCanSave(true)
        })
    }

	useEffect(()=>{
		const newCount: typeof countPedidos = {
			atraso: 0,
			pendentes: 0,
			entregues: 0,
			entregasAtrasadas: 0,
			semNPedido: 0,
		}
		sheetData.map(data=>{
			var mode: "normal" | "passou" | "entregue" | "entregueAtraso" = "normal"

			if(checkNullString(data.n_pedido)){
				newCount.semNPedido ++
			}

			if(data.previsao_entrega) {
				if(dayjs(data.previsao_entrega).isValid()){
					if(data.data_entrega && dayjs(data.data_entrega).isValid()){
						if(dayjs(data.data_entrega).toISOString() > dayjs(data.previsao_entrega).toISOString()){
							newCount.entregasAtrasadas ++;
						}else{
							newCount.entregues ++;
						}
					}else if(dayjs(data.previsao_entrega).startOf("day").toISOString() < dayjs().startOf("day").toISOString()){
						newCount.atraso ++;
					}else{
						newCount.pendentes ++;
					}
				}
			}
		})
		setCountPedidos(newCount)
	}, [sheetData])

	const changeEntregaFiltro = (tipo: EstadoEntregaType) => {
		if(deliverFilter == tipo){
			setDeliverFilter(undefined)
		}else{
			setDeliverFilter(tipo)
		}
	}

	const loadRowInfo = (id: number | string) => {
		setOpenEdit(true)
		setLoadingEdit(true)
		api.get("/sales/show/"+id, {
			headers: {
				token: user?.token
			}
		}).then(res=> {
			if(res){
				if(res.data){
					setEditData(res.data)
					setLoadingEdit(false)
				}
			}
		}).catch(err=> {
			toastError(err, false)
			setOpenEdit(false)
			setLoadingEdit(false)
		})
	}

    return (
        <div className="mt-5">
			<div className="flex justify-between">
				<h1 className="text-2xl font-bold dark:text-white w-fit">Controle de Pós Venda</h1>

				<div className="flex items-center gap-5 justify-end">
					<MultiSelect showSelected={false} placeholder="Funcionários" selectAllText="Todos"
						classNames={{
							container: '!w-[240px] bg-white rounded-xl',
							box: '!bg-white !text-navy-700 dark:!bg-navy-900 dark:!text-white border border-gay-700',
							items: '!bg-white hover:!bg-opacity-20 !text-gray-900 dark:!bg-navy-700 dark:!text-white',
							select: 'h-full !text-xs p-2 !w-[240px] flex items-center justify-center font-bold !text-black hover:cursor-pointer dark:!bg-navy-700 dark:text-white rounded shadow !bg-white placeholder:text-black',
							selectedItems: "!bg-white ",
							selectAll: "text-gray-700 hover:text-gray-800 dark:text-white hover:dark:text-white !right-5"
						}} filter={selectFilter} setFilter={setSelectFilter} selectedElements={selectedItems} setSelectedElements={setSelectedItems} data={funcs}
					/>

					<MultiSelect showSelected={false} placeholder="Operações" selectAllText="Todas"
						classNames={{
							container: '!w-[240px] bg-white',
							box: '!bg-white !text-navy-700 dark:!bg-navy-900 dark:!text-white border border-gay-700',
							items: '!bg-white hover:!bg-opacity-20 !text-gray-900 dark:!bg-navy-700 dark:!text-white',
							select: 'h-full !text-xs p-2 !w-[240px] flex items-center justify-center font-bold !text-black hover:cursor-pointer dark:!bg-navy-700 dark:text-white rounded shadow !bg-white placeholder:text-black',
							selectedItems: "!bg-white ",
							selectAll: "text-gray-700 hover:text-gray-800 dark:text-white hover:dark:text-white !right-5"
						}} filter={operacoesSelectFilter} setFilter={setOperacoesSelectFilter} selectedElements={operacoesSelectedItems} setSelectedElements={setOperacoesSelectedItems} data={operacoesFilter}
					/>

					<Dropdown
						button={
							<button
								type="button"
								className={`no-arrows text-xs text-white bg-orange-500 rounded w-fit py-[1px] px-2 ml-auto mr-auto`}
							>
								{selectedDate ?
									<>
										{/* <b>{months[dayjs(selectedDate).month()]}</b> de <b>{dayjs(selectedDate).year()}</b> */}
										{<b className="text-nowrap">{dayjs(selectedDate.from).format("DD/MM/YYYY")} - {dayjs(selectedDate.to).format("DD/MM/YYYY")}</b>}
									</>
									: "Clique para definir"
								}
							</button>
						}
						extraClassesWrapper="w-fit h-10"
						extraClassesButton="w-fit h-10"
						animation={"origin-top transition-all duration-300 ease-in-out"}
						classNames={`top-11 right-0 w-max `}
						children={
						<div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
							<Calendar
								value={[selectedDate.from, selectedDate.to]}

								selectRange={true}
								// onClickMonth={e=> setSelectedDate(e)}
								onChange={(e: any)=>{
									const [from, to] = e
									setSelectedDate({
										from,
										to
									})
								}}
								prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
								nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
								view={"month"}
							/>
						</div>
						}
					/>

					<div className="flex items-center gap-5 justify-self-end">
					</div>
				</div>
			</div>
			<div className="flex items-center gap-5">
				<div className="grid gap-5 grid-cols-5 w-full">
					<DashboardFinanceiroButtonWidget
						icon={<TbClockFilled className="h-5 w-5" />}
						title={"Em atraso"}
						loading={loadingPage}
						subtitle={countPedidos.atraso.toString()}
						clickAction={()=> {
							changeEntregaFiltro("passou")
						}}
						highlighted={deliverFilter == "passou"}
					/>
					<DashboardFinanceiroButtonWidget
						icon={<GrDeliver className="h-5 w-5" />}
						title={"A entregar"}
						loading={loadingPage}
						subtitle={countPedidos.pendentes.toString()}
						clickAction={()=> {
							changeEntregaFiltro("normal")
						}}
						highlighted={deliverFilter == "normal"}
					/>
					<DashboardFinanceiroButtonWidget
						icon={<TbClockOff className="h-5 w-5" />}
						title={"Entregas com atraso"}
						loading={loadingPage}
						subtitle={countPedidos.entregasAtrasadas.toString()}
						clickAction={()=> {
							changeEntregaFiltro("entregueAtraso")
						}}
						highlighted={deliverFilter == "entregueAtraso"}
					/>
					<DashboardFinanceiroButtonWidget
						icon={<BsCheck className="h-5 w-5" />}
						title={"Entregas concluidas no prazo"}
						loading={loadingPage}
						subtitle={countPedidos.entregues.toString()}
						clickAction={()=> {
							changeEntregaFiltro("entregue")
						}}
						highlighted={deliverFilter == "entregue"}
					/>
					<DashboardFinanceiroButtonWidget
						icon={<HiHashtag className="h-5 w-5" />}
						title={"Sem número de pedido"}
						loading={loadingPage}
						subtitle={countPedidos.semNPedido.toString()}
						clickAction={()=> {
							setSemNPedidoFilter(val => !val)
						}}
						highlighted={semNPedidoFilter}
					/>
				</div>
			</div>

			<PosVendasSidemenu transportadoras={transportadoras} funcNames={funcNames} funcs={selectFuncs} clients={clients} data={editData} editData={handleEditData} handleSave={handleSave} isOpen={openEdit} loading={loadingEdit} resetData={resetEditData} setIsOpen={setOpenEdit} title={editData?.client?.name} />
			<PosVendaTable handleEdit={loadRowInfo} reloadTable={loadData} loading={loadingPage || loadingFuncs} title="" tableData={filteredSheetData} disabledActions={openEdit}/>

        </div>
    )
}

export default PosVendaSheet