import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Button, ChakraProvider, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useDisclosure } from "@chakra-ui/react";
import Card from "components/card";
import CustomInput from "components/custom-input";
import { useEffect, useState } from "react";
import ExpensesTable from "./GastosOperacaoTable";
import { EmpresasType, OperacaoExpenses, SelectType } from "types/app";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toastError } from "components/toasts/toastError";
import ExpensesDropdown from "./GastosOperacaoDropdown";
import Dropdown from "components/dropdown";
import Calendar from "react-calendar";
import { MdAdd, MdBarChart, MdChevronLeft, MdChevronRight, MdEdit, MdNumbers, MdPlusOne, MdRefresh } from "react-icons/md";
import dayjs from "dayjs";
import { IoAdd } from "react-icons/io5";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import Switch from "components/switch";
import { months } from "helpers/months";
import EmpresaAutocomplete from "../EmpresaAutocomplete";
import { cnpjMask } from "masks/cnpjMask";
import consultarCNPJ from 'consultar-cnpj'
import ReactInputMask from "react-input-mask";
import apiCeps from "services/apiCeps";
import SideMenu from "components/sidemenu";
import AsyncSelect from 'react-select/async'
import ReactSelect from 'react-select'
import { formatToCurrency, replaceSpecialChars } from "helpers/conversions";
import { StylesConfig } from "react-select";
import CustomInputV2 from "components/custom-input-2";
import CustomInputV3 from "components/custom-input-3";
import DashboardFinanceiroButtonWidget from "views/dashboardfinanceiro/components/DashboardFinanceiroButtonWidget";
import { IoMdCash } from "react-icons/io";

interface CardType{
    id: number,
    maxH?: number,
    minH?: number,
    searchDate?: Date,
	title?: string,
	extraClasses?: string
}

const referenteSelect = [
	{value: 0, label: "Fornecedor"},
	{value: 1, label: "Cliente"},
	{value: 2, label: "Imposto"},
]

const metodosPagamentoSelect = [
	{value: 1, label: "Boleto"},
	{value: 2, label: "PIX"},
	{value: 3, label: "TED"},
]

const CardGastosOperacao = ({id, maxH, minH, searchDate, title, extraClasses}: CardType) => {
    const user = useAppSelector(selector => selector.user.user)
    const darkmode = useAppSelector(selector => selector.layout.darkMode)

    const [empresas, setEmpresas] = useState<SelectType[]>([])
	const [loadingEmpresas, setLoadingEmpresas] = useState(true)

    const [data, setData] = useState<OperacaoExpenses[]>([])

	const [isOpenNewExpense, setIsOpenNewExpense] = useState(false)
	const [isOpenEditExpense, setIsOpenEditExpense] = useState(false)
    const { isOpen: isOpenCreateEmpresa, onOpen: onOpenCreateEmpresa, onClose: onCloseCreateEmpresa } = useDisclosure()
    const { isOpen: isOpenEditEmpresa, onOpen: onOpenEditEmpresa, onClose: onCloseEditEmpresa } = useDisclosure()

	const [cardsData, setCardsData] = useState({
		recorrente: 0,
		variavel: 0,
		faturado: 0,
		resultado: 0
	})

	const [loadingEdit, setLoadingEdit] = useState(true)


	const selectStyle: StylesConfig = {
		input: (styles) => ({
			...styles,
			paddingLeft: 2,
			paddingRight: 2,
			height: 42
		}),
		option: (styles, { isSelected, isFocused, isDisabled}) => ({
			...styles,
			background: isSelected ? "#2d394d" : isFocused ? "#edf2f7" : styles.background,

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
				? "#cbd5e0" : undefined,
			},
		}),
		control: (styles) => ({
			...styles,
			borderColor: "#a3aed0",
			borderRadius: 0
		})
	}

    const [newExpenseData, setNewExpenseData] = useState<OperacaoExpenses>({
        date: dayjs().toDate(),
        description: "",
        value: 0,
        isRecurrent: false,
		empresaId: undefined,
		prazo: 0
    })

    const [editExpenseData, setEditExpenseData] = useState<OperacaoExpenses>({
        id: 0,
        date: dayjs().toDate(),
        description: "",
        value: 0,
        isRecurrent: false,
		empresaId: undefined,
    })

	const [newEmpresaData, setNewEmpresaData] = useState<EmpresasType>({
		address: "",
		cnpj: "",
		email: "",
		cep: "",
		city: "",
		complement: "",
		district: "",
		ie: "",
		name: "",
		number: undefined,
		state: "",
		tipo: "fornecedor"
	})

	const [editEmpresaData, setEditEmpresaData] = useState<EmpresasType>({
		address: "",
		cnpj: "",
		email: "",
		cep: "",
		city: "",
		complement: "",
		district: "",
		ie: "",
		name: "",
		number: undefined,
		state: "",
		tipo: "fornecedor"
	})

    const loadData = () => {
        api.get('/operacaoexpenses/list/'+id, {
            headers: {
                token: user?.token
            },
            params: {
                date: dayjs(searchDate).toISOString()
            }
        }).then(res=>{
            setData(res.data)
        }).catch(err=> {
            toastError(err, darkmode)
        })
    }

	const getEmpresas = (_search?: string) => {
		setLoadingEmpresas(true)
		api.get("/empresas/all/autocomplete", {
			headers: {
				token: user?.token
			},
			params: {
				search: _search ? _search : "",
				tipo: "fornecedor"
			}
		}).then((res: { data: EmpresasType[] }) => {
			setLoadingEmpresas(false)
			var _newEmpresas: SelectType[] = []
			res.data.map(client => {
				_newEmpresas.push({
					content: client.name ? client.name : "Sem nome",
					value: client.id.toString()
				})
			})

			setEmpresas(_newEmpresas)
		}).catch((error) => {
			setLoadingEmpresas(false)
			toast(error.response.data.error, {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		})
	}

    useEffect(()=> {
        loadData()
		getEmpresas()
		loadCardsData()
    }, [searchDate])


    const getCNPJ = async (cnpj: any, value: typeof newEmpresaData, setter: typeof setNewEmpresaData) => {
		const _val = value
        try{
            const empresa = await consultarCNPJ(cnpj)
            if(empresa){
                _val.name = empresa.razao_social
                _val.cep = empresa.estabelecimento.cep
                _val.number = empresa.estabelecimento.numero
                _val.complement = empresa.estabelecimento.complemento


                if(empresa.estabelecimento.inscricoes_estaduais.length && empresa.estabelecimento.inscricoes_estaduais){
                    let activeIE = false
                    let selectedIE = null
                    empresa.estabelecimento.inscricoes_estaduais.map(_ie=>{
                        if(_ie.ativo == true && !activeIE){
                            activeIE = true
                            selectedIE = _ie.inscricao_estadual
                        }
                    })

                    if(activeIE){
                        _val.ie = empresa.estabelecimento.inscricoes_estaduais[0].inscricao_estadual
                    }else{
                        _val.ie = "Isento"
                    }
                }else{
                    _val.ie = "Isento"
                }

                if(empresa.estabelecimento.cep){
                    _val.address = "Carregando..."
                    _val.state = "Carregando..."
                    _val.city = "Carregando..."
                    _val.district = 'Carregando...'

                    await apiCeps.get("/cep", {
                        params: {
                            cep: empresa.estabelecimento.cep
                        }
                    }).then(res=>{
                        if(res.data){
                            if(res.data.rua && res.data.rua != ""){
                                _val.address = res.data.rua
                            }else{
                                _val.address = empresa.estabelecimento.logradouro
                            }

                            if(res.data.estados.nome && res.data.estados.nome != ""){
                                _val.state = res.data.estados.nome
                            }else{

                            }

                            if(res.data.cidades.nome && res.data.cidades.nome != ""){
								_val.city = res.data.cidades.nome
                            }else{

                            }

                            if(res.data.bairro && res.data.bairro != ""){
								_val.district = res.data.bairro
                            }else{
                                _val.district = empresa.estabelecimento.bairro
                            }
                        }else{
                            _val.address = "Não encontrado"
                            _val.state = "Não encontrado"
                            _val.city = "Não encontrado"
                        }
                    })
                }
            }

			setter({
				..._val
			})
        }catch(error: any){
            toast("CNPJ não encontrado na base do Governo", {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        }
    }

    useEffect(() => {
        if(newEmpresaData.cnpj){
            if(newEmpresaData.cnpj.length >= 18){
                // console.log(cnpj.length)
                getCNPJ(newEmpresaData.cnpj, newEmpresaData, setNewEmpresaData)
            }
        }
    }, [newEmpresaData.cnpj])

	const resetEmpresaValues = () => {
		setNewEmpresaData({
			address: "",
			cnpj: "",
			email: "",
			cep: "",
			city: "",
			complement: "",
			district: "",
			ie: "",
			name: "",
			number: undefined,
			state: "",
			tipo: "fornecedor",
		})

		setEditEmpresaData({
			address: "",
			cnpj: "",
			email: "",
			cep: "",
			city: "",
			complement: "",
			district: "",
			ie: "",
			name: "",
			number: undefined,
			state: "",
			tipo: "fornecedor"
		})
	}

	const resetExpensesData = () => {
		setNewExpenseData({
            isRecurrent: false,
			date: dayjs().toDate(),
            prazo: 0,
			data_pagamento: null,
			data_vencimento: null,
			description: "",
			value: 0,
			metodoPagamento: 0,
			darf: 0,
			fgts: 0,
			simples_nacional: 0
		})
		setEditExpenseData({
            isRecurrent: false,
			date: dayjs().toDate(),
            prazo: 0,
			data_pagamento: null,
			data_vencimento: null,
			description: "",
			value: 0,
			metodoPagamento: 0,
			darf: 0,
			fgts: 0,
			simples_nacional: 0
		})
	}

    const handleCancelCreateEmpresa = () => {
        onCloseCreateEmpresa()
        resetEmpresaValues()
    }

    const handleCancelEditEmpresa = () => {
        onCloseEditEmpresa()
        resetEmpresaValues()
    }

    const handleCancelCreateExpense = () => {
        setIsOpenNewExpense(false)
        resetExpensesData()
    }

    const handleCancelEditExpense = () => {
        setIsOpenEditExpense(false)
        resetExpensesData()
    }

    const setNewExpenseDate = (date: Date) => {
        setNewExpenseData({
            ...newExpenseData,
            date: date
        })
    }

    const handleCreateExpense = () => {
        api.post('/operacaoexpenses/new', {
            operacaoId: id,
            description: newExpenseData.description,
            date: dayjs(newExpenseData.date).toISOString(),
            isRecurrent: newExpenseData.isRecurrent,
            value: newExpenseData.value,
			empresaId: newExpenseData.empresaId,
			prazo: newExpenseData.prazo,

			referente: newExpenseData.referente,
			data_pagamento: dayjs(newExpenseData.data_pagamento).isValid() ? dayjs(newExpenseData.data_pagamento).toISOString() : undefined,
			data_vencimento: dayjs(newExpenseData.data_vencimento).isValid() ? dayjs(newExpenseData.data_vencimento).toISOString() : undefined,
			metodoPagamento: newExpenseData.metodoPagamento,
			darf: newExpenseData.darf,
			fgts: newExpenseData.fgts,
			simples_nacional: newExpenseData.simples_nacional

        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Despesa criada com sucesso!", {
                type: "success",
            })
            handleCancelCreateExpense()
            loadData()
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
            })
        })
    }

    const handleEditExpense = () => {
        api.put('/operacaoexpenses/edit/'+editExpenseData.id, {
            description: editExpenseData.description,
            date: dayjs(editExpenseData.date).toISOString(),
            isRecurrent: editExpenseData.isRecurrent,
            value: editExpenseData.value,
			empresaId: editExpenseData.empresaId,

			referente: editExpenseData.referente,
			data_pagamento: dayjs(editExpenseData.data_pagamento).isValid() ? dayjs(editExpenseData.data_pagamento).toISOString() : undefined,
			data_vencimento: dayjs(editExpenseData.data_vencimento).isValid() ? dayjs(editExpenseData.data_vencimento).toISOString() : undefined,
			metodoPagamento: editExpenseData.metodoPagamento,
			darf: editExpenseData.darf,
			fgts: editExpenseData.fgts,
			simples_nacional: editExpenseData.simples_nacional
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Despesa editada com sucesso!", {
                type: "success",
            })
            handleCancelEditExpense()
            loadData()
        }).catch((error: ErrorData) => {
            if(error.response){
                toast(error.response.data.error, {
                    type: "error",
                })
            }else{
                toast("Erro indefinido", {
                    type: "error",
                })
            }
        })
    }

	const handleCreateEmpresa = () => {
		api.post('/empresas/create', newEmpresaData, {
            headers: {
                token: user?.token
            }
		}).then(()=> {
            toast("Empresa criada com sucesso!", {
                type: "success",
            })
            handleCancelCreateEmpresa()
            getEmpresas()
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
            })
        })
	}

    const editData = (key: keyof typeof editExpenseData, value: any) => {
        setEditExpenseData({
            ...editExpenseData,
            [key]: value
        })
    }

    const handleEditNewEmpresa = (key: keyof typeof newEmpresaData, value: any) => {
        setNewEmpresaData({
            ...newEmpresaData,
            [key]: value
        })
    }

    const handleEditEmpresaData = (key: keyof typeof editEmpresaData, value: any) => {
        setEditEmpresaData({
            ...editEmpresaData,
            [key]: value
        })
    }

    const cepBuilder = (val: any) => {
        return '99999-999'
    }

	const handleOpenEditEmpresa = () => {
		const selectedId = editExpenseData.empresaId || newExpenseData.empresaId

		if(!selectedId){
			toast("Nenhuma empresa selecionada", {
				type: "error",
				autoClose: 500
			})
			return
		}

		api.get('/empresas/view/'+selectedId, {
			headers: {
				token: user?.token
			}
			}).then((res)=>{
			if(res){
				if(res.data){
					setEditEmpresaData(res.data)
					onOpenEditEmpresa()
				}
			}
		}).catch((error: ErrorData)=>{
			toast(error.response.data.error, {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		})
	}


	const handleEditEmpresa = () => {
		const selectedId = editExpenseData.empresaId || newExpenseData.empresaId

		if(!selectedId){
			toast("Nenhuma empresa selecionada", {
				type: "error",
				autoClose: 500
			})
			return
		}

        api.put('/empresas/update/'+selectedId, editEmpresaData, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Empresa editada com sucesso!", {
                type: "success",
            })
            handleCancelEditEmpresa()
			getEmpresas()
        }).catch((error: ErrorData) => {
            if(error.response){
                toast(error.response.data.error, {
                    type: "error",
                })
            }else{
                toast("Erro indefinido", {
                    type: "error",
                })
            }
        })
	}

    const editNewExpenseData = (key: keyof OperacaoExpenses, val: any) => {
        let _new: OperacaoExpenses = newExpenseData
        ///@ts-ignore
        _new[key] = val
        setNewExpenseData({..._new})
    }

    const editEditExpenseData = (key: keyof OperacaoExpenses, val: any) => {
        let _new: OperacaoExpenses = editExpenseData
        ///@ts-ignore
        _new[key] = val
        setEditExpenseData({..._new})
    }

	const loadCardsData = () => {
        api.get('/operacaoexpenses/cards/'+id, {
            headers: {
                token: user?.token
            },
            params: {
                date: dayjs(searchDate).toISOString()
            }
        }).then(res=>{
            setCardsData(res.data)
        }).catch(err=> {
            toastError(err, darkmode)
        })
	}

    // useEffect(()=> {
    //     console.log(editExpenseData)
    // }, [editExpenseData])

    return(
        <>


			<SideMenu isOpen={isOpenNewExpense} setIsOpen={setIsOpenNewExpense} title={"Controle de despesa"} loading={false} onConfirm={() => {
					handleCreateExpense()
				}} onClose={()=> {
					resetExpensesData()
				}}>
                    <div className="flex flex-col gap-5">
						<div className="mt-3 flex items-center gap-3">
                                <Switch checked={newExpenseData.isRecurrent ? true : false} onChange={e=> {
									let newVal = !newExpenseData.isRecurrent
                                    const _data = {...newExpenseData, isRecurrent: newVal}

                                    setNewExpenseData(_data)
                                }} id="switch1" />
                                <label
                                    htmlFor="checkbox1"
                                    className="text-base font-medium text-navy-700 dark:text-white"
                                >
                                    Despesa recorrente?
                                </label>
						</div>
						{!newExpenseData.isRecurrent ?
							<>
								<div className="flex flex-col gap-1">
									<h2 className="text-md font-bold text-gray-800">Data</h2>
									<input type="date" className="h-[50px] px-2 border border-gray-600" defaultValue={dayjs(newExpenseData.date).format(`YYYY-MM-DDTHH:mm`)} onBlur={e=> {
										if(e.target.value && dayjs(e.target.value).isValid())
											editNewExpenseData(`date`, dayjs(e.target.value).toISOString())
									}} />
								</div>
							</>
						: <>

							<div className="flex flex-col gap-1">
								<h2 className="text-md font-bold text-gray-800">Número de parcelas(deixar 0 para infinito)</h2>
								<input type="number" className="h-[50px] px-2 border border-gray-600" defaultValue={newExpenseData.prazo} onBlur={e=> {
									editNewExpenseData(`prazo`, parseInt(e.target.value))
								}} />
							</div>
						</>}

						<div className="flex flex-col gap-1">
							<h2 className="text-md font-bold text-gray-800">Data de vencimento</h2>
							<input type="date" className="h-[50px] px-2 border border-gray-600" defaultValue={dayjs(newExpenseData.data_vencimento).format(`YYYY-MM-DDTHH:mm`)} onBlur={e=> {
								if(e.target.value && dayjs(e.target.value).isValid())
									editNewExpenseData(`data_vencimento`, dayjs(e.target.value).toISOString())
							}} />
						</div>

                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Referente</h2>

							<ReactSelect options={referenteSelect} value={{value: newExpenseData.referente, label: referenteSelect?.find(c=> c.value.toString() == newExpenseData.referente?.toString())?.label}} onChange={(e: any) => {
								editNewExpenseData("referente", e.value)
							}} noOptionsMessage={() => "Sem dados"} placeholder={"Selecione um"} styles={selectStyle} />
                        </div>

                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Empresa</h2>

							<AsyncSelect defaultOptions cacheOptions loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = empresas.filter(empresa=> replaceSpecialChars(empresa.content.toLowerCase()).includes(inputstring.toLowerCase())).map(empresa=>{
										let _empresa: any = {}
										_empresa.value = empresa.value
										_empresa.label = empresa.content
										return _empresa
									}).slice(0, 9)

									setTimeout(()=> {
										resolve(options)
									}, 250)
								})
							}} value={{value: newExpenseData.empresaId, label: empresas?.find(c=> c.value.toString() == newExpenseData.empresaId?.toString())?.content}} onChange={(e: any) => {
								editNewExpenseData("empresaId", e.value)
							}} noOptionsMessage={() => "Sem dados"} placeholder={"Selecione um"} styles={selectStyle} />
                        </div>


						<div className="flex flex-col gap-1">
							<h2 className="text-md font-bold text-gray-800">Descrição</h2>
							<textarea className="h-[150px] px-2 border border-gray-600 p-2" defaultValue={newExpenseData.description} onBlur={e=> {
								editNewExpenseData(`description`, e.target.value)
							}} />
						</div>


                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Valor</h2>
                            <CustomInputV2 type="currency" totalWidth={500} value={newExpenseData.value} setValue={(e) => {
                                editNewExpenseData('value', e)
                            }} containerClass="h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"/>
                        </div>

                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Método de pagamento</h2>

							<ReactSelect options={metodosPagamentoSelect} value={{value: newExpenseData.metodoPagamento, label: metodosPagamentoSelect?.find(c=> c.value.toString() == newExpenseData.metodoPagamento?.toString())?.label}} onChange={(e: any) => {
								editNewExpenseData("metodoPagamento", e.value)
							}} noOptionsMessage={() => "Sem dados"} placeholder={"Selecione um"} styles={selectStyle} />
                        </div>

						<div className="flex flex-col gap-1">
							<h2 className="text-md font-bold text-gray-800">Data de pagamento</h2>
							<input type="date" className="h-[50px] px-2 border border-gray-600" defaultValue={dayjs(newExpenseData.data_pagamento).format(`YYYY-MM-DD`)} onBlur={e=> {
								if(e.target.value && dayjs(e.target.value).isValid())
									editNewExpenseData(`data_pagamento`, dayjs(e.target.value).toISOString())
							}} />
						</div>

						{/* <div className="grid grid-cols-2 gap-2">
							<div className="flex flex-col gap-1">
								<h2 className="text-md font-bold text-gray-800">DARF</h2>
								<CustomInputV3
									suffixClass="dark:text-white absolute right-0"
									containerClass="relative h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"
									value={newExpenseData.darf}
									setValue={e=> editNewExpenseData('darf', e)} max={100} suffix="%"
								/>
							</div>
							<div className="flex flex-col gap-1">
								<h2 className="text-md font-bold text-gray-800">FGTS</h2>
								<CustomInputV3
									suffixClass="dark:text-white absolute right-0"
									containerClass="relative h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"
									value={newExpenseData.fgts}
									setValue={e=> editNewExpenseData('fgts', e)} max={100} suffix="%"
								/>
							</div>
							<div className="flex flex-col gap-1">
								<h2 className="text-md font-bold text-gray-800">Simples Nacional</h2>
								<CustomInputV3
									suffixClass="dark:text-white absolute right-0"
									containerClass="relative h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"
									value={newExpenseData.simples_nacional}
									setValue={e=> editNewExpenseData('simples_nacional', e)} max={100} suffix="%"
								/>
							</div>
						</div> */}

                    </div>
			</SideMenu>


			<SideMenu isOpen={isOpenEditExpense} setIsOpen={setIsOpenEditExpense} title={"Editar despesa"} loading={loadingEdit} onConfirm={() => {
					handleEditExpense()
				}} onClose={()=> {
					resetExpensesData()
				}}>
                    <div className="flex flex-col gap-5">

						<div className="flex flex-col gap-1">
							<h2 className="text-md font-bold text-gray-800">Data</h2>
							<input type="date" className="h-[50px] px-2 border border-gray-600" defaultValue={dayjs(editExpenseData.date).format(`YYYY-MM-DD`)} onBlur={e=> {
								if(e.target.value && dayjs(e.target.value).isValid())
									editEditExpenseData(`date`, dayjs(e.target.value).toISOString())
							}} />
						</div>

						<div className="flex flex-col gap-1">
							<h2 className="text-md font-bold text-gray-800">Data de vencimento</h2>
							<input type="date" className="h-[50px] px-2 border border-gray-600" defaultValue={dayjs(editExpenseData.data_vencimento).format(`YYYY-MM-DD`)} onBlur={e=> {
								if(e.target.value && dayjs(e.target.value).isValid())
									editEditExpenseData(`data_vencimento`, dayjs(e.target.value).toISOString())
							}} />
						</div>

                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Referente</h2>

							<ReactSelect options={referenteSelect} value={{value: editExpenseData.referente, label: referenteSelect?.find(c=> c.value.toString() == editExpenseData.referente?.toString())?.label}} onChange={(e: any) => {
								editEditExpenseData("referente", e.value)
							}} noOptionsMessage={() => "Sem dados"} placeholder={"Selecione um"} styles={selectStyle} />
                        </div>

                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Empresa</h2>

							<AsyncSelect defaultOptions cacheOptions loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = empresas.filter(empresa=> replaceSpecialChars(empresa.content.toLowerCase()).includes(inputstring.toLowerCase())).map(empresa=>{
										let _empresa: any = {}
										_empresa.value = empresa.value
										_empresa.label = empresa.content
										return _empresa
									}).slice(0, 9)

									setTimeout(()=> {
										resolve(options)
									}, 250)
								})
							}} value={{value: editExpenseData.empresaId, label: empresas?.find(c=> c.value.toString() == editExpenseData.empresaId?.toString())?.content}} onChange={(e: any) => {
								editEditExpenseData("empresaId", e.value)
							}} noOptionsMessage={() => "Sem dados"} placeholder={"Selecione um"} styles={selectStyle} />
                        </div>


						<div className="flex flex-col gap-1">
							<h2 className="text-md font-bold text-gray-800">Descrição</h2>
							<textarea className="h-[150px] px-2 border border-gray-600 p-2" defaultValue={editExpenseData.description} onBlur={e=> {
								editEditExpenseData(`description`, e.target.value)
							}} />
						</div>


                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Valor</h2>
                            <CustomInputV2 type="currency" totalWidth={500} value={editExpenseData.value} setValue={(e) => {
                                editEditExpenseData('value', e)
                            }} containerClass="h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"/>
                        </div>

                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Método de pagamento</h2>

							<ReactSelect options={metodosPagamentoSelect} value={{value: editExpenseData.metodoPagamento, label: metodosPagamentoSelect?.find(c=> c.value.toString() == editExpenseData.metodoPagamento?.toString())?.label}} onChange={(e: any) => {
								editEditExpenseData("metodoPagamento", e.value)
							}} noOptionsMessage={() => "Sem dados"} placeholder={"Selecione um"} styles={selectStyle} />
                        </div>

						<div className="flex flex-col gap-1">
							<h2 className="text-md font-bold text-gray-800">Data de pagamento</h2>
							<input type="date" className="h-[50px] px-2 border border-gray-600" defaultValue={dayjs(editExpenseData.data_pagamento).format(`YYYY-MM-DD`)} onBlur={e=> {
								if(e.target.value && dayjs(e.target.value).isValid())
									editEditExpenseData(`data_pagamento`, dayjs(e.target.value).toISOString())
							}} />
						</div>
{/*
						<div className="grid grid-cols-2 gap-2">
							<div className="flex flex-col gap-1">
								<h2 className="text-md font-bold text-gray-800">DARF</h2>
								<CustomInputV3
									suffixClass="dark:text-white absolute right-0"
									containerClass="relative h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"
									value={editExpenseData.darf}
									setValue={e=> editEditExpenseData('darf', e)} max={100} suffix="%"
								/>
							</div>
							<div className="flex flex-col gap-1">
								<h2 className="text-md font-bold text-gray-800">FGTS</h2>
								<CustomInputV3
									suffixClass="dark:text-white absolute right-0"
									containerClass="relative h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"
									value={editExpenseData.fgts}
									setValue={e=> editEditExpenseData('fgts', e)} max={100} suffix="%"
								/>
							</div>
							<div className="flex flex-col gap-1">
								<h2 className="text-md font-bold text-gray-800">Simples Nacional</h2>
								<CustomInputV3
									suffixClass="dark:text-white absolute right-0"
									containerClass="relative h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"
									value={editExpenseData.simples_nacional}
									setValue={e=> editEditExpenseData('simples_nacional', e)} max={100} suffix="%"
								/>
							</div>
						</div> */}

                    </div>
			</SideMenu>

			<Accordion allowToggle className={extraClasses}>
				<AccordionItem>
					<AccordionButton className="flex items-center justify-between">
						<h2 className="text-lg font-bold text-navy-700 dark:text-white">
							{title}
						</h2>
        				<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<Card extra={""} noShadow>
							<div className="mb-auto text-start flex items-center relative">
								<p className="mt-2 text-sm font-medium leading-4 text-gray-600">
									Mês referência: {months[dayjs(searchDate).month()]} de {dayjs(searchDate).year()}
								</p>
								<button type={"button"} onClick={()=> setIsOpenNewExpense(true)} className="text-sm ml-auto text-white rounded !font-bold !hover:font-bold hover:text-white bg-blue-500 p-2 flex items-center gap-2"><IoAdd /> Adicionar Despesa</button>
							</div>

							<div className="flex gap-2">
								<DashboardFinanceiroButtonWidget
									icon={<MdRefresh className="h-7 w-7" />}
									title={"Despesas recorrentes"}
									subtitle={formatToCurrency(cardsData.recorrente)}
								/>
								<DashboardFinanceiroButtonWidget
									icon={<MdNumbers className="h-7 w-7" />}
									title={"Despesas variáveis"}
									subtitle={formatToCurrency(cardsData.variavel)}
								/>
								<DashboardFinanceiroButtonWidget
									icon={<IoMdCash className="h-7 w-7" />}
									title={"Total faturado"}
									subtitle={formatToCurrency(cardsData.faturado)}
								/>
								<DashboardFinanceiroButtonWidget
									icon={<MdBarChart className="h-7 w-7" />}
									title={"Resultado"}
									subtitle={formatToCurrency(cardsData.resultado)}
								/>
							</div>
							<div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap">

								<div className="h-full w-full mt-5">
									<ExpensesTable setEditExpense={setEditExpenseData} setLoadingData={setLoadingEdit} setOpenEditExpense={(e) => {
										setIsOpenEditExpense(e)
									}} minH={minH} maxH={maxH} tableData={data} reloadTable={loadData} title="" loading={false}/>
								</div>
							</div>
						</Card>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
        </>
    )

}

export default CardGastosOperacao