import Card from "components/card"
import ReceitaProjetadaXRealizada from "./components/ReceitaProjetadaXRealizada"
import RentabilidadeXDespesa from "./components/RentabilidadeXDespesa"
import DetalhamentosDespesa from "./components/DetalhamentosDespesa"
import RelatorioVendasTotal from "./components/RelatorioVendasTotal"
import VendasXFabricante from "./components/VendasXFabricante"
import RankingReceitaFuncionarios from "./components/RankingReceitaFuncionarios"
import Calendar from "react-calendar"
import { MdBarChart, MdChevronLeft, MdChevronRight } from "react-icons/md"
import DashboardVendasButtonWidget from "./components/DashboardVendasButtonWidget"
import { FaMedal, FaMoneyBillWave } from "react-icons/fa"
import RankingFaturamentoFuncionario from "./components/RankingFaturamentoFuncionario"
import { useEffect, useState } from "react"
import Dropdown from "components/dropdown"
import dayjs from "dayjs"
import api from "services/api"
import { toastError } from "components/toasts/toastError"
import { useAppSelector } from "redux/hooks"
import MultiSelect, { useSelectItems } from "components/multi-select"
import { IoIosMedal } from "react-icons/io"
import DesempenhoAnoFiscalVendas from "./components/DesempenhoAnoFiscalVendas"

const DashboardVendas = () => {
	const user = useAppSelector(selector=> selector.user.user)
	const [cardsData, setCardsData] = useState({
		vendasTotais: 0,
		receitaBruta: 0,
		receitaLiquida: 0,
		liderRentabilidade: undefined,
		liderFaturamento: undefined
	})


	const [selectedDate, setSelectedDate] = useState({
		from: dayjs().startOf("month").toDate(),
		to: dayjs().endOf("month").toDate()
	})
	const [funcs, setFuncs] = useSelectItems([])
	const [funcsSelectedItems, setFuncsSelectedItems] = useSelectItems([])
	const [funcsSelectFilter, setFuncsSelectFilter] = useState('')

	const [operacoes, setOperacoes] = useSelectItems([])
	const [operacoesSelectedItems, setOperacoesSelectedItems] = useSelectItems([])
	const [operacoesSelectFilter, setOperacoesSelectFilter] = useState('')

	const [fabricantes, setFabricantes] = useState<{id: number, nome: string}[]>([])

	const [loadingPage, setLoadingPage] = useState(true)

	const getCardsData = () => {
		api.get("/financeiro/dashboard/cards", {
			params: {
				start: selectedDate.from,
				end: selectedDate.to,
                funcs: JSON.stringify(funcsSelectedItems),
				operacoes: JSON.stringify(operacoesSelectedItems)
			},
			headers: {
				token: user?.token
			}
		}).then(res=>{
			if(res){
				if(res.data){
					setCardsData(res.data)
				}
			}

			setLoadingPage(false)
		}).catch((err: any)=>{
			if(err){
				toastError(err, false)
			}
		})
	}

	const getFuncs = () => {
		api.get('/funcionarios/autocomplete2', {
			headers: {
			  token: user?.token
			},
		}).then((res: {data: {id: number, name: string, photo: string}[] })=>{
			const newData: {value: number, text: string}[] = []
			if(res){
				if(res.data){
					res.data.map(i=>{
						const val = {
							value: i.id.toString(),
							content: i.name,
						}
						newData.push({
							text: val.content,
							value: parseInt(val.value || '0')
						})
					})

				}
			}
			setFuncsSelectedItems([...newData])
			setFuncs([...newData])
		})
	}

	const getOperacoes = () => {
		api.get('/operacoes/autocomplete', {
			headers: {
			  token: user?.token
			},
		}).then((res: {data: {id: number, name: string}[] })=>{
			const newData: {value: number, text: string}[] = []
			if(res){
				if(res.data){
					res.data.map(i=>{
						const val = {
							value: i.id.toString(),
							content: i.name,
						}
						newData.push({
							text: val.content,
							value: parseInt(val.value || '0')
						})
					})

				}
			}
			setOperacoes([...newData])
			setOperacoesSelectedItems([...newData])
		})
	}

	const getFabricantes = () => {
		api.get('/salesdata/fabricantes', {
			headers: {
				token: user?.token
			}
		}).then(res=> {
			setFabricantes(res.data)
		})
	}

	const isSuperUser = (): boolean => {
		return (user?.admin || user?.permissions["acesso_bi"])
	}

	useEffect(()=> {
		getFuncs()
		getOperacoes()
		getFabricantes()
	}, [])
	useEffect(()=> {
		getCardsData()
	}, [selectedDate, funcsSelectedItems, operacoesSelectedItems])

	return (
		<div className="">

			<div className="mt-3 flex items-center gap-5 justify-end">
				{isSuperUser &&
				<>
					<MultiSelect showSelected={false} placeholder="Funcionários" selectAllText="Todos"
						classNames={{
							container: '!w-[240px] bg-white rounded-xl',
							box: '!bg-white !text-navy-700 dark:!bg-navy-900 dark:!text-white border border-gay-700',
							items: '!bg-white hover:!bg-opacity-20 !text-gray-900 dark:!bg-navy-700 dark:!text-white',
							select: 'h-full !text-xs p-2 !w-[240px] flex items-center justify-center font-bold !text-black hover:cursor-pointer dark:!bg-navy-700 dark:text-white rounded shadow !bg-white placeholder:text-black',
							selectedItems: "!bg-white ",
							selectAll: "text-gray-700 hover:text-gray-800 dark:text-white hover:dark:text-white !right-5"
						}} filter={funcsSelectFilter} setFilter={setFuncsSelectFilter} selectedElements={funcsSelectedItems} setSelectedElements={setFuncsSelectedItems} data={funcs}
					/>
					<MultiSelect showSelected={false} placeholder="Operações" selectAllText="Todas"
						classNames={{
							container: '!w-[240px] bg-white',
							box: '!bg-white !text-navy-700 dark:!bg-navy-900 dark:!text-white border border-gay-700',
							items: '!bg-white hover:!bg-opacity-20 !text-gray-900 dark:!bg-navy-700 dark:!text-white',
							select: 'h-full !text-xs p-2 !w-[240px] flex items-center justify-center font-bold !text-black hover:cursor-pointer dark:!bg-navy-700 dark:text-white rounded shadow !bg-white placeholder:text-black',
							selectedItems: "!bg-white ",
							selectAll: "text-gray-700 hover:text-gray-800 dark:text-white hover:dark:text-white !right-5"
						}} filter={operacoesSelectFilter} setFilter={setOperacoesSelectFilter} selectedElements={operacoesSelectedItems} setSelectedElements={setOperacoesSelectedItems} data={operacoes}
					/>
				</>}
				<Dropdown
					button={
						<button
							type="button"
							className={`no-arrows text-xs text-white bg-orange-500 rounded w-fit py-[1px] px-2 ml-auto mr-auto`}
						>
							{selectedDate ?
								<>
									{/* <b>{months[dayjs(selectedDate).month()]}</b> de <b>{dayjs(selectedDate).year()}</b> */}
									{<b className="text-nowrap">{dayjs(selectedDate.from).format("DD/MM/YYYY")} - {dayjs(selectedDate.to).format("DD/MM/YYYY")}</b>}
								</>
								: "Clique para definir"
							}
						</button>
					}
					extraClassesWrapper="w-fit h-10"
					extraClassesButton="w-fit h-10"
					animation={"origin-top transition-all duration-300 ease-in-out"}
					classNames={`top-11 right-0 w-max `}
					children={
					<div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
						<Calendar
							value={[selectedDate.from, selectedDate.to]}

							selectRange={true}
							// onClickMonth={e=> setSelectedDate(e)}
							onChange={(e: any)=>{
								const [from, to] = e
								setSelectedDate({
									from,
									to
								})
							}}
							prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
							nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
							view={"month"}
						/>
					</div>
					}
				/>
			</div>
			<div className="flex items-center gap-5 mt-3">

				<div className="grid gap-5 grid-cols-5 w-full">
					<DashboardVendasButtonWidget
						icon={<MdBarChart className="h-5 w-5" />}
						title={"Vendas no Período"}
						loading={loadingPage}
						subtitle={Intl.NumberFormat("pt-BR", {
							style: "currency",
							currency: "BRL",
						}).format(cardsData.vendasTotais)}
					/>
					<DashboardVendasButtonWidget
						icon={<FaMoneyBillWave className="h-5 w-5" />}
						title={"Receita Bruta no Período"}
						loading={loadingPage}
						subtitle={Intl.NumberFormat("pt-BR", {
							style: "currency",
							currency: "BRL",
						}).format(cardsData.receitaBruta)}
					/>
					<DashboardVendasButtonWidget
						icon={<MdBarChart className="h-5 w-5" />}
						title={"Receita Líquida no Período"}
						loading={loadingPage}
						subtitle={Intl.NumberFormat("pt-BR", {
							style: "currency",
							currency: "BRL",
						}).format(cardsData.receitaLiquida)}
					/>
					<DashboardVendasButtonWidget
						icon={<FaMedal className="h-5 w-5" />}
						title={"Líder de Rentabilidade"}
						loading={loadingPage}
						subtitle={cardsData.liderRentabilidade ? cardsData.liderRentabilidade : "N/A"}
					/>
					<DashboardVendasButtonWidget
						icon={<IoIosMedal className="h-5 w-5" />}
						title={"Líder de Faturamento"}
						loading={loadingPage}
						subtitle={cardsData.liderFaturamento ? cardsData.liderFaturamento : "N/A"}
					/>
				</div>
			</div>
			<div className="grid grid-cols-6 justify-center gap-10 mt-5 w-full">
				{isSuperUser ?
					<>
						<Card extra="w-full col-span-2">
							<RelatorioVendasTotal pageLoading={loadingPage} from={selectedDate.from} to={selectedDate.to} selectedFuncs={funcsSelectedItems} selectedOperacoes={operacoesSelectedItems} />
						</Card>
						<Card extra="w-full col-span-2">
							<VendasXFabricante pageLoading={loadingPage} fabricantes={fabricantes} from={selectedDate.from} to={selectedDate.to} selectedFuncs={funcsSelectedItems} selectedOperacoes={operacoesSelectedItems} />
						</Card>
						<Card extra="w-full col-span-2">
							<RankingReceitaFuncionarios pageLoading={loadingPage} from={selectedDate.from} to={selectedDate.to} selectedFuncs={funcsSelectedItems} selectedOperacoes={operacoesSelectedItems} />
						</Card>
						<Card extra="w-full col-span-2">
							<RankingFaturamentoFuncionario pageLoading={loadingPage} from={selectedDate.from} to={selectedDate.to} selectedFuncs={funcsSelectedItems} selectedOperacoes={operacoesSelectedItems} />
						</Card>
						<Card extra="w-full col-span-2">
							<DesempenhoAnoFiscalVendas pageLoading={loadingPage} from={selectedDate.from} to={selectedDate.to} selectedFuncs={funcsSelectedItems} selectedOperacoes={operacoesSelectedItems} />
						</Card>
					</>
				: <>

				</>}
			</div>
		</div>
	)
}

export default DashboardVendas