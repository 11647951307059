import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react"

import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { useNavigate } from "react-router-dom";
import { scopeNames } from "../data/scopeTypes";
import { OrderItem } from "types/app";

const NewScope = () => {
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const user = useAppSelector((state) => state.user.user)

    const [name, setName] = useState('')
    const [itemId, setItemId] = useState(-1)
    const [timeSpent, setTimeSpent] = useState("00:00")
    const [type, setType] = useState("")
    const [label, setLabel] = useState("")
    const [items, setItems] = useState<OrderItem[]>([])

    useEffect(()=>{
        api.get('/orders/items', {
        headers: {
            token: user?.token
        }
        }).then(res=>{
        setItems(res.data)
        }).catch((error: ErrorData)=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }, [])

    const navigate = useNavigate()

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if(name.trim() == ""){
            toast("Preencha o campo de Nome", {
                theme: darkmode ? "dark" : "light",
                type: "error"
            })
            return
        }
        if(type == "") {
            toast("Preencha o tipo de Escopo", {
                theme: darkmode ? "dark" : "light",
                type: "error"
            })
            return
        }
        // if(itemId == 0 || itemId == -1) {
        //     toast("Prencha o Item Referência", {
        //         theme: darkmode ? "dark" : "light",
        //         type: "error"
        //     })
        //     return
        // }

        const [hour, min] = timeSpent.split(":")

        const totalTime = (parseInt(hour) * 60) + parseInt(min)

        api.post('/scopesofservice/new', {
            itemId,
            name,
            timeSpent: totalTime,
            type,
            label
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Escopo cadastrado com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            navigate('/admin/scopesofservice')
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })

    }

    return(
        <>
            <form className="md:grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 mt-10 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">


                <FormControl className="flex flex-col gap-1 w-full lg:col-span-2">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Nome<b className="text-red-500">*</b></FormLabel>
                    <Input value={name} onChange={e=> setName(e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Nome do escopo(interno)" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full lg:col-span-2">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Descritivo</FormLabel>
                    <Input value={label} onChange={e=> setLabel(e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Descritivo do escopo(proposta)" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Tempo gasto<b className="text-red-500">*</b></FormLabel>
                    <Input style={{colorScheme: darkmode ? "dark" : "light"}} value={timeSpent} onChange={e=> {
                        setTimeSpent(e.target.value)
                    }} required type="time" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Email da empresa" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Tipo<b className="text-red-500">*</b></FormLabel>
                    <Select value={type} icon={<></>} onChange={e=> setType(e.target.value)} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
                        <option value={-1}>Não Atribuído</option>
                        {Object.entries(scopeNames).map(([key, name])=>(
                            <option key={"SCOPE-"+key} value={key}>{name}</option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Item Referência<b className="text-red-500">*</b></FormLabel>
                    <Select value={itemId ? itemId : -1} icon={<></>} onChange={e=> setItemId(parseInt(e.target.value))} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
                        <option value={-1}>Não Atribuído</option>
                        {items.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0).map((item)=>(
                            <option key={"ITEM-"+item.id} value={item.id}>{item.name}</option>
                        ))}
                    </Select>
                </FormControl>


                <button type="button" onClick={handleSubmit} className="linear h-10 mt-5 w-full md:w-fit px-10 ml-auto flex items-center justify-center rounded-xl bg-green-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
                    Criar
                </button>
            </form>
        </>
    )
}

export default NewScope