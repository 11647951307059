import React from "react";
import Card from "components/card";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useAppSelector } from "redux/hooks";

type RowObj = {
	funcId: number
	funcName: string
	baseSalary: number
	expenses: number
	architectComission: number
	contractsComission: number
	participantComission: number
	presellComission: number
	salesComission: number
	totalSalary: number
	refundExpenses: number
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function RelatorioSalarioTable(props: { tableData: RowObj[], title?: string, loading: boolean }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [defaultData, setDefaultData] = React.useState([])

  const columns = [
    columnHelper.accessor("funcName", {
      id: "funcName",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">FUNCIONÁRIO</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("baseSalary", {
      id: "baseSalary",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">SALÁRIO BASE</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {Intl.NumberFormat("BR", {
            currency: "BRL",
            style: "currency",

          }).format(info.getValue())}
        </p>
      ),
    }),
    columnHelper.accessor("salesComission", {
      id: "salesComission",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          COMISSÃO VENDAS
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
              {Intl.NumberFormat("BR", {
                currency: "BRL",
                style: "currency",

              }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("architectComission", {
      id: "architectComission",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          COMISSÃO ARQUITETO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
              {Intl.NumberFormat("BR", {
                currency: "BRL",
                style: "currency",

              }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("presellComission", {
      id: "presellComission",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          COMISSÃO PRÉ VENDAS
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
              {Intl.NumberFormat("BR", {
                currency: "BRL",
                style: "currency",

              }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("participantComission", {
      id: "participantComission",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          COMISSÃO PARTICIPANTE
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
              {Intl.NumberFormat("BR", {
                currency: "BRL",
                style: "currency",

              }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("expenses", {
      id: "rdv",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
			GASTOS
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {Intl.NumberFormat("BR", {
                currency: "BRL",
                style: "currency",

            }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("refundExpenses", {
      id: "refundExpenses",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
			GASTOS AO SALARIO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {Intl.NumberFormat("BR", {
                currency: "BRL",
                style: "currency",

            }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("contractsComission", {
      id: "contractsComission",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
			SALÁRIO TOTAL - SB
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {Intl.NumberFormat("BR", {
                currency: "BRL",
                style: "currency",

            }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("totalSalary", {
      id: "totalSalary",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
			SALÁRIO TOTAL - PROIT
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {Intl.NumberFormat("BR", {
                currency: "BRL",
                style: "currency",

            }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(()=>{
    setData(()=> [...tableData])
  }, [tableData])
  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-gray-900 dark:text-white">
          {props.title}
        </div>
      </div>

      <div className="mt-8 overflow-x-auto">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Nenhum orçamento encontrado...</h1> }
      </div>

    </Card>
  );
}
