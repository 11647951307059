import React from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import { MdArrowCircleLeft, MdArrowLeft, MdArrowRight, MdCancel, MdCheckCircle, MdDelete, MdEdit, MdOutlineError } from "react-icons/md";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@chakra-ui/tooltip";
import { ContactType, UserType } from "types/app";
import api from "services/api";
import avatar from "assets/img/avatars/default-profile.png";
import Swal from "sweetalert2";
import { useAppSelector } from "redux/hooks";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import TooltipHorizon from "components/tooltip";

const columnHelper = createColumnHelper<ContactType>();

// const columns = columnsDataCheck;
export default function ContactsTable(props: { tableData: ContactType[], reloadTable?: () => void, title?: string, page: number, setPage: React.Dispatch<number>, maxPages: number, loading?: boolean }) {
  const { tableData } = props;
  const darkmode = useAppSelector(state => state.layout.darkMode)
  const user = useAppSelector(state => state.user.user)
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [defaultData, setDefaultData] = React.useState([])
  const [openedImage, setOpenedImage] = React.useState(null)
  const navigate = useNavigate()

  const handleEdit = (data: CellContext<ContactType, any>) => {
    // console.log(data.row)
    navigate('edit/'+data.row.original.id)
  }

  const handleDelete = (data: CellContext<ContactType, any>) => {
    Swal.fire({
      title: "Remover Contato",
      text: "Você realmente deseja remover o contato "+data.row.original.name+"?",
      icon: "question",
      confirmButtonText: "Sim",
      showCancelButton: true,
      cancelButtonText: "Não",
      background: darkmode ? "rgb(11 20 55)" : "#fff",
      color: darkmode ? "#fff" : "#222",
    }).then(response=> {
      if(response.isConfirmed){
        api.delete('/contacts/delete/'+data.row.original.id, {
          headers: {
            token: user?.token
          }
        }).then(()=>{
          props.reloadTable()
          toast("Contato removido com sucesso!", {
              type: "success",
              theme: darkmode ? "dark" : "light"
          })
        }).catch((error: ErrorData)=>{
          // console.log(error)
          toast(error.response.data.error, {
              type: "error",
              theme: darkmode ? "dark" : "light"
          })
      })
      }
    })
  }

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">NOME</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white flex items-center gap-2">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor(null, {
      id: "companyName",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ORGANIZAÇÃO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {info.row.original.client ? info.row.original.client.name : "Sem empresa"}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          EMAIL
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {info.row.original.email ? info.row.original.email : "Sem email"}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("role", {
      id: "role",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          CARGO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {info.getValue() ? info.getValue() : "Sem cargo"}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor(null, {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">AÇÃO</p>
      ),
      cell: (info) => (
        <p className="flex items-center gap-2 text-xs text-navy-700 dark:text-white">
			<TooltipHorizon
				content={<span>Editar</span>}
				trigger={
					<button onClick={()=> handleEdit(info)} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<MdEdit />
					</button>
				}
				extra=""
				placement="top"
			/>
			<TooltipHorizon
				content={<span>Remover</span>}
				trigger={
					<button onClick={() => handleDelete(info)} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<MdDelete />
					</button>
				}
				extra=""
				placement="top"
			/>
        </p>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(()=>{
    setData(()=> [...tableData])
  }, [tableData])
  return (
    <>
      <div className={`${openedImage ? 'w-[400px] md:w-[500px] h-[400px]' : 'w-0 h-0'} object-cover transition-all overflow-hidden duration-200 fixed center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[200] bg-white dark:bg-navy-900 border rounded dark:border-navy-800`}>
        <div className="h-[30px] flex">
          <button onClick={()=> setOpenedImage(null)} className="ml-auto mt-2 mr-2 text-lg dark:text-white">X</button>
        </div>
        <div className="h-[90%] w-full p-5">
          <img
            className="rounded h-full w-full"
            style={{
              objectFit: "scale-down"
            }}
            src={openedImage}
            alt={"User"}
          />
        </div>
      </div>
      <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
        <div className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-gray-900 dark:text-white">
            {props.title}
          </div>
        </div>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="!border-px !border-gray-400">
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table
                .getRowModel()
                .rows
                .map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[150px] border-white/0 py-3  pr-4"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Não há nenhum usuário cadastrado na plataforma...</h1> }
        </div>

        <div className="mt-2 gap-6 flex items-center justify-center">
          <button disabled={props.page <= 1} onClick={() => props.setPage(props.page - 1)} className="linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
            <MdArrowLeft />
          </button>
          <span className="text-gray-900 dark:text-gray-200">
              {props.page}
          </span>
          <button disabled={props.page >= props.maxPages} onClick={() => props.setPage(props.page + 1)}  className="linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
            <MdArrowRight />
          </button>
        </div>
      </Card>
    </>
  );
}
