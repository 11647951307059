import React, { RefObject, useEffect, useRef } from 'react'

interface SheetType {
    data: any,
    setData: any,
    fixedHeader?: boolean
    selectedRows?: number[]
    setSelectedRows?: React.Dispatch<number[]>
    columns: {
        label: string,
        minWidth?: number,
        maxWidth?: number,
        width?: number,
        editable?: boolean,
        key: string,
        customRender?: (index: number, val: any, setVal: (value: any) => void) => JSX.Element
    }[],
    customStyles?: {
        outer?: string
        table?: string
        head?: {
            container?: string,
            line?: string,
            cell?: string
        },
        body?: {
            container?: string,
            line?: string,
            cell?: string
        }
    }
}

const Sheet = ({
    data,
    setData,
    columns,
    customStyles,
    selectedRows,
    setSelectedRows
}: SheetType) => {

    const mainCheckbox = useRef<HTMLInputElement>(null)

    const editValue = (index: number, key: string, value: any, e?   : React.ChangeEvent<HTMLInputElement>) => {
        let _newData = data
        _newData[index][key] = value

        setData([..._newData])
    }

    const handleSelectAll = () => {
        if (selectedRows == undefined || setSelectedRows == undefined) return;
        if (selectedRows.length > 0){
            setSelectedRows([])
        } else{
            if (data == undefined) return;
            let ids: number[] = []
            data.map((item: {id: number})=>{
                if (item.id == undefined) return;
                ids.push(item['id'])
            })

            setSelectedRows([...ids])
        }
    }

    const handleSelect = (id: number) => {
        if (id == undefined) return;
        if (selectedRows == undefined || setSelectedRows == undefined) return;
        if (selectedRows.find(_id=> _id == id)){
            let filteredSelected = selectedRows.filter(row=> row != id)
            setSelectedRows([...filteredSelected])
        }else{
            let addSelected = selectedRows
            addSelected.push(id)
            setSelectedRows([...addSelected])
        }
    }

    useEffect(()=> {
        if (!mainCheckbox.current) return
        if (selectedRows == undefined) return
        if(selectedRows.length == data.length) {
            mainCheckbox.current.indeterminate = false
        }else if(selectedRows.length > 0) {
            mainCheckbox.current.indeterminate = true
        }else{
            mainCheckbox.current.indeterminate = false
        }
    }, [selectedRows, mainCheckbox.current, data])

    return(
        <div className={`w-full overflow-x-auto ${customStyles?.outer}`}>
            <table className={`w-full border-collapse border border-gray-300 dark:text-white ${customStyles?.table}`}>
                <thead className={`w-full ${customStyles?.head?.container} border-b-[1px] border-gray-200 dark:border-navy-900 `}>
                    <tr className={`w-full ${customStyles?.head?.line}`}>
                        {selectedRows != undefined &&
                            <th style={{minWidth: 40, maxWidth: 40, width: 40}} className={`text-center border border-gray-300 dark:border-navy-900 dark:text-white p-1 px-2 py-2 bg-gray-200 dark:bg-navy-700 ${customStyles?.head?.cell}`}>
                                <input ref={mainCheckbox} checked={selectedRows != undefined ? selectedRows.length > 0 ? true : false : false} onClick={handleSelectAll} type='checkbox' />
                            </th>
                        }
                        {columns.map(col=>(
                            <th key={col.key} style={{minWidth: col.minWidth, maxWidth: col.maxWidth, width: col.width}} className={`text-start text-xs border border-gray-300 dark:border-navy-900 dark:text-white px-2 py-2 bg-gray-200 dark:bg-navy-700 ${customStyles?.head?.cell}`}>
                                {col.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className={`overflow-auto w-full ${customStyles?.body?.container}`}>
                    {data.map((row: any, index: any)=>(
                        <tr key={"ROW-"+index} className={`w-full ${customStyles?.body?.line}`}>

                            {selectedRows != undefined &&
                                <td style={{minWidth: 40, maxWidth: 40, width: 40}} className={` border border-gray-300 dark:border-navy-900 text-xs dark:text-white text-center ${customStyles?.body?.cell}`}>
                                    <input onClick={()=> handleSelect(row['id'])} checked={selectedRows.find(i=> i == row['id']) ? true : false || false} type='checkbox' />
                                </td>
                            }
                            {columns.map(col=>{
                                if(col.customRender){
                                    return <td style={{minWidth: col.minWidth, maxWidth: col.maxWidth, width: col.width}} key={"COL-"+index+"-"+col.key} className={`text-xs border border-gray-300 dark:border-navy-900 dark:text-white p-1 ${customStyles?.body?.cell}`}> {col.customRender(index, row[col.key], (val: any)=> {editValue(index, col.key, val)})} </td>
                                }else{
                                    return(
                                        <td style={{minWidth: col.minWidth, maxWidth: col.maxWidth, width: col.width}} key={"COL-"+index+"-"+col.key} className={`text-xs border border-gray-300 dark:border-navy-900 dark:text-white ${customStyles?.body?.cell}`}>
                                            <input disabled={col.editable != undefined ? !col.editable : false} type='text' className='h-full p-3 w-full bg-transparent' autoFocus={true} onChange={e=> {
                                                e.preventDefault()
                                                editValue(index, col.key, e.target.value, e)
                                            }} value={row[col.key] ? row[col.key] : ""} />
                                        </td>
                                    )
                                }
                            })}
                        </tr>
                    ))}

                </tbody>
            </table>
        </div>
    )
}

export default Sheet