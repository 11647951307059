import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react"
import InputField from "components/fields/InputField"
import SelectField from "components/fields/SelectField"
import TooltipHorizon from "components/tooltip"
import { useEffect, useState } from "react"
import { MdDelete } from "react-icons/md"
import { toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { addItem, addItemCategory, removeItem, resetOrder, selectCategory, selectClient, selectItem, selectItemQtd, selectParam, selectParamQtd, selectSubcategory, setClientName, setDate, setEstimatedTime1, setExecutive, setPaymentMethod1 } from "redux/reducers/orderSlice"
import api from "services/api"
import { ClientType, ItemParam, OrderCategory, OrderItem, OrderSubcategory } from "types/app"
import Cart from "../components/Cart"
import Swal from "sweetalert2"
import dayjs from "dayjs"
import ClientsAutocomplete from "../components/AutoComplete"
import consultarCNPJ from 'consultar-cnpj'
import apiCeps from "services/apiCeps"
import { ErrorData } from "types/fetch"

interface SelectType {
    content: string,
    value: string
}

const NewOrder = () => {
    const user = useAppSelector((state) => state.user.user)
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const order = useAppSelector((state) => state.order)
    const dispatch = useAppDispatch()
    const [categoryData, setCategoryData] = useState<OrderCategory[]>([])
    const [subcategoryData, setSubcategoryData] = useState<OrderSubcategory[]>([])
    const [itemsData, setItemsData] = useState<OrderItem[]>([])
    const [itemsParams, setItemsParams] = useState<ItemParam[]>([])
    const [clientSelect, setClientSelect] = useState<SelectType[]>([]);
    const [clientNameTimeout, setClientNameTimeout] = useState<any>()
    const [loadingClients, setLoadingClients] = useState(true)
    const [pageLoaded, setPageLoaded] = useState(false)

    const [executives, setExecutives] = useState<{value: number, content: string}[]>([])

    const getCategorys = () => {
        api.get("/orders/categorys", {
            headers: {
                token: user?.token
            }
        }).then((res: { data: OrderCategory[] }) => {
            setCategoryData(res.data)
        }).catch((error) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const getSubcategorys = () => {
        api.get("/orders/subcategorys", {
            headers: {
                token: user?.token
            }
        }).then((res: { data: OrderSubcategory[] }) => {
            setSubcategoryData(res.data)
        }).catch((error) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const getItems = () => {
        api.get("/orders/items", {
            headers: {
                token: user?.token
            }
        }).then((res: { data: OrderItem[] }) => {
            setItemsData(res.data)
        }).catch((error) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const getParams = () => {
        api.get("/orders/params", {
            headers: {
                token: user?.token
            }
        }).then((res: { data: ItemParam[] }) => {
            setItemsParams(res.data)
        }).catch((error) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const getClients = (search?: string) => {
        setLoadingClients(true)
        api.get("/clients/all/autocomplete", {
            headers: {
                token: user?.token
            },
            params: {
                search: search ? search : ""
            }
        }).then((res: { data: ClientType[] }) => {
            setLoadingClients(false)
            var _newClients: SelectType[] = []
            res.data.map(client => {
                _newClients.push({
                    content: client.name ? client.name : "Sem nome",
                    value: client.id.toString()
                })
            })
            setClientSelect(_newClients)
        }).catch((error) => {
            setLoadingClients(false)
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const getExecutives = () => {
        api.get("/orders/executives/self", {
            headers: {
                token: user?.token
            },
        }).then((res: { data: {content: string, value: number}[] }) => {
            setExecutives(res.data)
        }).catch((error) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    useEffect(() => {
        getCategorys()
        getSubcategorys()
        getItems()
        getParams()
        getClients()
        getExecutives()
    }, [])

    useEffect(() => {
        setPageLoaded(false)
        if (order.order.length > 0 || order.clientId || order.paymentMethod1 != "" || order.estimatedTime1 != "" || order.executive != -1) {
            Swal.fire({
                title: "Orçamento encontrado",
                text: "Você iniciou um orçamento e não finalizou, deseja continuá-lo?",
                icon: "question",
                confirmButtonText: "Sim",
                showCancelButton: true,
                cancelButtonText: "Não",
                background: darkmode ? "rgb(11 20 55)" : "#fff",
                color: darkmode ? "#fff" : "#222",
                allowOutsideClick: false
            }).then(response => {
                if (response.isConfirmed) {
                    setPageLoaded(true)
                } else if (response.isDenied) {
                    dispatch(resetOrder({}))
                    setPageLoaded(true)
                } else if (response.isDismissed) {
                    dispatch(resetOrder({}))
                    setPageLoaded(true)
                }
            })
        } else {
            dispatch(resetOrder({}))
            setPageLoaded(true)
        }
    }, [])

    useEffect(() => {
        if (!pageLoaded) return;
        if (order?.clientId) {

            api.get("/clients/checklastupdate/" + order?.clientId, {
                headers: {
                    token: user?.token
                }
            }).then(async (response) => {
                const { needUpdate, cnpj }: { needUpdate: boolean, cnpj: string } = response.data
                if (needUpdate) {


                    Swal.fire({
                        title: "Cliente desatualizado",
                        text: "Fazem 3 meses que não recebemos atualizações desse cliente, antes de continuar o orçamento deseja atualizar o cliente selecionado?",
                        icon: "question",
                        confirmButtonText: "Sim",
                        showCancelButton: true,
                        cancelButtonText: "Não",
                        background: darkmode ? "rgb(11 20 55)" : "#fff",
                        color: darkmode ? "#fff" : "#222",
                        allowOutsideClick: false
                    }).then(async (response) => {
                        if (response.isConfirmed) {

                            try {
                                const empresa = await consultarCNPJ(cnpj)
                                const inicioArray = empresa.estabelecimento.data_inicio_atividade.split('-')
                                const dataInicio = {
                                    ano: inicioArray[0] ? inicioArray[0] : '0',
                                    mes: inicioArray[1] ? inicioArray[1] : '0',
                                    dia: inicioArray[2] ? inicioArray[2] : '0'
                                }
                                if (empresa) {
                                    const data = {
                                        razao: empresa.razao_social,
                                        cep: empresa.estabelecimento.cep,
                                        number: empresa.estabelecimento.numero,
                                        complemento: empresa.estabelecimento.complemento,
                                        bairro: empresa.estabelecimento.bairro,
                                        cidade: empresa.estabelecimento.cidade.nome,
                                        estado: empresa.estabelecimento.estado.nome,
                                        endereco: empresa.estabelecimento.logradouro,
                                        ie: "",
                                        atividadesPr: empresa.estabelecimento.atividade_principal,
                                        atividadesSec: empresa.estabelecimento.atividades_secundarias,
                                        socios: empresa.socios,
                                        tipo: empresa.estabelecimento.tipo,
                                        capital: parseFloat(empresa.capital_social),
                                        inicio: dayjs().year(parseInt(dataInicio.ano)).month(parseInt(dataInicio.mes)).day(parseInt(dataInicio.dia)),
                                        situacao: empresa.estabelecimento.situacao_cadastral,
                                    }

                                    if (empresa.estabelecimento.inscricoes_estaduais.length && empresa.estabelecimento.inscricoes_estaduais) {
                                        let activeIE = false
                                        let selectedIE = null
                                        empresa.estabelecimento.inscricoes_estaduais.map(_ie => {
                                            if (_ie.ativo == true && !activeIE) {
                                                activeIE = true
                                                selectedIE = _ie.inscricao_estadual
                                            }
                                        })

                                        if (activeIE) {
                                            data["ie"] = empresa.estabelecimento.inscricoes_estaduais[0].inscricao_estadual;
                                        } else {
                                            data["ie"] = "Isento"
                                        }
                                    } else {
                                        data["ie"] = "Isento"
                                    }

                                    if (empresa.estabelecimento.cep) {

                                        apiCeps.get("/cep", {
                                            params: {
                                                cep: empresa.estabelecimento.cep
                                            }
                                        }).then(res => {
                                            if (res.data) {
                                                if (res.data.rua && res.data.rua != "") {
                                                    data['endereco'] = res.data.rua
                                                }

                                                if (res.data.estados.nome && res.data.estados.nome != "") {
                                                    data["estado"] = res.data.estados.nome
                                                }

                                                if (res.data.cidades.nome && res.data.cidades.nome != "") {
                                                    data['cidade'] = res.data.cidades.nome
                                                } else {

                                                }

                                                if (res.data.bairro && res.data.bairro != "") {
                                                    data["bairro"] = res.data.bairro
                                                } else {
                                                    data["bairro"] = empresa.estabelecimento.bairro
                                                }

                                                api.put('/client/' + order?.clientId, {
                                                    name: data["razao"],
                                                    address: data["endereco"],
                                                    ie: data["ie"],
                                                    number: data["number"],
                                                    city: data["cidade"],
                                                    complement: data["complemento"],
                                                    state: data["estado"],
                                                    district: data["bairro"],
                                                    cep: empresa.estabelecimento.cep,
                                                    lastUpdate: true,

                                                    atividadePrimaria: data["atividadesPr"],
                                                    atividadesSecundarias: data["atividadesSec"],
                                                    socios: data["socios"],
                                                    tipo: data["tipo"],
                                                    capital: data["capital"],
                                                    situacao: data["situacao"],
                                                    inicio: data["inicio"],
                                                }, {
                                                    headers: {
                                                        token: user?.token
                                                    }
                                                }).then(() => {
                                                    toast("Organização atualizada com sucesso!", {
                                                        type: "success",
                                                        theme: darkmode ? "dark" : "light"
                                                    })
                                                }).catch((error: ErrorData) => {
                                                    toast(error.response.data.error, {
                                                        type: "error",
                                                        theme: darkmode ? "dark" : "light"
                                                    })
                                                })
                                            }
                                        })
                                    }
                                }

                            } catch (error: any) {
                                toast("CNPJ não encontrado na base do Governo", {
                                    type: "error",
                                    theme: darkmode ? "dark" : "light"
                                })
                            }
                        } else if (response.isDenied) {
                        } else if (response.isDismissed) {
                        }
                    })
                } else {

                }
            })
        }
    }, [order?.clientId])

    const getSubcategorySelect = (categoryId: number): SelectType[] => {
        console.log(categoryId)
        var _newSubcategoryData: SelectType[] = []
        subcategoryData.filter(data => data.categoryId == categoryId).map(subcategory => {
            _newSubcategoryData.push({
                content: subcategory.name,
                value: subcategory.id.toString()
            })
        })
        return _newSubcategoryData.sort((a, b) => {
			if(a.content > b.content){
				return 1
			}else if(a.content < b.content){
				return -1
			}else{
				return 0
			}
		})
    }

    const getItemsSelect = (subcategoryId: number): SelectType[] => {
        var _newItemSelect: SelectType[] = []
        itemsData.filter(data => data.subcategoryId == subcategoryId).map(item => {
            _newItemSelect.push({
                content: item.name,
                value: item.id.toString()
            })
        })
        return _newItemSelect.sort((a, b) => {
			if(a.content > b.content){
				return 1
			}else if(a.content < b.content){
				return -1
			}else{
				return 0
			}
		})
    }

    const getParamsSelect = (itemId: number): SelectType[] => {
        var _newParamsSelect: SelectType[] = []
        itemsParams.filter(data => data.itemId == itemId).map(param => {
            _newParamsSelect.push({
                content: param.name,
                value: param.id.toString()
            })
        })

        return _newParamsSelect.sort((a, b) => {
			if(a.content > b.content){
				return 1
			}else if(a.content < b.content){
				return -1
			}else{
				return 0
			}
		})
    }

    const handleAddOrderItem = () => {
        dispatch(addItem({}))
    }

    const handleAddOrderItemInCart = (id: number) => {
        dispatch(addItemCategory({ categoryId: id }))
    }

    const handleRemoveOrderItem = (itemIndex: number) => {
        dispatch(removeItem(itemIndex))
    }

    const handleSelectCategory = (itemIndex: number, categoryId: string) => {
        const foundCat = categoryData.find(cat => cat.id == parseInt(categoryId))
        if (foundCat) {
            const categoryName = foundCat.name || ""
            dispatch(selectCategory({ itemIndex, categoryId: categoryId != "0" ? parseInt(categoryId) : 0, categoryName }))
        } else {
            dispatch(selectCategory({ itemIndex, categoryId: 0, categoryName: "" }))
        }
    }

    const handleSelectSubcategory = (itemIndex: number, subcategoryId: string) => {
        const foundSub = subcategoryData.find(sub => sub.id == parseInt(subcategoryId))
        if (foundSub) {
            const subcategoryName = foundSub.name || ""
            dispatch(selectSubcategory({ itemIndex, subcategoryId: subcategoryId != "0" ? parseInt(subcategoryId) : 0, subcategoryName }))
        } else {
            dispatch(selectSubcategory({ itemIndex, subcategoryId: 0, subcategoryName: "" }))
        }
    }

    const handleSelectItem = (itemIndex: number, itemId: string) => {
        const foundItem = itemsData.find(item => item.id == parseInt(itemId))
        if (foundItem) {
            const itemName = foundItem.name || ""
            const itemType = foundItem.type
            dispatch(selectItem({ itemIndex, itemId: itemId != "0" ? parseInt(itemId) : 0, itemName, itemType }))
        } else {
            dispatch(selectItem({ itemIndex, itemId: 0, itemName: "" }))
        }
    }

    const handleEditItemQtd = (itemIndex: number, qtd: number) => {
        if (qtd < 0) qtd = 0
        if (isNaN(qtd)) qtd = 0
        dispatch(selectItemQtd({ itemIndex, qtd }))
    }

    const handleSelectParam = (itemIndex: number, paramId: string) => {
        const foundParam = itemsParams.find(item => item.id == parseInt(paramId))
        if (foundParam) {
            const paramName = foundParam.name || ""
            dispatch(selectParam({ itemIndex, paramId: paramId != "0" ? parseInt(paramId) : 0, paramName }))
        } else {
            dispatch(selectParam({ itemIndex, paramId: 0, paramName: "" }))
        }
    }

    const handleEditParamQtd = (itemIndex: number, qtd: number) => {
        if (qtd < 0) qtd = 0
        dispatch(selectParamQtd({ itemIndex, qtd }))
    }

    const handleSelectClient = (newClient: string) => {
        const _clientId = isNaN(parseInt(newClient)) ? 0 : parseInt(newClient)
        dispatch(selectClient(_clientId))
    }

    const handleChangeClient = (clientName: string) => {
        dispatch(setClientName(clientName))
        if (clientNameTimeout) {
            clearInterval(clientNameTimeout)
        }
        setClientNameTimeout(setTimeout(() => {
            getClients(clientName)
            setClientNameTimeout(null)
        }, 500))
    }

    const handleEditValidity = (date: any) => {
        dispatch(setDate(dayjs(date).toISOString()))
    }

    const handleSelectEstimatedTime1 = (newTime: any) => {
        dispatch(setEstimatedTime1(newTime))
    }

    const handleSelectPaymentMethod1 = (newMethod: any) => {
        dispatch(setPaymentMethod1(newMethod))
    }
    const handleSelectExecutive = (newExecutive: any) => {
        dispatch(setExecutive(newExecutive))
    }

    return (
        <>
            <div className="mt-10">
                <div className="grid gap-5 grid-cols-1 md:grid-cols-2">
                    {/* LIST */}
                    <div className="flex flex-col gap-5 mt-2 w-full px-5 py-2">
                        {/* CLIENT AND DATE */}
                        <div className="grid gap-5 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-6">
                            <ClientsAutocomplete
                                loading={loadingClients}
                                extra=""
                                className="md:col-span-1 2xl:col-span-4"
                                label="Organização"
								labelClasses="font-bold text-sm pl-2"
                                variant="auth"
                                placeholder="Selecione uma organização"
                                id="orderClient"
                                onChange={handleChangeClient}
                                onSelect={handleSelectClient}
                                value={order.clientName}
                                options={clientSelect}
                            />
                            <InputField
                                variant="auth"
                                extra="2xl:col-span-2"
								inputExtra="bg-white"
								labelExtra="!font-bold text-sm pl-1"
                                label="Validade"
                                value={dayjs(order.validUntil).format("YYYY-MM-DD")}
                                onChange={e => handleEditValidity(e.target.value)}
                                placeholder="Validade da proposta"
                                id="validity"
                                type="date"
                            />
                            <SelectField
								extra="2xl:col-span-3"
								label="Prazo de Entrega"
								inputExtra="bg-white"
								onChange={e => handleSelectEstimatedTime1(e.target.value)}
								value={order.estimatedTime1}
								id="itemSubcategory"
								options={[
									{
										content: "Selecione um prazo",
										value: ""
									},
									{
										content: "15 dias após assinatura",
										value: "15 dias após assinatura"
									},
									{
										content: "30 dias após assinatura",
										value: "30 dias após assinatura"
									},
									{
										content: "45 dias após assinatura",
										value: "45 dias após assinatura"
									},
									{
										content: "60 dias após assinatura",
										value: "60 dias após assinatura"
									},

								]}
							/>
                            <SelectField
								extra="2xl:col-span-3"
								label="Condições de Pagamento"
								inputExtra="bg-white"
								onChange={e => handleSelectPaymentMethod1(e.target.value)}
								value={order.paymentMethod1}
								id="itemSubcategory"
								options={[
									{
										content: "Selecione uma condição",
										value: ""
									},
									{
										content: "Mensal",
										value: "Mensal"
									},
									{
										content: "30 dias",
										value: "30 dias"
									},
									{
										content: "30/60 dias",
										value: "30/60 dias"
									},
									{
										content: "30/60/90 dias",
										value: "30/60/90 dias"
									},

								]}
							/>

                            {(user.permissions.arquiteto_geral || executives.length > 0) && <SelectField
								extra="2xl:col-span-3"
								label="Executivo da Proposta"
								onChange={e => handleSelectExecutive(e.target.value)}
								value={order.executive}
								id="itemSubcategory"
								options={[
									{
										content: "Você",
										value: -1
									},
									...executives

								]}
							/>}
                        </div>

                        <div className="flex flex-col gap-5">
                            {categoryData?.map(category => (
                                <Accordion allowMultiple className="p-5 bg-gray-50 dark:bg-navy-700 shadow-md dark:hover:bg-navy-800 text-gray-600 dark:text-white rounded w-full">
                                    <AccordionItem className="">
                                        <div className="flex items-center ">
                                            <AccordionButton _expanded={{}} className="text-gray-900 dark:text-white">
                                                <span className="font-bold text-sm">{category.name}</span>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </div>

                                        <AccordionPanel className="flex flex-col">
                                            {/* ADD ITEM */}
                                            {/* <button onClick={() => handleAddOrderItemInCart(category.id)} className="mb-5 linear ml-auto w-fit rounded-xl bg-green-700 text-white transition duration-200 hover:bg-green-600 active:bg-green-800 dark:bg-green-800 dark:text-white dark:hover:bg-green-700 dark:active:bg-green-900 p-2">Adicionar item</button> */}
                                            {order?.order?.map((item, itemIndex) => item.categoryId == category.id && (
                                                <div className="orderItem rounded-xl p-2" key={"TOTALITEM-" + itemIndex}>
                                                    <Accordion allowMultiple className="hover:bg-gray-50 dark:hover:bg-navy-800 text-gray-600 dark:text-white rounded w-full">
                                                        <AccordionItem className="">
                                                            <div className="flex items-center ">
                                                                <AccordionButton _expanded={{}} className="text-gray-900 text-sm font-medium dark:text-white">
                                                                    <span className="text-bold">{item.paramName ? item.paramName != "" ? item.paramQtd : item.itemQtd : item.itemQtd}x {item.categoryName ? item.categoryName : ""} {item.paramName ? item.paramName : item.itemName ? item.itemName : ""}</span>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                                <TooltipHorizon
                                                                    content={<span>Remover item</span>}
                                                                    trigger={<button type="button" onClick={() => handleRemoveOrderItem(itemIndex)} className="rounded-full bg-red-900 dark:bg-red-800 text-white p-2">
                                                                        <MdDelete className="" size={12} />
                                                                    </button>}
                                                                    extra=""
                                                                    placement="top"
                                                                />
                                                            </div>
                                                            <AccordionPanel className="mt-2 pb-10 grid grid-cols-1 md:grid-cols-2 gap-5">
                                                                <SelectField
                                                                    extra=""
                                                                    label="Subcategoria"
																	inputExtra="shadow-lg border border-gray-200"
																	labelExtra="text-sm"
                                                                    disabled={order.order[itemIndex].categoryId ? false : true}
                                                                    variant="auth"
                                                                    placeholder="Subcategoria do item"
                                                                    onChange={e => handleSelectSubcategory(itemIndex, e.target.value)}
                                                                    value={order.order[itemIndex].subcategoryId}
                                                                    id="itemSubcategory"
                                                                    options={[
                                                                        {
                                                                            content: "Selecione uma subcategoria",
                                                                            value: "0"
                                                                        },
                                                                        ...getSubcategorySelect(order.order[itemIndex].categoryId)

                                                                    ]}
                                                                />
                                                                <SelectField
                                                                    extra=""
                                                                    label="Item"
																	labelExtra="text-sm"
																	inputExtra="shadow-lg border border-gray-200"
                                                                    disabled={order.order[itemIndex].subcategoryId ? false : true}
                                                                    variant="auth"
                                                                    placeholder="Item"
                                                                    onChange={e => handleSelectItem(itemIndex, e.target.value)}
                                                                    value={order.order[itemIndex].itemId}
                                                                    id="itemItem"
                                                                    options={[
                                                                        {
                                                                            content: "Selecione um item",
                                                                            value: "0"
                                                                        },
                                                                        ...getItemsSelect(order.order[itemIndex].subcategoryId)

                                                                    ]}
                                                                />
                                                                {order.order[itemIndex].itemType && order.order[itemIndex].itemType == "list" ?
                                                                    <>
                                                                        <SelectField
                                                                            extra=""
                                                                            label="Produto"
                                                                            disabled={order.order[itemIndex].itemId ? false : true}
                                                                            variant="auth"
																			labelExtra="text-sm"
																			inputExtra="shadow-lg border border-gray-200"
                                                                            placeholder="Produto"
                                                                            onChange={e => handleSelectParam(itemIndex, e.target.value)}
                                                                            value={order.order[itemIndex].paramId}
                                                                            id="itemParam"
                                                                            options={[
                                                                                {
                                                                                    content: "Selecione um item",
                                                                                    value: "0"
                                                                                },
                                                                                ...getParamsSelect(order.order[itemIndex].itemId)

                                                                            ]}
                                                                        />
                                                                        <InputField
                                                                            variant="auth"
                                                                            extra="mb-3"
                                                                            label="Quantidade"
																			labelExtra="text-sm"
																			inputExtra="shadow-lg border border-gray-200"
                                                                            disabled={order.order[itemIndex].paramId ? false : true}
                                                                            value={order.order[itemIndex].paramQtd}
                                                                            onChange={e => handleEditParamQtd(itemIndex, e.target.valueAsNumber)}
                                                                            placeholder="Quantidade de itens"
                                                                            id="quantity"
                                                                            type="number"
                                                                        />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <InputField
                                                                            variant="auth"
                                                                            extra="mb-3"
                                                                            label="Quantidade"
																			labelExtra="text-sm"
																			inputExtra="shadow-lg border border-gray-200"
                                                                            disabled={order.order[itemIndex].itemId ? false : true}
                                                                            value={order.order[itemIndex].itemQtd}
                                                                            onChange={e => handleEditItemQtd(itemIndex, e.target.valueAsNumber)}
                                                                            placeholder="Quantidade de itens"
                                                                            id="quantity"
                                                                            type="number"
                                                                        />
                                                                    </>
                                                                }
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    </Accordion>
                                                </div>
                                            ))}
                                            <button onClick={() => handleAddOrderItemInCart(category.id)} className="mt-5 linear ml-auto w-fit rounded-xl bg-orange-500 text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-green-800 dark:text-white dark:hover:bg-green-700 dark:active:bg-green-900 p-2">Adicionar item</button>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            ))}
                        </div>
                    </div>
                    {/* CART */}
                    <Cart />
                </div>
            </div>
        </>
    )
}

export default NewOrder