import { useEffect, useState } from "react"
import CardGastosOperacao from "./components/CardGastosOperacao"
import { Operacao } from "types/app"
import api from "services/api"
import { useAppSelector } from "redux/hooks"
import dayjs from "dayjs"
import GastosOperacaoCalendarDropdown from "./components/CardGastosOperacao/GastosOperacaoCalendarDropdown"
import Calendar from "react-calendar"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"

const DespesasOperacao = () => {
	const user = useAppSelector(selector => selector.user.user)
	const [operacoes, setOperacoes] = useState<Operacao[]>([])
	const [date, setDate] = useState(dayjs().toDate())

	useEffect(()=>{
		getOperacoes()
	}, [])

	const getOperacoes = () => {
		api.get('/operacoes/autocomplete', {
			headers: {
				token: user?.token
			}
		}).then(res => {
			setOperacoes(res.data)
		})
	}

	return(
		<div className="mt-5 flex flex-col">
			<div className="flex items-center justify-between">
				<div className="text-2xl font-bold text-black dark:text-white pl-5">
					Despesas das operações
				</div>
				<div className="">
					<GastosOperacaoCalendarDropdown className="" menus={[
						{
							children: <div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
							<Calendar
								value={date}
								onClickMonth={e=> setDate(dayjs(e).startOf('month').toDate())}
								prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
								nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
								view={"year"}
							/>
						</div>
						}
					]} />
				</div>
			</div>
			<div className="grid grid-cols-1 gap-5 mt-5">
				{operacoes.map(operacao=>(
					<CardGastosOperacao key={operacao.id} searchDate={date} id={operacao.id} minH={400} extraClasses="p-5 rounded-lg border border-gray-100 shadow-2xl shadow-shadow-700 h-fit" title={`${operacao.name}`}/>
				))}
			</div>
		</div>
	)
}

export default DespesasOperacao