//@ts-nocheck
import {
    StyleSheet,
    Font
} from "@react-pdf/renderer";

import ArialBold from '../../../../../assets/fonts/arial-bold.ttf';

Font.register({
    family: "ArialBold",
    src: ArialBold
});

const normalStyles = StyleSheet.create({
    title: {
        fontWeight: 800,
        fontSize: 20,
        fontWeight: "bold",
        fontFamily: "ArialBold"
    },
    text: {
        fontWeight: "normal",
        fontSize: 10,
        // textAlign: 'justify',
    },
    textTitle: {
        marginTop: 10,
        marginBottom: 10,
        textDecoration: 'underline',
    },
    textTitle2: {
        marginTop: 10,
        marginBottom: 10,
        fontWeight: "bold",
        fontFamily: "ArialBold"
    },
    m_t: {
        marginTop: 5
    },
    ml: {
        marginLeft: 20
    },
    cartItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 10,
        padding: 5
    },
    title1: {
        fontSize: 35
    },
    title2: {
        fontSize: 25
    },
    title3: {
        fontSize: 20
    },
    paragraph: {
        fontSize: 10
    },
    left: {
        textAlign: 'left'
    },
    right: {
        textAlign: 'right'
    },
    center: {
        textAlign: 'center'
    },
    justify: {
        textAlign: 'justify'
    },
    normal: {
        fontWeight: "normal"
    },
    bold: {
        fontWeight: "bold",
        fontFamily: "ArialBold"
    },
    italic: {
        fontStyle: ''
    },
    underline: {
        textDecoration: 'underline'
    }

});

export default normalStyles;
