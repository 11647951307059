import Card from "components/card"
import avatar from "assets/img/avatars/default-profile.png";
import banner from "assets/img/profile/banner.png";
import InputMask from 'react-input-mask';
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect, useState } from "react";
import { Role, UserType } from "types/app";
import api from "services/api";
import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ErrorData } from "types/fetch";
import { setUser as setUserDispatch } from '../../redux/reducers/userSlice';

interface EditUserType extends UserType {
    confirmPassword?: string;
}

const Profile = () => {
    const _user = useAppSelector(selector => selector.user.user)
    const [user, setUser] = useState<EditUserType>({
        id: 0,
        username: "",
        name: "",
        email: "",
        phone: ""

    });
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const dispatch = useAppDispatch();
    const [preview, setPreview] = useState('')
    const [selectedPhoto, setSelectedPhoto] = useState()

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const _password = user.password ? user.password.trim() != "" ? user.password : undefined : undefined;

        if(_password){
            if(user.password !== user.confirmPassword){
                return toast("As senhas não conferem", {
                    type: "error",
                    theme: darkmode ? "dark" : "light"
                })
            }
        }

        const bodyFormData = new FormData();
        bodyFormData.append("name", user.name)
        bodyFormData.append("username", user.username)
        bodyFormData.append("password", _password)
        bodyFormData.append("image", selectedPhoto)
        bodyFormData.append("email", user.email)
        bodyFormData.append("phone", user.phone)
        // name: user.name,
        // username: user.username,
        // admin: user.admin,
        // password: _password,
        // roleId: _roleId

        api.put('/users/self', bodyFormData, {
            headers: {
                "Content-Type": "multipart/form-data",
                token: _user?.token
            }
        }).then((res: {data: UserType})=> {

            dispatch(setUserDispatch({
                ..._user,
                name: res.data.name,
                admin: res.data.admin,
                photo: res.data.photo,
            }))

            toast("Salvo com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })

    }

    const handlePhotoChange = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }

        setSelectedPhoto(e.target.files[0])
    }

    useEffect(()=> {
        api.get('/users/self', {
            headers: {
                token: _user?.token
            }
        }).then((res: {data: UserType})=> {
            setUser(res.data)
        })
    }, [])

    useEffect(()=> {
        if(selectedPhoto){
            const objectUrl = URL.createObjectURL(selectedPhoto)
            setPreview(objectUrl)

            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl)
        }
    }, [selectedPhoto])

    const ajusta = (v: any) => {
        const digitos = !v ? '' : v.replace(/[^\d]/g, '');
        if (!digitos || digitos.length < 10) return v;
        const corte = digitos.length === 10 ? 6 : 7;
        const max = digitos.length > 11 ? 11 : digitos.length;
        return `(${digitos.substring(0, 2)}) ${digitos.substring(2, corte)}-${digitos.substring(corte, max)}`;
    }

    const maskBuilder = (v: any) => {
        if (!v || v.length == 0) return '';
        const a = ajusta(v);
        return (a.length >= 6 && a[5] === '9') ? '(99) 99999-9999' : '(99) 9999-9999';
    }

    const handlePhoneChange = (value: any) => {
        setUser({...user, phone: value});
    }

    return(
        <div className="flex w-full flex-col gap-5">
            <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
                <div className="col-span-4 lg:!mb-0">
                    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
                        {/* Background and profile */}
                        <div
                            className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
                            style={{ backgroundImage: `url(${banner})` }}
                        >
                            <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
                                <input onChange={handlePhotoChange} type="file" accept="image/png, image/gif, image/jpeg" id="photo" className="hidden-input"/>
                                <label className="hidden-label-profile cursor-pointer" htmlFor="photo">
                                    <img className="h-full w-full rounded-full object-cover" src={preview ? preview : user.photo ? api.defaults.baseURL + "/uploads/" + user.photo : avatar} alt="" />
                                </label>
                            </div>
                        </div>

                        {/* Name and position */}
                        <div className="mt-16 flex flex-col items-center">
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                {user?.name}
                            </h4>
                            <p className="text-base font-normal text-gray-600">{user?.role?.name}</p>
                            <p className="admin-text text-base font-normal text-gray-600">{user?.admin ? "Administrador" : ""}</p>
                        </div>

                    </Card>
                </div>

                <div className="col-span-8 lg:!mb-0">
                    <form className="grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">
                        <FormControl className="w-full">
                            <FormLabel className="text-gray-600 dark:text-white">Usuário</FormLabel>
                            <Input value={user.username} onChange={e=> setUser({...user, username: e.target.value})} type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Usuário de Login" />
                        </FormControl>

                        <FormControl className="w-full">
                            <FormLabel className="text-gray-600 dark:text-white">Nome</FormLabel>
                            <Input value={user.name} onChange={e=> setUser({...user, name: e.target.value})} type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Nome do usuário" />
                        </FormControl>

                        <FormControl className="w-full">
                            <FormLabel className="text-gray-600 dark:text-white">Email</FormLabel>
                            <Input value={user.email} onChange={e=> setUser({...user, email: e.target.value})} type="email" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Email do usuário" />
                        </FormControl>


                        <FormControl className="w-full">
                            <FormLabel className="text-gray-600 dark:text-white">Telefone</FormLabel>
                            <InputMask mask={maskBuilder(user.phone)} value={user.phone} onChange={e=> {handlePhoneChange(e.target.value);}} maskChar={null} type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Telefone" />
                        </FormControl>

                        <FormControl>
                            <FormLabel className="text-gray-600 dark:text-white">Senha</FormLabel>
                            <Input value={user.password} onChange={e=> setUser({...user, password: e.target.value})} type="password" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Senha do usuário" />
                        </FormControl>

                        <FormControl>
                            <FormLabel className="text-gray-600 dark:text-white">Confirmar senha</FormLabel>
                            <Input value={user.confirmPassword} onChange={e=> setUser({...user, confirmPassword: e.target.value})} type="password" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Senha do usuário" />
                        </FormControl>

                        <button type="button" onClick={handleSubmit} className="linear h-10 mt-5 w-full md:w-30p xl:w-20p ml-auto flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
                            Salvar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Profile