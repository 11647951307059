import React, { Dispatch, SetStateAction, useState } from "react";
import Card from "components/card";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { IoMdEye } from "react-icons/io";
import { OperacaoExpenses } from "types/app";
import { BsTrash } from "react-icons/bs";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import api from "services/api";
import TooltipHorizon from "components/tooltip";
import { Text } from "@chakra-ui/react";

interface RowType extends OperacaoExpenses {
}

const columnHelper = createColumnHelper<RowType>();

// const columns = columnsDataCheck;
export default function GastosOperacaoTable(props: {
  setEditExpense: Dispatch<SetStateAction<OperacaoExpenses>>,
  setOpenEditExpense: (val: boolean) => void,
  setLoadingData: Dispatch<SetStateAction<boolean>>
  reloadTable: ()=> void,
  tableData: RowType[],
  title?: string,
  loading: boolean,
  maxH?: number,
  minH?: number
}) {
  const { tableData } = props;
  const user = useAppSelector(state=> state.user.user)
  const darkmode = useAppSelector(state=> state.layout.darkMode)
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [defaultData, setDefaultData] = React.useState([])
  const navigate = useNavigate()

  const [selectedId, setSelectedId] = useState(null)

  const [extraClasses, setExtraClasses] = useState('')

  const handleEdit = (data: CellContext<RowType, any>) => {
    const id = data.row.original.id
	props.setLoadingData(true)

    api.get('/operacaoexpenses/view/'+id, {
      headers: {
        token: user?.token
      }
    }).then((res)=>{
      if(res){
        if(res.data){
          props.setEditExpense(res.data)

		  props.setLoadingData(false)
          props.setOpenEditExpense(true)
        }
      }
    }).catch((error: ErrorData)=>{
		props.setOpenEditExpense(false)
		toast(error.response.data.error, {
			type: "error",
			theme: darkmode ? "dark" : "light"
		})
    })


  }

  const handleDelete = (data: CellContext<RowType, any>) => {
    const id = data.row.original.id
    Swal.fire({
      title: "Remover Gasto",
      text: "Você realmente deseja remover o gasto do dia "+dayjs(data.row.original.date).format("DD/MM/YYYY")+" no valor de " + Intl.NumberFormat('pt-BR', {style: "currency", currency: "BRL"}).format(data.row.original.value) + "?",
      icon: "question",
      confirmButtonText: "Sim",
      showCancelButton: true,
      cancelButtonText: "Não",
      background: darkmode ? "rgb(11 20 55)" : "#fff",
      color: darkmode ? "#fff" : "#222",
    }).then(response=> {
      if(response.isConfirmed){
        api.delete('/operacaoexpenses/delete/'+id, {
          headers: {
            token: user?.token
          }
        }).then(()=>{
          toast("Gasto removido com sucesso!", {
              type: "success",
              theme: darkmode ? "dark" : "light"
          })
          props.reloadTable()
        }).catch((error: ErrorData)=>{
          toast(error.response.data.error, {
              type: "error",
              theme: darkmode ? "dark" : "light"
          })
        })
      }
    })
  }

  const columns = [
    columnHelper.accessor("date", {
      id: "date",
      size: 95,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">DATA</p>
      ),
      cell: (info) => (
        <p className="flex items-center text-xs text-navy-700 dark:text-white">
          {dayjs(info.getValue()).format("DD/MM/YYYY")}{info.row.original.isRecurrent ? <TooltipHorizon content={<span>Recorrente</span>} trigger={<Text ml={1}> - R</Text>} extra="" placement="top"/> : ""}
        </p>
      ),
    }),
    columnHelper.accessor("data_vencimento", {
      id: "data_vencimento",
      size: 95,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">DATA VENCIMENTO</p>
      ),
      cell: (info) => (
        <p className="flex items-center text-xs text-navy-700 dark:text-white">
          {dayjs(info.getValue()).isValid() ? dayjs(info.getValue()).format("DD/MM/YYYY") : "Data inválida"}
        </p>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      size: 180,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">DESCRIÇÃO</p>
      ),
      cell: (info) => (
		<TooltipHorizon
			content={<span>{info.row.original.referente == 2 ? "IMPOSTO - " : ""}{info.getValue() ? info.getValue() : "Não definido"}</span>}
			trigger={
				<Text noOfLines={1} className="text-xs text-navy-700 dark:text-white">
				  {info.row.original.referente == 2 ? "IMPOSTO - " : ""}{info.getValue() ? info.getValue() : "Não definido"}
				</Text>
			}
			extra=""
			placement="top"
		/>
      ),
    }),
    columnHelper.accessor("value", {
      id: "value",
      size: 120,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">VALOR</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {Intl.NumberFormat('pt-BR', {style: "currency", currency: "BRL"}).format((info.getValue()))}
        </p>
      ),
    }),
    columnHelper.accessor("metodoPagamento", {
      id: "metodoPagamento",
      size: 95,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">FORMA DE PAGAMENTO</p>
      ),
      cell: (info) => (
        <p className="flex items-center text-xs text-navy-700 dark:text-white">
          	{info.getValue() == 1 ? "Boleto" : info.getValue() == 2 ? "PIX" : "TED" }
        </p>
      ),
    }),
    columnHelper.accessor(null, {
      id: "status_pagamento",
      size: 95,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">STATUS PAGAMENTO</p>
      ),
      cell: (info) => (
        <p className="flex items-center text-xs text-navy-700 dark:text-white">
          {info.row.original.data_pagamento ? dayjs(info.row.original.data_pagamento).isValid() ? `Pago dia ${dayjs(info.row.original.data_pagamento).format("DD/MM/YYYY")}` : `Data inválida` : "Pagamento pendente"}
        </p>
      ),
    }),
    columnHelper.accessor(null, {
      id: "acoes",
      size: 90,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          AÇÕES
        </p>
      ),
      cell: (info) => (
        <div className="flex gap-2 items-center">
			<TooltipHorizon
				content={<span>Visualizar</span>}
				trigger={
					<button onClick={()=> handleEdit(info)} className="linear rounded-[20px] bg-gray-200 hover:bg-gray-300 px-1 py-1 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<IoMdEye />
					</button>
				}
				extra=""
				placement="top"
			/>

			<TooltipHorizon
				content={<span>Remover</span>}
				trigger={
					<button onClick={()=> handleDelete(info)} className="linear rounded-[20px] bg-gray-200 hover:bg-gray-300 px-1 py-1 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<BsTrash />
					</button>
				}
				extra=""
				placement="top"
			/>
        </div>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(()=>{
    setData(()=> [...tableData])
  }, [tableData])
  return (
	<>
	<Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto border border-gray-200"} noShadow>
		<div className="relative flex items-center justify-between pt-4">
			<div className="text-xl font-bold text-navy-700 dark:text-white">
			{props.title}
			</div>
		</div>

		<div className="overflow-x-scroll">
			<table className="w-full">
			<thead className="table w-full">
				{table.getHeaderGroups().map((headerGroup) => (
				<tr key={headerGroup.id} className="!border-px !border-gray-400">
					{headerGroup.headers.map((header) => {
					return (
						<th
						key={header.id}
						colSpan={header.colSpan}
                        style={{width: header.getSize(), maxWidth: header.getSize(), minWidth: header.getSize()}}
						className="border-b-[1px] border-gray-200 text-start"
						>
						<div className="items-center justify-between text-xs text-gray-200">
							{flexRender(
                header.column.columnDef.header,
                header.getContext()
							)}
							{{
							asc: "",
							desc: "",
							}[header.column.getIsSorted() as string] ?? null}
						</div>
						</th>
					);
					})}
				</tr>
				))}
			</thead>
			<tbody className={`overflow-y-auto block w-full`} style={{
        height: props.minH ? props.minH : undefined,
        minHeight: props.minH ? props.minH : undefined,
        maxHeight: props.maxH ? props.maxH : undefined,
      }}>
				{table
				.getRowModel()
				.rows
				.sort((a, b)=> (dayjs(a.original.date).date() < dayjs(b.original.date).date()) ? -1 : (dayjs(a.original.date).date() > dayjs(b.original.date).date()) ? 1 : 0)
				.map((row) => {
					return (
					<tr key={row.id} className="table w-full hover:bg-gray-50">
						{row.getVisibleCells().map((cell) => {
						return (
              <td
                key={cell.id}
                style={{width: cell.column.getSize() == 100 ? '100%': cell.column.getSize(), maxWidth: cell.column.getSize() == 100 ? "100%": cell.column.getSize(), minWidth: cell.column.getSize() == 100 ? "100%": cell.column.getSize()}}
                className={` border-white/0 py-1 pr-2 `}
              >
                {flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )}
              </td>
						);
						})}
					</tr>
					);
				})}
			{props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Nenhum gasto encontrado...</h1> }
			</tbody>
			</table>
		</div>

		</Card>
	</>
  );
}
