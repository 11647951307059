import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { useNavigate, useParams } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import SelectField from "components/fields/SelectField";
import dayjs, { Dayjs } from "dayjs";

interface OrderEditType {
	discount: number;
	pretendDiscount: string;
	awaitingApproval: boolean;
	status: number;
	end_at: Dayjs;
	estimatedTime1: string;
	paymentMethod1: string;
	executive: number;
}

const EditOrder = () => {
	const { id } = useParams()

	const [data, setData] = useState<OrderEditType>({
		discount: 0,
		pretendDiscount: '0',
		awaitingApproval: true,
		status: 0,
		end_at: null,
		estimatedTime1: "",
		paymentMethod1: "",
		executive: -1,
	})
	const darkmode = useAppSelector((state) => state.layout.darkMode)
	const user = useAppSelector((state) => state.user.user)

    const [executives, setExecutives] = useState<{value: number, content: string}[]>([])

	const navigate = useNavigate()

	useEffect(() => {
		api.get("/orders/view/" + id, {
			headers: {
				token: user?.token
			}
		}).then(res => {
			setData({
				pretendDiscount: res.data.discount ? res.data.discount.toString() : 0,
				discount: res.data.discount,
				awaitingApproval: res.data.awaitingApproval,
				status: res.data.status,
				end_at: res.data.end_at,
				estimatedTime1: res.data.estimatedTime1,
				paymentMethod1: res.data.paymentMethod1,
				executive: res.data.funcId
			})

			getExecutives()
		}).catch((error: ErrorData) => {
			console.log({ error })
			toast(error.response.data.error, {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		})
	}, [])

	const handleSubmit = (e: any) => {
		e.preventDefault();
		api.put('/orders/edit/' + id, {
			pretendDiscount: data.pretendDiscount != undefined && data.pretendDiscount != null ? data.pretendDiscount : undefined,
			status: data.status,
			end_at: data.end_at,
			estimatedTime1: data.estimatedTime1,
			paymentMethod1: data.paymentMethod1,
			executive: data.executive
		}, {
			headers: {
				token: user?.token
			}
		}).then(() => {
			toast("Solicitação efetuada com sucesso!", {
				type: "success",
				theme: darkmode ? "dark" : "light"
			})
			navigate('/admin/orcamentos')
		}).catch((error: ErrorData) => {
			toast(error.response.data.error, {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		})

	}

	const ajusta = (v: any) => {
		console.log(v)
		const digitos = !v ? '' : v.replace(/[^\d]/g, '');
		return `${digitos}%`;
	}

	const maskBuilder = (v: any) => {
		if (!v || v.length == 0) return '';
		const a = ajusta(v);
		return '99%';
	}

	const maskToPerc = (v: string) => {
		const a = v.replace(/[^\d]/gi, "")
		return a
	}

	const handleDiscountChange = (value: any) => {
		setData({ ...data, pretendDiscount: ajusta(value) });
	}

    const getExecutives = () => {
        api.get("/orders/executives/self", {
            headers: {
                token: user?.token
            },
        }).then((res: { data: {content: string, value: number}[] }) => {
            setExecutives(res.data)
        }).catch((error) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

	return (
		<>
			<form className="pt-5 grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 mt-10 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">

				<div className="grid md:grid-cols-3 md:col-span-2 gap-5">
					<div className="flex flex-col items-center justify-center">
						<span className="text-md font-bold text-gray-900 dark:text-white">Desconto atual:</span>
						<span className="text-sm font-base text-gray-900 dark:text-white">{data.discount || 0}%</span>
					</div>

					<SelectField
						label="Executivo da Proposta"
						extra=""
						disabled={executives.length <= 0}
						inputExtra="bg-white border border-gray-200 shadow-md shadow-600"
						onChange={e => setData({...data, executive: parseInt(e.target.value)})}
						value={data.executive}
						id="itemSubcategory"
						options={[
							{
								content: "Você",
								value: -1
							},
							...executives

						]}
					/>

					{user?.admin && <FormControl className="w-full">
						<FormLabel className="dark:text-white font-bold text-sm pl-2">Início das Atividades</FormLabel>
						<Input value={dayjs(data.end_at).format("YYYY-MM-DD")} style={{
							colorScheme: darkmode ? "dark" : "light"
						}} onChange={e => setData({
							...data,
							end_at: dayjs(e.target.value)
						})} required type="date" className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white border border-gray-200 shadow-md shadow-600 p-3 text-sm outline-none text-gray-900 border-gray-200 dark:!border-white/10 dark:text-white" placeholder="" />
					</FormControl>}
				</div>

					<FormControl className="w-full">
						<FormLabel className="dark:text-white font-bold text-sm pl-2">Desconto pretendido</FormLabel>
						<ReactInputMask disabled={data.awaitingApproval ? true : false} mask={maskBuilder(data.pretendDiscount)} value={data.pretendDiscount} onChange={e => { handleDiscountChange(e.target.value); }} maskChar={null} type="text" className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white border border-gray-200 shadow-md shadow-600 p-3 text-sm outline-none text-gray-900 border-gray-200 dark:!border-white/10 dark:text-white" placeholder="Desconto pretendido" />
						{/* <Input value={data.pretendDiscount} pattern="%" onChange={e=> setData({...data, pretendDiscount: e.target.valueAsNumber})} type="number" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Acréscimo" />% */}
					</FormControl>

				{!user?.admin && <span></span>}

				<SelectField
					extra=""
					label="Status"
					variant="auth"
					placeholder="Tipo da item"
					inputExtra="bg-white border border-gray-200 shadow-md shadow-600"
					value={data.status}
					onChange={(e: any) => setData({ ...data, status: parseInt(e.target.value) })}
					id="itemTyoe"
					options={[
						{
							content: "Em andamento",
							value: 0
						},
						{
							content: "Ganho",
							value: 1
						},
						{
							content: "Perdido",
							value: 2
						},
						{
							content: "Cancelado",
							value: 3
						},
					]}
				/>

				<SelectField
					extra=""
					label="Prazo de Entrega"
					inputExtra="bg-white border border-gray-200 shadow-md shadow-600"
					onChange={e => setData({
						...data,
						estimatedTime1: e.target.value
					})}
					value={data.estimatedTime1}
					id="itemSubcategory"
					options={[
						{
							content: "Selecione um prazo",
							value: ""
						},
						{
							content: "15 dias após assinatura",
							value: "15 dias após assinatura"
						},
						{
							content: "30 dias após assinatura",
							value: "30 dias após assinatura"
						},
						{
							content: "45 dias após assinatura",
							value: "45 dias após assinatura"
						},
						{
							content: "60 dias após assinatura",
							value: "60 dias após assinatura"
						},

					]}
				/>
				<SelectField
					extra=" "
					inputExtra="bg-white border border-gray-200 shadow-md shadow-600"
					label="Condições de Pagamento"
					labelExtra=""
					onChange={e => setData({
						...data,
						paymentMethod1: e.target.value
					})}
					value={data.paymentMethod1}
					id="itemSubcategory"
					options={[
						{
							content: "Selecione uma condição",
							value: ""
						},
						{
							content: "30 dias",
							value: "30 dias"
						},
						{
							content: "30/60 dias",
							value: "30/60 dias"
						},
						{
							content: "30/60/90 dias",
							value: "30/60/90 dias"
						},

					]}
				/>
			</form>

			<div className="flex items-center justify-center w-full mt-5">
				<button type="button" onClick={handleSubmit} className="linear h-10 px-5 w-fit flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-darkorange-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
					Salvar
				</button>
			</div>
		</>
	)
}

export default EditOrder