export const cnpjMask = (value: string) => {
    return value != undefined ? value
      .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
	: ""
}

export const cnpjMaskToNumber = (cnpj: string) => {
  const pureCNPJ = cnpj.replaceAll(".", "").replaceAll(",","").replaceAll("-", "").replaceAll("/", "")
  const cnpjNumber = pureCNPJ.slice(0, 14)

  return cnpjNumber
}