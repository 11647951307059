import { FiSearch } from "react-icons/fi";

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { Input, Select } from "@chakra-ui/react";
import ScopesOfServiceTable from "./components/ScopesOfServiceTable";
import { OrderItem, ScopeOfService } from "types/app";

const ScopesOfService = () => {

  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [data, setData] = useState<ScopeOfService[]>([])
  const [search, setSearch] = useState('')
  const user = useAppSelector((state) => state.user.user)
  const [page, setPage] = useState<number>(1)
  const [maxPages, setMaxPages] = useState<number>(2)

  const [items, setItems] = useState<OrderItem[]>([])
  const [itemSearch, setItemSearch] = useState(-1)
  const navigate = useNavigate()

  const [loadingPage, setLoadingPage] = useState(false)

  const getData = (): void => {
    api.get(`/scopesofservice`, {
      headers: {
        token: user?.token
      },
      params: {
        page,
        item_id: itemSearch != -1 ? itemSearch : undefined,
        perpage: rowsPerPage,
        search
      }
    }).then(res=>{
      setLoadingPage(false)
      setData(res.data.scopes)
      setMaxPages(res.data.maxPages)
    })
  }

  useEffect(()=>{
    getData()
  }, [page])

  useEffect(()=>{
    api.get('/orders/items', {
      headers: {
        token: user?.token
      }
    }).then(res=>{
      setItems(res.data)
    })
  }, [])

  const handleSearch = (e: any): void => {
    e.preventDefault()
    setPage(1)
    setLoadingPage(true)

    getData()
  }

  const handleChangePage = (newPage: number) => {
    if(loadingPage) return;
    setLoadingPage(true)
    setPage(newPage)
  }

  const handleNewClient = () => {
    navigate('/admin/scopesofservice/new')
  }

  return (
    <>
      <div className="flex items-center justify-center mt-10 h-full w-full py-2">
        <button onClick={handleNewClient} className="linear ml-auto mr-5 flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-sm font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
          Novo escopo
        </button>
      </div>
      <form className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 grid-cols gap-5 items-center justify-center mt-2 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2 gap-2" onSubmit={handleSearch}>
        <div className="col-span-1 md:col-span-1 xl:col-span-3 2xl:col-span-5 flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            value={search}
            onChange={e=> setSearch(e.target.value)}
            type="text"
            placeholder="Procurar..."
            className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
          />
        </div>

        <Select value={itemSearch} onChange={e=> setItemSearch(parseInt(e.target.value))} icon={<></>} gridColumn={""} className="no-arrows text-sm col-span-1 flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white px-5">
          {items.length > 0 ? <option value={-1}>Item</option> : <option value={-1}>Carregando...</option>}
          {items.sort((a, b)=> a.name < b.name ? -1 : a.name > b.name ? 1 : 0).map(item=>(
            <option value={item.id}>{item.name}</option>
          ))}
        </Select>

        <button onClick={handleSearch} disabled={loadingPage} className="linear flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Procurar
        </button>
      </form>

      <div className="mt-5">
        <ScopesOfServiceTable reloadTable={getData} page={page} setPage={handleChangePage} title="Lista de Escopos" tableData={data} maxPages={maxPages} loading={loadingPage} />
      </div>
    </>
  );
};

export default ScopesOfService;
