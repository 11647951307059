import { ChakraProvider, Skeleton, SkeletonText, Text } from "@chakra-ui/react"
import { useState } from "react"

const SideMenu = ({children, isOpen, setIsOpen, title, onConfirm, confirmText, loading, onClose}: { loading?: boolean, title?: string, children?: JSX.Element, isOpen?: boolean, setIsOpen?: React.Dispatch<boolean>, onConfirm?: ()=> void, confirmText?: string, onClose?: () => void}) => {
    const close = () => {
		setIsOpen(false)
		onClose()
	}
	return(
		isOpen &&
        <ChakraProvider>
            <div className={`
                z-[5000] fixed right-0 top-[86px] bottom-0 w-[100vw] max-w-[500px] bg-gray-100 transition-all shadow-xl
            `}>
                <div className="w-[500px] h-[100%]">
                    <div className="h-[80px] p-5 bg-gray-800 flex items-center justify-between w-full">
                        <Text className="text-white font-bold" noOfLines={1}>{!loading ? title : `-`}</Text>
                        <button className="text-white font-bold text-lg" onClick={()=> close()}>X</button>
                    </div>
                    <div className="bg-white w-full h-[80%] p-2 overflow-auto">
                        {loading ? <>
                            <Skeleton width={'100%'} h={50} mt={5} />
                            <Skeleton width={'100%'} h={50} mt={5} />
                            <Skeleton width={'100%'} h={50} mt={5} />
                            <Skeleton width={'100%'} h={50} mt={5} />
                            <Skeleton width={'100%'} h={50} mt={5} />
                            <Skeleton width={'100%'} h={50} mt={5} />
                            <Skeleton width={'100%'} h={50} mt={5} />
                            <Skeleton width={'100%'} h={50} mt={5} />
                            <Skeleton width={'100%'} h={50} mt={5} />
                            <Skeleton width={'100%'} h={50} mt={5} />
                        </>
                        : children}
                    </div>
                    <div className="flex items-center justify-between h-[calc(100%-80px-80%)] p-5 gap-5">
                        <div className="flex gap-2 items-center">
                            <button className="text-lg text-white h-[40px] w-[100px] bg-red-400 rounded-sm disabled:bg-gray-300" disabled={loading} onClick={()=> close()}>Cancelar</button>
                            <button className="text-lg text-white h-[40px] w-[100px] bg-green-500 rounded-sm disabled:bg-gray-300" disabled={loading} onClick={onConfirm}>{confirmText || "Salvar"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </ChakraProvider>
    )
}

export default SideMenu