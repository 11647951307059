import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Card from "components/card";
import { MdEdit} from "react-icons/md";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@chakra-ui/tooltip";
import { useAppSelector } from "redux/hooks";
import { IoMdEye } from "react-icons/io";
import { BancoType } from "types/app";
import { BsTrash } from "react-icons/bs";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import api from "services/api";
import TooltipHorizon from "components/tooltip";

interface RowType extends BancoType {
}

const columnHelper = createColumnHelper<RowType>();

// const columns = columnsDataCheck;
export default function BancosFuncTable(props: {
  setEditExpense: Dispatch<SetStateAction<BancoType>>,
  onOpenExpense: () => void,
  reloadTable: ()=> void,
  tableData: RowType[],
  title?: string,
  loading: boolean,
  maxH?: number,
  minH?: number
}) {
  const { tableData } = props;
  const user = useAppSelector(state=> state.user.user)
  const darkmode = useAppSelector(state=> state.layout.darkMode)
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [defaultData, setDefaultData] = React.useState([])
  const navigate = useNavigate()

  const [selectedId, setSelectedId] = useState(null)

  const [extraClasses, setExtraClasses] = useState('')

  const handleEdit = (data: CellContext<RowType, any>) => {
    const id = data.row.original.id

    api.get('/users/banks/view/'+id, {
      headers: {
        token: user?.token
      }
    }).then((res)=>{
      if(res){
        if(res.data){
          props.setEditExpense(res.data)

          props.onOpenExpense()
        }
      }
    }).catch((error: ErrorData)=>{
      toast(error.response.data.error, {
          type: "error",
          theme: darkmode ? "dark" : "light"
      })
    })


  }

  const handleDelete = (data: CellContext<RowType, any>) => {
	Swal.fire({
		title: "Remover Banco",
		text: "Você realmente deseja remover o banco "+data.row.original.banco+"?",
		icon: "question",
		confirmButtonText: "Sim",
		showCancelButton: true,
		cancelButtonText: "Não",
		background: "#fff",
		color: "#222",
	  }).then(response=> {
		if(response.isConfirmed){
		  api.delete('/users/banks/delete/'+data.row.original.id, {
			headers: {
			  token: user?.token
			}
		  }).then(()=>{
			props.reloadTable()
			toast("Banco removido com sucesso!", {
				type: "success",
				theme: "light"
			})
		  }).catch((error: ErrorData)=>{
			toast(error.response.data.error, {
				type: "error",
				theme: "light"
			})
		})
		}
	  })
  }

  const columns = [
    columnHelper.accessor(null, {
      id: "nomeBanco",
      size: 450,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">BANCO</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.row.original.banco}
        </p>
      ),
    }),
    columnHelper.accessor("agencia", {
      id: "agencia",
      size: 120,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">AGÊNCIA</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.getValue() ? info.getValue() : "Não definido"} - {info.row.original.digitoAg ? info.row.original.digitoAg : ''}
        </p>
      ),
    }),
    columnHelper.accessor("conta", {
      id: "conta",
      size: 120,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">CONTA</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
			{info.getValue() ? info.getValue() : "Não definido"} - {info.row.original.digitoConta ? info.row.original.digitoConta : ''}
        </p>
      ),
    }),
    columnHelper.accessor("anotacao", {
      id: "anotacao",
      size: 450,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">ANOTAÇÃO</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
			{info.getValue() ? info.getValue() : "S/A"}
        </p>
      ),
    }),
    columnHelper.accessor(null, {
      id: "acoes",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          AÇÕES
        </p>
      ),
      cell: (info) => (
        <div className="flex gap-2 items-center">
			<TooltipHorizon
				content={<span>Visualizar</span>}
				trigger={
					<button onClick={()=> handleEdit(info)} className="linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<IoMdEye />
					</button>
				}
				extra=""
				placement="top"
			/>

			<TooltipHorizon
				content={<span>Remover</span>}
				trigger={
					<button onClick={()=> handleDelete(info)} className="linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<BsTrash />
					</button>
				}
				extra=""
				placement="top"
			/>
        </div>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(()=>{
    setData(()=> [...tableData])
  }, [tableData])
  return (
	<>
	<Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto border border-gray-200"} noShadow>
		<div className="relative flex items-center justify-between pt-4">
			<div className="text-xl font-bold text-navy-700 dark:text-white">
			{props.title}
			</div>
		</div>

		<div className="overflow-x-scroll">
			<table className="w-full">
			<thead className="table w-full">
				{table.getHeaderGroups().map((headerGroup) => (
				<tr key={headerGroup.id} className="!border-px !border-gray-400">
					{headerGroup.headers.map((header) => {
					return (
						<th
						key={header.id}
						colSpan={header.colSpan}
                        style={{width: header.getSize(), maxWidth: header.getSize(), minWidth: header.getSize()}}
						className="border-b-[1px] border-gray-200 text-start"
						>
						<div className="items-center justify-between text-xs text-gray-200">
							{flexRender(
                header.column.columnDef.header,
                header.getContext()
							)}
							{{
							asc: "",
							desc: "",
							}[header.column.getIsSorted() as string] ?? null}
						</div>
						</th>
					);
					})}
				</tr>
				))}
			</thead>
			<tbody className={`overflow-y-auto block w-full`} style={{
        height: props.minH ? props.minH : undefined,
        minHeight: props.minH ? props.minH : undefined,
        maxHeight: props.maxH ? props.maxH : undefined,
      }}>
				{table
				.getRowModel()
				.rows
				.map((row) => {
					return (
					<tr key={row.id} className="table w-full">
						{row.getVisibleCells().map((cell) => {
						return (
              <td
                key={cell.id}
                style={{width: cell.column.getSize() == 100 ? '100%': cell.column.getSize(), maxWidth: cell.column.getSize() == 100 ? "100%": cell.column.getSize(), minWidth: cell.column.getSize() == 100 ? "100%": cell.column.getSize()}}
                className={` border-white/0 py-3 pr-2 `}
              >
                {flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )}
              </td>
						);
						})}
					</tr>
					);
				})}
			{props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Nenhum dado bancário encontrado...</h1> }
			</tbody>
			</table>
		</div>

		</Card>
	</>
  );
}
