import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { useNavigate, useParams } from "react-router-dom";
import { GruposPermissoes, Role, UserType } from "types/app";
import CurrencyInput from "../components/CurrencyInput";
import Switch from "components/switch";
import socket from "socketio/socket";
import { toastError } from "components/toasts/toastError";
import permissionsArray from "helpers/permissions";

interface EditUserType extends UserType {
    confirmPassword?: string;
    goal: any,
}

const EditUser = () => {
    const { id } = useParams()

    const [data, setData] = useState<EditUserType>({
        id: 0,
        name: "",
        username: "",
        goal: 0,
        color: "#000",
        goalDashboard: false,
        permissions: {}
    })

    const [roles, setRoles] = useState<Role[]>([])
    const [architects, setArchitects] = useState<UserType[]>([])
	const [grupos, setGrupos] = useState<GruposPermissoes[]>([])
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const user = useAppSelector((state) => state.user.user)

    const navigate = useNavigate()

    const handleChangeRole = (e: any) => {
        setData({...data, roleId: e.target.value})
    }

    const handleChangeArchitect = (e: any) => {
        setData({...data, architectId: e.target.value})
    }

    const handleChangeGrupo = (e: any) => {
		const newData = data
		if(e.target.value){
			if(e.target.value != -1){
				const grupoSelecionado = grupos.find(g=> g.id == e.target.value)
				if(grupoSelecionado){
					const permissoes = grupoSelecionado.permissoes
					const permissions: {[perm: string]: boolean} = {}
					permissoes.map(p=> permissions[p] = true)

					newData.permissions = permissions
				}else{
					newData.permissions = {}
				}
			}else{
				newData.permissions = {}
			}
		}else{
			newData.permissions = {}
		}

		newData.grupoId = e.target.value
        setData({...newData})
    }

    const handleChangeAdmin = (e: any) => {
        setData({...data, admin: e.target.value == 1 ? true : false})
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const _password = data.password ? data.password.trim() != "" ? data.password : undefined : undefined;
        const _roleId = data.roleId != undefined ? parseInt(data.roleId.toString()) : undefined;
        const _architectId = data.architectId != undefined ? parseInt(data.architectId.toString()) : undefined;

        if(_password){
            if(data.password !== data.confirmPassword){
                return toast("As senhas não conferem", {
                    type: "error",
                    theme: "light"
                })
            }
        }

        api.put('/user/'+id, {
            name: data.name,
            username: data.username,
            admin: data.admin,
            password: _password,
            roleId: _roleId ? _roleId != -1 ? _roleId : null : null,
            goal: parseFloat(data.goal.toString().replace(/\D/gi, '')),
            color: data.color,
            goalDashboard: data.goalDashboard,
			architectId: _architectId ? _architectId != -1 ? _architectId : null : null,
            permissions: data.permissions,
			grupodId: data.grupoId ? parseInt(data.grupoId.toString()) : -1
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            if(socket.connected){
                socket.emit("updatePermission", {
                    id: id
                })
            }
            toast("Usuário editado com sucesso!", {
                type: "success",
                theme: "light"
            })
            navigate('/admin/users')
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: "light"
            })
        })

    }

	const getGrupos = () => {
		api.get("/users/grupos/all", {
			headers: {
				token: user?.token,
			},
		}).then((res) => {
			setGrupos(res.data)
		});
	}

    useEffect(()=>{
        api.get('/user/'+id, {
            headers: {
                token: user?.token
            }
        }).then((res: {data: UserType})=> {
            setData({
                id: res.data.id,
                name: res.data.name,
                username: res.data.username,
                admin: res.data.admin,
                password: res.data.password,
                roleId: res.data.role ? res.data.role.id : -1,
				architectId: res.data.architectId ? res.data.architectId : -1,
                goal: res.data.goal,
                color: res.data.color,
                goalDashboard: res.data.goalDashboard,
                permissions: res.data.permissions ? res.data.permissions : {},
				grupoId: res.data.grupoId
            })
        })

        api.get('/role/all', {
            headers: {
                token: user?.token
            }
        }).then((res: {data: Role[]}) => {
            setRoles(res.data)
        }).catch((error: ErrorData)=> {
            toast(error.response.data.error, {
                type: "error",
                theme: "light"
            })
        })

        api.get('/users/select', {
            headers: {
                token: user?.token
            }
        }).then((res: {data: UserType[]}) => {
            setArchitects(res.data)
        }).catch((error: ErrorData)=> {
            toastError(error, darkmode)
        })

		getGrupos()
    }, [])

    return(
        <>
            <form className="grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 mt-10 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Usuário</FormLabel>
                    <Input value={data.username} onChange={e=> setData({...data, username: e.target.value})} type="text" className="no-arrows text-gray-8,0 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Usuário de Login" />
                </FormControl>

                <div className="w-full flex gap-5">
                    <FormControl className="flex flex-col gap-1 w-50p">
                        <FormLabel className="font-bold text-sm pl-2 dark:text-white">Senha</FormLabel>
                        <Input value={data.password} onChange={e=> setData({...data, password: e.target.value})} type="password" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Senha do usuário" />
                    </FormControl>
                    <FormControl className="flex flex-col gap-1 w-50p">
                        <FormLabel className="font-bold text-sm pl-2 dark:text-white">Confirmar senha</FormLabel>
                        <Input value={data.confirmPassword} onChange={e=> setData({...data, confirmPassword: e.target.value})} type="password" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Senha do usuário" />
                    </FormControl>
                </div>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Nome</FormLabel>
                    <Input value={data.name} onChange={e=> setData({...data, name: e.target.value})} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Nome do usuário" />
                </FormControl>

				<div className="grid md:grid-cols-2 gap-5">
					<FormControl className="flex flex-col gap-1 w-full">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Cargo</FormLabel>
						<Select value={data.roleId} icon={<></>} onChange={handleChangeRole} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
							<option value={null}>Nenhum</option>
							{roles.map(role=>(
								<option key={role.id} value={role.id}>{role.name}</option>
							))}
						</Select>
					</FormControl>

					<FormControl className="flex flex-col gap-1 w-full">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Administrador</FormLabel>
						<Select value={data.admin ? 1 : 0} icon={<></>} onChange={handleChangeAdmin} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Selecione se é administrador ou não">
							<option value={1}>SIM</option>
							<option value={0}>NÃO</option>
						</Select>
					</FormControl>
				</div>

                <div className="w-full grid grid-cols-6 gap-5">

                    <FormControl className="flex flex-col gap-1 w-full">
                        <FormLabel className="text-gray-700 dark:text-white">Cor</FormLabel>
                        <Input value={data.color} onChange={e=> setData({
                            ...data,
                            color: e.target.value
                        })} type="color" className="text-gray-900 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2 h-10" placeholder="Cor" />
                    </FormControl>
                </div>


				<div className="grid grid-cols-2 items-center gap-5">
					<FormControl className="flex flex-col gap-1 w-full">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Arquiteto responsável</FormLabel>
						<Select value={data.architectId} icon={<></>} onChange={handleChangeArchitect} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
							<option value={null}>Nenhum</option>
							{architects.map(architect=>(
								<option key={architect.id} value={architect.id}>{architect.name}</option>
							))}
						</Select>
					</FormControl>

					<FormControl className="flex flex-col gap-1 w-full">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Grupo</FormLabel>
						<Select value={data.grupoId} icon={<></>} onChange={handleChangeGrupo} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
							<option value={null}>Nenhum</option>
							{grupos.map(grupo=>(
								<option key={grupo.id} value={grupo.id}>{grupo.nome}</option>
							))}
						</Select>
					</FormControl>
				</div>

                <div className="flex flex-col">
                    {/* the custom checkbox desing added in src/index.js */}
                    <div className="mt-3 flex items-center gap-3">
                        <Switch checked={data.goalDashboard} onChange={e=> setData({...data, goalDashboard: e.target.checked})} id="switch1" />
                        <label
                            htmlFor="checkbox1"
                            className="text-base font-medium text-navy-700 dark:text-white"
                        >
                            Gráfico de Metas
                        </label>
                    </div>

					<div className="flex flex-col gap-1 mt-5">
						{permissionsArray.map(perm=> {
							if(perm.req){
								if(data.permissions[perm.req]){
									return (
										<div key={perm.perm} className="flex items-center gap-3">
											<Switch checked={data.permissions[perm.perm] ? true : false} onChange={e=> {
												const _data = {...data, permissions: {
													...data.permissions
												}}
												if(e.target.checked){
													_data.permissions[perm.perm] = true
												}else{
													delete _data.permissions[perm.perm]
												}
												setData(_data)
											}} id="switch1" />
											<label
												htmlFor="checkbox1"
												className="text-base font-medium text-navy-700 dark:text-white"
											>
												{perm.text}
											</label>
										</div>
									)
								}
							}else{
								return (
									<div key={perm.perm} className="flex items-center gap-3">
										<Switch checked={data.permissions[perm.perm] ? true : false} onChange={e=> {
											const _data = {...data, permissions: {
												...data.permissions
											}}
											if(e.target.checked){
												_data.permissions[perm.perm] = true
											}else{
												delete _data.permissions[perm.perm]
											}
											setData(_data)
										}} id="switch1" />
										<label
											htmlFor="checkbox1"
											className="text-base font-medium text-navy-700 dark:text-white"
										>
											{perm.text}
										</label>
									</div>
								)
							}
						})}
					</div>
				</div>

                <button type="button" onClick={handleSubmit} className="linear h-10 mt-auto w-full md:w-30p xl:w-20p ml-auto flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-darkorange-400 dark:text-white dark:hover:bg-darkorange-300 dark:active:bg-darkorange-200">
                    Salvar
                </button>
            </form>
        </>
    )
}

export default EditUser