import Card from "components/card";
import { useEffect, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toastError } from "components/toasts/toastError";
import { ErrorData } from "types/fetch";
import dayjs from "dayjs";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { formatToCurrency } from "helpers/conversions";
import { ChakraProvider, Skeleton } from "@chakra-ui/react";

interface ChartType{
	name: string,
	data: {
		x: string | number,
		y: string | number,
		goals: {
				name: string,
				value: number,
				strokeHeight: number,
				strokeColor: string
		}[]

	}[],
}

const ReceitaProjetadaXRealizada = ({ from, to, selectedFuncs, selectedOperacoes, pageLoading }: {
	from?: Date,
	to?: Date,
	searchDate?: Date,
	selectedFuncs: any,
	selectedOperacoes: any,
	pageLoading?: boolean
}) => {
	const user = useAppSelector(selector => selector.user.user)
	const darkmode = useAppSelector(selector => selector.layout.darkMode)
	const [loading, setLoading] = useState(true)
	const [chartOptions, setChartOptions] = useState<ApexOptions>({
		chart: {
			height: 350,
			type: 'bar',
		},
		plotOptions: {
			bar: {
				columnWidth: '60%',
			},
		},
		colors: ['#00E396'],
		dataLabels: {
			enabled: false
		},
		yaxis: {
			labels: {
				formatter: (val: number) => {
					const formatted = formatToCurrency(val)
					return formatted
				}
			}
		},
		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (val: number) {
					const formatted = formatToCurrency(val)
					return formatted
				}
			}
		},
		legend: {
			show: true,
			showForSingleSeries: true,
			customLegendItems: ['Receita Realizada', 'Receita Projetada'],
			markers: {
				fillColors: ['#00E396', '#775DD0']
			}
		}
	})

	const [chartData, setChartData] = useState<ChartType[]>([
	])

	const getData = () => {
		setLoading(true)
		api.get("/financeiro/dashboard/rentabilidadeprojetadaxrealizada", {
			params: {
				start: from,
				end: to,
				funcs: JSON.stringify(selectedFuncs),
				operacoes: JSON.stringify(selectedOperacoes)
			},
			headers: {
				token: user?.token
			}
		}).then(res => {
			if(res){
				if(res.data){
					setChartData(res.data)
				}
			}
			setLoading(false)
		}).catch(err=>{
			toastError(err, false)
		})
	}

	useEffect(()=> {
		getData()
	}, [from, to, selectedFuncs, selectedOperacoes])


	return (
		<Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center !shadow-shadow-500">
			<ChakraProvider>
				<Skeleton isLoaded={!pageLoading} startColor="white" endColor="gray.300" className="py-6 px-2 !rounded-3xl">
					<div className="mb-auto flex items-center justify-between px-6">
						<h4 className="text-md font-bold text-navy-700 dark:text-white">
							Rentabilidade Projetada X Realizada
						</h4>
					</div>

					<div className="md:mt-16 lg:mt-0">
						<div className="h-[250px] w-full xl:h-[350px]">
							<Chart
								options={chartOptions}
								series={chartData}
								type="bar"
								width="100%"
								height="100%"
							/>
						</div>
					</div>
				</Skeleton>
			</ChakraProvider>
		</Card>
	);
};

export default ReceitaProjetadaXRealizada;
