// Custom components
import React from "react";
import CurrencyInput from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";

function MoneyInput(props: {
  id: string;
  label?: string;
  extra: string;
  placeholder: string;
  variant: string;
  state?: string;
  disabled?: boolean;
  defaultValue?: any;
  inputClasses?: string;
  onChange?: (value: string, name?: string, values?: CurrencyInputOnChangeValues) => void;
  value?: any
}) {
  const { label, id, extra, placeholder, variant, state, disabled, onChange, value, defaultValue, inputClasses} =
    props;

  return (
    <div className={`${extra}`}>
      {label &&
        <label
          htmlFor={id}
          className={`text-sm text-navy-700 dark:text-white ${
            variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
          }`}
        >
          {label}
        </label>
      }

      <CurrencyInput
        placeholder={placeholder}
        value={value}
		allowDecimals={true}
		intlConfig={{locale: "pt-BR", currency: "BRL"}}
		decimalsLimit={2}
        defaultValue={isNaN(defaultValue) ? 0 : defaultValue}
        onValueChange={onChange}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none text-gray-900 ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-300 dark:!border-white/10 dark:text-white"
        } ${inputClasses}`}
      />
      {/* <input
        disabled={disabled}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none text-gray-900 ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-300 dark:!border-white/10 dark:text-white"
        }`}
      /> */}
    </div>
  );
}

export default MoneyInput;
