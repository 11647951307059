import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useAppSelector } from "redux/hooks"
import api from "services/api"
import { ErrorData } from "types/fetch"
import Sheet from "./components/sheet"
import Card from "components/card"
import CustomInput from "components/custom-input"
import CurrencyInput from "react-currency-input-field"
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps"
import ClientsAutocomplete from "views/orcamentos/components/AutoComplete"
import { SelectType } from "types/app"
import { MdDelete } from "react-icons/md"

interface SheetType{
	name: string,
	sheetsColumns: {
		id: number
		name: string
		dataType: number
		defaults: string
		sheetId: number
		sheetsAnswers: {

		}[]
	}[]
}

const SheetsUserSheet = () => {
	const {id} = useParams()

	const user = useAppSelector(selector => selector.user.user)
	const darkmode = useAppSelector(selector => selector.layout.darkMode)
	const [sheetData, setSheetData] = useState<SheetType>()
	const [sheetAnswers, setSheetAnswers] = useState([])
	const [newItems, setNewItems] = useState(1)

	const [selectedRows, setSelectedRows] = useState<number[]>([])
	const [toDeleteSheet, setToDeleteSheet] = useState<number[]>([])


	const [clients, setClients] = useState<{id: number, name: string}[]>([])


	const addSheetLine = () => {
		const _data: any = sheetAnswers
		_data.push({
			id: "NOVO " + newItems,
			name: "",
			dataType: -1,
			defaultValues: ""
		})

		setNewItems(i => i += 1)

		setSheetAnswers([..._data])
	}

	const removeSheetItems = () => {
		let _data = sheetAnswers
		let _toDelete = toDeleteSheet

		selectedRows.map(id=> {
		  _toDelete.push(id)
		  _data = _data.filter(d=> d['id'] != id)
		})

		setToDeleteSheet([..._toDelete])
		setSheetAnswers([..._data])
		setSelectedRows([])
	  }

	const loadClients = () => {
		api.get("/clients/all/full", {
			headers: {
				token: user?.token
			}
		}).then((response)=> {
			if(response){
				if(response.data){
					setClients(response.data)
				}
			}
		}).catch((error: ErrorData)=>{
			toast(error.response.data.error, {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		})

	}

	const loadData = () => {
		api.get("sheets/user/sheet/"+id, {
			headers: {
				token: user?.token
			}
		}).then((response)=> {
			if(response){
				if(response.data){
					setSheetData(response.data)
				}
			}
		}).catch((error: ErrorData)=>{
			toast(error.response.data.error, {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		})
	}

	useEffect(()=> {
		loadData()
		loadClients()
	}, [])

	interface SheetColumnType{
		label: string,
        minWidth?: number,
        maxWidth?: number,
        width?: number,
        editable?: boolean,
        key: string,
        customRender?: (index: number, val: any, setVal: (value: any) => void) => JSX.Element
	}
	const [sheetColumns, setSheetColumns] = useState<SheetColumnType[]>([])
	useEffect(()=>{
		if(!sheetData) return;
		const _newColumns: SheetColumnType[] = []
		{/*
			<option value={-1}>Texto</option>
			<option value={1}>Numeral</option>
			<option value={2}>Dinheiro</option>
			<option value={3}>Cliente</option>
			<option value={4}>Selecionar</option>
		*/}
		sheetData.sheetsColumns.map(col=>{
			_newColumns.push({
				key: col.name,
				label: col.name,
				customRender: col.dataType != -1 ?
					(index, val, setVal) => {
						if(col.dataType == 1){
							const [min, max] = col.defaults.split(',')
							return(
								<input key={"input-"+index} type="number" min={min} max={max} value={val} onChange={e=> setVal(e.target.value)} className="!border-none !outline-none text-gray-800 dark:text-white rounded w-full cursor-pointer !text-xs dark:bg-navy-800" placeholder="0" />
							)
						}
						else if(col.dataType == 2){
							return(
								<CurrencyInput
									key={"input-"+index}
									placeholder={'R$0,00'}
									value={val}
									allowDecimals={true}


									intlConfig={{locale: "pt-BR", currency: "BRL"}}
									decimalsLimit={2}
									defaultValue={isNaN(val) ? 0 : val}
									onValueChange={(value: string, name?: string, values?: CurrencyInputOnChangeValues)=> setVal(values.value)}
									className={`no-arrows !border-none !outline-none text-gray-800 dark:text-white rounded w-full cursor-pointer !text-xs dark:bg-navy-800`}
								/>
							)
						}
						else if(col.dataType == 3){
							return(
								<select key={"input-"+index} value={val} onChange={e=> setVal(e.target.value)} className="no-arrows !border-none !outline-none text-gray-800 dark:text-white rounded w-full cursor-pointer !text-xs dark:bg-navy-800">
									{clients.map(client=>(
										<option key={client.id}>{client.name}</option>
									))}
									{/* {col.defaults.split(',').map((opt, index)=>(
										<option key={"OPT-"+index} value={opt}>{opt}</option>
									))} */}
								</select>
							)
						}
						else if(col.dataType == 4){
							return (
								<select key={"input-"+index} value={val} onChange={e=> setVal(e.target.value)} className="no-arrows !border-none !outline-none text-gray-800 dark:text-white rounded w-full cursor-pointer !text-xs dark:bg-navy-800">
									{col.defaults.split(',').map((opt, index)=>(
										<option key={"OPT-"+index} value={opt}>{opt}</option>
									))}
								</select>
							)
						}
						else{
							return(<h1></h1>)
						}
					}
				: undefined
			})
		})

		setSheetColumns(_newColumns)
	}, [sheetData])

    return(
		<>
			<h1 className="font-bold text-2xl dark:text-white mt-5 mb-5">{sheetData?.name || "Carregando..."}</h1>
			<Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto pt-10"}>
				<Sheet data={sheetAnswers} setData={setSheetAnswers} selectedRows={selectedRows} setSelectedRows={setSelectedRows} columns={sheetColumns || []} />
				<div className="flex w-full justify-end gap-2">
					<button onClick={removeSheetItems} type='button' className='flex items-center justify-center rounded bg-red-200 hover:bg-red-300 shadow w-[24px] h-[24px] dark:bg-red-900 mt-2 dark:hover:bg-red-700' ><MdDelete size={12} /></button>
					<button onClick={addSheetLine} type='button' className='flex items-center justify-center rounded bg-white hover:bg-gray-100 shadow w-[24px] h-[24px] dark:bg-navy-900 mt-2 dark:hover:bg-navy-700 dark:text-white' >+</button>
				</div>
			</Card>
		</>
    )
}

export default SheetsUserSheet