import React from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import { MdArrowCircleLeft, MdArrowLeft, MdArrowRight, MdCancel, MdCheckCircle, MdDelete, MdEdit, MdOutlineError, MdPictureAsPdf, MdTimer } from "react-icons/md";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Tooltip } from "@chakra-ui/tooltip";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toast } from "react-toastify";
import { toastError } from "components/toasts/toastError";
import { Button, ChakraProvider, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { ContactType } from "types/app";
import TooltipHorizon from "components/tooltip";

type RowObj = {
    id: number;
    clientName: string;
    date: string;
    price: number;
    funcName: string;
    awaitingApproval: boolean;
    status: number;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function OrcamentosTable(props: { tableData: RowObj[], title?: string, page: number, setPage: React.Dispatch<number>, maxPages: number, loading: boolean }) {
  const { tableData } = props;
  const user = useAppSelector(state=> state.user.user)
  const darkmode = useAppSelector(state=> state.layout.darkMode)
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [defaultData, setDefaultData] = React.useState([])
  const navigate = useNavigate()
  const [usersSignature, setUsersSignature] = React.useState<ContactType[]>([]);
  const [signature, setSignature] = React.useState(-1)
  const [opened, setOpened] = React.useState(null)

  const { isOpen: isOpenPDF, onOpen: onOpenPDF, onClose: onClosePDF } = useDisclosure();

  const handleDownloadPDF = (data: CellContext<RowObj, any>) => {
	setUsersSignature([])
	setOpened(data.row.original.id)
	setSignature(-1)
	api.get('/orders/getSignature/'+data.row.original.id, {
		headers: {
			token: user?.token
		}
	}).then(res=>{
		setUsersSignature(res.data);
	}).catch(err=> {
		toastError(err, darkmode)
	})
	toast.promise(api.get('/orders/getSignature/'+data.row.original.id, {
      headers: {
        token: user?.token
      },
    }).then((res)=> {
		onOpenPDF()
    }), {
      error: 'Erro ao abrir proposta',
      pending: `Carregando proposta de ID: ${data.row.original.id}`,
      success: {
		render: 'Proposta carregada com sucesso!',
		autoClose: 500
	  }
    }, {
      theme: darkmode ? "dark" : "light"
    })

  }

  const handleEditOrder = (data: CellContext<RowObj, any>) => {
    navigate('/admin/orcamentos/edit/'+data.row.original.id)
  }

  const handleGeneratePDF = () => {
	onClosePDF()
    toast.promise(api.get('/orders/createpdf/'+opened, {
      headers: {
        token: user?.token,
		signature: signature
      },
      responseType: "blob"
    }).then((res)=> {
      const fileURL = URL.createObjectURL(res.data);
      window.open(fileURL);
    }), {
      error: 'Erro ao gerar PDF',
      pending: `Gerando o PDF da proposta: ${opened}`,
      success: 'PDF gerado com sucesso!'
    }, {
      theme: darkmode ? "dark" : "light"
    })
  }

  const columns = [
    columnHelper.accessor("clientName", {
      id: "clientName",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">CLIENTE</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("funcName", {
      id: "funcName",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">CRIADO POR</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          DATA
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {dayjs(info.getValue()).format("DD/MM/YYYY HH:mm")}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("price", {
      id: "price",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          VALOR
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {Intl.NumberFormat("BR", {
              currency: "BRL",
              style: "currency",

            }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-xs font-bold text-gray-900 dark:text-white">
          STATUS
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className={`text-xs ${info.getValue() == 0 ? "bg-orange-600" : info.getValue() == 1 ? "bg-green-600" : info.getValue() == 2 ? "bg-red-700" : "bg-gray-700"} p-1 rounded text-white`}>
            {info.getValue() == 0 ? "Em andamento" : info.getValue() == 1 ? "Ganho" : info.getValue() == 2 ? "Perdido" : "Cancelado"}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor(null, {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">AÇÃO</p>
      ),
      cell: (info) => (
        <p className="flex items-center gap-2 text-sm text-navy-700 dark:text-white">
			<TooltipHorizon
				content={<span>Baixar PDF</span>}
				trigger={
					<button onClick={() => handleDownloadPDF(info)} className="linear rounded-[20px] bg-gray-200 px-4 py-2 text-base font-medium text-orange-600 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<MdPictureAsPdf />
					</button>
				}
				extra=""
				placement="top"
			/>
          {info.row.original.awaitingApproval ?
			<TooltipHorizon
				content={<span>Aguardando aprovação</span>}
				trigger={
					<button disabled className="linear rounded-[20px] bg-gray-200 px-4 py-2 text-base font-medium text-orange-600 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<MdTimer />
					</button>
				}
				extra=""
				placement="top"
			/>
          :
			<TooltipHorizon
				content={<span>Editar Orçamento</span>}
				trigger={
					<button onClick={() => handleEditOrder(info)} className="linear rounded-[20px] bg-gray-200 px-4 py-2 text-base font-medium text-orange-600 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<MdEdit />
					</button>
				}
				extra=""
				placement="top"
			/>
          }
        </p>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(()=>{
    setData(()=> [...tableData])
  }, [tableData])
  return (
	<>
		<ChakraProvider>
			<Modal isOpen={isOpenPDF} onClose={onClosePDF}>
				<ModalOverlay />
				<ModalContent className="dark:bg-navy-800">
					<ModalHeader className="dark:text-white">Selecione quem irá assinar a proposta</ModalHeader>
					<ModalCloseButton className="dark:text-white" />
					<ModalBody className="dark:text-white">
						<FormControl className="w-full">
							<FormLabel className="text-gray-600 dark:text-white">Nome do responsável</FormLabel>
							<select value={signature} onChange={e=> setSignature(parseInt(e.target.value))} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="Nome do Contato">
								<option value={-1}>Nenhum</option>
								{usersSignature.map(user =>(
									<option key={"USER-"+user.id} value={user.id}>{user.name}</option>
								))}
							</select>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme='red' mr={3} onClick={onClosePDF}>
							Fechar
						</Button>
						<Button onClick={handleGeneratePDF} colorScheme="green" variant='solid'>Gerar</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</ChakraProvider>
		<Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
		<div className="relative flex items-center justify-between pt-4">
			<div className="text-xl font-bold text-gray-900 dark:text-white">
				{props.title}
			</div>
		</div>

		<div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
			<table className="w-full">
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
				<tr key={headerGroup.id} className="!border-px !border-gray-400">
					{headerGroup.headers.map((header) => {
					return (
						<th
						key={header.id}
						colSpan={header.colSpan}
						className="border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
						>
						<div className="items-center justify-between text-xs text-gray-800 font-bold">
							{flexRender(
								header.column.columnDef.header,
								header.getContext()
							)}
							{{
								asc: "",
								desc: "",
							}[header.column.getIsSorted() as string] ?? null}
						</div>
						</th>
					);
					})}
				</tr>
				))}
			</thead>
			<tbody>
				{table
				.getRowModel()
				.rows
				.map((row) => {
					return (
					<tr key={row.id} className="hover:bg-gray-50">
						{row.getVisibleCells().map((cell) => {
						return (
							<td
							key={cell.id}
							className="min-w-[150px] border-white/0 py-2 pr-4"
							>
							{flexRender(
								cell.column.columnDef.cell,
								cell.getContext()
							)}
							</td>
						);
						})}
					</tr>
					);
				})}
			</tbody>
			</table>
			{props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Nenhum orçamento encontrado...</h1> }
		</div>

		<div className="mt-2 gap-6 flex items-center justify-center">
			<button disabled={props.page <= 1} onClick={() => props.setPage(props.page - 1)} className="linear rounded-[20px] bg-gray-200 px-2 py-2 text-base font-medium text-orange-600 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
				<MdArrowLeft />
			</button>
			<span className="text-gray-900 dark:text-gray-200">
				{props.page}
			</span>
			<button disabled={props.page >= props.maxPages} onClick={() => props.setPage(props.page + 1)}  className="linear rounded-[20px] bg-gray-200 px-2 py-2 text-base font-medium text-orange-600 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
				<MdArrowRight />
			</button>
		</div>
		</Card>
	</>
  );
}
