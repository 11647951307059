import React, { useEffect, useState } from 'react'
import LogoBranco from "../../assets/img/layout/Logo_Branco.png"
import avatar from "assets/img/avatars/default-profile.png";
import { MdArrowDropDown, MdArrowRight, MdLightMode, MdNotifications } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import api from 'services/api'
import routes, { devMode } from 'routes'
import { logoffUser } from 'redux/reducers/userSlice';

const homePath = "/admin/bi/vendas"

interface MenusType{
	label: string,
	to?: string,
	submenus?: {
		label?: string,
		to?: string,
		submenus?: {
			label?: string,
			to?: string
		}[]
	}[]
}

const Navbarv2 = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const user = useAppSelector(selector => selector.user.user)
    const [menus, setMenus] = useState<MenusType[]>([])

	const goTo = (to?: string) => {
        if(to)
            navigate(to)
    }


    const handleLogout = (e: any) => {
        e.preventDefault();

        dispatch(logoffUser())
        navigate("/auth/sign-in")
    }

    const userMenus = [
        {label: "Perfil", to: "/admin/profile"},
        {label: "Sair", action: handleLogout}
    ]

    const checkRoutePermission = (route: RoutesType | DropdownLinkType) => {
		if(user.admin){
			return true
		}else if(route.permission != undefined){
            if(user.permissions[route.permission] == true){
                return true
            }
        }else if(route.admin){
			if(user.admin){
				return true
			}
		}else if(!route.permission && !route.admin){
			return true
		}else{
            return false
        }
        // if(route.permission != undefined){
        //     if(user.permissions[route.permission] == true || user.admin){
        //         return true
        //     }else if(route.admin){
        //         if(user.admin){
        //             return true
        //         }
        //     }else{
        //         return true
        //     }

        //     return false
        // }else{
        //     return true
        // }
    }

    useEffect(()=> {
        let _routes: MenusType[] = []

        // routes.map((route)=>{
        //     if(route.forDevs && !devMode) return;
        //     if(!route.hidden
        //         && (route.layout == "/admin" || route.layout == "dropdown" || route.layout == "external")){
        //             if(checkRoutePermission(route)){
        //                 let _newMenus: MenusType["submenus"] = []
        //                 route.dropdownLinks?.map(link=>{
        //                     if(checkRoutePermission(link)){

        //                     }
        //                     _newMenus.push({
        //                         label: link.name,
        //                         to: link.path ? link.layout + "/" + link.path : undefined
        //                     })
        //                 })

        //                 _routes.push({
        //                     label: route.name,
        //                     to: route.path ? route.layout + "/" + route.path : undefined,
        //                     submenus: _newMenus
        //                 })
        //             }
        //         }
        // })

        routes.map((route)=>{
            if(route.forDevs && !devMode) return;
            if(!route.hidden
                && (route.layout == "/admin" || route.layout == "dropdown" || route.layout == "external")){
                    if(checkRoutePermission(route)){
                        let _newMenus: MenusType["submenus"] = []
                        route.dropdownLinks?.map(link=>{
                            if(checkRoutePermission(link)){
								_newMenus.push({
									label: link.name,
									to: link.path ? link.layout + "/" + link.path : undefined
								})
                            }
                        })

                        _routes.push({
                            label: route.name,
                            to: route.path ? route.layout + "/" + route.path : undefined,
                            submenus: _newMenus
                        })
                    }
                }
        })

        setMenus(_routes)
    }, [routes, user.permissions])

    return(
        <div className='h-[86px] flex flex-col w-full bg-gray-800 fixed z-[4000]'>
            <div className='h-[32px] w-full flex items-center justify-between p-2'>
                <div className='px-10'>
                    <img src={LogoBranco} className='h-[24px] cursor-pointer' onClick={()=> goTo(homePath)}/>
                </div>
                <div className='px-10 flex items-center'>
                    {/* <MdNotifications color='white' size={"24px"} className='cursor-pointer h-[32px] w-[32px] px-[8px] hover:bg-gray-750'/>
                    <MdLightMode color='white' size={"24px"} className='cursor-pointer h-[32px] w-[32px] px-[8px] hover:bg-gray-750'/> */}
                    <div className='relative group/user px-[8px] hover:bg-gray-750 h-[32px] flex items-center gap-2 cursor-pointer'>
                        {/* <img src='/avatar.png' className='rounded h-[24px] rounded-full' /> */}
						<img
							className="rounded h-[24px] w-[24px] rounded-full object-cover"
							src={user?.photo ? api.defaults.baseURL + "/uploads/" + user?.photo : avatar}
							alt={user?.name ? user?.name : "User"}
						/>
                        <span className='text-xs text-white'>{user.name}</span>
                        <MdArrowDropDown color='white'/>

                        <div className="invisible group-hover/user:visible absolute bg-gray-750 left-0 right-0 top-[32px] z-[101] flex flex-col py-2">
                            {userMenus.map((userMenu, index)=>(
                                <a key={`usermenu-${index}`} className='p-2 text-white text-xs font-bold hover:bg-gray-800' onClick={(e)=>{
                                    if(userMenu.action){
                                        userMenu.action(e)
                                    }
                                    if(userMenu.to){
                                        navigate(userMenu.to)
                                    }
                                }}>{userMenu.label}</a>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
            <div className='h-[54px] w-full flex items-center bg-gray-750 p-2 px-7'>
                {menus.map((menu, menu_index)=>(
					<>
						<div key={`menu-${menu_index}`} className='relative h-[54px] hover:bg-gray-800 text-white flex justify-center items-center cursor-pointer gap-2 group/container'>
							<div className='flex items-center gap-2 w-full h-full px-5' onClick={()=> {
								if(menu.to){
									goTo(menu.to)
								}
							}}>
								<span className='text-xs font-bold'>{menu.label}</span>
								{menu.submenus?.length > 0 && <span className='text-white group-hover/container:text-orange-500'><MdArrowDropDown/></span>}
							</div>
							{menu.submenus?.length > 0 &&
							<>
								<div className='invisible w-full absolute top-[100%] bg-gray-800 pb-2 group-hover/container:visible flex flex-col z-[100]'>
									{menu.submenus.map((sub, sub_index) => {
										if(true){
											return (
												<a key={`menu-${menu_index}-sub-${sub_index}`} className='text-xs group/submenu hover:bg-gray-750 w-full flex items-center justify-between relative'>
													<div className='gap-2 h-full w-full px-2 p-2 flex items-center justify-between' onClick={() => {
														if(sub.to){
															goTo(sub.to)}
														}
													}>
														<span className='font-bold'>{sub.label}</span>
														{sub.submenus?.length > 0 && <MdArrowRight className='w-[15px] h-[15px] group-hover/submenu:text-orange-500'/> }
													</div>

													{sub.submenus?.length > 0 &&
														<>
															<div className='invisible w-full absolute top-0 left-[100%] bg-gray-800 py-2 group-hover/submenu:visible flex flex-col z-[100]'>
																{sub.submenus.map((submenu, submenu_idx)=>(
																	<a onClick={() => {
																		if(submenu.to){
																			goTo(submenu.to)
																		}
																	}} key={`menu-${menu_index}-sub-${sub_index}-submenu-${submenu_idx}`} className='p-2 font-bold hover:bg-gray-750'>
																		{submenu.label}
																	</a>
																))}
															</div>
														</>
													}
												</a>
											)
										}
									})}
								</div>
							</>
							}

						</div>
					</>
                ))}
            </div>
        </div>
    )
}

export default Navbarv2