import { replaceSpecialChars } from 'helpers/conversions';
import { useEffect, useMemo, useState } from 'react';
import { StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async'
import { useAppSelector } from 'redux/hooks';
import api from 'services/api';
import { toast } from 'react-toastify';
import { ConfigDataType, NFs } from 'types/app';
import { toastError } from 'components/toasts/toastError';
import NFsCard from './components/NFsCard';
import { FiSearch } from 'react-icons/fi';
import dayjs from 'dayjs';
import Dropdown from 'components/dropdown';
import { months } from 'helpers/months';
import Calendar from 'react-calendar';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { ChakraProvider, Checkbox } from '@chakra-ui/react';


const NFsList = () => {
	const user = useAppSelector(selector => selector.user.user)
	const [data, setData] = useState({
		operacaoNF: {valueStr: "", valueNum: 0},
		optanteSimplesNacional: {valueStr: "", valueNum: 0},
		regime_especial_tributacao: {valueStr: "", valueNum: 0},
	})

	const [loadingOperacoes, setLoadOperacoes] = useState(true)
	const [operacoes, setOperacoes] = useState<{value: string, label: string}[]>([])

	const [search, setSearch] = useState('')
	const [loadingPage, setLoadingPage] = useState(false)

	const [nfsData, setNfsData] = useState<NFs[]>([])
	const [loadingNfs, setLoadingNfs] = useState(true)
	const [searchDate, setSearchDate] = useState(dayjs().startOf('month').toDate())

	const [ignorarData, setIgnorarData] = useState(false)

	const loadOperacoes = () => {
		setLoadOperacoes(true)
		api.get('/operacoes/autocomplete', {
			headers: {
                token: user?.token
            }
		}).then(response=> {
			setLoadOperacoes(false)
			if(response){
				if(response.data){
					return setOperacoes(response.data.map((operacao: {id: number, name: string})=>({value: operacao.id.toString(), label: operacao.name.toString()})))
				}
			}
		}).catch(()=> {
			setLoadOperacoes(false)
			return setOperacoes([])
		})
	}

	const loadData = () => {
        api.post('financeiro/get', {
			configs: "operacaoNF,optanteSimplesNacional,regime_especial_tributacao"
		}, {
            headers: {
                token: user?.token
            }
        }).then((res: {data: any})=> {
			setData(res.data)
        }).catch(err=> {
            toastError(err, false)
        })
	}

	const handleSave = () => {
		console.log(data)
        api.post('financeiro/save', {
			...data
		}, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
			toast("Configurações salvas com sucesso!", {
				type: "success",
				theme: "light"
			})
        }).catch(err=> {
            toastError(err, false)
        })
	}

	const selectStyle: StylesConfig = {
		singleValue: (styles, { isDisabled }) => ({
			...styles,
			color: isDisabled ? "rgb(112 126 174 / 1)" : undefined
		}),
		input: (styles, { isDisabled }) => ({
			...styles,
			paddingLeft: 2,
			paddingRight: 2,
			height: 42,

		}),
		option: (styles, { isSelected, isFocused, isDisabled}) => ({
			...styles,
			background: isSelected ? "#2d394d" : isFocused ? "#edf2f7" : styles.background,
			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
				? "#cbd5e0" : undefined,
			},
		}),
		control: (styles, {isDisabled}) => ({
			...styles,
			borderColor: "#a3aed0",
			borderRadius: 0,
			background: isDisabled ? "#f8f9fa" : "white",
			cursor: isDisabled ? "default" : "text"
		})
	}

	const editData = (key: keyof typeof data, valueNum?: number, valueStr?: string) => {
		setData({...data, [key]: {
			valueNum,
			valueStr
		} });
	}

	const loadNfs = () => {
		setLoadingNfs(true)

		api.get("/nfs/listar", {
			headers: {
				token: user?.token,
			},
			params: {
				date: ignorarData ? undefined : dayjs(searchDate).toISOString(),
				search
			}
		}).then(res=> {
			setNfsData([...res.data])
            setLoadingNfs(false)
		}).catch(err=> {
			toastError(err, false)
            setLoadingNfs(false)
		})
	}

	const handleSearch = (e: any) => {
		e.preventDefault();
		loadNfs()
	}

	const handleClickMonth = (date: Date) => {
	  setSearchDate(dayjs(date).startOf('month').toDate())
	}

	useEffect(()=> {
		loadData()
		loadOperacoes()
	}, [])

	useEffect(()=> {
		loadNfs()
	}, [ignorarData])

	return(
		<div className="mt-5 flex flex-col px-5">
			<div className="flex justify-between flex-col md:flex-row">
				<h1 className="text-2xl font-bold dark:text-white w-fit">Notas Fiscais</h1>

				<div className="flex md:items-center gap-5 flex-col md:flex-row w-full max-w-[50vw]">
					<form className="flex flex-col gap-2 md:flex-row items-center w-full" onSubmit={handleSearch}>

						<ChakraProvider>
							<Checkbox className='flex-nowrap text-nowrap' checked={ignorarData} onChange={(e)=> {
								setIgnorarData(e.target.checked)
							}}>Ignorar data</Checkbox>
						</ChakraProvider>
						<div className="flex h-12 w-full items-center rounded bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white">
							<p className="pl-3 pr-2 text-xl">
								<FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
							</p>
							<input
								value={search}
								onChange={e => setSearch(e.target.value)}
								type="text"
								placeholder="Procurar..."
								className="block h-full w-full rounded bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
							/>
						</div>

						<button type="button" onClick={handleSearch} disabled={loadingPage} className="linear flex flex-none items-center justify-center rounded bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
							Procurar
						</button>
					</form>

					<div className="flex items-center gap-2 w-full md:w-fit">
						<Dropdown
							extraClassesWrapper={`w-full md:w-fit`}
							extraClassesButton="w-full md:w-fit"
							button={
								<button
									className={`w-full flex flex-nowrap text-nowrap items-center text-md hover:cursor-pointer bg-orange-500 border border-gray-200 rounded p-2 shadow-xl shadow-shadow-600 transition hover:bg-orange-600 text-white font-bold`}
								>
									Mês: {months[dayjs(searchDate).month()]}
								</button>
							}
							animation={"origin-top-right transition-all duration-300 ease-in-out"}
							classNames={`right-0 w-max`}
							children={
								<div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none flex flex-col gap-2 dark:text-white">
									<Calendar
										value={searchDate}
										onClickMonth={handleClickMonth}
										prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
										nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
										view={"year"}
									/>
								</div>
							}
						/>
					</div>
				</div>
			</div>

			<div className="mt-5">
				<NFsCard data={nfsData} loadData={loadNfs} loading={loadingNfs} date={searchDate} />
			</div>
		</div>
	)
}

export default NFsList