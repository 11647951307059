import Card from "components/card"
import CustomInputV3 from "components/custom-input-3"
import { toastError } from "components/toasts/toastError"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useAppSelector } from "redux/hooks"
import api from "services/api"

interface PageType{
	[key: string]: {
		valueNum: number,
		valueStr: string
	}
}

const TaxasConfig = () => {
	const user = useAppSelector(selector=> selector.user.user)
	const darkmode = useAppSelector(selector=> selector.layout.darkMode)
	const [data, setData] = useState<PageType>({})

	useEffect(()=> {
		loadData()
	}, [])

	const loadData = () => {
        api.post('configs/get', {
			configs: "taxa_proit,taxa_distribuidor,taxa_normal"
		}, {
            headers: {
                token: user?.token
            }
        }).then((res: {data: PageType})=> {
			console.log(res.data)
			setData(res.data)
        }).catch(err=> {
            toastError(err, darkmode)
        })
	}

	const handleChangeValue = (key: string, valueStr?: string | undefined | null , valueNum?: number | undefined | null) => {
		let _data = data
		if(!_data[key]){
			_data[key] = {
				valueNum: 0,
				valueStr: ''
			}
		}

		if(valueNum != undefined && valueNum != null) _data[key].valueNum = valueNum
		if(valueStr != undefined && valueStr != null) _data[key].valueStr = valueStr

		setData({..._data})
	}

	const handleSave = () => {
        api.post('configs/save', {
			...data
		}, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
			toast("Configurações salvas com sucesso!", {
				type: "success",
				theme: darkmode ? "dark" : "light"
			})
        }).catch(err=> {
            toastError(err, darkmode)
        })
	}

	return(
		<Card extra="mt-5 p-5">
			<div onClick={handleSave} className="p-2 px-3 bg-orange-500 text-white text-sm ml-auto rounded-full cursor-pointer hover:bg-orange-600 transition">
				Salvar
			</div>
			<div className="flex flex-row gap-2 mt-5">
				<div className="flex flex-col gap-1">
					<label className="dark:text-white font-bold text-sm pl-2">Taxa Adicional Pro IT</label>
					<CustomInputV3 containerClass="w-full relative" suffixClass="dark:text-white absolute right-0" inputClass="no-arrows dark:text-white bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 rounded w-full p-2" value={data.taxa_proit?.valueNum} setValue={e=> handleChangeValue('taxa_proit', undefined, e)} max={100} suffix="%"/>
				</div>
				<div className="flex flex-col gap-1">
					<label className="dark:text-white font-bold text-sm pl-2">Partilha Distribuidor</label>
					<CustomInputV3 containerClass="w-full relative" suffixClass="dark:text-white absolute right-0" inputClass="no-arrows dark:text-white bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 rounded w-full p-2" value={data.taxa_distribuidor?.valueNum} setValue={e=> handleChangeValue('taxa_distribuidor', undefined, e)} max={100} suffix="%"/>
				</div>
				<div className="flex flex-col gap-1">
					<label className="dark:text-white font-bold text-sm pl-2">Imposto</label>
					<CustomInputV3 containerClass="w-full relative" suffixClass="dark:text-white absolute right-0" inputClass="no-arrows dark:text-white bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 rounded w-full p-2" value={data.taxa_normal?.valueNum} setValue={e=> handleChangeValue('taxa_normal', undefined, e)} max={100} suffix="%"/>
				</div>
			</div>
		</Card>
	)
}

export default TaxasConfig