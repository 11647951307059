import { createSlice } from '@reduxjs/toolkit';

interface UserType{
    user: {
		id?: number,
        name?: string,
        admin?: boolean,
        token?: string,
        photo?: string,
        permissions ?: {[key: string]: boolean}
    }
}

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null
    } as UserType,
    reducers: {
        setUser: (state, action) => {
            if(!state.user){
                state.user = {}
            }
            state.user = action.payload
        },
        logoffUser: (state) => {
            state.user = null
        },
        setPermissions: (state, action) => {
            if(!state.user.permissions){
                state.user.permissions = {}
            }
            state.user.permissions = action.payload
        }
    }
})

export const { setUser, logoffUser, setPermissions } = userSlice.actions

export default userSlice.reducer