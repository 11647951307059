import { FiSearch } from "react-icons/fi";

import { Link, useNavigate } from "react-router-dom";
import UsersTable from "./components/UsersTable";
import { users } from "./data/usersFakeData";
import { useEffect, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import RolesTable from "./components/RolesTable";
import { Select } from "@chakra-ui/react";
import GruposTable from "./components/GruposTable";

const Users = () => {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [usersData, setUsersData] = useState([]);
	const [search, setSearch] = useState("");
	const user = useAppSelector((state) => state.user.user);
	const [page, setPage] = useState<number>(1);
	const [maxPages, setMaxPages] = useState<number>(2);
	const navigate = useNavigate();

	const [rolesPage, setRolesPage] = useState<number>(1);
	const [rolesData, setRolesData] = useState([]);
	const [roleSearch, setRoleSearch] = useState("");
	const [rolesMaxPages, setRolesMaxPages] = useState<number>(1);

	const [gruposPage, setGruposPage] = useState<number>(1);
	const [gruposData, setGruposData] = useState([]);
	const [gruposSearch, setGruposSearch] = useState("");
	const [gruposMaxPages, setGruposMaxPages] = useState<number>(1);

	const [loadingPage, setLoadingPage] = useState(false);
	const [loadingRoles, setLoadingRoles] = useState(false);
	const [loadingGrupos, setLoadingGrupos] = useState(false);

	const [mode, setMode] = useState(1);

	const getRoles = () => {
		api.get("/role/all/paginated", {
			headers: {
				token: user?.token,
			},
			params: {
				page,
				perpage: rowsPerPage,
				search: roleSearch,
			},
		}).then((res) => {
			setLoadingRoles(false);
			setRolesData(res.data.roles);
			setRolesMaxPages(res.data.maxPages);
		});
	};

	const getGrupos = () => {
		api.get("/users/grupos/all/paginated", {
			headers: {
				token: user?.token,
			},
			params: {
				page,
				perpage: rowsPerPage,
				search: roleSearch,
			},
		}).then((res) => {
			console.log({data: res.data})
			setLoadingGrupos(false);
			setGruposData(res.data.grupos);
			setGruposMaxPages(res.data.maxPages);
		});
	};

	const getUsers = () => {
		api.get(`/users/all`, {
			headers: {
				token: user?.token,
			},
			params: {
				active: mode,
				page,
				perpage: rowsPerPage,
				search,
			},
		}).then((res) => {
			setLoadingPage(false);
			setUsersData(res.data.users);
			setMaxPages(res.data.maxPages);
		});
	};

	useEffect(() => {
		setLoadingPage(true);
		setLoadingRoles(true);

		getRoles();
		getUsers();
	}, [page, mode]);

	useEffect(()=> {
		setLoadingGrupos(true)

		getGrupos();
	}, [gruposPage])

	const handleSearch = (e: any) => {
		e.preventDefault();

		if (loadingPage) return;

		setPage(1);
		setLoadingPage(true);

		getUsers();
	};

	const handleRolesSearch = (e: any) => {
		e.preventDefault();

		if (loadingRoles) return;

		setPage(1);
		setLoadingRoles(true);

		getRoles();
	};

	const handleGruposSearch = (e: any) => {
		e.preventDefault();

		if (loadingGrupos) return;

		setPage(1);
		setLoadingGrupos(true);

		getGrupos();
	};

	const handleChangeGruposPage = (e: any) => {
		e.preventDefault();

		if (loadingGrupos) return;

		setPage(1);
		setLoadingGrupos(true);
	};

	const handleChangePage = (newPage: number) => {
		if (loadingPage) return;
		setLoadingPage(true);
		setPage(newPage);
	};

	const handleChangeRolesPage = (newPage: number) => {
		if (loadingPage) return;
		setLoadingRoles(true);
		setRolesPage(newPage);
	};

	const handleNewClient = () => {
		navigate("/admin/users/new");
	};

	const handleNewRole = () => {
		navigate("/admin/roles/new");
	};

	const handleNewGrupo = () => {
		navigate("/admin/grupos/new");
	};

	return (
		<>
			<div className="mt-10 flex flex h-full w-full items-center justify-end gap-2 py-2">
				<select
					className="cursor-pointer rounded bg-transparent p-2 text-sm"
					value={mode}
					onChange={(e) => setMode(parseInt(e.target.value))}
				>
					<option value="1">Usuários ativos</option>
					<option value="0">Usuários inativos</option>
				</select>
				<button
					onClick={handleNewClient}
					className="linear dark:bg-darkgreen-400 dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200 mr-5 flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-sm font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:text-white"
				>
					Novo usuário
				</button>
			</div>
			<form
				className="mt-2 flex h-full w-full items-center justify-center gap-2 rounded-xl bg-white px-5 py-2 dark:!bg-navy-800"
				onSubmit={handleSearch}
			>
				<div className="shadow-600 flex h-12 w-full items-center rounded-full border border-gray-200 bg-white text-navy-700	 shadow-md dark:bg-navy-900 dark:text-white">
					<p className="pl-3 pr-2 text-xl">
						<FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
					</p>
					<input
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						type="text"
						placeholder="Procurar..."
						className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
					/>
				</div>
				<button
					onClick={handleSearch}
					disabled={loadingPage}
					className="linear flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
				>
					Procurar
				</button>
			</form>
			<div className="mt-5">
				<UsersTable
					page={page}
					loading={loadingPage}
					setPage={handleChangePage}
					title="Lista de Usuários"
					tableData={usersData}
					maxPages={maxPages}
					refreshData={getUsers}
				/>
			</div>

			<div className="flex gap-5">


				<div className="flex flex-col w-full">

					<form
						className="mt-2 flex w-full justify-center gap-2 rounded-xl bg-white px-5 py-2 dark:!bg-navy-800"
						onSubmit={handleSearch}>
						<div className="shadow-600 flex h-12 w-full items-center rounded-full border border-gray-200 bg-white text-navy-700 shadow-md dark:bg-navy-900 dark:text-white">
							<p className="pl-3 pr-2 text-xl">
								<FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
							</p>
							<input
								value={roleSearch}
								onChange={(e) => setRoleSearch(e.target.value)}
								type="text"
								placeholder="Procurar..."
								className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
							/>
						</div>
						<button
							onClick={handleRolesSearch}
							disabled={loadingRoles}
							className="linear text-sm flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
						>Procurar</button>
						<button
							onClick={handleNewRole}
							className="text-nowrap text-sm linear dark:bg-darkgreen-400 dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200 flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-sm font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:text-white"
						>Novo cargo</button>
					</form>

					<div className="mt-5">
						<RolesTable
							page={rolesPage}
							loading={loadingRoles}
							setPage={handleChangeRolesPage}
							title="Lista de Cargos"
							tableData={rolesData}
							maxPages={rolesMaxPages}
							refreshData={getRoles}
						/>
					</div>
				</div>

				<div className="flex flex-col w-full">
					<form
						className="mt-2 flex w-full items-center justify-center gap-2 rounded-xl bg-white px-5 py-2 dark:!bg-navy-800"
						onSubmit={handleSearch}>
						<div className="shadow-600 flex h-12 w-full items-center rounded-full border border-gray-200 bg-white text-navy-700 shadow-md dark:bg-navy-900 dark:text-white">
							<p className="pl-3 pr-2 text-xl">
								<FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
							</p>
							<input
								value={roleSearch}
								onChange={(e) => setRoleSearch(e.target.value)}
								type="text"
								placeholder="Procurar..."
								className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
							/>
						</div>

						<button
							onClick={handleGruposSearch}
							disabled={loadingGrupos}
							className="linear flex text-sm items-center justify-center rounded-xl bg-orange-500 !h-[48px] px-2 py-2 font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
						>Procurar</button>
						<button
							onClick={handleNewGrupo}
							className="text-nowrap text-sm linear dark:bg-darkgreen-400 dark:hover:bg-darkgreen-300 !h-[48px] dark:active:bg-darkgreen-200 flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-sm font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:text-white"
						>Novo grupo</button>
					</form>

					<div className="mt-5">
						<GruposTable
							refreshData={getGrupos}
							page={gruposPage}
							loading={loadingGrupos}
							setPage={handleChangeGruposPage}
							title="Lista de Grupos"
							tableData={gruposData}
							maxPages={gruposMaxPages}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Users;
