import React from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import { MdArrowCircleLeft, MdArrowLeft, MdArrowRight, MdCancel, MdCheckCircle, MdDelete, MdEdit, MdOutlineError } from "react-icons/md";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@chakra-ui/tooltip";
import { ScopeOfService } from "types/app";
import { numberToHour } from "helpers/conversions";
import Swal from "sweetalert2";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import { scopeNames, scopeTypes } from "../data/scopeTypes";
import TooltipHorizon from "components/tooltip";

interface RowData extends ScopeOfService {

}

const columnHelper = createColumnHelper<RowData>();

// const columns = columnsDataCheck;
export default function ScopesOfServiceTable(props: { tableData: RowData[], reloadTable: any, title?: string, page: number, setPage: React.Dispatch<number>, maxPages: number, loading?: boolean }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [defaultData, setDefaultData] = React.useState([])

  const darkmode = useAppSelector((state) => state.layout.darkMode)
  const user = useAppSelector((state) => state.user.user)
  const navigate = useNavigate()

  const handleEdit = (data: CellContext<RowData, any>) => {
    navigate('edit/'+data.row.original.id)
  }
  const handleDelete = (data: CellContext<RowData, any>) => {
    Swal.fire({
      title: "Remover Escopo",
      text: "Você realmente deseja remover o escopo "+data.row.original.name+"?",
      icon: "question",
      confirmButtonText: "Sim",
      showCancelButton: true,
      cancelButtonText: "Não",
      background: darkmode ? "rgb(11 20 55)" : "#fff",
      color: darkmode ? "#fff" : "#222",
    }).then(response=> {
      if(response.isConfirmed){
        api.delete('/scopesofservice/delete/'+data.row.original.id, {
          headers: {
            token: user?.token
          }
        }).then(()=>{
          props.reloadTable()
          toast("Escopo removido com sucesso!", {
              type: "success",
              theme: darkmode ? "dark" : "light"
          })
        }).catch((error: ErrorData)=>{
          toast(error.response.data.error, {
              type: "error",
              theme: darkmode ? "dark" : "light"
          })
      })
      }
    })
  }

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">NOME</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("label", {
      id: "label",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          DESCRIÇÃO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {info.getValue() ? info.getValue() : "Não registrado"}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("item.name", {
      id: "itemName",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ITEM
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {info.getValue() ? info.getValue() : "Não associado"}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("type", {
      id: "type",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          TIPO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {scopeNames[info.getValue()] ? scopeNames[info.getValue()] : "Não associado"}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("timeSpent", {
      id: "timeSpent",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          TEMPO GASTO
        </p>
      ),
      cell: (info) => {
        const {hours, minutes} = numberToHour(info.getValue())
        return(
          <div className="flex items-center">
            <p className="text-xs text-navy-700 dark:text-white">
              {hours ? hours : 0}:{minutes ? minutes : 0}hrs
            </p>
          </div>
        )
      },
    }),
    columnHelper.accessor(null, {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">AÇÃO</p>
      ),
      cell: (info) => (
        <p className="flex items-center gap-2 text-xs text-navy-700 dark:text-white">
			<TooltipHorizon
				content={<span>Editar</span>}
				trigger={
					<button onClick={()=> handleEdit(info)} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<MdEdit />
					</button>
				}
				extra=""
				placement="top"
			/>
			<TooltipHorizon
				content={<span>Deletar</span>}
				trigger={
					<button onClick={()=> handleDelete(info)} className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<MdDelete />
					</button>
				}
				extra=""
				placement="top"
			/>
        </p>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(()=>{
    setData(()=> [...tableData])
  }, [tableData])
  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          {props.title}
        </div>
      </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>

        {props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData?.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Não há nenhum escopo cadastrado na plataforma...</h1> }
      </div>

      <div className="mt-2 gap-6 flex items-center justify-center">
        <button disabled={props.page <= 1} onClick={() => props.setPage(props.page - 1)} className="linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
          <MdArrowLeft />
        </button>
        <span className="text-gray-900 dark:text-gray-200">
            {props.page}
        </span>
        <button disabled={props.page >= props.maxPages} onClick={() => props.setPage(props.page + 1)}  className="linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
          <MdArrowRight />
        </button>
      </div>
    </Card>
  );
}
