import type { Reducer } from '@reduxjs/toolkit'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import * as rp from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import userSlice from './reducers/userSlice'
import layoutSlice from './reducers/layoutSlice'
import orderSlice from './reducers/orderSlice'
import notificationsSlice from './reducers/notificationsSlice'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciles: hardSet as (inboundState: CombinedState) => CombinedState,
  blacklist: ['notifications'],
  version: 1,
} as rp.PersistConfig<CombinedState>

type CombinedState = typeof rootReducer extends Reducer<infer U, any> ? U : never

const rootReducer = combineReducers({
    user: userSlice,
    layout: layoutSlice,
    order: orderSlice,
    notifications: notificationsSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [rp.FLUSH, rp.REHYDRATE, rp.PAUSE, rp.PERSIST, rp.PURGE, rp.REGISTER],
      },
    }),
})

export const persistor = persistStore(store)


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch