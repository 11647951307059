import CustomInputV2 from "components/custom-input-2";
import SideMenu from "components/sidemenu";
import dayjs from "dayjs";
import { replaceSpecialChars } from "helpers/conversions";
import { cnpjMask } from "masks/cnpjMask";
import { useEffect, useState } from "react";
import { StylesConfig } from "react-select";
import AsyncSelect from 'react-select/async'
import api from "services/api";
import avatar from "assets/img/avatars/default-profile.png";
import { ConfigDataType, IUserOperacoes, Operacao, SaleSheetType, SelectType, SelectTypePhoto } from "types/app";
import { distribuidores, fabricantes, faturamentos, formasDePagamento, segmentos, status_pedidos, statusPedido, tiposProdutos } from "views/sheets/planilhaVendas";

const referenteSelect = [
	{value: 0, label: "Fornecedor"},
	{value: 1, label: "Cliente"},
	{value: 2, label: "Imposto"},
]

const metodosPagamentoSelect = [
	{value: 1, label: "Boleto"},
	{value: 2, label: "PIX"},
	{value: 3, label: "TED"},
]

interface IMargemType {
	bruta: number,
	liquida: number
}

interface IPosVendasSidemenu {
	isOpen: boolean;
	operacoes: Operacao[];
	userOperacoes: IUserOperacoes[];
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    loading: boolean;
    editData: (key: string, value: any) => void;
    resetData: () => void;
    handleSave: () => void;
	data: SaleSheetType;
	clients: SelectType[];
	funcs: {id: string, label: string}[],
	funcNames: SelectTypePhoto[],
	cnpjs: {id: number, cnpj: string}[],
	configs: ConfigDataType,
	canDelete?: boolean
	deleteText?: string
	deleteAction?: (id: number) => void
	margens: IMargemType
	setMargens: React.Dispatch<IMargemType>;
	transportadoras: SelectType[];
}

const PosVendasSidemenu = ({
	isOpen,
    setIsOpen,
    title,
    loading,
    editData,
    resetData,
    handleSave,
    data,
	clients,
	funcs,
	funcNames,
	cnpjs,
	configs,
	operacoes,
	userOperacoes,
	deleteText,
	deleteAction,
	margens,
	setMargens,
	transportadoras
}: IPosVendasSidemenu) => {

	const [arquitetos, setArquitetos] = useState([])
	const [preVendas, setPreVendas] = useState([])
	const [participantes, setParticipantes] = useState([])

	useEffect(() => {
		const _margens = {
			bruta: 0,
			liquida: 0
		}

		_margens.bruta = (data.valor_venda - data.valor_custo > 0 ? data.valor_venda - data.valor_custo : 0)

		const _taxa = data.faturamento == 2 ? configs["taxa_distribuidor"] : configs["taxa_proit"]
		const _taxa_normal = configs["taxa_normal"]

		const taxa = _taxa ? (_taxa.valueNum) / 100 : 0
		const taxa_normal = _taxa_normal ? _taxa_normal.valueNum / 100 : 0

		const calc_faturamento = _margens.bruta - (_margens.bruta * taxa)
		const calc_taxa = calc_faturamento - (calc_faturamento * taxa_normal)

		_margens.liquida = calc_taxa

		setMargens(_margens)

	}, [data.valor_custo, data.valor_venda, data.faturamento])

	useEffect(()=> {
		const funcs = data.arquitetos
		let funcsArray = []
		if(typeof funcs == "object"){
			funcsArray = funcs.map((v: any)=> v.toString())
		}

		setArquitetos(funcsArray)
	}, [data.arquitetos])
	useEffect(()=> {
		const funcs = data.pre_vendas
		let funcsArray = []
		if(typeof funcs == "object"){
			funcsArray = funcs.map((v: any)=> v.toString())
		}

		setPreVendas(funcsArray)
	}, [data.pre_vendas])
	useEffect(()=> {
		const funcs = data.participantes
		let funcsArray = []
		if(typeof funcs == "object"){
			funcsArray = funcs.map((v: any)=> v.toString())
		}

		setParticipantes(funcsArray)
	}, [data.participantes])

	const selectStyle: StylesConfig = {
		singleValue: (styles, { isDisabled }) => ({
			...styles,
			color: isDisabled ? "rgb(112 126 174 / 1)" : undefined
		}),
		input: (styles, { isDisabled }) => ({
			...styles,
			paddingLeft: 2,
			paddingRight: 2,
			height: 42,

		}),
		option: (styles, { isSelected, isFocused, isDisabled}) => ({
			...styles,
			background: isSelected ? "#2d394d" : isFocused ? "#edf2f7" : styles.background,
			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
				? "#cbd5e0" : undefined,
			},
		}),
		control: (styles, {isDisabled}) => ({
			...styles,
			borderColor: "#a3aed0",
			borderRadius: 0,
			background: isDisabled ? "#f8f9fa" : "white",
			cursor: isDisabled ? "default" : "text"
		})
	}

	return (
		<SideMenu
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={title}
			loading={loading}
			onConfirm={() => {
				handleSave();
			}}
			onClose={() => {
				resetData();
			}}
		>
			<div className="flex flex-col gap-5">
				<div className="grid grid-cols-3 gap-2">

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">
							Data do pedido
						</h2>
						<input
							type="date"
							className="h-[50px] border border-gray-600 px-2"
							defaultValue={data.data ? dayjs(
								data.data
							).format(`YYYY-MM-DD`) : null}
							onBlur={(e) => {
								if (
									e.target.value &&
									dayjs(e.target.value).isValid()
								)
									editData(
										`data`,
										dayjs(e.target.value).toISOString()
									);
								else
									editData("data", null)
							}}
						/>
					</div>
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">
							Previsão de entrega
						</h2>
						<input
							type="date"
							disabled
							className="h-[50px] border border-gray-600 px-2 cursor-text text-gray-700"
							defaultValue={data.previsao_entrega ? dayjs(
								data.previsao_entrega
							).format(`YYYY-MM-DD`) : null}
							onBlur={(e) => {
								if (
									e.target.value &&
									dayjs(e.target.value).isValid()
								)
									editData(
										`previsao_entrega`,
										dayjs(e.target.value).toISOString()
									);
								else
									editData("previsao_entrega", null)
							}}
						/>
					</div>
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">
							Data de entrega
						</h2>
						<input
							type="date"
							className="h-[50px] border border-gray-600 px-2 cursor-text text-gray-700"
							disabled
							defaultValue={data.data_entrega ? dayjs(
								data.data_entrega
							).format(`YYYY-MM-DD`) : null}
							onBlur={(e) => {
								if (
									e.target.value &&
									dayjs(e.target.value).isValid()
								)
									editData(
										`data_entrega`,
										dayjs(e.target.value).toISOString()
									);
								else
									editData("data_entrega", null)
							}}
						/>
					</div>
				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">
						Transportadora
					</h2>

					<AsyncSelect
						defaultOptions
						cacheOptions
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = transportadoras
									.filter((transportadora) =>
										replaceSpecialChars(
											transportadora.content.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((transportadora) => {
										let _transportadora: any = {};
										_transportadora.value = transportadora.value;
										_transportadora.label = transportadora.content;
										return _transportadora;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.transportadora?.toString(),
							label: transportadoras?.find(
								(c) =>
									c.value.toString() ==
									data.transportadora?.toString()
							)?.content,
						}}
						onChange={(e: any) => {
							editData("transportadora", e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Vendedor</h2>

					<AsyncSelect
						defaultOptions
						cacheOptions
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = funcs
									.filter((func) =>
										replaceSpecialChars(
											func.label.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((func) => {
										let _func: any = {};
										_func.value = func.id;
										_func.label = func.label;
										return _func;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.userId,
							label: funcs?.find(
								(c) =>
									c.id.toString() ==
									data.userId?.toString()
							)?.label,
						}}
						onChange={(e: any) => {
							editData("userId", e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Operação</h2>

					<AsyncSelect
						defaultOptions
						cacheOptions
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = operacoes.filter(op=> {
									return userOperacoes.find(u=> u.id == data.userId)?.userOperacoes.includes(op.id)
								}).filter((op) =>
										replaceSpecialChars(
											op.name.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((func) => {
										let _func: any = {};
										_func.value = func.id;
										_func.label = func.name;
										return _func;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.operacaoId,
							label: operacoes?.find(
								(c) =>
									c.id.toString() ==
									data.operacaoId?.toString()
							)?.name,
						}}
						onChange={(e: any) => {
							editData("operacaoId", e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione uma"}
						styles={selectStyle}
					/>
				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Cliente</h2>

					<AsyncSelect
						defaultOptions
						cacheOptions
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = clients
									.filter((cliente) =>
										replaceSpecialChars(
											cliente.content.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((cliente) => {
										let _cliente: any = {};
										_cliente.value = cliente.value;
										_cliente.label = cliente.content;
										return _cliente;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.clientId,
							label: clients?.find(
								(c) =>
									c.value.toString() ==
									data.clientId?.toString()
							)?.content,
						}}
						onChange={(e: any) => {
							editData("clientId", e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">CNPJ</h2>

					<input
						className="h-[50px] border border-gray-600 p-2 px-2 text-gray-700"
						value={cnpjs.find(c=> c.id == data.clientId) ? cnpjMask(cnpjs.find(c=> c.id == data.clientId).cnpj) : "Não identificado"}
						disabled
					/>
				</div>
				<div className="grid grid-cols-2 gap-2">
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Segmento</h2>

						<AsyncSelect
							defaultOptions
							cacheOptions
							menuPlacement="auto"
							loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = segmentos
										.filter((item) =>
											replaceSpecialChars(
												item.label.toLowerCase()
											).includes(inputstring.toLowerCase())
										)
										.map((item) => {
											let _item: any = {};
											_item.value = item.id;
											_item.label = item.label;
											return _item;
										})
										.slice(0, 9);

									setTimeout(() => {
										resolve(options);
									}, 250);
								});
							}}
							value={{
								value: data.segmento,
								label: segmentos?.find(
									(c) =>
										c.id.toString() ==
										data.segmento?.toString()
								)?.label,
							}}
							onChange={(e: any) => {
								editData("segmento", e.value);
							}}
							noOptionsMessage={() => "Sem dados"}
							placeholder={"Selecione um"}
							styles={selectStyle}
						/>
					</div>
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Tipo de produto</h2>

						<AsyncSelect
							defaultOptions
							cacheOptions
							menuPlacement="auto"
							loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = tiposProdutos
										.filter((item) =>
											replaceSpecialChars(
												item.label.toLowerCase()
											).includes(inputstring.toLowerCase())
										)
										.map((item) => {
											let _item: any = {};
											_item.value = item.id;
											_item.label = item.label;
											return _item;
										})
										.slice(0, 9);

									setTimeout(() => {
										resolve(options);
									}, 250);
								});
							}}
							value={{
								value: data.tipo_produto,
								label: tiposProdutos?.find(
									(c) =>
										c.id.toString() ==
										data.tipo_produto?.toString()
								)?.label,
							}}
							onChange={(e: any) => {
								editData("tipo_produto", e.value);
							}}
							noOptionsMessage={() => "Sem dados"}
							placeholder={"Selecione um"}
							styles={selectStyle}
						/>
					</div>
				</div>

				<div className="grid grid-cols-2 gap-2">
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Fabricante</h2>

						<AsyncSelect
							defaultOptions
							cacheOptions
							menuPlacement="auto"
							loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = fabricantes
										.filter((item) =>
											replaceSpecialChars(
												item.label.toLowerCase()
											).includes(inputstring.toLowerCase())
										)
										.map((item) => {
											let _item: any = {};
											_item.value = item.id;
											_item.label = item.label;
											return _item;
										})
										.slice(0, 9);

									setTimeout(() => {
										resolve(options);
									}, 250);
								});
							}}
							value={{
								value: data.fabricante,
								label: fabricantes?.find(
									(c) =>
										c.id.toString() ==
										data.fabricante?.toString()
								)?.label,
							}}
							onChange={(e: any) => {
								editData("fabricante", e.value);
							}}
							noOptionsMessage={() => "Sem dados"}
							placeholder={"Selecione um"}
							styles={selectStyle}
						/>
					</div>
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Distribuidor</h2>

						<AsyncSelect
							defaultOptions
							cacheOptions
							menuPlacement="auto"
							loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = distribuidores
										.filter((item) =>
											replaceSpecialChars(
												item.label.toLowerCase()
											).includes(inputstring.toLowerCase())
										)
										.map((item) => {
											let _item: any = {};
											_item.value = item.id;
											_item.label = item.label;
											return _item;
										})
										.slice(0, 9);

									setTimeout(() => {
										resolve(options);
									}, 250);
								});
							}}
							value={{
								value: data.distribuidor,
								label: distribuidores?.find(
									(c) =>
										c.id.toString() ==
										data.distribuidor?.toString()
								)?.label,
							}}
							onChange={(e: any) => {
								editData("distribuidor", e.value);
							}}
							noOptionsMessage={() => "Sem dados"}
							placeholder={"Selecione um"}
							styles={selectStyle}
						/>
					</div>
				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Número de cotação</h2>

					<input
						className="h-[50px] border border-gray-600 p-2 px-2"
						defaultValue={data.n_cotacao}
						onBlur={e=> editData("n_cotacao", e.target.value)}
					/>
				</div>

				<div className="grid grid-cols-2 gap-2">
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Número do pedido distribuidor</h2>

						<input
							className="h-[50px] border border-gray-600 p-2 px-2"
							defaultValue={data.n_pedido}
							onBlur={e=> editData("n_pedido", e.target.value)}
						/>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">
							Data de envio ao distribuidor
						</h2>
						<input
							type="date"
							className="h-[50px] border border-gray-600 px-2"
							defaultValue={data.data_envio_distribuidor ? dayjs(
								data.data_envio_distribuidor
							).format(`YYYY-MM-DD`) : null}
							onBlur={(e) => {
								if (
									e.target.value &&
									dayjs(e.target.value).isValid()
								)
									editData(
										`data_envio_distribuidor`,
										dayjs(e.target.value).toISOString()
									);
							}}
						/>
					</div>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">
						Descrição
					</h2>
					<textarea
						className="h-[80px] min-h-[80px] border border-gray-600 p-2 px-2 "
						defaultValue={data.descricao_produto}
						onBlur={(e) => {
							editData(`descricao_produto`, e.target.value);
						}}
					/>
				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Valor de venda</h2>
					<CustomInputV2
						type="currency"
						totalWidth={500}
						value={data.valor_venda}
						setValue={(e) => {
							editData("valor_venda", e);
						}}
						containerClass="h-[50px] px-2 border border-gray-600 w-full"
						inputClass="w-full outline-none h-[50px]"
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Valor de custo</h2>
					<CustomInputV2
						type="currency"
						totalWidth={500}
						value={data.valor_custo}
						setValue={(e) => {
							editData("valor_custo", e);
						}}
						containerClass="h-[50px] px-2 border border-gray-600 w-full"
						inputClass="w-full outline-none h-[50px]"
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Margem bruta</h2>
					<CustomInputV2
						type="currency"
						disabled
						totalWidth={500}
						value={margens.bruta}
						setValue={(e) => {

						}}
						containerClass="h-[50px] px-2 border border-gray-600 w-full"
						inputClass="w-full outline-none h-[50px]"
					/>
				</div>
				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Margem líquida</h2>
					<CustomInputV2
						type="currency"
						disabled
						totalWidth={500}
						value={margens.liquida}
						setValue={(e) => {

						}}
						containerClass="h-[50px] px-2 border border-gray-600 w-full"
						inputClass="w-full outline-none h-[50px]"
					/>
				</div>

				<div className="grid grid-cols-2 gap-2 gap-y-5">
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Forma de pagamento</h2>

						<AsyncSelect
							defaultOptions
							cacheOptions
							menuPlacement="auto"
							loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = formasDePagamento
										.filter((item) =>
											replaceSpecialChars(
												item.label.toLowerCase()
											).includes(inputstring.toLowerCase())
										)
										.map((item) => {
											let _item: any = {};
											_item.value = item.id;
											_item.label = item.label;
											return _item;
										})
										.slice(0, 9);

									setTimeout(() => {
										resolve(options);
									}, 250);
								});
							}}
							value={{
								value: data.forma_pagamento,
								label: formasDePagamento?.find(
									(c) =>
										c.id.toString() ==
										data.forma_pagamento?.toString()
								)?.label,
							}}
							onChange={(e: any) => {
								editData("forma_pagamento", e.value);
							}}
							noOptionsMessage={() => "Sem dados"}
							placeholder={"Selecione um"}
							styles={selectStyle}
						/>
					</div>
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Faturamento</h2>

						<AsyncSelect
							defaultOptions
							cacheOptions
							menuPlacement="auto"
							loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = faturamentos
										.filter((item) =>
											replaceSpecialChars(
												item.label.toLowerCase()
											).includes(inputstring.toLowerCase())
										)
										.map((item) => {
											let _item: any = {};
											_item.value = item.id;
											_item.label = item.label;
											return _item;
										})
										.slice(0, 9);

									setTimeout(() => {
										resolve(options);
									}, 250);
								});
							}}
							value={{
								value: data.faturamento,
								label: faturamentos?.find(
									(c) =>
										c.id.toString() ==
										data.faturamento?.toString()
								)?.label,
							}}
							onChange={(e: any) => {
								editData("faturamento", e.value);
							}}
							noOptionsMessage={() => "Sem dados"}
							placeholder={"Selecione um"}
							styles={selectStyle}
						/>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">
							Status do pedido
						</h2>

						<AsyncSelect
							defaultOptions
							cacheOptions
							menuPlacement="auto"
							isDisabled
							loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = status_pedidos
										.filter((item) =>
											replaceSpecialChars(
												item.label.toLowerCase()
											).includes(inputstring.toLowerCase())
										)
										.map((item) => {
											let _item: any = {};
											_item.value = item.id;
											_item.label = item.label;
											return _item;
										})
										.slice(0, 9);

									setTimeout(() => {
										resolve(options);
									}, 250);
								});
							}}
							value={{
								value: data.status_pedido,
								label: status_pedidos?.find(
									(c) =>
										c.id.toString() ==
										data.status_pedido?.toString()
								)?.label,
							}}
							onChange={(e: any) => {
								editData("status_pedido", e.value);
							}}
							noOptionsMessage={() => "Sem dados"}
							placeholder={"Selecione um"}
							styles={selectStyle}
						/>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">NFs Faturamento</h2>

						<input
							className="h-[50px] border border-gray-600 p-2 px-2 text-gray-700"
							defaultValue={data.nfs_faturamento}
							disabled
							onBlur={(e) => {
								editData(`nfs_faturamento`, e.target.value);
							}}
						/>
					</div>
				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Link da oportunidade</h2>

					<input
						className="h-[50px] border border-gray-600 p-2 px-2"
						defaultValue={data.link_oportunindade}
						onBlur={(e) => {
							editData(`link_oportunindade`, e.target.value);
						}}
					/>
				</div>


				<div className="flex flex-col gap-1">

					<h2 className="text-md font-bold text-gray-800">Arquitetos</h2>

					<AsyncSelect
						className="w-full"
						defaultOptions
						cacheOptions
						menuPlacement="auto"
						isMulti
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = funcNames
									.filter((item) =>
										replaceSpecialChars(
											item.content.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((item) => {
										let _item: any = {};
										_item.value = parseInt(item.value);
										_item.label = item.content;
										return _item;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={data.arquitetos?.map(i=>{
							return {
								value: i,
								label: funcNames.find(f=> f.value.toString() == i.toString())?.content,
							}
						})}
						onChange={(e: any) => {
							const arr = e.map((i: any)=> i.value)
							editData(`arquitetos`, arr);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>

					<div className="flex hidden items-center gap-2">
						{arquitetos.length > 0 ? arquitetos.map((id: any) => {
							const func = funcNames.find(f=> f.value == id)
							const photo = func?.photo
							const name = func?.content
							return(
								<div key={id} className="w-[120px] h-[120px] rounded border hover:border-gray-600 p-2 flex flex-col gap-1">
									<img
										className="h-[60px] w-full cursor-pointer object-cover"
										src={photo ? api.defaults.baseURL + "/uploads/" + photo : avatar}
										alt={name ? name : "User"}
									/>
									<span className="w-full text-md text-center">{name}</span>
								</div>
							)
						}) : <span>Não há arquiteto(s) nessa negociação</span>}
					</div>

				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Pré-vendas</h2>

					<AsyncSelect
						className="w-full"
						defaultOptions
						cacheOptions
						menuPlacement="auto"
						isMulti
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = funcNames
									.filter((item) =>
										replaceSpecialChars(
											item.content.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((item) => {
										let _item: any = {};
										_item.value = parseInt(item.value);
										_item.label = item.content;
										return _item;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={data.pre_vendas?.map(i=>{
							return {
								value: i,
								label: funcNames.find(f=> f.value.toString() == i.toString())?.content,
							}
						})}
						onChange={(e: any) => {
							const arr = e.map((i: any)=> i.value)
							editData(`pre_vendas`, arr);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>

					<div className="flex hidden items-center gap-2">
						{preVendas.length > 0 ? preVendas.map((id: any) => {
							const func = funcNames.find(f=> f.value == id)
							const photo = func?.photo
							const name = func?.content
							return(
								<div key={id} className="w-[120px] h-[120px] rounded border hover:border-gray-600 p-2 flex flex-col gap-1">
									<img
										className="h-[60px] w-full cursor-pointer object-cover"
										src={photo ? api.defaults.baseURL + "/uploads/" + photo : avatar}
										alt={name ? name : "User"}
									/>
									<span className="w-full text-md text-center">{name}</span>
								</div>
							)
						}) : <span>Não há pré-vendas nessa negociação</span>}
					</div>

				</div>

				<div className="flex flex-col gap-1">
					<h2 className="text-md font-bold text-gray-800">Participantes</h2>

					<AsyncSelect
						className="w-full"
						defaultOptions
						cacheOptions
						isMulti
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = funcNames
									.filter((item) =>
										replaceSpecialChars(
											item.content.toLowerCase()
										).includes(inputstring.toLowerCase())
									)
									.map((item) => {
										let _item: any = {};
										_item.value = parseInt(item.value);
										_item.label = item.content;
										return _item;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={data.participantes?.map(i=>{
							return {
								value: i,
								label: funcNames.find(f=> f.value.toString() == i.toString())?.content,
							}
						})}
						onChange={(e: any) => {
							const arr = e.map((i: any)=> i.value)
							editData(`participantes`, arr);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>

					<div className="flex items-center hidden gap-2">
						{participantes.length > 0 ? participantes.map((id: any) => {
							const func = funcNames.find(f=> f.value == id)
							const photo = func?.photo
							const name = func?.content
							return(
								<div key={id} className="w-[120px] h-[120px] rounded border hover:border-gray-600 p-2 flex flex-col gap-1">
									<img
										className="h-[60px] w-full cursor-pointer object-cover"
										src={photo ? api.defaults.baseURL + "/uploads/" + photo : avatar}
										alt={name ? name : "User"}
									/>
									<span className="w-full text-md text-center">{name}</span>
								</div>
							)
						}) : <span>Não há participante(s) nessa negociação</span>}
					</div>

				</div>

				{(deleteText != undefined && deleteAction != undefined) && <>
					<button onClick={()=> deleteAction(data.id)} className="w-fit ml-auto hover:underline text-red-600">{deleteText}</button>
				</>}

			</div>
		</SideMenu>
	);
};

export default PosVendasSidemenu;
