import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { useNavigate, useParams } from "react-router-dom";
import { Role, UserType } from "types/app";

interface NewUserType extends UserType {
    confirmPassword?: string;
}

const NewUser = () => {
    const { id } = useParams()

    const [data, setData] = useState<NewUserType>({
        id: 0,
        name: "",
        username: "",
    })

    const [roles, setRoles] = useState<Role[]>([])
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const user = useAppSelector((state) => state.user.user)

    const navigate = useNavigate()

    const handleChangeRole = (e: any) => {
        setData({...data, roleId: e.target.value})
    }

    const handleChangeAdmin = (e: any) => {
        setData({...data, admin: e.target.value == 1 ? true : false})
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const _password = data.password ? data.password.trim() != "" ? data.password : undefined : undefined;
        const _roleId = data.roleId != undefined ? parseInt(data.roleId.toString()) : undefined;

        if(_password){
            if(data.password !== data.confirmPassword){
                return toast("As senhas não conferem", {
                    type: "error",
                    theme: "light"
                })
            }
        }

        api.post('/auth/register', {
            name: data.name,
            username: data.username,
            admin: data.admin,
            password: _password,
            roleId: _roleId
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Usuário criado com sucesso!", {
                type: "success",
                theme: "light"
            })
            navigate('/admin/users')
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: "light"
            })
        })

    }

    useEffect(()=>{

        api.get('/role/all', {
            headers: {
                token: user?.token
            }
        }).then((res: {data: Role[]}) => {
            setRoles(res.data)
        }).catch((error: ErrorData)=> {
            toast(error.response.data.error, {
                type: "error",
                theme: "light"
            })
        })
    }, [])

    return(
        <>
            <form className="grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 mt-10 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Usuário</FormLabel>
                    <Input value={data.username} onChange={e=> setData({...data, username: e.target.value})} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Usuário de Login" />
                </FormControl>

                <div className="w-full flex gap-5">
                    <FormControl className="flex flex-col gap-1 w-50p">
                        <FormLabel className="font-bold text-sm pl-2 dark:text-white">Senha</FormLabel>
                        <Input value={data.password} onChange={e=> setData({...data, password: e.target.value})} type="password" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Senha do usuário" />
                    </FormControl>
                    <FormControl className="flex flex-col gap-1 w-50p">
                        <FormLabel className="font-bold text-sm pl-2 dark:text-white">Confirmar senha</FormLabel>
                        <Input value={data.confirmPassword} onChange={e=> setData({...data, confirmPassword: e.target.value})} type="password" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Senha do usuário" />
                    </FormControl>
                </div>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Nome</FormLabel>
                    <Input value={data.name} onChange={e=> setData({...data, name: e.target.value})} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Nome do usuário" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Cargo</FormLabel>
                    <Select value={data.roleId} icon={<></>} onChange={handleChangeRole} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
                        <option value={null}>Nenhum</option>
                        {roles.map(role=>(
                            <option key={role.id} value={role.id}>{role.name}</option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Administrador</FormLabel>
                    <Select value={data.admin ? 1 : 0} icon={<></>} onChange={handleChangeAdmin} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Selecione se é administrador ou não">
                        <option value={1}>SIM</option>
                        <option value={0}>NÃO</option>
                    </Select>
                </FormControl>


                <button type="button" onClick={handleSubmit} className="linear h-10 mt-5 w-full md:w-30p xl:w-20p ml-auto flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-darkorange-400 dark:text-white dark:hover:bg-darkorange-300 dark:active:bg-darkorange-200">
                    Criar usuário
                </button>
            </form>
        </>
    )
}

export default NewUser