import { FiSearch } from "react-icons/fi";

import { Link, useNavigate } from "react-router-dom";
import PDFTable from "./components/PDFTable";
import { clients } from "./data/clientsFakeData";
import { useEffect, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { PDFPage } from "types/app";
import { toast } from "react-toastify";

const Clients = () => {

  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [pdfTableData, setPDFTableData] = useState([])
  const [search, setSearch] = useState('')
  const user = useAppSelector((state) => state.user.user)
  const darkmode = useAppSelector((state) => state.layout.darkMode)
  const [page, setPage] = useState<number>(1)
  const [maxPages, setMaxPages] = useState<number>(2)
  const navigate = useNavigate()

  const [loadingPage, setLoadingPage] = useState(false)

  useEffect(()=>{
    api.get(`/orders/config/pdf/pages`, {
      headers: {
        token: user?.token
      },
      params: {
        page,
        perpage: rowsPerPage,
        search
      }
    }).then(res=>{
      setLoadingPage(false)
      setPDFTableData(res.data.pages)
      setMaxPages(res.data.maxPages)
    })
  }, [page])

  const handleSearch = (e: any) => {
    if(e){
      e.preventDefault()
    }
    setPage(1)
    setLoadingPage(true)

    api.get(`/orders/config/pdf/pages`, {
      headers: {
        token: user?.token
      },
      params: {
        page,
        perpage: rowsPerPage,
        search
      }
    }).then(res=>{
      setLoadingPage(false)
      setPDFTableData(res.data.pages)
      setMaxPages(res.data.maxPages)
    })
  }

  const handleChangePage = (newPage: number) => {
    if(loadingPage) return;
    setLoadingPage(true)
    setPage(newPage)
  }

  const handleNewPage = () => {
    if (loadingPage) return;

    setLoadingPage(true)
    api.post("/orders/config/pdf/new/page", {}, {
      headers: {
        token: user?.token
      }
    }).then((res: {data: PDFPage}) => {
      setLoadingPage(false)
      toast("Página criada com sucesso", {
          theme: darkmode ? "dark" : "light",
          type: "success"
      })
      navigate('/admin/pdf/edit/'+res.data.id)
    }).catch(()=> {
      setLoadingPage(false)
    })
  }

  return (
    <>
      <div className="flex items-center justify-center mt-10 h-full w-full py-2">
        <button onClick={handleNewPage} className="linear ml-auto mr-5 flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-sm font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
          Nova página
        </button>
      </div>
      <form className="flex items-center justify-center mt-2 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2 gap-2" onSubmit={handleSearch}>
        <div className="flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            value={search}
            onChange={e=> setSearch(e.target.value)}
            type="text"
            placeholder="Procurar..."
            className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
          />
        </div>
        <button onClick={handleSearch} disabled={loadingPage} className="linear flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Procurar
        </button>
      </form>

      <div className="mt-5">
        <PDFTable page={page} setPage={handleChangePage} title="Lista de Páginas" reloadTable={handleSearch} tableData={pdfTableData} maxPages={maxPages} loading={loadingPage} />
      </div>
    </>
  );
};

export default Clients;
