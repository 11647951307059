import { MdArrowDropUp } from "react-icons/md";
import Card from "components/card";
import { useEffect, useState } from "react";
import RankingTecnicosChart from "./chart";
import DropdownMenu from "./dropdown";
import dayjs from "dayjs";
import { TicketsType } from "types/app";

const RankingTecnicos = ({date, className, rankingData}: {date: any, className: any, rankingData: TicketsType["ranking"]}) => {
  const [searchDate, setSearchDate] = useState(dayjs().toDate())
  const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

  return (
    <Card extra={"pb-7 p-[20px] "+className}>
      <div className="flex flex-row justify-between">
        <div className="ml-1 pt-2">
          <h2 className="text-lg font-bold text-navy-700 dark:text-white">
            Performance Mensal por Técnico
          </h2>
          {/* <p className="ml-2 mt-2 text-sm font-medium leading-4 text-gray-600">
            Mês referência: {months[dayjs(date).month()]} de {dayjs(date).year()}
          </p> */}
        </div>
        {/* <div className="mt-2 flex items-start">
          <DropdownMenu searchDate={searchDate} setSearchDate={setSearchDate} />
        </div> */}
      </div>

      <div className="h-[300px] w-full pb-0 pt-10">
        <RankingTecnicosChart rankingData={rankingData} searchDate={date} />
      </div>
    </Card>
  );
};

export default RankingTecnicos;
