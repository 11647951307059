import React from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import {
	MdArrowCircleLeft,
	MdArrowLeft,
	MdArrowRight,
	MdCancel,
	MdCheckCircle,
	MdChevronLeft,
	MdChevronRight,
	MdDelete,
	MdEdit,
	MdOutlineError,
	MdPictureAsPdf,
	MdTimer,
} from "react-icons/md";

import {
	CellContext,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Tooltip } from "@chakra-ui/tooltip";
import { useAppSelector } from "redux/hooks";
import TooltipHorizon from "components/tooltip";
import { Cell, Row } from "react-table";
import { ClipLoader } from "react-spinners";

interface RowType {
	id: number;
	data: string;
	previsao_entrega?: string;
	data_entrega?: string;
	client?: {
		name: string;
	};
}

const columnHelper = createColumnHelper<RowType>();

// const columns = columnsDataCheck;
export default function PosVendaTable(props: {
	reloadTable: () => void;
	tableData: RowType[];
	title?: string;
	loading: boolean;
	handleEdit: (id: number | string) => void;
    disabledActions?: boolean;
}) {
	const { tableData } = props;
	const user = useAppSelector((state) => state.user.user);
	const darkmode = useAppSelector((state) => state.layout.darkMode);
	const [sorting, setSorting] = React.useState<SortingState>([]);
	const [defaultData, setDefaultData] = React.useState([]);

	const handleEdit = (data: Row<RowType>) => {
		if (!props.disabledActions) {
			props.handleEdit(data.original.id);
		}
		// navigate('/admin/contracts/edit/'+data.row.original.id)
	};

	const columns = [
		columnHelper.accessor("data", {
			id: "ownerName",
			header: () => (
				<p className="text-sm font-bold text-gray-900 dark:text-white">
					DATA DO PEDIDO
				</p>
			),
			cell: (info) => (
				<p className="text-xs text-navy-700 dark:text-white">
					{dayjs(info.getValue()).format("DD/MM/YYYY")}
				</p>
			),
		}),
		columnHelper.accessor("client.name", {
			id: "clientName",
			header: () => (
				<p className="text-sm font-bold text-gray-900 dark:text-white">
					CLIENTE
				</p>
			),
			cell: (info) => (
				<p className="text-xs text-navy-700 dark:text-white">
					{info.getValue() ? info.getValue() : "Não definido"}
				</p>
			),
		}),
		columnHelper.accessor("previsao_entrega", {
			id: "active",
			header: () => (
				<p className="text-sm font-bold text-gray-900 dark:text-white">
					PREVISÃO DE ENTREGA
				</p>
			),
			cell: (info) => (
				<div className="flex items-center">
					<p className="text-xs text-navy-700 dark:text-white">
						{dayjs(info.getValue()).isValid()
							? dayjs(info.getValue()).format("DD/MM/YYYY")
							: "Não identificado"}
					</p>
				</div>
			),
		}),
		columnHelper.accessor("data_entrega", {
			id: "data_entrega",
			header: () => (
				<p className="text-sm font-bold text-gray-900 dark:text-white">
					DATA DE ENTREGA
				</p>
			),
			cell: (info) => (
				<div className="flex items-center">
					<p className="text-xs text-navy-700 dark:text-white">
						{dayjs(info.getValue()).isValid()
							? dayjs(info.getValue()).format("DD/MM/YYYY")
							: "Não identificado"}
					</p>
				</div>
			),
		}),
	]; // eslint-disable-next-line
	const [data, setData] = React.useState(() => [...defaultData]);
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true,
	});

	React.useEffect(() => {
		setData(() => [...tableData]);
	}, [tableData]);
	return (
		<>
			<Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
				<div className="mt-8 overflow-x-scroll">
					<table className="w-full">
						<thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<tr
									key={headerGroup.id}
									className="!border-px !border-gray-400"
								>
									{headerGroup.headers.map((header) => {
										return (
											<th
												key={header.id}
												colSpan={header.colSpan}
												className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
											>
												<div className="items-center justify-between text-xs text-gray-200">
													{flexRender(
														header.column.columnDef
															.header,
														header.getContext()
													)}
													{{
														asc: "",
														desc: "",
													}[
														header.column.getIsSorted() as string
													] ?? null}
												</div>
											</th>
										);
									})}
								</tr>
							))}
						</thead>
						<tbody>
							{(!props.loading && tableData.length > 0) && table.getRowModel().rows.map((row: any) => {
								return (
									<tr key={row.id} className="hover:bg-gray-100" onClick={()=> handleEdit(row)}>
										{row.getVisibleCells().map((cell: any) => {
											return (
												<td
													key={cell.id}
													className={`min-w-[150px] border-white/0 py-3 pr-2 cursor-pointer group relative `}
												>
													<button className="px-2 py-1 absolute right-5 top-2 border bg-white rounded-md invisible group-hover:visible text-xs">Editar</button>
													{flexRender(
														cell.column.columnDef
															.cell,
														cell.getContext()
													)}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
					
					{props.loading ? (
						<div className="flex items-center justify-center h-[120px]">
							<ClipLoader size={25} />
						</div>
					) : tableData.length <= 0 &&
						<div className="flex items-center justify-center h-[120px]">
							Sem resultados...
						</div>
					}
				</div>
			</Card>
		</>
	);
}
