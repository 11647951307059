import { FiSearch } from "react-icons/fi";

import { Link, useNavigate } from "react-router-dom";
import ClientsTable from "./components/ClientsTable";
import { clients } from "./data/clientsFakeData";
import { useEffect, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { Input, Select } from "@chakra-ui/react";
import apiCeps from "services/apiCeps";

const Clients = () => {

  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [clientsData, setClientsData] = useState([])
  const [search, setSearch] = useState('')
  const user = useAppSelector((state) => state.user.user)
  const [page, setPage] = useState<number>(1)
  const [maxPages, setMaxPages] = useState<number>(2)
  const [stateSearch, setStateSearch] = useState(-1)
  const [stateSearchName, setStateSearchName] = useState('')
  const [citySearch, setCitySearch] = useState('')
  const [letterSearch, setLetterSearch] = useState('#')

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const navigate = useNavigate()

  const [loadingPage, setLoadingPage] = useState(false)

  useEffect(()=>{
    api.get(`/clients/all`, {
      headers: {
        token: user?.token
      },
      params: {
        page,
        perpage: rowsPerPage,
        search,
        letter: letterSearch != '#' ? letterSearch : undefined,
      }
    }).then(res=>{
      setLoadingPage(false)
      setClientsData(res.data.clients)
      setMaxPages(res.data.maxPages)
    })
  }, [page, letterSearch])

  useEffect(()=>{
    apiCeps.get('/estados').then(res=>{
      setStates(res.data)
    })
  }, [])

  useEffect(()=>{
    setCities([])
    setStateSearchName('')
    if(stateSearch != -1){
      setStateSearchName(states.find(state=>state.id == stateSearch).nome)
      apiCeps.get('/cidades/'+stateSearch).then(res=>{
        setCities(res.data)
      })
    }
  }, [stateSearch])

  const handleSearch = (e: any) => {
    e.preventDefault()
    setPage(1)
    setLoadingPage(true)

    api.get(`/clients/all`, {
      headers: {
        token: user?.token
      },
      params: {
        page,
        perpage: rowsPerPage,
        search,
        letter: letterSearch != '#' ? letterSearch : undefined,
        state: stateSearchName,
        city: citySearch
      }
    }).then(res=>{
      setLoadingPage(false)
      setClientsData(res.data.clients)
      setMaxPages(res.data.maxPages)
    })
  }

  const handleChangePage = (newPage: number) => {
    if(loadingPage) return;
    setLoadingPage(true)
    setPage(newPage)
  }

  const handleNewClient = () => {
    navigate('/admin/clients/new')
  }

  return (
    <>
      <div className="flex items-center justify-center mt-10 h-full w-full py-2">
	  <button onClick={handleNewClient} className="linear ml-auto mr-5 flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-sm font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
          Novo cliente
        </button>
      </div>
      {/* <form className="flex items-center justify-center mt-2 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2" onSubmit={handleSearch}>
        <div className="flex h-12 w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            value={search}
            onChange={e=> setSearch(e.target.value)}
            type="text"
            placeholder="Procurar..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
          />
        </div>
        <button onClick={handleSearch} disabled={loadingPage} className="linear ml-5 flex items-center justify-center rounded-xl bg-brand-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Procurar
        </button>
      </form> */}
      <form className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 grid-cols gap-5 items-center justify-center mt-2 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2" onSubmit={handleSearch}>
        {/* <div className="col-span-1 md:col-span-2 xl:col-span-3 2xl:col-span-5 flex h-12 w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white"> */}
        <div className="col-span-1 md:col-span-2 xl:col-span-3 flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            value={search}
            onChange={e=> setSearch(e.target.value)}
            type="text"
            placeholder="Procurar..."
            className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
          />
        </div>

        <Select value={stateSearch} onChange={e=> setStateSearch(parseInt(e.target.value))} icon={<></>} className="no-arrows text-sm col-span-1 flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white px-5">
          {states.length > 0 ? <option value={-1}>Estado</option> : <option value={-1}>Carregando...</option>}
          {states.map(state=>(
            <option value={state.id}>{state.nome}</option>
          ))}
        </Select>

        <Select value={citySearch} onChange={e=> setCitySearch(e.target.value)} icon={<></>} gridColumn={"span 2"}  className="no-arrows text-sm col-span-2 flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white px-5">
          {stateSearch != -1 ? cities.length > 0 ? <option value={''}>Cidade</option> : <option value={''}>Carregando...</option> : <option value={''}>Selecione um estado primeiro</option>}
          {cities.map(city=>(
            <option value={city.nome}>{city.nome}</option>
          ))}
        </Select>
        {/* <p className="hidden xl:block 2xl:hidden"></p> */}
        {/* <p className="hidden xl:block 2xl:hidden"></p>
        <p className="hidden xl:block 2xl:hidden"></p> */}
        {/* <p className="block xl:hidden"></p> */}
        <button onClick={handleSearch} disabled={loadingPage} className="linear flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Procurar
        </button>
      </form>

      <div className="mt-5">
        <ClientsTable page={page} letterSearch={letterSearch} setLetterSearch={setLetterSearch} setPage={handleChangePage} title="Lista de Organizações" tableData={clientsData} maxPages={maxPages} loading={loadingPage} />
      </div>
    </>
  );
};

export default Clients;
