import React from "react";
import Card from "components/card";
import { MdEdit} from "react-icons/md";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@chakra-ui/tooltip";
import { useAppSelector } from "redux/hooks";
import { IoMdEye } from "react-icons/io";
import TooltipHorizon from "components/tooltip";
import { UserType } from "types/app";

interface RowType extends UserType {

}

const columnHelper = createColumnHelper<RowType>();

{/* <option value={-1}>Selecione</option>
<option value={0}>CLT</option>
<option value={1}>PJ</option>
<option value={2}>Estágio</option> */}

const getModalidade = (modalidade: any) => {
	switch(modalidade){
		case 0:
			return "CLT"
		case 1:
			return "PJ"
		case 2:
			return "Estágio"
		default:
			return "Não identificado"
	}
}

// const columns = columnsDataCheck;
export default function ContractsTable(props: { reloadTable: ()=> void, tableData: RowType[], title?: string, loading: boolean }) {
  const { tableData } = props;
  const user = useAppSelector(state=> state.user.user)
  const darkmode = useAppSelector(state=> state.layout.darkMode)
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [defaultData, setDefaultData] = React.useState([])
  const navigate = useNavigate()

  const handleEdit = (data: CellContext<RowType, any>) => {
    navigate('/admin/funcionarios/edit/'+data.row.original.id)
  }

  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      size: 60,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">ID</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("funcional", {
      id: "funcional",
      size: 400,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">FUNCIONAL</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
		{(info.getValue() && info.getValue() != '') ? info.getValue() : "Não identificado"}
        </p>
      ),
    }),
    columnHelper.accessor("name", {
      id: "name",
      size: 400,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">NOME</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {(info.getValue() && info.getValue() != '') ? info.getValue() : "Não identificado"}
        </p>
      ),
    }),
    columnHelper.accessor("funcao", {
      id: "funcao",
      size: 400,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">FUNÇÃO</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
		{(info.getValue() && info.getValue() != '') ? info.getValue() : "Não identificado"}
        </p>
      ),
    }),
    columnHelper.accessor("modalidadeContratacao", {
      id: "modalidadeContratacao",
      size: 400,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">MODALIDADE CONTRATAÇÃO</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
			{getModalidade(info.getValue())}
        </p>
      ),
    }),
    columnHelper.accessor(null, {
      id: "operacao.name",
      size: 400,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">OPERAÇÃO</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.row.original.operacao?.name || "Não identificado"}
        </p>
      ),
    }),
    columnHelper.accessor(null, {
      id: "acoes",
      size: 90,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          AÇÕES
        </p>
      ),
      cell: (info) => (
        <div className="flex gap-2 items-center">
			<TooltipHorizon
				content={<span>Visualizar</span>}
				trigger={
					<button onClick={()=> handleEdit(info)} className="linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<IoMdEye />
					</button>
				}
				extra=""
				placement="top"
			/>
        </div>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(()=>{
    setData(()=> [...tableData])
  }, [tableData])
  return (
	<>
		<Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
		<div className="relative flex items-center justify-between pt-4">
			<div className="text-xl font-bold text-gray-900 dark:text-white">
			{props.title}
			</div>
		</div>

		<div className="mt-8 overflow-x-scroll">
			<table className="w-full">
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
				<tr key={headerGroup.id} className="!border-px !border-gray-400">
					{headerGroup.headers.map((header) => {
					return (
						<th
						key={header.id}
						colSpan={header.colSpan}
                        style={{width: header.getSize()}}
						className="border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
						>
						<div className="items-center justify-between text-xs text-gray-200">
							{flexRender(
                header.column.columnDef.header,
                header.getContext()
							)}
							{{
							asc: "",
							desc: "",
							}[header.column.getIsSorted() as string] ?? null}
						</div>
						</th>
					);
					})}
				</tr>
				))}
			</thead>
			<tbody>
				{table
				.getRowModel()
				.rows
				.map((row) => {
					return (
					<tr key={row.id} className="hover:bg-gray-100">
						{row.getVisibleCells().map((cell) => {
						return (
							<td
								key={cell.id}
								style={{width: cell.column.getSize() == 100 ? '100%': cell.column.getSize()}}
								className={`min-w-[${cell.column.getSize()}] border-white/0 py-3 pr-2  `}
							>
							{flexRender(
							cell.column.columnDef.cell,
							cell.getContext()
							)}
              				</td>
						);
						})}
					</tr>
					);
				})}
			</tbody>
			</table>
			{props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Nenhum orçamento encontrado...</h1> }
		</div>

		</Card>
	</>
  );
}
