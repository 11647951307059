import Card from "components/card";
import { useEffect, useMemo, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toastError } from "components/toasts/toastError";
import { ErrorData } from "types/fetch";
import dayjs from "dayjs";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { formatToCurrency } from "helpers/conversions";
import { ChakraProvider, Skeleton } from "@chakra-ui/react";

interface ChartType {
	name: string;
	data: number[];
}

const RankingReceitaFuncionarios = ({ from, to, selectedFuncs, selectedOperacoes, pageLoading }: {
	from?: Date,
	to?: Date,
	searchDate?: Date,
	selectedFuncs: any,
	selectedOperacoes: any,
	pageLoading?: boolean
}) => {
	const user = useAppSelector((selector) => selector.user.user);
	const darkmode = useAppSelector((selector) => selector.layout.darkMode);
	const [loading, setLoading] = useState(true)
	const [funcs, setFuncs] = useState<string[]>([])
	const chartOptions = useMemo<any>(()=> ({
		chart: {
			type: "bar",
			height: 350,
			stacked: true,
		},
		dataLabels: {
			enabled: false,
			style: {
				fontSize: "10px",
				fontWeight: 800,
			},
			formatter: (val: number) => {
				const formatted = formatToCurrency(val);
				return formatted;
			},
		},
		plotOptions: {
			bar: {
				barHeight: 20,
				horizontal: {
					enabled: true,
				},
				dataLabels: {
					total: {
						enabled: true,
						offsetX: 20,
						style: {
							fontSize: "10px",
							fontWeight: 400,
						},
						formatter: (val: number) => {
							const formatted = formatToCurrency(val);
							return formatted;
						},
					},
				},
			},
		},
		stroke: {
			width: 1,
			colors: ["#fff"],
		},
		xaxis: {
			categories: funcs,
			labels: {
				formatter: (val: number) => {
					const formatted = formatToCurrency(val);
					return formatted;
				},
			},
		},
		yaxis: {
			title: {
				text: undefined,
			},
		},
		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (val: number) {
					const formatted = formatToCurrency(val);
					return formatted;
				},
			},
		},
		fill: {
			opacity: 1,
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
			offsetX: 40,
		},
	}), [funcs]);

	const [salesData, setSalesData] = useState([])

	const chartData = useMemo<ChartType[]>(() => [
		{ name: "Vendido", data: salesData || []},
	], [salesData]);

	useEffect(()=> {
		getData()
	}, [from, to, selectedFuncs, selectedOperacoes])

	const getData = () => {
		setLoading(true)
		api.get("/contratos/dashboard/rankingrentabilidadecolaborador", {
			params: {
				start: from,
				end: to,
				funcs: JSON.stringify(selectedFuncs),
				operacoes: JSON.stringify(selectedOperacoes)
			},
			headers: {
				token: user?.token
			}
		}).then((res: {data: {colaboradores: string[], rentabilidade: number[]}}) => {
			if(res){
				if(res.data){
					setFuncs(res.data.colaboradores)
					setSalesData(res.data.rentabilidade)
				}
			}
			setLoading(false)
		}).catch(err=>{
			toastError(err, false)
		})
	}

	// "colaboradores": [
	// 	"Lucas Pereira",
	// 	"User3",
	// 	"Teste de Vendedor"
	// ],
	// "rentabilidade": [
	// 	{
	// 		"client": 6240,
	// 		"enterprise": 7800,
	// 		"servicos": 117
	// 	},
	// 	{
	// 		"client": 0,
	// 		"enterprise": 0,
	// 		"servicos": 0
	// 	},
	// 	{
	// 		"client": 0,
	// 		"enterprise": 0,
	// 		"servicos": 0
	// 	}
	// ]

	return (
		<Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center !shadow-shadow-500">
			<ChakraProvider>
				<Skeleton isLoaded={!pageLoading} startColor="white" endColor="gray.300" className="py-6 px-2 !rounded-3xl">
					<div className="mb-auto flex items-center justify-between px-6">
						<h4 className="text-md font-bold text-navy-700 dark:text-white">
							Ranking de Fechamento por Colaborador
						</h4>
					</div>

					<div className="md:mt-16 lg:mt-0">
						<div className="h-[250px] w-full xl:h-[350px]">
							<Chart
								options={chartOptions}
								series={chartData as any}
								type="bar"
								width="100%"
								height="100%"
							/>
						</div>
					</div>
				</Skeleton>
			</ChakraProvider>
		</Card>
	);
};

export default RankingReceitaFuncionarios;
