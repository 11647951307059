import Card from "components/card";
import { useEffect, useMemo, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toastError } from "components/toasts/toastError";
import { ErrorData } from "types/fetch";
import dayjs from "dayjs";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { formatToCurrency } from "helpers/conversions";
import colorArray from "helpers/colorsArray";
import { ChakraProvider, Skeleton } from "@chakra-ui/react";

interface ChartType{
	name: string,
	data: number[],
}

const MetaNoMes = ({ from, to, selectedFuncs, selectedOperacoes, pageLoading }: {
	from?: Date,
	to?: Date,
	searchDate?: Date,
	selectedFuncs: any,
	selectedOperacoes: any,
	pageLoading?: boolean
}) => {
	const user = useAppSelector(selector => selector.user.user)
	const darkmode = useAppSelector(selector => selector.layout.darkMode)
	const [loading, setLoading] = useState(true)
	const [labels, setLabels] = useState(['Enterprise', 'Client', 'Serviços'],)
	const chartOptions = useMemo<ApexOptions>(()=> ({
		chart: {
			width: 380,
			type: 'pie',
			toolbar: {
				show: true
			}
		},
		labels: labels,
		responsive: [{
			breakpoint: 480,
			options: {
			chart: {
				width: 200
			},
			legend: {
				position: 'bottom'
			}
			}
		}],
		colors: colorArray,
		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (val: number) {
					const formatted = formatToCurrency(val);
					return formatted;
				},
			},
		},

	}), [labels])

	const [chartData, setChartData] = useState<number[]>([2500,10000,12000])

	useEffect(()=> {
		getData()
	}, [from, to, selectedFuncs, selectedOperacoes])

	const getData = () => {
		setLoading(true)
		api.get("/contratos/dashboard/metapessoalmes", {
			params: {
				date: from,
				funcs: JSON.stringify(selectedFuncs),
				operacoes: JSON.stringify(selectedOperacoes)
			},
			headers: {
				token: user?.token
			}
		}).then(res => {
			if(res){
				if(res.data){
					setChartData(res.data.data)
					setLabels(res.data.labels)
				}
			}
			setLoading(false)
		}).catch(err=>{
			toastError(err, false)
		})
	}

	return (
		<Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center !shadow-shadow-500">
			<ChakraProvider>
				<Skeleton isLoaded={!pageLoading} startColor="white" endColor="gray.300" className="py-6 px-2 !rounded-3xl">
					<div className="mb-auto flex items-center justify-between px-6">
						<h4 className="text-md font-bold text-navy-700 dark:text-white">
							Meta Pessoal no Mês
						</h4>
					</div>

					<div className="md:mt-16 lg:mt-0">
						<div className="h-[250px] w-full xl:h-[350px]">
							<Chart
								options={chartOptions}
								series={chartData as any}
								type="pie"
								width="100%"
								height="100%"
							/>
						</div>
					</div>
				</Skeleton>
			</ChakraProvider>
		</Card>
	);
};

export default MetaNoMes;
