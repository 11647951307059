import { FiSearch } from "react-icons/fi";

import { Link, useNavigate } from "react-router-dom";
import PendingDiscountsTable from "../components/PendingDiscountsTable";
import { useEffect, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import dayjs from "dayjs";
import { Input } from '@chakra-ui/react';
import { OrderType } from "types/app";
import socket from "socketio/socket";

interface OrderTableType {
  id: number;
  clientName: string;
  date: string;
  price: number;
  funcName: string;
  awaitingApproval: boolean;
  discount: number,
  pretendDiscount: number,
  originalValue: number
}

interface EditOrderType extends OrderType{
  originalValue?: number
}

const PendingDiscounts = () => {

  const [tableData, setTableData] = useState([])
  const user = useAppSelector((state) => state.user.user)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()


  const getOrders = (noTime?: boolean) => {
    setLoading(true)
    api.get(`/orders/waitingapproval`, {
      headers: {
        token: user?.token
      },
    }).then((res: {data: EditOrderType[] })=>{
      setLoading(false)
      var _tableData: OrderTableType[] = []
      res.data.map(order=>{
        _tableData.push({
          id: order.id,
          date: order.createdAt,
          funcName: order.funcName,
          clientName: order.clientName,
          price: order.price,
          discount: order.discount,
          pretendDiscount: order.pretendDiscount,
          awaitingApproval: order.awaitingApproval,
          originalValue: order.originalValue
        })
      })

      setTableData(_tableData)
    }).catch(()=>{
      setLoading(false)
    })
  }

  useEffect(()=>{ 
    getOrders()

    socket.on("update-pending-orders", getOrders)

    return () => {
      socket.off("update-pending-orders", getOrders)
    }
    // setTableData(orcamentos)
  }, [])

  return (
    <>
      <div className="mt-5">
        <PendingDiscountsTable title="Lista de Orçamentos para Aprovação" tableData={tableData} refresh={getOrders} loading={loading}/>
      </div>
    </>
  );
};

export default PendingDiscounts;
