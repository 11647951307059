import React, { useEffect, useRef, useState } from 'react';
import './defaultStyles.scss';


export interface DataType{
    value: number;
    text: string;
}

interface MultiSelectType{
    classNames: {
        container: string;
        select: string;
        box: string;
        items: string;
        selectedItems: string;
        selectAll: string;
    },
    selectAllText: string;
    showSelected: boolean;
    placeholder: string;
    data: DataType[];
    selectedElements: DataType[];
    filter: string;
    setFilter: React.Dispatch<string>
    setSelectedElements: React.Dispatch<React.SetStateAction<DataType[]>>;
}

export const useSelectItems = (data: DataType[]) : [DataType[], React.Dispatch<React.SetStateAction<DataType[]>>] => {
    const [_variable, _hook] = useState<DataType[]>(data);

    return [_variable, _hook]
}

const MultiSelect = ({filter, setFilter , selectedElements, setSelectedElements, data, classNames, placeholder, showSelected, selectAllText}: MultiSelectType) => {
    const [selectOpen, setSelectOpen] = useState(false)
    const wrapperRef = useRef<HTMLDivElement>(null)

    const selectItem = (selectedItem: DataType) => {
        if(selectedElements.find(item => item.value == selectedItem.value)){
            setSelectedElements(selectedElements.filter(item => item.value != selectedItem.value))
        }else {
            setSelectedElements([...selectedElements, selectedItem])
        }
    }

    const removeItem = (selectedItem: DataType) => {
        setSelectedElements(selectedElements.filter(item => item.value != selectedItem.value))
    }

    const selectAll = () => {
        var toSelect: DataType[] = [];
        data.map(item=>{
            if(!selectedElements.find(_item => _item.value == item.value)){
                toSelect.push(item);
            }
        })

        if(toSelect.length > 0){
            let newSelectElements = selectedElements;
            toSelect.map(item=> {
                newSelectElements.push(item)
            })

            setSelectedElements([...newSelectElements])
        }else{
            setSelectedElements([])
        }
    }

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
          if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            if(selectOpen){
                setSelectOpen(false)
            }
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () =>  document.removeEventListener("mousedown", handleClickOutside);
    });

    return(
        <div ref={wrapperRef} className={`default-multi-container ${classNames.container}`}>
            {showSelected &&
                <div className={`default-multi-selected-items`}>
                    {selectedElements.map((selected, index)=>(
                        <span className={`default-multi-selected-item ${classNames.selectedItems}`} onClick={() => removeItem(selected)} key={`SELECTED-ITEMS-${index}`}>{selected.text}</span>
                    ))}
                </div>
            }
            <div className={`default-multi-input`}>
                <input onClick={()=>{
                    if(!selectOpen){
                        setSelectOpen(true)
                    }
                }} value={filter} onChange={e=> setFilter(e.target.value)} placeholder={placeholder} className={`default-multi-select ${classNames.select}`}>
                </input>

                <button type='button' onClick={selectAll} className={`default-multi-select-all-text ${classNames.selectAll}`}>{selectAllText ? selectAllText : "Todos"}</button>
            </div>

            { selectOpen && <div className={`default-multi-box ${classNames.box}`}>
                {data?.length > 0 && data.filter(item=> item.text.toLowerCase().includes(filter.toLowerCase())).map((item)=>(
                    <div key={`EL-${item.value}`} onClick={() => selectItem(item)} className={`default-multi-items ${classNames.items}`}>
                        <input type='checkbox' checked={selectedElements.find(_item => _item.value == item.value) ? true : false} onClick={() => selectItem(item)} />
                        {item.text}
                    </div>
                ))}
            </div>}
        </div>
    )
}

export default MultiSelect