import React, { ReactNode } from "react";
import Dropdown from "components/dropdown";
import { AiOutlineUser } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { AiOutlineShop } from "react-icons/ai";
import { TiLightbulb } from "react-icons/ti";
import { IconType } from "react-icons";

function ExpensesDropdown(props: { transparent?: boolean, className?: string,  menus: {
  text?: string,
  icon?: ReactNode,
  action?: () => void,
  children?: JSX.Element,
  extraButtonClasses?: string,
}[] }) {
  const { transparent } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <Dropdown
      extraClassesWrapper={`${props.className ? props.className : ''}`}
      button={
        <button
          onClick={() => setOpen(!open)}
          className={`flex items-center text-xl hover:cursor-pointer text-white p-1 bg-orange-500 rounded hover:bg-orange-600`}
        >
          <BsThreeDots className="h-6 w-6" />
        </button>
      }
      animation={"origin-top-right transition-all duration-300 ease-in-out border rounded border-gray-200 shadow-xl"}
      classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
      children={
        <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none flex flex-col gap-2">
          {props.menus.map(menu=>(
            <>
              {menu.children ? menu.children :
                <p className={`hover:text-gray-900 dark:hover:text-white flex cursor-pointer items-center gap-2 text-gray-700 hover:font-medium ${menu.extraButtonClasses}`}onClick={menu.action}>
                  <span>
                    {menu.icon}
                  </span>
                  {menu.text}
                </p>
              }
            </>
          ))}
        </div>
      }
    />
  );
}

export default ExpensesDropdown;
