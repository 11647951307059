import { FiSearch } from "react-icons/fi";

import { Link, useNavigate } from "react-router-dom";
import OrcamentosTable from "./components/OrcamentosTable";
import { useEffect, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import dayjs from "dayjs";
import { Input, Select } from '@chakra-ui/react';
import { OrderType } from "types/app";

interface OrderTableType {
  id: number;
  clientName: string;
  date: string;
  price: number;
  funcName: string;
  awaitingApproval: boolean;
  status: number;
}

const Orcamentos = () => {

  const [tableData, setTableData] = useState([])
  const [search, setSearch] = useState('')
  const [funcSearch, setFuncSearch] = useState('')
  const user = useAppSelector((state) => state.user.user)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [maxPages, setMaxPages] = useState<number>(2)
  const navigate = useNavigate()

  const [statusSearch, setStatusSearch] = useState(-1)
  const [dateSearch, setDateSearch] = useState("")

  const [loadingPage, setLoadingPage] = useState(false)

  const getOrders = (noTime?: boolean) => {
    setLoadingPage(true)
    api.get(`/orders/all`, {
      headers: {
        token: user?.token
      },
      params: {
        page,
        perpage: rowsPerPage,
        search,
        func_name: funcSearch,
        search_date: dateSearch ? dateSearch != "" ? dateSearch : undefined : undefined,
        status: statusSearch == -1 ? undefined : statusSearch
      }
    }).then((res: {data: {orders: OrderType[], maxPages: number} })=>{
      setLoadingPage(false)
      var _tableData: OrderTableType[] = []
      res.data.orders.map(order=>{
        _tableData.push({
          id: order.id,
          date: order.createdAt,
          funcName: order.funcName,
          clientName: order.clientName,
          price: order.price,
          awaitingApproval: order.awaitingApproval,
          status: order.status
        })
      })

      setTableData(_tableData)
      setMaxPages(res.data.maxPages)
    }).catch(()=>{
      setLoadingPage(false)
    })
  }

  useEffect(()=>{
    getOrders()
    // setTableData(orcamentos)
  }, [page])

  const handleSearch = (e: any) => {
    e.preventDefault()

    getOrders()
  }

  const handleChangePage = (newPage: number) => {
    if(loadingPage) return;
    setLoadingPage(true)
    setPage(newPage)
  }

  const handleSetDate = (event: any) => {
    setDateSearch((dayjs(event.target.value).format("YYYY-MM-DD")))
  }

  const handleNovoOrcamento = () => {
    navigate("/admin/novo-orcamento")
  }

  return (
    <>
      <div className="flex items-center justify-center mt-10 h-full w-full py-2">
        <button onClick={handleNovoOrcamento} className="linear ml-auto mr-5 flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-sm font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
          Novo orçamento
        </button>
      </div>
      <form className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 grid-cols gap-5 items-center justify-center mt-2 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2" onSubmit={handleSearch}>
        {/* <div className="col-span-1 md:col-span-2 xl:col-span-3 2xl:col-span-5 flex h-12 w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white"> */}
        <div className="col-span-1 md:col-span-2 xl:col-span-3 flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            value={search}
            onChange={e=> setSearch(e.target.value)}
            type="text"
            placeholder="Procurar..."
            className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
          />
        </div>

        <Select value={statusSearch} onChange={e=> setStatusSearch(parseInt(e.target.value))} icon={<></>} className="no-arrows text-sm col-span-1 flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white px-5">
          <option value={-1}>Status</option>
          <option value={0}>Em andamento</option>
          <option value={1}>Ganho</option>
          <option value={2}>Perdido</option>
          <option value={3}>Cancelado</option>
        </Select>

        { user?.admin ?
          <div className="col-span-1 flex h-12 w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
            <input
              value={funcSearch}
              onChange={e=> setFuncSearch(e.target.value)}
              type="text"
              placeholder="Criado por"
              className="block h-full px-5 w-full rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
            />
          </div>
        : <div></div>}
        <Input
          className="block h-12 w-full p-5 rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white"
          type="date"
          value={dateSearch}
          onChange={e=> handleSetDate(e)}
        >
        </Input>
        <p className="hidden xl:block 2xl:hidden"></p>
        <p className="hidden xl:block 2xl:hidden"></p>
        <p className="hidden xl:block 2xl:hidden"></p>
        {/* <p className="block xl:hidden"></p> */}
        <button onClick={handleSearch} disabled={loadingPage} className="linear flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Procurar
        </button>
      </form>

      <div className="mt-5">
        <OrcamentosTable loading={loadingPage} page={page} setPage={handleChangePage} title="Lista de Orçamentos" tableData={tableData} maxPages={maxPages} />
      </div>
    </>
  );
};

export default Orcamentos;
