import Card from "components/card";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, FormControl } from "@chakra-ui/react";
import CustomInputV3 from "components/custom-input-3";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import { PiFloppyDiskBold } from "react-icons/pi";

interface ContractType{
    id: string,
    maxH?: number,
    minH?: number,
	data: {
		contract: {
			comission: number,
			setComission: (e: number) => void,
		},

		sales: {
			notArrived: number,
			setNotArrived: (e: number) => void,

			arrived: number,
			setArrived: (e: number) => void,

			special: number,
			setSpecial: (e: number) => void,
		},

		presell: {
			notArrived: number,
			setNotArrived: (e: number) => void,

			arrived: number,
			setArrived: (e: number) => void,

			special?: number,
			setSpecial?: (e: number) => void,
		},

		architect: {
			notArrived: number,
			setNotArrived: (e: number) => void,

			arrived: number,
			setArrived: (e: number) => void,

			special?: number,
			setSpecial?: (e: number) => void,
		},

		participant: {
			comission: number,
			setComission: (e: number) => void,
		}
	}
}

const ComissionsCard = ({id, maxH, minH, data}: ContractType) => {
    const user = useAppSelector(selector => selector.user.user)
    const darkmode = useAppSelector(selector => selector.layout.darkMode)
	 //  /user/comissions/:id


	const handleSave = () => {
		api.put('/user/comissions/' + id, {
			architectComission: data.architect.notArrived,
			architectComissionGoalArrived: data.architect.arrived,
			salesComission: data.sales.notArrived,
			salesComissionGoalArrived: data.sales.arrived,
			salesComissionSpecial: data.sales.special,
			presellComission: data.presell.notArrived,
			presellComissionGoalArrived: data.presell.arrived,
			participantComission: data.participant.comission,
			contractComission: data.contract.comission,
		}, {
			headers: {
				token: user?.token
			}
		}).then(() => {
			toast("Dados salvos com sucesso!", {
				type: "success",
				theme: darkmode ? "dark" : "light"
			})

		}).catch((error: ErrorData) => {
			toast(error.response.data.error, {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		})
	}
    return(
        <>
			<Accordion allowToggle>
				<AccordionItem>
					<AccordionButton className="flex items-center justify-between">
						<h2 className="text-lg font-bold text-navy-700 dark:text-white">
							Configuração de Comissões
						</h2>
        				<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<Card extra="relative" noShadow>
							<button onClick={handleSave} type="button" className="absolute top-5 right-0 ml-auto flex items-center justify-center cursor-pointer text-lg w-[40px] min-w-[40px] min-h-[40px] h-[40px] rounded-full p-2 bg-orange-500 text-white dark:bg-orange-500">
								<PiFloppyDiskBold />
							</button>
							<div className="mb-auto text-start flex flex-col relative">
								<p className="mt-2 text-sm font-medium leading-4 text-gray-600">
									Essas configurações são únicas de cada funcionário
								</p>
							</div>

							<div className="ml-5 flex flex-col gap-5">
								<div className="flex flex-col gap-2 mt-5">
									<h3 className="text-md text-navy-700 font-bold">Comissões de Contrato</h3>
									<div className="flex gap-5 ml-10">
										<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
											<div className="flex flex-col gap-2 w-full">
												<p className="font-bold text-sm pl-2 dark:text-white">
													Comissão:
												</p>

												<FormControl className="w-full flex items-center gap-2 w-full">
													<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] !w-full p-2" value={data.contract.comission} setValue={e=> data.contract.setComission(e)} max={100} suffix="%"/>
												</FormControl>
											</div>
										</FormControl>
									</div>
								</div>

								<div className="flex flex-col gap-2 mt-5">
									<h3 className="text-md text-navy-700 font-bold">Comissões como Vendedor</h3>
									<div className="flex gap-5 ml-10">
										<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
											<div className="flex flex-col gap-2 w-full">
												<p className="font-bold text-sm pl-2 dark:text-white">
													Comissão sem bater meta:
												</p>

												<FormControl className="w-full flex items-center gap-2 w-full">
													<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] !w-full p-2" value={data.sales.notArrived} setValue={e=> data.sales.setNotArrived(e)} max={100} suffix="%"/>
												</FormControl>
											</div>
										</FormControl>
										<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
											<div className="flex flex-col gap-2 w-full">
												<p className="font-bold text-sm pl-2 dark:text-white">
													Comissão com meta batida:
												</p>
												<FormControl className="w-full flex items-center gap-2 w-full">
													<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 !w-full p-2" value={data.sales.arrived} setValue={e=> data.sales.setArrived(e)} max={100} suffix="%"/>
												</FormControl>
											</div>
										</FormControl>
										<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
											<div className="flex flex-col gap-2 w-full">
												<p className="font-bold text-sm pl-2 dark:text-white">
													Comissão com meta especial:
												</p>
												<FormControl className="w-full flex items-center gap-2 w-full">
													<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 !w-full p-2" value={data.sales.special} setValue={e=> data.sales.setSpecial(e)} max={100} suffix="%"/>
												</FormControl>
											</div>
										</FormControl>
									</div>
								</div>

								{/* <div className="flex flex-col gap-2 mt-5">
									<h3 className="text-md text-navy-700 font-bold">Comissões como Pré Vendas</h3>
									<div className="flex gap-5 ml-10">
										<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
											<div className="flex flex-col gap-2 w-full">
												<p className="font-bold text-sm pl-2 dark:text-white">
													Comissão sem bater meta:
												</p>

												<FormControl className="w-full flex items-center gap-2 w-full">
													<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] !w-full p-2" value={data.presell.notArrived} setValue={e=> data.presell.setNotArrived(e)} max={100} suffix="%"/>
												</FormControl>
											</div>
										</FormControl>
										<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
											<div className="flex flex-col gap-2 w-full">
												<p className="font-bold text-sm pl-2 dark:text-white">
													Comissão com meta batida:
												</p>
												<FormControl className="w-full flex items-center gap-2 w-full">
													<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 !w-full p-2" value={data.presell.arrived} setValue={e=> data.presell.setArrived(e)} max={100} suffix="%"/>
												</FormControl>
											</div>
										</FormControl>
									</div>
								</div>

								<div className="flex flex-col gap-2 mt-5">
									<h3 className="text-md text-navy-700 font-bold">Comissões como Arquiteto</h3>
									<div className="flex gap-5 ml-10">
										<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
											<div className="flex flex-col gap-2 w-full">
												<p className="font-bold text-sm pl-2 dark:text-white">
													Comissão sem bater meta:
												</p>

												<FormControl className="w-full flex items-center gap-2 w-full">
													<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] !w-full p-2" value={data.architect.notArrived} setValue={e=> data.architect.setNotArrived(e)} max={100} suffix="%"/>
												</FormControl>
											</div>
										</FormControl>
										<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
											<div className="flex flex-col gap-2 w-full">
												<p className="font-bold text-sm pl-2 dark:text-white">
													Comissão com meta batida:
												</p>
												<FormControl className="w-full flex items-center gap-2 w-full">
													<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 !w-full p-2" value={data.architect.arrived} setValue={e=> data.architect.setArrived(e)} max={100} suffix="%"/>
												</FormControl>
											</div>
										</FormControl>
									</div>
								</div>

								<div className="flex flex-col gap-2 mt-5">
									<h3 className="text-md text-navy-700 font-bold">Comissões como Participante</h3>
									<div className="flex gap-5 ml-10">
										<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
											<div className="flex flex-col gap-2 w-full">
												<p className="font-bold text-sm pl-2 dark:text-white">
													Comissão:
												</p>

												<FormControl className="w-full flex items-center gap-2 w-full">
													<CustomInputV3 suffixClass="dark:text-white absolute right-0" containerClass="w-full relative" inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] !w-full p-2" value={data.participant.comission} setValue={e=> data.participant.setComission(e)} max={100} suffix="%"/>
												</FormControl>
											</div>
										</FormControl>
									</div>
								</div> */}
							</div>
						</Card>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
        </>
    )

}

export default ComissionsCard