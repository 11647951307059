// Custom components
import React from "react";
import { useAppSelector } from "redux/hooks";

function SelectField(props: {
  id: string;
  label: string;
  extra: string;
  placeholder?: string;
  variant?: string;
  state?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  inputExtra?: string;
  labelExtra?: string;
  value?: any,
  options: {value: any, content: any}[]
}) {
  const { label, id, extra, placeholder, variant, state, disabled, onChange, value, labelExtra, inputExtra } =
    props;

	const darkmode = useAppSelector((state)=> state.layout.darkMode)

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white font-bold text-sm pl-2 ${labelExtra}`}
      >
        {label}
      </label>
      <select
        disabled={disabled}
        id={id}
        value={value}
		style={{
			colorScheme: darkmode ? "dark" : "light"
		}}
        onChange={onChange}
        placeholder={placeholder}
        className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none text-gray-900 ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:text-white"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-300 dark:!border-white/10 dark:text-white"
        } ${inputExtra}`}
      >
        {props.options.map(opt => (
            <option className="text-gray-900 dark:text-gray-800" key={opt.value} value={opt.value}>{opt.content}</option>
        ))}
      </select>
    </div>
  );
}

export default SelectField;
