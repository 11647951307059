// Custom components
import React from "react";
import { useAppSelector } from "redux/hooks";

function InputField(props: {
  id: string;
  label: string;
  extra: string;
  placeholder: string;
  variant: string;
  state?: string;
  disabled?: boolean;
  labelExtra?: string;
  inputExtra?: string;
  pattern?: string;
  type?: "button" | "checkbox" | "color" | "date" | "datetime-local" | "email" | "file" | "hidden" | "image" | "month" | "number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url" | "week";
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: any
}) {
  const { label, id, extra, type, placeholder, variant, state, disabled, onChange, value, pattern, labelExtra, inputExtra } =
    props;
    const darkmode = useAppSelector((state) => state.layout.darkMode)

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm dark:text-white font-bold text-sm pl-2 ${labelExtra}`}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        type={type}
        id={id}
        pattern={props.pattern}
        value={value}
        onChange={onChange}
		style={{
			colorScheme: "light"
		}}
        placeholder={placeholder}
        className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none text-gray-900 ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:text-white"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-300 dark:!border-white/10 dark:text-white"
        } ${inputExtra}`}
      />
    </div>
  );
}

export default InputField;
