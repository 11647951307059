import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Button, ChakraProvider, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useDisclosure } from "@chakra-ui/react";
import Card from "components/card";
import CustomInput from "components/custom-input";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import BancosFuncTable from "./BancosFuncTable";
import { ClientType, BancoType, SelectType, BrasilAPIBanco } from "types/app";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toastError } from "components/toasts/toastError";
import BancosFuncDropdown from "./BancosFuncDropdown";
import { BsPerson } from "react-icons/bs";
import Dropdown from "components/dropdown";
import Calendar from "react-calendar";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import dayjs, { Dayjs } from "dayjs";
import { IoAdd } from "react-icons/io5";
import { PiFloppyDisk, PiFloppyDiskBold, PiFloppyDiskFill } from "react-icons/pi";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import ClientsAutocomplete from "views/orcamentos/components/AutoComplete";
import Switch from "components/switch";
import { months } from "helpers/months";
import configs from "variables/configs";
import { ViewFuncionarioType } from "../..";
import BankSelector from "../BankSelector";

interface CardType{
    maxH?: number,
    minH?: number,
    searchDate?: Date,
    id?: any,
}

const BancosFuncCard = ({maxH, minH, searchDate, id}: CardType) => {
    const user = useAppSelector(selector => selector.user.user)
    const darkmode = useAppSelector(selector => selector.layout.darkMode)

    const [data, setData] = useState<BancoType[]>([])
	const [diasUsados, setDiasUsados] = useState(0)

    const { isOpen: isOpenNew, onOpen: onOpenNew, onClose: onCloseNew } = useDisclosure()
    const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure()

	const [banks, setBanks] = useState<BrasilAPIBanco[]>([])

    const [newData, setNewData] = useState<BancoType>({
    })

    const [editData, setEditData] = useState<BancoType>({

    })

    const loadData = () => {
		api.get('/users/banks/showAll/'+id, {
			headers: {
				token: user?.token
			}
		}).then((res: {data: BancoType[]}) => {
			if(res)
				if(res.data){
					setData(res.data)
				}
		}).catch((error: ErrorData)=> {
            toastError(error, darkmode)
        })

		api.get("https://brasilapi.com.br/api/banks/v1").then((res: {data: BrasilAPIBanco[]}) => {
			if(res){
				if(res.data){
					setBanks(res.data.filter(i=>{
						return (i.code !== undefined && i.code !== null) && (i.name !== undefined && i.name !== null)
					}).sort((a,b)=> {
						if(a.code > b.code){
							return 1
						}else if(a.code < b.code){
							return -1
						}else{
							return 0
						}
					}))
				}
			}
		}).catch(err=> {
			toast("Falha ao comunicar com API de bancos", {
				type: "error"
			})
		})
    }

    useEffect(()=> {
        loadData()
    }, [searchDate])

    const resetValues = () => {
        setNewData({

        })

        setEditData({

        })
    }

    const handleCancelCreate = () => {
        onCloseNew()
        resetValues()
    }

    const handleCancelEdit = () => {
        onCloseEdit()
        resetValues()
    }

    const handleCreate = () => {
        api.post('/users/banks/create/'+id, newData, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Banco criado com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            handleCancelCreate()
            loadData()
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const handleEdit = () => {
        api.put('/users/banks/update/'+editData.id, editData, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Gasto editado com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            handleCancelEdit()
            loadData()
        }).catch((error: ErrorData) => {
            if(error.response){
                toast(error.response.data.error, {
                    type: "error",
                    theme: darkmode ? "dark" : "light"
                })
            }else{
                toast("Erro indefinido", {
                    type: "error",
                    theme: darkmode ? "dark" : "light"
                })
            }
        })
    }

    const handleEditData = (key: keyof typeof editData, value: any) => {
        // setEditData({
        //     ...editData,
        //     [key]: value
        // })
		var _newEditData: any = editData
		_newEditData[key] = value

		setEditData({..._newEditData})
    }

    const handleEditCreateData = (key: keyof typeof newData, value: any) => {
		var _newData: any = newData
		_newData[key] = value

		setNewData({..._newData})

        // setNewData({
        //     ...newData,
        //     [key]: value
        // })
    }

    // useEffect(()=> {
    //     console.log(editData)
    // }, [editData])

    return(
        <>

            <ChakraProvider>
                <Modal isOpen={isOpenNew} onClose={handleCancelCreate} isCentered>
                    <ModalOverlay />
                    <ModalContent className="dark:bg-navy-800">
                        <ModalHeader className="dark:text-white">Adicionar novo banco</ModalHeader>
                        <ModalCloseButton className="dark:text-white" />
                        <ModalBody className="dark:text-white flex flex-col">
                            {/* <div className="mt-3 flex items-center gap-3">
                                <Switch checked={true} onChange={e=> {

                                }} id="switch1" />
                                <label
                                    htmlFor="checkbox1"
                                    className="text-base font-medium text-navy-700 dark:text-white"
                                >
                                    Gasto recorrente?
                                </label>
                            </div>
                            <div className="mt-3 flex items-center gap-3">
                                <Switch checked={newData.toSalary ? true : false} onChange={e=> {
                                    const _data = {...newData, toSalary: !newData.toSalary}

                                    setNewData(_data)
                                }} id="switch1" />
                                <label
                                    htmlFor="checkbox1"
                                    className="text-base font-medium text-navy-700 dark:text-white"
                                >
                                    Adicionar ao Salário?
                                </label>
                            </div> */}
							<FormControl className="w-full col-span-1 flex flex-col items-start gap-1 mt-2">
								<label className="!font-bold !text-sm !pl-2 dark:text-white">Banco</label>
								<BankSelector data={banks} className="w-full" val={newData.codigo} setVal={e=> handleEditCreateData("codigo", e)} setBank={e=> {
									handleEditCreateData("banco", e)
								}}/>
							</FormControl>

							<div className="grid grid-cols-4 items-center gap-2">
								<FormControl className="w-full col-span-3 flex flex-col items-start gap-1 mt-2">
									<label className="!font-bold !text-sm !pl-2 dark:text-white">Conta</label>
									<Input type="number" resize={"block"} value={newData.conta} onChange={e=>{
										handleEditCreateData("conta", e.target.valueAsNumber)
									}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
								<FormControl className="w-full col-span-1 flex flex-col items-start gap-1 mt-2">
									<label className="!font-bold !text-sm !pl-2 dark:text-white">Dígito</label>
									<Input type="number" resize={"block"} value={newData.digitoConta} onChange={e=>{
										handleEditCreateData("digitoConta", e.target.valueAsNumber)
									}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
							</div>

							<div className="grid grid-cols-4 items-center gap-2">
								<FormControl className="w-full col-span-3 flex flex-col items-start gap-1 mt-2">
									<label className="!font-bold !text-sm !pl-2 dark:text-white">Agência</label>
									<Input type="number" resize={"block"} value={newData.agencia} onChange={e=>{
										handleEditCreateData("agencia", e.target.valueAsNumber)
									}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
								<FormControl className="w-full col-span-1 flex flex-col items-start gap-1 mt-2">
									<label className="!font-bold !text-sm !pl-2 dark:text-white">Dígito</label>
									<Input type="number" resize={"block"} value={newData.digitoAg} onChange={e=>{
										handleEditCreateData("digitoAg", e.target.valueAsNumber)
									}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
							</div>

                            <FormControl className="w-full flex flex-col items-start gap-1 mt-2">
                                <label className="!font-bold !text-sm !pl-2 dark:text-white">Anotação</label>
                                <Textarea h={100} minH={100} maxH={100} resize={"block"} value={newData.anotacao} onChange={e=>{
                                    handleEditCreateData("anotacao", e.target.value)
                                }} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='red' mr={3} onClick={handleCancelCreate}>
                                Cancelar
                            </Button>
                            <Button colorScheme='blue' mr={3} onClick={handleCreate}>
                                Criar
                            </Button>
                            {/* <Button onClick={handleGeneratePDF} colorScheme="green" variant='solid'>Gerar</Button> */}
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Modal isOpen={isOpenEdit} onClose={handleCancelEdit} isCentered>
                    <ModalOverlay />
                    <ModalContent className="dark:bg-navy-800">
                        <ModalHeader className="dark:text-white">Editar banco</ModalHeader>
                        <ModalCloseButton className="dark:text-white" />
                        <ModalBody className="dark:text-white flex flex-col">
							<FormControl className="w-full col-span-1 flex flex-col items-start gap-1 mt-2">
								<label className="!font-bold !text-sm !pl-2 dark:text-white">Banco</label>
								<BankSelector data={banks} className="w-full" val={editData.codigo} setVal={e=> handleEditData("codigo", e)} setBank={e=> {
									handleEditData("banco", e)
								}}/>
							</FormControl>

							<div className="grid grid-cols-4 items-center gap-2">
								<FormControl className="w-full col-span-3 flex flex-col items-start gap-1 mt-2">
									<label className="!font-bold !text-sm !pl-2 dark:text-white">Conta</label>
									<Input type="number" resize={"block"} value={editData.conta} onChange={e=>{
										handleEditData("conta", e.target.valueAsNumber)
									}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
								<FormControl className="w-full col-span-1 flex flex-col items-start gap-1 mt-2">
									<label className="!font-bold !text-sm !pl-2 dark:text-white">Dígito</label>
									<Input type="number" resize={"block"} value={editData.digitoConta} onChange={e=>{
										handleEditData("digitoConta", e.target.valueAsNumber)
									}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
							</div>

							<div className="grid grid-cols-4 items-center gap-2">
								<FormControl className="w-full col-span-3 flex flex-col items-start gap-1 mt-2">
									<label className="!font-bold !text-sm !pl-2 dark:text-white">Agência</label>
									<Input type="number" resize={"block"} value={editData.agencia} onChange={e=>{
										handleEditData("agencia", e.target.valueAsNumber)
									}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
								<FormControl className="w-full col-span-1 flex flex-col items-start gap-1 mt-2">
									<label className="!font-bold !text-sm !pl-2 dark:text-white">Dígito</label>
									<Input type="number" resize={"block"} value={editData.digitoAg} onChange={e=>{
										handleEditData("digitoAg", e.target.valueAsNumber)
									}} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
							</div>

                            <FormControl className="w-full flex flex-col items-start gap-1 mt-2">
                                <label className="!font-bold !text-sm !pl-2 dark:text-white">Anotação</label>
                                <Textarea h={100} minH={100} maxH={100} resize={"block"} value={editData.anotacao} onChange={e=>{
                                    handleEditData("anotacao", e.target.value)
                                }} className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='red' mr={3} onClick={handleCancelEdit}>
                                Cancelar
                            </Button>
                            <Button colorScheme='blue' mr={3} onClick={handleEdit}>
                                Salvar
                            </Button>
                            {/* <Button onClick={handleGeneratePDF} colorScheme="green" variant='solid'>Gerar</Button> */}
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ChakraProvider>

			<Accordion allowToggle>
				<AccordionItem>
					<AccordionButton className="flex items-center justify-between">
						<h2 className="text-lg font-bold text-navy-700 dark:text-white">
							Bancos
						</h2>
        				<AccordionIcon />
					</AccordionButton>
					<AccordionPanel>
						<Card extra="relative" noShadow>
							<div className="mb-auto text-start flex flex-col relative">
								<BancosFuncDropdown className="!absolute top-0 right-0" menus={[
									{
										action: ()=> {
											onOpenNew()
										},
										icon: <IoAdd />,
										text: "Adicionar Banco",
										extraButtonClasses:"ml-auto text-white rounded !font-bold !hover:font-bold hover:text-white bg-blue-500 p-2"
									},
								]}/>
							</div>
							<div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap mt-10">

								<div className="h-full w-full mt-2">
									<BancosFuncTable setEditExpense={setEditData} onOpenExpense={onOpenEdit} minH={minH} maxH={maxH} tableData={data} reloadTable={loadData} title="" loading={false}/>
								</div>
							</div>
						</Card>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
        </>
    )

}

export default BancosFuncCard