import React, { useState } from "react";
import Dropdown from "components/dropdown";
import { BsThreeDots } from "react-icons/bs";
import Calendar from "react-calendar";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import SelectField from "components/fields/SelectField";
import { useAppSelector } from "redux/hooks";

function SelectMasterDate(props: {
  transparent?: boolean;
  searchDate: any;
  setSearchDate: any;
  extra?: string;
  dateMode: any,
  setDateMode: any,
}) {
  const { transparent } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <div className="flex gap-2">
      <select value={props.dateMode} onChange={e=> props.setDateMode(e.target.value)} className={`flex items-center text-sm hover:cursor-pointer ${
              transparent
                ? "bg-none text-white hover:bg-none active:bg-none"
                : "bg-lightPrimary p-2 text-gray-900 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-bold transition duration-200`}>
        <option value={"mensal"}>Mensal</option>
        <option value={"anual"}>Anual</option>
      </select>
      <Dropdown
        button={
          <button
            onClick={() => setOpen(!open)}
            className={`flex items-center text-xl hover:cursor-pointer ${
              transparent
                ? "bg-none text-white hover:bg-none active:bg-none"
                : "bg-lightPrimary p-2 text-orange-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <BsThreeDots className="h-6 w-6" />
          </button>
        }
        animation={"origin-top-right transition-all duration-300 ease-in-out"}
        classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max ${props.extra}`}
        children={
          <div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <Calendar
              value={props.searchDate}
              onClickMonth={e=> props.setSearchDate(e)}
              onClickYear={e=> props.setSearchDate(e)}
              prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
              nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
              view={props.dateMode == "mensal" ? "year" : "decade"}
            />
          </div>
        }
      />
    </div>
  );
}

export default SelectMasterDate;
