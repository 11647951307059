//@ts-nocheck
import {
    StyleSheet,
    Font
} from "@react-pdf/renderer";

import Arial from '../../../../../assets/fonts/arial.ttf';
import Calibri from '../../../../../assets/fonts/calibri.ttf';

Font.register({
    family: "Arial",
    src: Arial
});
Font.register({
    family: "Calibri",
    src: Calibri
});

const globalStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        display: 'flex',
        paddingTop: 20,
        paddingLeft: 10,
        paddingRight: 10,
        fontFamily: 'Calibri',
        lineHeight: 1.3,
        background: "#333"

    },
    bgImage: {
        position: "absolute",
        objectFit: "stretch",
        width: "100%",
        top: 0,
        bottom: 0,
        opacity: 1,

    },

    logo: {
        width: 150,
        position: "absolute",
        top: 20,
        right: 40,
        paddingLeft: 5
    },

    dellPartner: {
        width: 150,
        position: "absolute",
        top: 40,
        left: 40
    },

    pageContent: {
        paddingLeft: 80,
        paddingRight: 80,
        paddingTop: 120,
        paddingBottom: 20
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 0,
        right: 0,
    }
})

export default globalStyles