import React from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes";
import api from "services/api";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { toast } from "react-toastify";
import { logoffUser, setPermissions } from "redux/reducers/userSlice";
import socket from "socketio/socket";
import { refreshNotifications } from "redux/reducers/notificationsSlice";
import Navbarv2 from "components/navbarv2";

export default function Admin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const location = useLocation();
  const darkmode = useAppSelector((state) => state.layout.darkMode)
  const user = useAppSelector((state) => state.user.user)
  const [loaded, setLoaded] = React.useState(false)

  const dispatch = useAppDispatch()

  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const navigate = useNavigate();


  React.useEffect(()=> {
	if(!user){
		navigate("/admin")
		return;
	}
  }, [])

  const getNotifications = () => {
    api.get("/notifications/all", {
      headers: {
        token: user?.token
      }
    }).then((res) => {
      dispatch(refreshNotifications(res.data))
    })
  }

  React.useEffect(()=>{
    const checkLogin = () => {
      api.get('/auth/check', {
        headers: {
          token: user?.token
        }
      }).then(()=>{
        // if(!socket.connected){
          socket.connect()
          socket.emit("userLogin", {token: user?.token})
        // }
      }).catch(()=>{
        toast('Faça login novamente.', {
          theme: darkmode ? 'dark' : 'light'
        });
        if(user){
          dispatch(logoffUser())
        }
        navigate('/auth/sign-in')
		return;
      })
    }

    const getPermissions = () => {
      api.get('/users/self/permissions', {
        headers: {
          token: user?.token
        }
      }).then(res=>{
        if(res){
          if(res.data){
            dispatch(setPermissions(res.data))
          }
        }
      }).catch((error)=>{
        toast(error.response.data.error, {
          type: "error",
          theme: darkmode ? "dark" : "light"
        })
      })
    }

    checkLogin();
    getPermissions();
    const timeInSeconds = 15 * 60;
    const checkInterval = setInterval(()=>{
      checkLogin();
    }, timeInSeconds * 1000)

    const getPermissionsInterval = setInterval(()=>{
      getPermissions()
    }, timeInSeconds * 1000)

    socket.on("updatePermissions", ()=> {
      getPermissions()
    })

    socket.on("webnotification", (data) => {
      new Notification(data.title, {
        body: data.content,
      })
    })

    socket.on("update-notifications", (data)=> {
      getNotifications()
    })

    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }

    return () => {
      clearInterval(checkInterval)
      clearInterval(getPermissionsInterval)
      socket.disconnect()
      socket.off("updatePermissions")
      socket.off("webnotification")
    }
  }, [])

  React.useEffect(() => {
    window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
    setLoaded(true)
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
    window.scrollTo(0, 0)

  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      }
      if (prop.layout == "dropdown"){
        var routes: any[] = []
        prop.dropdownLinks.map(link=>{
          routes.push(<Route path={`/${link.path}`} element={link.component} key={key} />)
        })

        return routes
      }
      else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";

  if(loaded && user)
    return (
      <div className="flex flex-col h-full w-full">
        {/* <Sidebar open={open} onClose={() => setOpen(false)} /> */}
		<Navbarv2 />
        {/* Navbar & Main Content */}
        {/* <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900"> */}
        <div className="h-full w-full bg-white mt-[84px]">
          {/* Main Content */}
          {/* <main
            className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
          > */}
          <main
            className={`mx-[12px] h-full flex-none transition-all md:pr-2`}
          >
            {/* Routes */}
            <div className="h-full">

              {/* <Navbar
                onOpenSidenav={() => setOpen(true)}
                brandText={currentRoute}
                secondary={getActiveNavbar(routes)}
                {...rest}
              /> */}

              {/* <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2"> */}
              <div className="pt-5s mx-auto mb-auto h-full min-h-[96vh] p-2 md:pr-2">
                <Routes>
                  {getRoutes(routes)}

                  <Route
                    path="/"
                    element={<Navigate to="/admin/bi/vendas" replace />}
                  />
                </Routes>
              </div>
              <div className="p-3">
                <Footer />
              </div>
            </div>
          </main>
        </div>
      </div>
    );

  else(
    <></>
  )
}
