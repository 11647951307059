import { toastError } from "components/toasts/toastError"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FiSearch } from "react-icons/fi"
import { Link } from "react-router-dom"
import { useAppSelector } from "redux/hooks"
import api from "services/api"
import { EmpresasType } from "types/app"
import EmpresasTable from "./components/EmpresasTable"
import { Button, ChakraProvider, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import ReactInputMask from "react-input-mask"
import { cnpjMask } from "masks/cnpjMask"
import { toast } from "react-toastify"
import { ErrorData } from "types/fetch"
import consultarCNPJ from 'consultar-cnpj'
import apiCeps from "services/apiCeps"

const tiposEmpresas = [
	{label: "Fornecedores", key: "fornecedor"},
	{label: "Transportadoras", key: "transportadora"},
	{label: "Fabricantes", key: "fabricante"},
	{label: "Distribuidores", key: "distribuidor"},
]

const EmpresasCadastradas = () => {
	const user = useAppSelector(selector => selector.user.user)
	const darkmode = useAppSelector(selector => selector.layout.darkMode)
	const [selectedTipo, setSelectedTipo] = useState(tiposEmpresas[0].key)
	const [search, setSearch] = useState('')
	const [loading, setLoading] = useState(true)
	const [empresas, setEmpresas] = useState<EmpresasType[]>([])

    const { isOpen: isOpenCreateEmpresa, onOpen: onOpenCreateEmpresa, onClose: onCloseCreateEmpresa } = useDisclosure()
    const { isOpen: isOpenEditEmpresa, onOpen: onOpenEditEmpresa, onClose: onCloseEditEmpresa } = useDisclosure()

	const [newEmpresaData, setNewEmpresaData] = useState<EmpresasType>({
		address: "",
		cnpj: "",
		email: "",
		cep: "",
		city: "",
		complement: "",
		district: "",
		ie: "",
		name: "",
		number: undefined,
		state: "",
	})

	const [editEmpresaData, setEditEmpresaData] = useState<EmpresasType>({
		id: undefined,
		address: "",
		cnpj: "",
		email: "",
		cep: "",
		city: "",
		complement: "",
		district: "",
		ie: "",
		name: "",
		number: undefined,
		state: "",
	})

    const getCNPJ = async (cnpj: any, value: typeof newEmpresaData, setter: typeof setNewEmpresaData) => {
		const _val = value
        try{
            const empresa = await consultarCNPJ(cnpj)
            if(empresa){
                _val.name = empresa.razao_social
                _val.cep = empresa.estabelecimento.cep
                _val.number = empresa.estabelecimento.numero
                _val.complement = empresa.estabelecimento.complemento


                if(empresa.estabelecimento.inscricoes_estaduais.length && empresa.estabelecimento.inscricoes_estaduais){
                    let activeIE = false
                    let selectedIE = null
                    empresa.estabelecimento.inscricoes_estaduais.map(_ie=>{
                        if(_ie.ativo == true && !activeIE){
                            activeIE = true
                            selectedIE = _ie.inscricao_estadual
                        }
                    })

                    if(activeIE){
                        _val.ie = empresa.estabelecimento.inscricoes_estaduais[0].inscricao_estadual
                    }else{
                        _val.ie = "Isento"
                    }
                }else{
                    _val.ie = "Isento"
                }

                if(empresa.estabelecimento.cep){
                    _val.address = "Carregando..."
                    _val.state = "Carregando..."
                    _val.city = "Carregando..."
                    _val.district = 'Carregando...'

                    await apiCeps.get("/cep", {
                        params: {
                            cep: empresa.estabelecimento.cep
                        }
                    }).then(res=>{
                        if(res.data){
                            if(res.data.rua && res.data.rua != ""){
                                _val.address = res.data.rua
                            }else{
                                _val.address = empresa.estabelecimento.logradouro
                            }

                            if(res.data.estados.nome && res.data.estados.nome != ""){
                                _val.state = res.data.estados.nome
                            }else{

                            }

                            if(res.data.cidades.nome && res.data.cidades.nome != ""){
								_val.city = res.data.cidades.nome
                            }else{

                            }

                            if(res.data.bairro && res.data.bairro != ""){
								_val.district = res.data.bairro
                            }else{
                                _val.district = empresa.estabelecimento.bairro
                            }
                        }else{
                            _val.address = "Não encontrado"
                            _val.state = "Não encontrado"
                            _val.city = "Não encontrado"
                        }
                    })
                }
            }

			setter({
				..._val
			})
        }catch(error: any){
			const {status, detalhes, titulo} = error
            toast(detalhes, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        }
    }

	useEffect(()=> {
		if(newEmpresaData.cnpj){
			if(newEmpresaData.cnpj.length >= 18){
				// console.log(cnpj.length)
				getCNPJ(newEmpresaData.cnpj, newEmpresaData, setNewEmpresaData)
			}
		}
		if(editEmpresaData.cnpj){
			if(editEmpresaData.cnpj.length >= 18){
				// console.log(cnpj.length)
				getCNPJ(editEmpresaData.cnpj, editEmpresaData, setEditEmpresaData)
			}
		}
	}, [newEmpresaData.cnpj, editEmpresaData.cnpj])

	useEffect(()=> {
		loadEmpresas()
	}, [search, selectedTipo])

	const resetValues = () => {
		setNewEmpresaData({
			address: "",
			cnpj: "",
			email: "",
			cep: "",
			city: "",
			complement: "",
			district: "",
			ie: "",
			name: "",
			number: undefined,
			state: "",
			tipo: "financeiro"
		})

		setEditEmpresaData({
			id: undefined,
			address: "",
			cnpj: "",
			email: "",
			cep: "",
			city: "",
			complement: "",
			district: "",
			ie: "",
			name: "",
			number: undefined,
			state: "",
			tipo: "financeiro"
		})
	}

	const loadEmpresas = async () => {
		setLoading(true)
		try{
			const response = await api.get('/empresas/all', {
				headers:{
					token: user?.token
				},
				params: {
					search,
					tipo: selectedTipo
				}
			})
			setLoading(false)
			if(response){
				if(response.data){
					return setEmpresas(response.data as EmpresasType[])
				}
			}
			setEmpresas([] as EmpresasType[])
		}catch(err: any){
			toastError(err, false)
			setLoading(false)

			setEmpresas([] as EmpresasType[])
		}
	}

	const handleAddEmpresa = () => {
		onOpenCreateEmpresa()
	}

    const handleCancelEditEmpresa = () => {
        onCloseEditEmpresa()
        resetValues()
    }

    const handleCancelCreateEmpresa = () => {
        onCloseCreateEmpresa()
        resetValues()
    }

    const handleEditNewEmpresa = (key: keyof typeof newEmpresaData, value: any) => {
        setNewEmpresaData({
            ...newEmpresaData,
            [key]: value
        })
    }

    const handleEditEmpresaData = (key: keyof typeof editEmpresaData, value: any) => {
        setEditEmpresaData({
            ...editEmpresaData,
            [key]: value
        })
    }

	const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const data = new FormData(e.target as HTMLFormElement);
		const _search = data.get('search')

		setSearch(_search.toString())
	}

	const handleCreateEmpresa = () => {
		api.post('/empresas/create', {...newEmpresaData,
			tipo: selectedTipo
		}, {
            headers: {
                token: user?.token
            }
		}).then(()=> {
            toast("Empresa criada com sucesso!", {
                type: "success",
            })
            handleCancelCreateEmpresa()
            loadEmpresas()
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
            })
        })
	}

	const handleEditEmpresa = () => {
		const selectedId = editEmpresaData.id

		if(!selectedId){
			return toast("Falha ao editar empresa", {
				type: "error"
			})
		}

		if(!selectedId){
			toast("Nenhuma empresa selecionada", {
				type: "error",
				autoClose: 500
			})
			return
		}

        api.put('/empresas/update/'+selectedId, {...editEmpresaData,
			tipo: selectedTipo
		}, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Empresa editada com sucesso!", {
                type: "success",
            })
            handleCancelEditEmpresa()
			loadEmpresas()
        }).catch((error: ErrorData) => {
            if(error.response){
                toast(error.response.data.error, {
                    type: "error",
                })
            }else{
                toast("Erro indefinido", {
                    type: "error",
                })
            }
        })
	}

    const cepBuilder = (val: any) => {
        return '99999-999'
    }

	return(
		<>

            <ChakraProvider>
                <Modal isOpen={isOpenCreateEmpresa} onClose={handleCancelCreateEmpresa} isCentered>
                    <ModalOverlay />
                    <ModalContent className="dark:bg-navy-800">
                        <ModalHeader className="dark:text-white">Cadastro de {tiposEmpresas.find(t=> t.key == selectedTipo)?.label || "Empresa"}</ModalHeader>
                        <ModalCloseButton className="dark:text-white" />
                        <ModalBody className="dark:text-white flex flex-col gap-2">

							<div className="flex items-center justify-between gap-2">
								<FormControl className="flex flex-col w-full">
										<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">CNPJ</FormLabel>
										<Input value={newEmpresaData.cnpj ? cnpjMask(newEmpresaData.cnpj) : ""} onChange={e=> handleEditNewEmpresa("cnpj", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
								<FormControl className="flex flex-col w-full">
										<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">IE</FormLabel>
										<Input value={newEmpresaData.ie} onChange={e=> handleEditNewEmpresa("ie", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>

							</div>

							<FormControl className="flex flex-col w-full">
									<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">RAZÃO SOCIAL</FormLabel>
									<Input value={newEmpresaData.name} onChange={e=> handleEditNewEmpresa("name", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
							</FormControl>

							<div className="flex items-center justify-between gap-2">
								<FormControl className="flex flex-col w-full">
									<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">CEP</FormLabel>
									<Input as={ReactInputMask} mask={cepBuilder(newEmpresaData.cep)} value={newEmpresaData.cep} onChange={e=> {handleEditNewEmpresa("cep", e.target.value);}} maskChar={null} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
								<FormControl className="flex flex-col w-full">
									<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">RUA</FormLabel>
									<Input value={newEmpresaData.address} onChange={e=> handleEditNewEmpresa("address", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
							</div>

							<div className="flex items-center justify-between gap-2">
								<FormControl className="flex flex-col !w-[100px]">
									<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">NÚMERO</FormLabel>
									<Input value={newEmpresaData.number} onChange={e=> handleEditNewEmpresa("number", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
								<FormControl className="flex flex-col w-full">
									<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">COMPLEMENTO</FormLabel>
									<Input value={newEmpresaData.complement} onChange={e=> handleEditNewEmpresa("complement", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
							</div>
							<FormControl className="flex flex-col w-full 2xl:col-span-2">
								<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">BAIRRO</FormLabel>
								<Input value={newEmpresaData.district} onChange={e=> handleEditNewEmpresa("district", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
							</FormControl>
							<FormControl className="flex flex-col w-full 2xl:col-span-2">
								<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">CIDADE</FormLabel>
								<Input value={newEmpresaData.city} onChange={e=> handleEditNewEmpresa("city", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
							</FormControl>
							<FormControl className="flex flex-col w-full">
								<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">ESTADO</FormLabel>
								<Input value={newEmpresaData.state} onChange={e=> handleEditNewEmpresa("state", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
							</FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='red' mr={3} onClick={handleCancelCreateEmpresa}>
                                Cancelar
                            </Button>
                            <Button colorScheme='blue' mr={3} onClick={handleCreateEmpresa}>
                                Salvar
                            </Button>
                            {/* <Button onClick={handleGeneratePDF} colorScheme="green" variant='solid'>Gerar</Button> */}
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Modal isOpen={isOpenEditEmpresa} onClose={handleCancelEditEmpresa} isCentered>
                    <ModalOverlay />
                    <ModalContent className="dark:bg-navy-800">
                        <ModalHeader className="dark:text-white">Edição de {tiposEmpresas.find(t=> t.key == selectedTipo)?.label || "Empresa"}</ModalHeader>
                        <ModalCloseButton className="dark:text-white" />
                        <ModalBody className="dark:text-white flex flex-col gap-2">

							<div className="flex items-center justify-between gap-2">
								<FormControl className="flex flex-col w-full">
										<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">CNPJ</FormLabel>
										<Input value={editEmpresaData.cnpj ? cnpjMask(editEmpresaData.cnpj) : ""} onChange={e=> handleEditEmpresaData("cnpj", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
								<FormControl className="flex flex-col w-full">
										<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">IE</FormLabel>
										<Input value={editEmpresaData.ie} onChange={e=> handleEditEmpresaData("ie", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>

							</div>

							<FormControl className="flex flex-col w-full">
									<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">RAZÃO SOCIAL</FormLabel>
									<Input value={editEmpresaData.name} onChange={e=> handleEditEmpresaData("name", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" />
							</FormControl>

							<div className="flex items-center justify-between gap-2">
								<FormControl className="flex flex-col w-full">
									<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">CEP</FormLabel>
									<Input as={ReactInputMask} mask={cepBuilder(editEmpresaData.cep)} value={editEmpresaData.cep} onChange={e=> {handleEditEmpresaData("cep", e.target.value);}} maskChar={null} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
								<FormControl className="flex flex-col w-full">
									<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">RUA</FormLabel>
									<Input value={editEmpresaData.address} onChange={e=> handleEditEmpresaData("address", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
							</div>

							<div className="flex items-center justify-between gap-2">
								<FormControl className="flex flex-col !w-[100px]">
									<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">NÚMERO</FormLabel>
									<Input value={editEmpresaData.number} onChange={e=> handleEditEmpresaData("number", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
								<FormControl className="flex flex-col w-full">
									<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">COMPLEMENTO</FormLabel>
									<Input value={editEmpresaData.complement} onChange={e=> handleEditEmpresaData("complement", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
								</FormControl>
							</div>
							<FormControl className="flex flex-col w-full 2xl:col-span-2">
								<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">BAIRRO</FormLabel>
								<Input value={editEmpresaData.district} onChange={e=> handleEditEmpresaData("district", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
							</FormControl>
							<FormControl className="flex flex-col w-full 2xl:col-span-2">
								<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">CIDADE</FormLabel>
								<Input value={editEmpresaData.city} onChange={e=> handleEditEmpresaData("city", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
							</FormControl>
							<FormControl className="flex flex-col w-full">
								<FormLabel className="!font-bold !text-sm !pl-2 dark:text-white !mb-1">ESTADO</FormLabel>
								<Input value={editEmpresaData.state} onChange={e=> handleEditEmpresaData("state", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 dark:bg-navy-900 rounded w-full p-2" />
							</FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='red' mr={3} onClick={handleCancelEditEmpresa}>
                                Cancelar
                            </Button>
                            <Button colorScheme='blue' mr={3} onClick={handleEditEmpresa}>
                                Salvar
                            </Button>
                            {/* <Button onClick={handleGeneratePDF} colorScheme="green" variant='solid'>Gerar</Button> */}
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ChakraProvider>
			<div className="">
				<div className="flex items-center w-full mt-[-8px] justify-start">
					{tiposEmpresas.map(tipo=>{
						return(
							<button onClick={()=> setSelectedTipo(tipo.key)} key={tipo.key} className={`p-2 text-sm shadow transition-all ${selectedTipo == tipo.key ? "bg-gray-750 text-white" : ""}`}>{tipo.label}</button>
						)
					})}
				</div>

				<div className="flex items-center justify-center h-full w-full">
					<button onClick={handleAddEmpresa} className="linear ml-auto mr-5 flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-sm font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
						Nova empresa
					</button>
				</div>
				<form className="flex items-center justify-center h-full w-full bg-white mt-5 dark:!bg-navy-800 rounded-xl px-5 gap-2" onSubmit={(e)=>{
					handleSearch(e)
				}}>
					<div className="flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white">
						<p className="pl-3 pr-2 text-xl">
							<FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
						</p>
						<input
							name="search"
							type="text"
							placeholder="Procurar..."
							className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
						/>
					</div>
					<button type="submit" disabled={loading} className="linear flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
						Procurar
					</button>
				</form>

				<div className="mt-5">
					<EmpresasTable title="Lista de Empresas" selectedLabel={tiposEmpresas.find(t=> t.key == selectedTipo)?.label || "Empresa"} tableData={empresas} loading={loading} reloadTable={loadEmpresas} setEdit={setEditEmpresaData} onOpenEdit={onOpenEditEmpresa}/>
				</div>
			</div>
		</>
	)
}

export default EmpresasCadastradas