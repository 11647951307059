
const ajusta = (v: any) => {
  const digitos = !v ? '' : v.replace(/[^\d]/g, '');
  if (!digitos || digitos.length < 10) return v;
  const corte = digitos.length === 10 ? 6 : 7;
  const max = digitos.length > 11 ? 11 : digitos.length;
  return `(${digitos.substring(0, 2)}) ${digitos.substring(2, corte)}-${digitos.substring(corte, max)}`;
}

export const phoneMaskBuilder = (v: any) => {
  if (!v || v.length == 0) return '';
  const a = ajusta(v);
  return (a.length >= 6 && a[5] === '9') ? '(99) 99999-9999' : '(99) 9999-9999';
}

export const phoneMask = (value: any) => {
  return ajusta(value);
}

export const phoneMaskToNumber = (phone: string) => {
  if(phone){
    const purePhone = phone.replaceAll(".", "").replaceAll(",","").replaceAll("-", "").replaceAll("/", "").replaceAll("(", "").replaceAll(")", "").replace(/\D+/g, '')
    const phoneNumber = purePhone.slice(0, 11)
  
    return phoneNumber
  }
  return phone
}