import { toast } from "react-toastify"
import { ErrorData } from "types/fetch"

export const toastError = (error: ErrorData, darkmode: boolean) => {
	if(error.response){
		if(error.response.data.error){
			toast(error.response.data.error, {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		}else{
			toast("Erro de comunicação com o servidor", {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		}
	}else{
		toast("Erro ao se comunicar com o backend", {
			type: "error",
			theme: darkmode ? "dark" : "light"
		})
	}
}

export const toastErrorMsg = (error: string, darkmode: boolean) => {
	toast(error, {
		type: "error",
		theme: darkmode ? "dark" : "light"
	})
}