import { useEffect, useRef, useState } from "react";

interface CompanyAutoCompleteType{
  id: string;
  label?: string;
  extra: string;
  placeholder: string;
  variant: string;
  state?: string;
  disabled?: boolean;
  onChange?: (clientName: string) => void;
  onSelect?: (clientId: string) => void,
  value?: any,
  options: {value: any, content: any}[]
  loading: boolean
  className?: string;
  inputClass?: string;
}
const CompanyAutoComplete = ({className, inputClass, loading, options, onSelect, label, id, extra, placeholder, variant, state, disabled, onChange, value}: CompanyAutoCompleteType) => {

    const [selectOpen, setSelectOpen] = useState(false)
    const ref = useRef()

    const openSelect = () => {
        setSelectOpen(true)
    }

    const handleOutsideClick = (e: any) => {
        if(e.target.parentNode != ref.current && e.target != ref.current){
            setSelectOpen(false)
        }
    }

    const handleSelect = (client: {value: any, content: any}) => {
        onChange(client.content)
        onSelect(client.value)
        setSelectOpen(false)
    }

    useEffect(()=> {
        document.addEventListener("mousedown", handleOutsideClick)
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    })
    return(
        <div className={`relative ${className}`}>
            {label && <label
                htmlFor={id}
                className={`dark:text-white font-bold text-sm pl-2`}
            >
                {label}
            </label> }
            <input
                disabled={disabled}
                id={id}
                value={value}
                onChange={e=> onChange(e.target.value)}
                placeholder={placeholder}
                autoComplete="off"
                onFocus={openSelect}
                onClick={openSelect}
                className={`${inputClass}`}
            />
            <ul ref={ref} className={`${selectOpen ? '' : 'hidden'} ${loading ? "text-center justify-center" : ""} z-50 flex flex-col h-[150px] list-none overflow-auto w-full absolute top-[105%] left-0 right-0 border border-gray-200 dark:border-gray-900 bg-white dark:bg-navy-900 rounded`}>
                {!loading ? options.map(client=>(
                    <li key={"CLIENTS-"+client.value} onClick={() => handleSelect(client)} className="dark:text-white p-2 hover:bg-gray-50 hover:dark:bg-navy-800 cursor-pointer">{client.content}</li>
                )) : <span className="dark:text-white">
                    Carregando...
                </span>}
            </ul>
        </div>
    )
}

export default CompanyAutoComplete