import { FiSearch } from "react-icons/fi";

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "services/api";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsTable from "./components/ContactsTable";
import CompanyAutoComplete from "components/companyAutoComplete/CompanyAutoComplete";
import { toast } from "react-toastify";
import { ClientType, SelectType } from "types/app";

const Contacts = () => {
  const user = useAppSelector((state) => state.user.user)
  const darkmode = useAppSelector((state) => state.layout.darkMode)

  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [contactsData, setContactsData] = useState([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState<number>(1)
  const [maxPages, setMaxPages] = useState<number>(2)
  const navigate = useNavigate()
  const [loadingPage, setLoadingPage] = useState(false)
  const dispatch = useAppDispatch
  const [companyNameTimeout, setCompanyNameTimeout] = useState<any>()
  const [loadingContacts, setLoadingContacts] = useState(true)
  const [companySelect, setCompanySelect] = useState<SelectType[]>([]);
  const [companyName, setCompanyName] = useState('')
  const [companyId, setCompanyId] = useState(null)

  useEffect(()=>{
    setLoadingPage(true)

    getClients('')

    api.get(`/contacts/all`, {
      headers: {
        token: user?.token
      },
      params: {
        page,
        perpage: rowsPerPage,
        search,
        company_name: companyName
      }
    }).then(res=>{
      setLoadingPage(false)
      setContactsData(res.data.contacts)
      setMaxPages(res.data.maxPages)
    })
  }, [page])

  const reloadTable = () => {
    api.get(`/contacts/all`, {
      headers: {
        token: user?.token
      },
      params: {
        page,
        perpage: rowsPerPage,
        search,
        company_name: companyName
      }
    }).then(res=>{
      setLoadingPage(false)
      setContactsData(res.data.contacts)
      setMaxPages(res.data.maxPages)
    })
  }

  const handleSearch = (e: any) => {
    e.preventDefault()

    if(loadingPage || loadingContacts) return;
    setLoadingContacts(true)

    setPage(1)
    setLoadingPage(true)


    api.get(`/contacts/all`, {
      headers: {
        token: user?.token
      },
      params: {
        page,
        perpage: rowsPerPage,
        search,
        company_name: companyName
      }
    }).then(res=>{
      setLoadingPage(false)
      setLoadingContacts(false)
      setContactsData(res.data.contacts)
      setMaxPages(res.data.maxPages)
    })
  }

  const handleChangePage = (newPage: number) => {
    if(loadingPage) return;
    setLoadingPage(true)
    setPage(newPage)
  }

  const handleNewContact = () => {
    navigate('/admin/crm/contacts/new')
  }


  const getClients = (_search?: string) => {
    setLoadingContacts(true)
    api.get("/clients/all/autocomplete", {
        headers: {
            token: user?.token
        },
        params: {
            search: _search ? _search : ""
        }
    }).then((res: { data: ClientType[] }) => {
        setLoadingContacts(false)
        var _newClients: SelectType[] = []
        res.data.map(client => {
            _newClients.push({
                content: client.name ? client.name : "Sem nome",
                value: client.id.toString()
            })
        })

        setCompanySelect(_newClients)
    }).catch((error) => {
        setLoadingContacts(false)
        toast(error.response.data.error, {
            type: "error",
            theme: darkmode ? "dark" : "light"
        })
    })
  }

  const handleSelectClient = (newClient: string) => {
    const _companyId = isNaN(parseInt(newClient)) ? 0 : parseInt(newClient)
    setCompanyId(_companyId)
  }

  const handleChangeClient = (companyName: string) => {
      setCompanyName(companyName)
      if (companyNameTimeout) {
          clearInterval(companyNameTimeout)
      }
      setCompanyNameTimeout(setTimeout(() => {
          getClients(companyName)
          setCompanyNameTimeout(null)
      }, 500))
  }

  return (
    <>
      <div className="flex items-center justify-center mt-10 h-full w-full py-2">
        <button onClick={handleNewContact} className="linear ml-auto mr-5 flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-sm font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
          Novo contato
        </button>
      </div>
      <form className="flex gap-2 items-center justify-center mt-2 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2" onSubmit={handleSearch}>
        <div className="flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            value={search}
            onChange={e=> setSearch(e.target.value)}
            type="text"
            placeholder="Procurar..."
            className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
          />
        </div>

        <CompanyAutoComplete
            loading={loadingContacts}
            extra=""
            inputClass="block h-full px-5 py-2 w-full rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white"
            className="md:col-span-1 2xl:col-span-4 h-12 w-[40%]"
            variant="auth"
            placeholder="Busca por organização"
            id="orderClient"
            onChange={handleChangeClient}
            onSelect={handleSelectClient}
            value={companyName}
            options={companySelect}
        />
        <button onClick={handleSearch} disabled={loadingPage} className="linear flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Procurar
        </button>
      </form>
      <div className="mt-5">
        <ContactsTable page={page} reloadTable={reloadTable} loading={loadingPage} setPage={handleChangePage} title="Lista de Contatos" tableData={contactsData} maxPages={maxPages} />
      </div>
    </>
  );
};

export default Contacts;
