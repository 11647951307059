import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { useNavigate, useParams } from "react-router-dom";
import { ClientType, ContactType, Role, SelectType, UserType } from "types/app";
import Switch from "components/switch";
import ReactInputMask from "react-input-mask";
import { phoneMask, phoneMaskBuilder } from "masks/phoneMask";
import dayjs from "dayjs";
import CompanyAutoComplete from "components/companyAutoComplete/CompanyAutoComplete";

interface EditContactType extends ContactType {
}

const EditContact = () => {
    const { id } = useParams()

    const [data, setData] = useState<EditContactType>({
        id: 0,
        name: "",
        birthdate: dayjs(),
        clientId: null,
        createdAt: "",
        department: "",
        email: "",
        linkedin: "",
        role: "",
        whatsapp: null,
        assinaturaPadrao: false,
        testemunhaPadrao: false,
    })

    const [roles, setRoles] = useState<Role[]>([])
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const user = useAppSelector((state) => state.user.user)

    const [companyNameTimeout, setCompanyNameTimeout] = useState<any>()
    const [companyName, setCompanyName] = useState('')
    const [loadingCompanys, setLoadingCompanys] = useState(false)
    const [companySelect, setCompanySelect] = useState<SelectType[]>([]);

    const navigate = useNavigate()

    const handleChangeInput = (key: keyof (ContactType), val: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [key]: val.target.value })
    }
    const handleChangeValue = (key: keyof (ContactType), val: any) => {
        setData({ ...data, [key]: val })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const _clientId = data.clientId != undefined ? parseInt(data.clientId.toString()) : undefined;

        api.put('/contacts/edit/'+id, {
            ...data,
            clientId: _clientId
        }, {
            headers: {
                token: user?.token
            }
        }).then(() => {
            toast("Contato editado com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            navigate('/admin/crm/contacts')
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }



    const getClients = (_search?: string) => {
        setLoadingCompanys(true)
        api.get("/clients/all/autocomplete", {
            headers: {
                token: user?.token
            },
            params: {
                search: _search ? _search : ""
            }
        }).then((res: { data: ClientType[] }) => {
            setLoadingCompanys(false)
            var _newClients: SelectType[] = []
            res.data.map(client => {
                _newClients.push({
                    content: client.name ? client.name : "Sem nome",
                    value: client.id.toString()
                })
            })
            setCompanySelect(_newClients)
        }).catch((error) => {
            setLoadingCompanys(false)
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const handleSelectClient = (newClient: string) => {
        const _companyId = isNaN(parseInt(newClient)) ? 0 : parseInt(newClient)
        handleChangeValue("clientId", _companyId)
    }

    const handleChangeClient = (companyName: string) => {
        setCompanyName(companyName)
        if (companyNameTimeout) {
            clearInterval(companyNameTimeout)
        }
        setCompanyNameTimeout(setTimeout(() => {
            getClients(companyName)
            setCompanyNameTimeout(null)
        }, 500))
    }

    const loadContact = () => {
        api.get('/contacts/show/'+id, {
            headers: {
                token: user?.token
            }
        }).then((res: {data: ContactType})=> {
            setData(res.data)
            setCompanyName(res.data.client?.name)
        }).catch((error) => {
            setLoadingCompanys(false)
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    useEffect(()=> {
        loadContact()
        getClients()
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit} className="grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 mt-10 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Nome</FormLabel>
                    <Input value={data.name} onChange={e => handleChangeInput("name", e)} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Nome do Contato" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Email</FormLabel>
                    <Input value={data.email} onChange={e => handleChangeInput("email", e)} type="email" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="email@dominio.com" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Telefone</FormLabel>
                    <ReactInputMask mask={phoneMaskBuilder(data.whatsapp)} value={data.whatsapp} onChange={e => { handleChangeValue("whatsapp", phoneMask(e.target.value)) }} maskChar={null} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="(99) 99999-9999" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">LinkedIn</FormLabel>
                    <Input value={data.linkedin} onChange={e => handleChangeInput("linkedin", e)} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="https://br.linkedin.com/in/usuario" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Data de Nascimento</FormLabel>
                    <Input value={dayjs(data.birthdate).format("YYYY-MM-DD")} style={{
                        colorScheme: darkmode ? "dark" : "light",
                    }} onChange={e => handleChangeInput("birthdate", e)} type="date" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Empresa</FormLabel>
                    <CompanyAutoComplete
                        loading={loadingCompanys}
                        extra=""
                        className="md:col-span-1 2xl:col-span-4"
                        inputClass="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2"
                        label=""
                        variant="auth"
                        placeholder=""
                        id="orderClient"
                        onChange={handleChangeClient}
                        onSelect={handleSelectClient}
                        value={companyName}
                        options={companySelect}
                    />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Cargo</FormLabel>
                    <Input value={data.role} onChange={e => handleChangeInput("role", e)} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Nome do Contato" />
                </FormControl> <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Departamento</FormLabel>
                    <Input value={data.department} onChange={e => handleChangeInput("department", e)} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Nome do Contato" />
                </FormControl>

                <div className="col-span-2 flex flex-col">

                    <div className="mt-3 flex items-center gap-3">
                        <Switch checked={data.assinaturaPadrao ? true : false} onChange={() => {
                            setData({
                                ...data,
                                ["assinaturaPadrao"]: !data.assinaturaPadrao
                            })
                        }} id="switch1" />
                        <label
                            htmlFor="checkbox1"
                            className="text-base font-medium text-navy-700 dark:text-white"
                        >
                            Por padrão, assina todos os documentos
                        </label>
                    </div>
                    <div className="mt-3 flex items-center gap-3">
                        <Switch checked={data.testemunhaPadrao ? true : false} onChange={() => {
                            setData({
                                ...data,
                                ["testemunhaPadrao"]: !data.testemunhaPadrao
                            })
                        }} id="switch1" />
                        <label
                            htmlFor="checkbox1"
                            className="text-base font-medium text-navy-700 dark:text-white"
                        >
                            Por padrão, é a testemunha de todos os documentos
                        </label>
                    </div>

                </div>

                <button type="submit" className="col-span-2 linear h-10 mt-5 w-full md:w-30p xl:w-20p ml-auto flex items-center justify-center rounded-xl bg-green-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
                    Criar contato
                </button>
            </form>
        </>
    )
}


export default EditContact