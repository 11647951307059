import { FiSearch } from "react-icons/fi";

import { Link, useNavigate } from "react-router-dom";
import RelatorioSalarioTable from "./components/RelatorioSalarioTable";
import { useEffect, useState } from "react";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import dayjs from "dayjs";
import { Input, useDisclosure } from '@chakra-ui/react';
import Dropdown from "components/dropdown";
import Calendar from "react-calendar";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { months } from "helpers/months";
import { toastError, toastErrorMsg } from "components/toasts/toastError";

import { Buffer } from "buffer";
import { generateXlsx } from "helpers/generateXlsx";


interface SalaryReportType{
	funcId: number
	funcName: string
	baseSalary: number
	expenses: number
	architectComission: number
	contractsComission: number
	participantComission: number
	presellComission: number
	salesComission: number
	totalSalary: number
};

const RelatorioSalario = () => {

  const [tableData, setTableData] = useState([])
  const user = useAppSelector((state) => state.user.user)
  const darkmode = useAppSelector((state) => state.layout.darkMode)
  const [page, setPage] = useState<number>(1)
  const [maxPages, setMaxPages] = useState<number>(2)
  const navigate = useNavigate()

  const [statusSearch, setStatusSearch] = useState(-1)
  const [fromDate, setFromDate] = useState(dayjs().startOf("month").format("YYYY-MM-DD"))
  const [toDate, setToDate] = useState(dayjs().endOf("month").format("YYYY-MM-DD"))
  const [searchDate, setSearchDate] = useState(dayjs().startOf('month').toDate())

  const [loadingPage, setLoadingPage] = useState(false)

  const [valSearch, setValSearch] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const getSalarys = () => {
    setLoadingPage(true)
    api.get(`/funcexpenses/report/salary2`, {
      headers: {
        token: user?.token
      },
      params: {
		date: searchDate
      }
    }).then((res: {data: SalaryReportType[] })=>{
		setTableData(res.data)
		setLoadingPage(false)
    }).catch(()=>{
      setLoadingPage(false)
    }).catch(err=>{
		toastError(err, darkmode)
	})
  }

  useEffect(()=>{
    getSalarys()
  }, [searchDate])

  const handleExport = () => {
      api.get(`/funcexpenses/report/salary/download`, {
        headers: {
          token: user?.token,
        },
        params: {
			date: searchDate
        }
      }).then((res )=>{
		const data = res.data
		if(data){
			const {name, sheetData, sheetName} = data
			try{
				generateXlsx(name, sheetName, sheetData)
			}catch(err){
				toastErrorMsg("Falha ao exportar relatório", false)
				console.error(err)
			}
		}

        //   const url = window.URL.createObjectURL(new Blob([res.data]))
        //   const link = document.createElement('a')
        //   link.href = url
        //   link.setAttribute('download', `Relatório de SALARIOS - ${months[dayjs(searchDate).month()]}.xlsx`)
        //   document.body.appendChild(link)
        //   link.click()
        //   link.remove()

      }).catch(err=>{
		console.log(err)
		toastError(err, darkmode)
	})
  }

  const handleClickMonth = (date: Date) => {
	setSearchDate(dayjs(date).startOf('month').toDate())
  }

  return (
    <>
      <div className="flex items-center justify-center mt-10 h-full w-full py-2">
        {tableData.length > 0 && <button onClick={handleExport} className="linear ml-auto flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
          Baixar relatório
        </button>}
      </div>
      <div className="flex gap-5 items-center justify-end mt-2 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">
        {/* <div className="col-span-1 md:col-span-2 xl:col-span-3 2xl:col-span-5 flex h-12 w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white"> */}

		<div className="flex items-center gap-2 w-full md:w-fit">
			<b className="dark:text-white">Mês:</b>
			<Dropdown
				extraClassesWrapper={`w-full md:w-fit`}
				extraClassesButton="w-full md:w-fit"
				button={
					<button
						className={`w-full flex items-center text-md hover:cursor-pointer bg-white border border-gray-200 rounded-[20px] p-2 shadow-xl shadow-shadow-600 transition hover:bg-lightPrimary`}
					>
						{months[dayjs(searchDate).month()]}
					</button>
				}
				animation={"origin-top-right transition-all duration-300 ease-in-out"}
				classNames={`right-0 w-max`}
				children={
					<div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none flex flex-col gap-2 dark:text-white">
						<Calendar
							value={searchDate}
							onClickMonth={handleClickMonth}
							prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
							nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
							view={"year"}
						/>
					</div>
				}
			/>
		</div>
      </div>

      <div className="mt-5">
        <RelatorioSalarioTable loading={loadingPage} title="Relatório de Salários" tableData={tableData} />
      </div>
    </>
  );
};

export default RelatorioSalario;
