import { FormControl, FormLabel, Input } from "@chakra-ui/react"
import InputMask from 'react-input-mask';
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { useNavigate, useParams } from "react-router-dom";
import apiCeps from "services/apiCeps";
import { IBGEMunicipio, Operacao } from "types/app";
import { cnpjMask, cnpjMaskToNumber } from "masks/cnpjMask"
import AsyncSelect from 'react-select/async'
import consultarCNPJ from 'consultar-cnpj'
import { StylesConfig } from "react-select";
import { replaceSpecialChars } from "helpers/conversions";
import { toastError, toastErrorMsg } from "components/toasts/toastError";

const selectStyle: StylesConfig = {
    singleValue: (styles, { isDisabled }) => ({
        ...styles,
        color: isDisabled ? "rgb(112 126 174 / 1)" : undefined
    }),
    input: (styles, { isDisabled }) => ({
        ...styles,
        paddingLeft: 2,
        paddingRight: 2,
        height: 42,

    }),
    option: (styles, { isSelected, isFocused, isDisabled}) => ({
        ...styles,
        background: isSelected ? "#2d394d" : isFocused ? "#edf2f7" : styles.background,
        ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
            ? "#cbd5e0" : undefined,
        },
    }),
    control: (styles, {isDisabled}) => ({
        ...styles,
        borderColor: "#a3aed0",
        borderRadius: 0,
        background: isDisabled ? "#f8f9fa" : "white",
        cursor: isDisabled ? "default" : "text"
    })
}

const EditOperacao = () => {
	const { id } = useParams()
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const user = useAppSelector((state) => state.user.user)

    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [number, setNumber] = useState('')
    const [complement, setComplement] = useState('')
    const [cep, setCep] = useState('')
    const [district, setDistrict] = useState('')
	const [pageLoaded, setPageLoaded] = useState(false)
    const [cnpj, setCnpj] = useState('')
	const [inscricaoMunicipal, setInscricaoMunicipal] = useState('')
    const [ie, setIE] = useState('')
    const [codigoCidade, setCodigoCidade] = useState('')

	const [loadingCidades, setLoadingCidades] = useState(true)
    const [cidades, setCidades] = useState<{
        codigo_municipio: string,
        nome_municipio: string
    }[]>([])

    const navigate = useNavigate()

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const _cnpj = cnpjMaskToNumber(cnpj)

        if(address.trim() == ""){
            toast("Preencha o campo de Endereço", {
                type: "error"
            })
            return
        }

        api.put('/operacoes/update/'+id, {
			name,
            state,
            cep: cep.split('-').join(''),
            number,
            city,
            codigo_municipio: codigoCidade,
			inscricao_municipal: inscricaoMunicipal,
            complement,
            district,
			address,
			cnpj: _cnpj,
			ie
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Operação atualizada com sucesso!", {
                type: "success",
            })
            navigate('/admin/operacoes')
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
            })
        })

    }

    const handleCepChange = (val: any) => {
        setCep(val)
    }

    const cepBuilder = (val: any) => {
        return '99999-999'
    }

    const getCNPJ = async (cnpj: any) => {
        try{
            const empresa = await consultarCNPJ(cnpj)
            if(empresa){
                setCep(empresa.estabelecimento.cep)
                setName(empresa.razao_social)
                setNumber(empresa.estabelecimento.numero)
                setComplement(empresa.estabelecimento.complemento)
                const inicioArray = empresa.estabelecimento.data_inicio_atividade.split('-')
                const dataInicio = {
                    ano: inicioArray[0] ? inicioArray[0] : '0',
                    mes: inicioArray[1] ? inicioArray[1] : '0',
                    dia: inicioArray[2] ? inicioArray[2] : '0'
                }

                if(empresa.estabelecimento.inscricoes_estaduais.length && empresa.estabelecimento.inscricoes_estaduais){
                    let activeIE = false
                    let selectedIE = null
                    empresa.estabelecimento.inscricoes_estaduais.map(_ie=>{
                        if(_ie.ativo == true && !activeIE){
                            activeIE = true
                            selectedIE = _ie.inscricao_estadual
                        }
                    })

                    if(activeIE){
                        setIE(empresa.estabelecimento.inscricoes_estaduais[0].inscricao_estadual);
                    }else{
                        setIE("Isento")
                    }
                }else{
                    setIE("Isento")
                }

                if(empresa.estabelecimento.cep){
                    setAddress("Carregando...")
                    setState("Carregando...")
                    setCity("Carregando...")
                    setDistrict('Carregando...')

                    apiCeps.get("/cep", {
                        params: {
                            cep: empresa.estabelecimento.cep
                        }
                    }).then(res=>{
                        if(res.data){
                            if(res.data.rua && res.data.rua != ""){
                                setAddress(res.data.rua)
                            }else{
                                setAddress(empresa.estabelecimento.logradouro)
                            }

                            if(res.data.estados.nome && res.data.estados.nome != ""){
                                setState(res.data.estados.nome)
                            }else{

                            }

                            if(res.data.cidades.nome && res.data.cidades.nome != ""){
                                setCity(res.data.cidades.nome)
                            }else{

                            }

                            if(res.data.bairro && res.data.bairro != ""){
                                setDistrict(res.data.bairro)
                            }else{
                                setDistrict(empresa.estabelecimento.bairro)
                            }
                        }else{
                            setAddress("Não encontrado")
                            setState("Não encontrado")
                            setCity("Não encontrado")
                        }
                    })
                }
            }
        }catch(error: any){
            toast("CNPJ não encontrado na base do Governo", {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        }
    }

    const handleLoadCidades = () => {
		setLoadingCidades(true)
		api.get<IBGEMunicipio[]>("https://servicodados.ibge.gov.br/api/v1/localidades/municipios").then((res)=> {
			if(res){
				if(res.data){
					setCidades(res.data.map(c=> {
						return {
                            codigo_municipio: c.id.toString(),
                            nome_municipio: c.nome
                        }
					}))
				}
			}
			setLoadingCidades(false)
		}).catch(err=>{
			toastErrorMsg("Erro ao carregar cidades IBGE", false)
			setLoadingCidades(false)
		})
    }
    useEffect(()=> {
        handleLoadCidades()
    }, [])

	useEffect(() => {
		if (!pageLoaded) return;
		setAddress('')
		setState('')
		setCity('')
		setDistrict('')
		if (cep) {
			if (cep.length >= 9) {
				setAddress("Carregando...")
				setState("Carregando...")
				setCity("Carregando...")
				setDistrict('Carregando...')

				apiCeps.get("/cep", {
					params: {
						cep: cep.split('-').join('')
					}
				}).then((res) => {
					if (res.data) {
						setAddress(res.data.rua)
						setState(res.data.estados.nome)
						setCity(res.data.cidades.nome)
						setDistrict(res.data.bairro)
					} else {
						setAddress("Não encontrado")
						setState("Não encontrado")
						setCity("Não encontrado")
					}
				})
			}
		}
	}, [cep])

	useEffect(() => {
		api.get('/operacoes/show/' + id, {
			headers: {
				token: user?.token
			}
		}).then((res: { data: Operacao }) => {
			setCep(res.data.cep)
			setState(res.data.state)
			setCity(res.data.city)
			setNumber(res.data.number)
			setComplement(res.data.complement)
			setDistrict(res.data.district)
			setName(res.data.name)
			setAddress(res.data.address)
			setCnpj(res.data.cnpj)
            setCodigoCidade(res.data.codigo_municipio)
			setIE(res.data.ie)
			setInscricaoMunicipal(res.data.inscricao_municipal)
			setTimeout(() => {
				setPageLoaded(true)
			}, 500);
		})
	}, [])

    useEffect(() => {
        if(cnpj){
            if(cnpj.length >= 18){
                // console.log(cnpj.length)
                getCNPJ(cnpj)
            }
        }
    }, [cnpj])

    return(
        <>

			<div className="text-2xl font-bold text-black dark:text-white mt-5 pl-5">
				Editar operação
			</div>
            <form className="grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6 mt-2 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">

				<FormControl className="flex flex-col gap-1 w-full 2xl:col-span-4">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Razão Social<b className="text-red-500">*</b></FormLabel>
                    <Input value={name} onChange={e=> setName(e.target.value)} required type="text" className="h-[50px] border border-gray-600 px-2 cursor-text" placeholder="Nome" />
                </FormControl>
				<FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">CNPJ<b className="text-red-500">*</b></FormLabel>
                    <Input value={cnpjMask(cnpj)} onChange={e=> setCnpj(e.target.value)} required type="text" className="h-[50px] border border-gray-600 px-2 cursor-text" placeholder="CNPJ da empresa" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Inscrição Estadual</FormLabel>
                    <Input value={ie} onChange={e=> setIE(e.target.value)} required type="text" className="h-[50px] border border-gray-600 px-2 cursor-text" placeholder="IE da empresa" />
                </FormControl>


                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">CEP<b className="text-red-500">*</b></FormLabel>
                    <InputMask mask={cepBuilder(cep)} value={cep} onChange={e=> {handleCepChange(e.target.value);}} maskChar={null} type="text" className="h-[50px] border border-gray-600 px-2 cursor-text" placeholder="CEP da empresa" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Rua<b className="text-red-500">*</b></FormLabel>
                    <Input value={address} onChange={e=> setAddress(e.target.value)} required type="text" className="h-[50px] border border-gray-600 px-2 cursor-text" placeholder="Rua do estabelecimento" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Número<b className="text-red-500">*</b></FormLabel>
                    <Input value={number} onChange={e=> setNumber(e.target.value)} required type="text" className="h-[50px] border border-gray-600 px-2 cursor-text" placeholder="0" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Complemento</FormLabel>
                    <Input value={complement} onChange={e=> setComplement(e.target.value)} required type="text" className="h-[50px] border border-gray-600 px-2 cursor-text" placeholder="Complemento" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full 2xl:col-span-2">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Bairro<b className="text-red-500">*</b></FormLabel>
                    <Input value={district} onChange={e=> setDistrict(e.target.value)} required type="text" className="h-[50px] border border-gray-600 px-2 cursor-text" placeholder="Bairro do estabelecimento" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full 2xl:col-span-2">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Cidade<b className="text-red-500">*</b></FormLabel>
                    <Input value={city} disabled onChange={e=> setCity(e.target.value)} required type="text" className="h-[50px] border border-gray-600 px-2 cursor-text" placeholder="Cidade do estabelecimento" />
                </FormControl>
                <FormControl className="flex flex-col gap-1 w-full">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Estado<b className="text-red-500">*</b></FormLabel>
                    <Input value={state} disabled onChange={e=> setState(e.target.value)} required type="text" className="h-[50px] border border-gray-600 px-2 cursor-text" placeholder="Estado do estabelecimento" />
                </FormControl>

                <FormControl className="flex flex-col gap-1 w-full col-span-2">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Cidade NFE<b className="text-red-500">*</b></FormLabel>
                    <AsyncSelect
                        defaultOptions={ cidades.map((item) => { let _item: any = {}; _item.value = item.codigo_municipio; _item.label = item.nome_municipio; return _item; }).slice(0, 9) }
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = cidades
									.filter((item) =>
										replaceSpecialChars(
											item.nome_municipio.toLowerCase()
										).includes(replaceSpecialChars(inputstring.toLowerCase()))
									)
									.map((item) => {
										let _item: any = {};
										_item.value = item.codigo_municipio;
										_item.label = item.nome_municipio;
										return _item;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: codigoCidade?.toString(),
							label: cidades?.find(
								(c) =>
									c.codigo_municipio.toString() ==
									codigoCidade?.toString()
							)?.nome_municipio,
						}}
						menuPlacement="auto"
                        onChange={(e: any)=> {
                            setCodigoCidade(e.value)
                        }}
						isLoading={loadingCidades ? true : undefined}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
                </FormControl>
				<FormControl className="flex flex-col gap-1 w-full">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Inscrição Municipal(NF)</FormLabel>
					<Input value={inscricaoMunicipal} onChange={e=> setInscricaoMunicipal(e.target.value)} required type="text" className="h-[50px] border border-gray-600 px-2 cursor-text" placeholder="Inscrição Municipal" />
				</FormControl>
            </form>
			<div className="flex items-center justify-center gap-2 w-full mt-5">
				<button type="button" onClick={()=> navigate('/admin/operacoes')} className="linear h-10 w-[120px] flex items-center justify-center rounded-xl bg-red-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-darkred-400 dark:text-white dark:hover:bg-darkred-300 dark:active:bg-darkred-200">
                    Cancelar
                </button>
                <button type="button" onClick={handleSubmit} className="linear h-10 w-[120px] flex items-center justify-center rounded-xl bg-green-500 px-5 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
                    Salvar
                </button>
			</div>
        </>
    )
}

export default EditOperacao