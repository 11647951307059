import { createSlice } from '@reduxjs/toolkit';
import api from 'services/api';
import { Notifications } from 'types/app';

interface NotificationsSliceType{
    notifications: Notifications[]
}

export const notificationsSlice = createSlice({
    name: "layout",
    initialState: {
        notifications: []
    } as NotificationsSliceType ,
    reducers: {
        refreshNotifications: (state, action) => {
            state.notifications = action.payload
        }
    }
})

export const { refreshNotifications } = notificationsSlice.actions

export default notificationsSlice.reducer