import { useEffect, useState } from "react"
import RDVSheetComponent from "./components/RDVSheetComponent"
import { MdChevronLeft, MdChevronRight, MdDelete } from "react-icons/md"
import Card from "components/card"
import CustomInputV2 from "components/custom-input-2"
import CustomInput from "components/custom-input"
import CompanyAutoCompleteVirtualized from "components/companyAutoCompleteVirtualized/CompanyAutoCompleteVirtualized"
import { CityType, ClientType, SelectType } from "types/app"
import api from "services/api"
import { toast } from "react-toastify"
import { useAppSelector } from "redux/hooks"
import ClientSheetAutocomplete from "./components/ClientSheetAutoComplete"
import Dropdown from "components/dropdown"
import dayjs from "dayjs"
import Calendar from "react-calendar"
import { months } from "helpers/months"
import { RDVPaymentStatus } from "helpers/dbData"
import CitySelectorAutocomplete from "./components/CitySelector"
import apiCeps from "services/apiCeps"
import SideMenu from "components/sidemenu"
import { toastError } from "components/toasts/toastError"
import { formatToCurrency, replaceSpecialChars } from "helpers/conversions"
import AsyncSelect  from 'react-select/async'
import { ChakraBaseProvider, ChakraProvider, Text } from "@chakra-ui/react"
import { StylesConfig } from "react-select"
import { FcInfo } from "react-icons/fc"
import TooltipHorizon from "components/tooltip"

export interface RDVRowType{
    id?: any,
    data?: string,
    origem?: string
    destino?: string,
	client?: {
		name?: string,
	}
	rdvEmpresas?: {
		empresa: {
			id: number,
			name: string
		}
	}[]

	selectedRDVEmpresas?: {label: string, value: string}[]

	clientId?: number
    km?: number
    pedagio?: number
    almoco?: number
    estacionamento?: number
    status?: number
    oportunidadeGerada?: string
    rdvUploads?: {id: number | string, fileName: string}[],
    toCreatePhotos?: {id: any, preview: any}[]
    toCreatePhotosFiles?: any[]
	toDeletePhotos?: string[]
}

// const defaultData: SheetRowType[] = [
//     {id: 1, data: dayjs().format('YYYY-MM-DD'), origem: "SJRP", destino: "Bauru", clientId: 0, km: 200, pedagio: 34.5, almoco: 49.90, estacionamento: 10, status: 1},
//     {id: 2, data: dayjs().subtract(76, 'day').format('YYYY-MM-DD'), origem: "BAURU", destino: "Jaci", clientId: 1, km: 120, pedagio: 34.5, almoco: 49.90, estacionamento: 10, status: 0}
// ]

const RDVSheet = () => {
    const user = useAppSelector((state) => state.user.user)
    const darkmode = useAppSelector((state) => state.layout.darkMode)

    const [data, setData] = useState<RDVRowType[]>([])
    const [selectedRows, setSelectedRows] = useState<number[]>([])
    const [newItems, setNewItems] = useState(1)
    const [toDeleteSheet, setToDeleteSheet] = useState<number[]>([])
    const [clients, setClients] = useState<SelectType[]>([])
    const [loadingClients, setLoadingClients] = useState(true)
    const [selectedDate, setSelectedDate] = useState(dayjs().startOf("month").toDate())
    const [canSave, setCanSave] = useState(false)

	const [cities, setCities] = useState<SelectType[]>([])
	const [loadingCities, setLoadingCities] = useState(true)

    const [menuOpen, setMenuOpen] = useState(false)
    const [loadingMenu, setLoadingMenu] = useState(true)

    const [editData, setEditData] = useState<RDVRowType>({
        rdvUploads: [],
    })

    const [selectedImage, setSelectedImage] = useState<{
        id?: number | string,
        url?: string,
    }>({})

	const selectStyle: StylesConfig = {
		input: (styles) => ({
			...styles,
			paddingLeft: 2,
			paddingRight: 2,
			height: 42
		}),
		option: (styles, { isSelected, isFocused, isDisabled}) => ({
			...styles,
			background: isSelected ? "#2d394d" : isFocused ? "#edf2f7" : styles.background,

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
				? "#cbd5e0" : undefined,
			},
		}),
		control: (styles) => ({
			...styles,
			borderColor: "#a3aed0",
			borderRadius: 0
		})
	}

	const resetMenuData = () => {
		setEditData({
			data: undefined,
			origem: null,
			destino: null,
			clientId: null,
			km: 0,
			pedagio: 0,
			almoco: 0,
			estacionamento: 0,
			status: 0,
			oportunidadeGerada: "",
			rdvUploads: [],
			toCreatePhotos: [],
			toCreatePhotosFiles: [],
			toDeletePhotos: [],
		})
	}

    const loadData = () => {
        api.get("/rdv/user/index", {
            headers: {
                token: user?.token
            },
            params: {
                date: selectedDate.toISOString(),
                user_id: undefined
            }
        }).then((response: {data: any})=>{
            if(response){
                if(response.data){
                    setData(response.data)
                }
            }
            setCanSave(true)
        }).catch((error) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
            setCanSave(true)
        })
    }

    useEffect(()=>{
        loadData()
    }, [selectedDate])

    const getClients = (_search?: string) => {
        setLoadingClients(true)
        api.get("/clients/all/autocomplete", {
            headers: {
                token: user?.token
            },
            params: {
                search: _search ? _search : ""
            }
        }).then((res: { data: ClientType[] }) => {
            setLoadingClients(false)
            var _newClients: SelectType[] = []
            res.data.map(client => {
                _newClients.push({
                    content: client.name ? client.name : "Sem nome",
                    value: client.id.toString()
                })
            })
            setClients(_newClients)
        }).catch((error) => {
            setLoadingClients(false)
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const getCities = () => {
        setLoadingCities(true)
        apiCeps.get('/cidades').then((res: {data: CityType[]})=>{
            if(res){
                if(res.data){
                    let newCities: SelectType[] = []
                    res.data.map(city=>{
                        let newCity: SelectType = {value: city.id.toString(), content: city.nome}
                        newCities.push(newCity)
                    })
                    setCities([...newCities])
                    setLoadingCities(false)
                }
            }
        }).catch(err=>{
            setLoadingCities(false)
            if(err){
                if(err.response){
                    toast(err.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                }
            }
        })
    }

    useEffect(()=> {
        getClients()
        getCities()
    }, [])

    // const handleSave = () => {
    //     if(!canSave) return;

    //     setCanSave(false)
    //     api.post("/rdv/user/save", {
    //         selectedDate,
    //         sheetData,
    //         toDeleteSheet
    //     },{
    //         headers: {
    //             token: user?.token
    //         },
    //     }).then((res: { data: any }) => {

    //         toast("Dados salvos com sucesso", {
    //             type: "success",
    //             theme: darkmode ? "dark" : "light"
    //         })
    //         loadData()
    //         setCanSave(true)
    //     }).catch((error) => {
    //         if(error){
    //             if(error.response){
    //                 toast(error.response.data.error, {
    //                     type: "error",
    //                     theme: darkmode ? "dark" : "light"
    //                 })
    //             }
    //         }
    //         setCanSave(true)
    //     })
    // }

	const handleCreate = () => {
        const bodyFormData = new FormData();
		if(editData.almoco)
			bodyFormData.append("almoco", editData.almoco.toString())
		if(editData.clientId)
			bodyFormData.append("clientId", editData.clientId.toString())
		if(editData.data)
        	bodyFormData.append("data", editData.data.toString())
        if(editData.destino)
			bodyFormData.append("destino", editData.destino.toString())
        if(editData.estacionamento)
			bodyFormData.append("estacionamento", editData.estacionamento.toString())
        if(editData.selectedRDVEmpresas)
			bodyFormData.append("selectedRDVEmpresas", JSON.stringify(editData.selectedRDVEmpresas))
        if(editData.km)
			bodyFormData.append("km", editData.km.toString())
        if(editData.oportunidadeGerada)
			bodyFormData.append("oportunidadeGerada", editData.oportunidadeGerada.toString())
        if(editData.origem)
			bodyFormData.append("origem", editData.origem.toString())
        if(editData.pedagio)
			bodyFormData.append("pedagio", editData.pedagio.toString())
        if(editData.toCreatePhotosFiles){
			editData.toCreatePhotosFiles.map((photo, index) => {
				///@ts-ignore
				bodyFormData.append("toCreatePhotosFiles", photo)
			})
		}
		if(editData.id){
			bodyFormData.append("id", editData.id)
		}
        if(editData.toDeletePhotos)
			bodyFormData.append("toDeletePhotos", JSON.stringify(editData.toDeletePhotos))

		api.post('/rdv/user/create', bodyFormData, {
            headers: {
                "Content-Type": "multipart/form-data",
                token: user?.token
            }
        }).then(()=> {
            toast("Salvo com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })

			resetMenuData()
			setMenuOpen(false)
			loadData()
        }).catch((err) => {
			toastError(err, false)
        })
	}

    const handleEditData = (key: keyof RDVRowType, val: any) => {
        let _new: RDVRowType = editData
        ///@ts-ignore
        _new[key] = val
        setEditData({..._new})
    }

    useEffect(()=> {
        loadMenu()
    }, [])

    const loadMenu = () => {
        setLoadingMenu(true)
        setTimeout(()=> {
            setLoadingMenu(false)
        }, 50)
    }

    const handleAddPhoto = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }

        const selectedPhoto = e.target.files[0]
        let preview = ''
        if(selectedPhoto){
            const objectUrl = URL.createObjectURL(selectedPhoto)
            preview = objectUrl

            let _new = editData
            if(!_new.toCreatePhotos){
                _new.toCreatePhotos = []
            }

            if(!_new.toCreatePhotosFiles){
                _new.toCreatePhotosFiles = []
            }

			_new.toCreatePhotosFiles.push(selectedPhoto)

            _new.toCreatePhotos.push({
                preview: preview,
                id: `NEW-${Date.now().toString()}`
            })

            setEditData({..._new})
        }
    }

    return (
        <div className="mt-5">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 p-2">
                <h1 className="text-2xl font-bold dark:text-white">Planilha de RDV</h1>
                <Dropdown
                    button={
						<button
							type="button"
							className={`no-arrows text-xs text-white bg-blue-500 rounded w-fit py-[1px] px-2 ml-auto mr-auto`}
							disabled={menuOpen}
						>
							{selectedDate ?
								<>
									<b>{months[dayjs(selectedDate).month()]}</b> de <b>{dayjs(selectedDate).year()}</b>
								</>
								: "Clique para definir"
							}
						</button>
                    }
                    extraClassesWrapper="w-full h-10"
                    extraClassesButton="w-full h-10"
                    animation={"origin-top transition-all duration-300 ease-in-out"}
                    classNames={`top-11 right-0 w-max `}
                    children={
                    <div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                        <Calendar
                            value={selectedDate}
                            onClickMonth={e=> setSelectedDate(e)}
                            prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
                            nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
                            view={"year"}
                        />
                    </div>
                    }
                />
                <div className="flex items-center gap-5 justify-self-end">
                    <button className="py-2 px-5 rounded bg-orange-500 text-white font-bold text-sm" onClick={()=>{
						setMenuOpen(true)
					}}>Adicionar novo</button>
                </div>

            </div>
            <Card extra="mt-5 p-5">
                <div className={`fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-[10000] invisible ${selectedImage.id != undefined ? "!visible" : ""}`}>
                    <div className="absolute w-[100%] max-w-[1024px] h-[100%] max-h-[800px] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded overflow-hidden">
                        <button className="z-[2] text-red-500 font-bold absolute text-2xl top-2 right-2 w-[40px] h-[40px] bg-gray-500/50 hover:bg-gray-400/50 rounded" onClick={()=> {
                            setSelectedImage({})
                        }}>X</button>
                        <div className="">
                            <img src={selectedImage.url} alt={`ATTACH-${selectedImage.id}`} className="object-cover absolute top-0 bottom-0 left-0 right-0 h-full w-full z-[1]"/>
                        </div>
                    </div>
                </div>
                <SideMenu isOpen={menuOpen} setIsOpen={setMenuOpen} title={"Controle de RDV"} loading={loadingMenu} onConfirm={() => {
					handleCreate()
				}} onClose={()=> {
					resetMenuData()
				}}>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Data</h2>
                            <input type="datetime-local" className="h-[50px] px-2 border border-gray-600" value={dayjs(editData.data).format(`YYYY-MM-DDTHH:mm`)} onChange={e=> {
                                handleEditData(`data`, dayjs(e.target.value).toISOString())
                            }} />
                        </div>
                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Cidade de Origem</h2>
                            {/* <CitySelectorAutocomplete inputClass="h-[50px] px-2 border border-gray-600" selected={editData.origem} setVal={(e)=> {
                                handleEditData(`origem`, e)
                            }} cities={cities} id={1} /> */}

							<AsyncSelect defaultOptions cacheOptions loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = cities.filter(city=> replaceSpecialChars(city.content.toLowerCase()).includes(inputstring.toLowerCase())).map(city=>{
										let _city: any = {}
										_city.value = city.value
										_city.label = city.content
										return _city
									}).slice(0, 9)

									setTimeout(()=> {
										resolve(options)
									}, 250)
								})
							}} value={{value: editData.origem, label: cities.find(c=> c.value == editData.origem)?.content}} onChange={(e: any) => {
								handleEditData("origem", e.value)
							}} noOptionsMessage={() => "Sem dados"} placeholder={"Selecione um"} styles={selectStyle} />
                        </div>
                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Cidade de Destino</h2>
                            {/* <CitySelectorAutocomplete inputClass="h-[50px] px-2 border border-gray-600" selected={editData.destino} setVal={(e)=> {
                                handleEditData(`destino`, e)
                            }} cities={cities} id={1} /> */}

							<AsyncSelect defaultOptions cacheOptions loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = cities.filter(city=> replaceSpecialChars(city.content.toLowerCase()).includes(inputstring.toLowerCase())).map(city=>{
										let _city: any = {}
										_city.value = city.value
										_city.label = city.content
										return _city
									}).slice(0, 9)

									setTimeout(()=> {
										resolve(options)
									}, 250)
								})
							}} value={{value: editData.destino, label: cities.find(c=> c.value == editData.destino)?.content}} onChange={(e: any) => {
								handleEditData("destino", e.value)
							}} noOptionsMessage={() => "Sem dados"} placeholder={"Selecione um"} styles={selectStyle} />
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="flex items-center gap-2">
                                <h2 className="text-md font-bold text-gray-800">Empresa(s) visitada(s)</h2>
								<FcInfo size={16} title="Lembre-se, o cliente deve estar cadastrado em nossa plataforma" />
                            </div>
                            {/* <ClientSheetAutocomplete inputClass="h-[50px] px-2 border border-gray-600" selected={editData.clientId} setVal={e=> {
                                handleEditData('clientId', e)
                            }} clients={clients} id={1} /> */}
							<AsyncSelect defaultOptions cacheOptions loadOptions={(inputstring) => {
								return new Promise((resolve, reject) => {
									const options = clients.filter(client=> replaceSpecialChars(client.content.toLowerCase()).includes(inputstring.toLowerCase())).map(client=>{
										let _client: any = {}
										_client.value = client.value
										_client.label = client.content
										return _client
									}).slice(0, 9)

									setTimeout(()=> {
										resolve(options)
									}, 250)
								})
							}} value={editData.selectedRDVEmpresas} isMulti onChange={(e: any) => {
								handleEditData("selectedRDVEmpresas", e)
							}} noOptionsMessage={() => "Sem dados"} placeholder={"Selecione um"} styles={selectStyle} />
                        </div>
                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">KM Rodado</h2>
                            <CustomInputV2 type="number" totalWidth={500} suffix="KM" value={editData.km} setValue={(e) => {
                                handleEditData('km', e)
                            }} containerClass="h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"/>
                        </div>

                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Pedágio</h2>
                            <CustomInputV2 type="currency" totalWidth={500} value={editData.pedagio} setValue={(e) => {
                                handleEditData('pedagio', e)
                            }} containerClass="h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"/>
                        </div>

                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Almoço</h2>
                            <CustomInputV2 type="currency" totalWidth={500} value={editData.almoco} setValue={(e) => {
                                handleEditData('almoco', e)
                            }} containerClass="h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"/>
                        </div>
                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Estacionamento</h2>
                            <CustomInputV2 type="currency" totalWidth={500} value={editData.estacionamento} setValue={(e) => {
                                handleEditData('estacionamento', e)
                            }} containerClass="h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"/>
                        </div>

						<div className="flex flex-col gap-1">
							<h2 className="text-md font-bold text-gray-800">Oportunidade Gerada</h2>
							<textarea className="h-[150px] px-2 py-2 border border-gray-600" value={editData.oportunidadeGerada} onChange={e=> {
								handleEditData(`oportunidadeGerada`, e.target.value)
							}} />
						</div>
                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Status</h2>
                            <h2 className="text-md text-gray-800">{RDVPaymentStatus[editData.status] ? RDVPaymentStatus[editData.status] : RDVPaymentStatus[0]}</h2>
                        </div>

                        <div className="flex flex-col gap-1">
                            <h2 className="text-md font-bold text-gray-800">Anexos</h2>
                            <div className="grid grid-cols-3 gap-5">
                                {editData.rdvUploads?.map(attach=> {
                                    return(
                                        <div key={attach.id} className="w-full object-cover rounded relative group">
                                            <button className="text-red-500 font-bold absolute text-2xl top-2 right-2 w-[40px] h-[40px] bg-gray-200/50 rounded invisible group-hover:visible" onClick={()=> {
                                                let _new = editData
                                                _new.rdvUploads = _new.rdvUploads.filter(photo => photo.id != attach.id)

												if(!_new.toDeletePhotos){
													_new.toDeletePhotos = []
												}

												_new.toDeletePhotos.push(attach.id.toString())
                                                setEditData({..._new})
                                            }}>X</button>
                                            <img src={api.defaults.baseURL + "/uploads/rdv/" + attach.fileName} alt={`Attach ${attach.id}`} className="cursor-pointer w-full h-[100px] object-cover" onClick={()=> {
                                                setSelectedImage({
                                                    id: attach.id,
                                                    url: api.defaults.baseURL + "/uploads/rdv/" + attach.fileName
                                                })
                                            }} />
                                        </div>
                                    )
                                })}
                                {editData.toCreatePhotos?.map(attach=> {
                                    return(
                                        <div key={attach.id} className="w-full object-cover rounded relative group">
                                            <button className="text-red-500 font-bold absolute text-2xl top-2 right-2 w-[40px] h-[40px] bg-gray-200/50 rounded invisible group-hover:visible" onClick={()=> {
                                                let _new = editData
                                                _new.toCreatePhotos = _new.toCreatePhotos.filter(photo => photo.id != attach.id)
                                                setEditData({..._new})
                                            }}>X</button>
                                            <img src={attach.preview} alt={`Attach ${attach.id}`} className="cursor-pointer w-full h-[100px] object-cover" onClick={()=> {
                                                setSelectedImage({
                                                    id: attach.id,
                                                    url: attach.preview
                                                })
                                            }} />
                                        </div>
                                    )
                                })}

                                <label className="w-full rounded flex items-center justify-center border border-dotted border-gray-400 cursor-pointer h-[100px]" htmlFor="photo" title="Adicionar arquivo">
                                    <span className="text-gray-800 font-bold text-2xl top-2 right-2 w-[40px] h-[40px] !min-h-[40px] bg-gray-200/50 rounded-full text-2xl flex items-center justify-center">+</span>
                                </label>

                                <input type="file" onChange={e=>{
                                    handleAddPhoto(e)
                                }} accept="image/png, image/gif, image/jpeg" id="photo" hidden/>
                            </div>
                        </div>
                    </div>
                </SideMenu>


				<table className="w-full text-sm text-left">
					<thead className="text-xs text-gray-700 uppercase bg-gray-50">
						<tr>
							<th scope="col" className="px-6 py-3">Data</th>
							<th scope="col" className="px-6 py-3">Cidade de Origem</th>
							<th scope="col" className="px-6 py-3">Cidade de Destino</th>
							<th scope="col" className="px-6 py-3">Empresa(s) Visitada(s)</th>
							<th scope="col" className="px-6 py-3">Valor total</th>
						</tr>
					</thead>

					<tbody>
						{data.map(item=> (
							<tr key={item.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer" onClick={()=>{
								if(!menuOpen){
									setMenuOpen(true)
									setEditData({...item})
								}
							}}>
								<td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap relative group">
									<button className="px-2 py-1 absolute right-0 top-2 border bg-white rounded-md invisible group-hover:visible">Editar</button>
									{dayjs(item.data).format("DD/MM/YYYY HH:mm")}
								</td>
								<td className="px-6 py-4 relative group">
									<button className="px-2 py-1 absolute right-0 top-2 border bg-white rounded-md invisible group-hover:visible">Editar</button>
									{cities.find(city=> item.origem == city.value)?.content || "Não encontrado"}
								</td>
								<td className="px-6 py-4 relative group">
									<button className="px-2 py-1 absolute right-0 top-2 border bg-white rounded-md invisible group-hover:visible">Editar</button>
									{cities.find(city=> item.destino == city.value)?.content || "Não encontrado"}
								</td>
								<td className="px-6 py-4 relative group">
									<button className="px-2 py-1 absolute right-0 top-2 border bg-white rounded-md invisible group-hover:visible">Editar</button>
									{item.rdvEmpresas.length > 0 ? 
                                        <Text noOfLines={1} w={240} title={item.rdvEmpresas.map((e, index)=> e.empresa.name).toString()}>{item.rdvEmpresas.map((e, index)=> index > 0 ? ", " + e.empresa.name : e.empresa.name)}</Text>
                                    : "Não encontrado"}
								</td>
								<td className="px-6 py-4 relative group">
									<button className="px-2 py-1 absolute right-0 top-2 border bg-white rounded-md invisible group-hover:visible">Editar</button>
									{formatToCurrency(item.almoco) || "Não encontrado"}
								</td>
							</tr>
						))}
					</tbody>

				</table>
            </Card>
        </div>
    )
}

export default RDVSheet