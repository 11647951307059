import { ChakraProvider, ColorModeProvider, ColorModeScript, Flex, FocusLock, FormControl, FormLabel, Input, Popover, PopoverContent, PopoverTrigger, Tooltip, useDisclosure } from "@chakra-ui/react";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from "@choc-ui/chakra-autocomplete";
import { VirtualizedGrid } from "@mierak/react-virtualized-grid";
import { useEffect, useRef, useState } from "react";
import { BrasilAPIBanco, SelectType, SelectTypePhoto } from "types/app";
import { AutoSizer, CellMeasurer, CellMeasurerCache, CellMeasurerProps, List, ListRowRenderer } from 'react-virtualized'
import api from "services/api";
import avatar from "assets/img/avatars/default-profile.png";
import { toastError } from "components/toasts/toastError";
import { toast } from "react-toastify";
import { padWithLeadingZeros } from "helpers/conversions";

interface BankSelectorType{
    label?: string,
    id?: any,
	data: BrasilAPIBanco[],
    placeholder?: string
    disabled?: boolean,
    className?: string
    inputClass?: string
    loading?: boolean
    key?: string
    val: number
    setVal: (val: any) => any,
	setBank: (val: any) => any,
	defaultValue?: number[]
}

const cache = new CellMeasurerCache({
	fixedWidth: true,
	defaultHeight: 60
});

const BankSelector = ({data, label, id = "funcbankselector", placeholder, disabled, inputClass, className, key, val, setVal, setBank} : BankSelectorType) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [search, setSearch] = useState('')
    const ref = useRef(null)
    const [filteredData, setFilteredData] = useState<typeof data>([])
    const [loaded, setLoaded] = useState(false)

	const listRef = useRef<List>(null)

    useEffect(()=> {
        if(!loaded && data.length > 0){
			if(val !== undefined && val !== null){
				const bank = data.find(_bank => _bank.code == val)
				if(bank){
					setSearch(`${padWithLeadingZeros(bank.code, 3)} - ${bank.name}`)
				}
			}

			setTimeout(()=>{
				setLoaded(true)
			}, 100)
        }
    }, [data])

    useEffect(()=> {
        setFilteredData(data.filter((_data)=> {
			var paddedNumber: string | number = _data.code
			if(_data.code){
				paddedNumber = padWithLeadingZeros(paddedNumber, 3)
			}
			return _data.name?.toLowerCase().includes(search.toLowerCase()) || _data.code?.toString().includes(search) || paddedNumber.toString().includes(search) || _data.fullName.toLowerCase().includes(search.toLowerCase())
		}))
		cache.clearAll()
    }, [data, search])

	useEffect(()=>{
		if(loaded)
			if(search.length > 0){
				if(!isOpen){
					onOpen()
				}
			}
	}, [search])

    const handleSelect = (item: typeof data[0]) => {
		setSearch(`${padWithLeadingZeros(item.code, 3)} - ${item.name}`)
		setBank(`${padWithLeadingZeros(item.code, 3)} - ${item.name}`)
		setVal(item.code)
    }

    const handleOutsideClick = (e: any) => {
		const mainRef = e.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
		const inputRef = e.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode
        if(e.target?.parentNode != ref.current && e.target != ref.current
		&& mainRef != ref.current && mainRef?.parentNode != ref.current && inputRef != ref.current){
            onClose()
        }
    }

    useEffect(()=> {
        document.addEventListener("mousedown", handleOutsideClick)
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    })

    const rowRenderer: ListRowRenderer = ({
        key, // Unique key within array of rows
        index, // Index of row within collection
		parent,
		columnIndex,
		style
    }) => {
        return(
			<CellMeasurer
				key={key}
				cache={cache}
				parent={parent}
				rowIndex={index}
				columnIndex={columnIndex}>

				{({registerChild}) => (
					 <div ref={registerChild} style={style}>
						<div onClick={() => handleSelect(filteredData[index])} className="dark:text-white p-2 hover:bg-gray-50 hover:dark:bg-navy-800 cursor-pointer flex items-center gap-2">
							{padWithLeadingZeros(filteredData[index].code, 3)} - {filteredData[index].name}
						</div>
					</div>
				)}
			</CellMeasurer>

        )
    }
    return(
        <>
            <div className={`${className}`} ref={ref} key={key}>
                {label && <label
                    htmlFor={id}
                    className={`text-sm text-navy-700 dark:text-white "ml-3 font-bold"}`}
                >
                    {label}
                </label> }
                <Popover isOpen={isOpen} onClose={onClose} onOpen={()=>{
					setTimeout(()=>{
						cache.clearAll()
						listRef.current.recomputeRowHeights()
					}, 100)
					onOpen()
				}} closeOnEsc={true} placement="bottom" autoFocus={false} matchWidth>
                    <PopoverTrigger>
						<Input
							disabled={disabled || !loaded}
							id={id}
							value={search}
							placeholder={placeholder}
							autoComplete="off"
							onChange={e=> setSearch(e.target.value)}
							className={`${inputClass} w-full cursor-default`}
						/>
                    </PopoverTrigger>
                    <PopoverContent autoFocus={false} className="!w-full">
                        <div className="bg-white dark:bg-navy-900 border border-gray-200 dark:border-navy-700 shadow-xl h-[200px] w-full rounded">
							{/* <div className="p-2 flex items-center">
								<input
									disabled={disabled}
									id={id}
									value={search}
									onChange={e=> setSearch(e.target.value)}
									placeholder={placeholder}
									autoComplete="off"
									className={`${inputClass} h-[30px] w-full bg-gray-200 dark:bg-navy-800 p-2`}
								/>
							</div> */}
							<AutoSizer>
								{
									 ({ width, height }) => (
										<List
											ref={listRef}
											height={height}
											width={width}
											rowHeight={cache.rowHeight}
											deferredMeasurementCache={cache}
											rowCount={filteredData.length}
											rowRenderer={rowRenderer}
										/>
									)
								}
							</AutoSizer>
                            {/* <ul>
                            {!loading ? clients.filter(client=> client.content.toLowerCase().includes(search.toLowerCase())).map(client=>(
                                <li key={"CLIENTS-"+client.value} onClick={() => handleSelect(client)} className="dark:text-white p-2 hover:bg-gray-50 hover:dark:bg-navy-800 cursor-pointer">{client.content}</li>
                            )) : <span className="dark:text-white">
                                Carregando...
                            </span>}
                            </ul> */}
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
        </>
    )
}

export default BankSelector