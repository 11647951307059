//@ts-nocheck
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import * as React from "react";

import globalStyles from "./styles/global";
import normalStyles from "./styles/normalStyles";
import api from "services/api";
import { ContentType, PDFPage } from "types/app";
import path from 'path'
import CalibriBold from '../../../../assets/fonts/calibri-bold.ttf'
import Calibri from '../../../../assets/fonts/calibri.ttf'

interface PDFViewerType {
  page: PDFPage;
  contents: ContentType[];
}

Font.register({ family: 'CalibriBold', fonts: [
  {
    src: CalibriBold
  }
] });
Font.register({ family: 'Calibri', fonts: [
  {
    src: Calibri
  }
] });

const PDFPageViewer: React.FC<PDFViewerType> = ({ page, contents }) => {
  return (
    <Document author="Pro IT">
      <Page
        size="A4"
        style={[
          {
            padding: 0,
            margin: 0,
            position: "relative",
            fontFamily: "CalibriBold"
          },
        ]}
        wrap={true}
        fixed
      >
        <Image src={api.defaults.baseURL+'/uploads/'+page?.pagePath} style={globalStyles.bgImage} fixed />
        <View style={[]} wrap={true}>
          {contents.map((item, id) => (
            <View key={"VIEW-"+id}>
              <View style={{
                position:   "absolute",
                left:             item.x,
                top:              item.y,
                width:            item.width,
                height:           item.height,
                backgroundColor:  item.ghost,
                zIndex: 2,
                fontFamily: "CalibriBold"
              }}>
                <Text> </Text>
              </View>
              <Text
                style={[
                  normalStyles.text,
                  {
					  textIndent: item.textIndent,
                      position:   "absolute",
                      left:       item.x,
                      top:        item.y,
                      width:      item.width,
                      height:     item.height,
                      fontSize:   item.fontSize,
                      textAlign:  item.textAlign,
					  fontWeight: item.fontFace ? "bold" : "normal",
                      zIndex: 1,
                      fontFamily: item.fontFace ? "CalibriBold" : "Calibri"
                  },

                ]}
              >
                {item.content}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PDFPageViewer;
