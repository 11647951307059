import SideMenu from "components/sidemenu"
import { useEffect, useState } from "react"
import FinanceiroServicosTable from "./FinanceiroServicosTable"
import { NFItemCodigo } from "types/app"
import { IoAdd } from "react-icons/io5"
import CustomInputV2 from "components/custom-input-2"
import { toastError, toastErrorMsg } from "components/toasts/toastError"
import api from "services/api"
import { useAppSelector } from "redux/hooks"
import { toast } from "react-toastify"


const FinanceiroServicosCard = () => {
	const user = useAppSelector(selector=> selector.user.user)
	const [openNew, setOpenNew] = useState(false)

	const [loading, setLoading] = useState(true)
	const [data, setData] = useState<NFItemCodigo[]>([])

	const [loadingNew, setLoadingNew] = useState(false)
	const [newData, setNewData] = useState<NFItemCodigo>({

	})

	const [loadingEdit, setLoadingEdit] = useState(true)
	const [editData, setEditData] = useState<NFItemCodigo>({

	})

	const [openEdit, setOpenEdit] = useState(false)

	const handleCreateService = () => {
		if(!newData.codigo.includes(".")){
			return toastErrorMsg("Código inválido", false)
		}
		if(newData.item_lista_servico.includes(".")){
			return toastErrorMsg("Item inválido", false)
		}

		api.post<any, any, NFItemCodigo>('/nfsitens/new', newData, {
			headers: {
                token: user?.token
            }
		}).then(res=> {
			toast("Item criado com sucesso", {
				type: "success",
			})
			loadData()
			setOpenNew(false)
		}).catch(err=> {
			toastError(err, false)
		})
	}
	const handleEditService = () => {
		if(!editData.codigo.includes(".")){
			return toastErrorMsg("Código inválido", false)
		}
		if(editData.item_lista_servico.includes(".")){
			return toastErrorMsg("Item inválido", false)
		}

		api.put<any, any, NFItemCodigo>('/nfsitens/update/'+editData.id, editData, {
			headers: {
                token: user?.token
            }
		}).then(res=> {
			toast("Item editado com sucesso", {
				type: "success",
			})
			loadData()
			setOpenEdit(false)
		}).catch(err=> {
			toastError(err, false)
		})
	}

	const resetNewService = () => {
		setNewData({})
	}
	const resetEditService = () => {
		setEditData({})
	}

	const loadData = () => {
		setLoading(true)

		api.get("/nfsitens/list", {
			headers: {
				token: user?.token
			}
		}).then(res=> {
			setData([...res.data])
            setLoading(false)
		}).catch(err=> {
			toastError(err, false)
            setLoading(false)
		})
	}

	const handleEditNewData = (key: keyof typeof newData, newVal: any) => {
		setNewData({
            ...newData,
            [key]: newVal
        })
	}
	const handleEditEditData = (key: keyof typeof editData, newVal: any) => {
		setEditData({
			...editData,
            [key]: newVal
		})
	}

	useEffect(()=> {
		loadData()
	}, [])

	return (
		<>
			<SideMenu isOpen={openNew} setIsOpen={setOpenNew} title={"Novo serviço fiscal"} loading={loadingNew} onConfirm={() => {
					handleCreateService()
				}} onClose={()=> {
					resetNewService()
				}}>
				<div className="flex flex-col gap-5">
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Item</h2>
						<input type="text" className="h-[50px] px-2 border border-gray-600" onBlur={e=> {
							if(e.target.value)
								handleEditNewData(`item_lista_servico`, e.target.value)
						}} />
						<p className="text-sm text-gray-600">Exemplo: 0107</p>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Código</h2>
						<input type="text" className="h-[50px] px-2 border border-gray-600" onBlur={e=> {
							if(e.target.value)
								handleEditNewData(`codigo`, e.target.value)
						}} />
						<p className="text-sm text-gray-600">Exemplo: 01.07.00</p>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Alíquota</h2>
						<CustomInputV2 type="number" suffix="%" totalWidth={500} value={newData.aliquota} setValue={(e) => {
							handleEditNewData('aliquota', e)
						}} containerClass="h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"/>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Descrição</h2>
						<textarea className="min-h-[150px] max-h-[250px] px-2 border border-gray-600 p-2" onBlur={e=> {
							if(e.target.value)
								handleEditNewData(`descricao`, e.target.value)
						}} />
					</div>
				</div>
			</SideMenu>
			<SideMenu isOpen={openEdit} setIsOpen={setOpenEdit} title={"Editar serviço fiscal"} loading={loadingEdit} onConfirm={() => {
					handleEditService()
				}} onClose={()=> {
					resetEditService()
				}}>
				<div className="flex flex-col gap-5">
					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Item</h2>
						<input type="text" className="h-[50px] px-2 border border-gray-600" defaultValue={editData.item_lista_servico} onBlur={e=> {
							if(e.target.value)
								handleEditEditData(`item_lista_servico`, e.target.value)
						}} />
						<p className="text-sm text-gray-600">Exemplo: 0107</p>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Código</h2>
						<input type="text" className="h-[50px] px-2 border border-gray-600" defaultValue={editData.codigo} onBlur={e=> {
							if(e.target.value)
								handleEditEditData(`codigo`, e.target.value)
						}} />
						<p className="text-sm text-gray-600">Exemplo: 01.07.00</p>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Alíquota</h2>
						<CustomInputV2 type="number" suffix="%" totalWidth={500} value={editData.aliquota} setValue={(e) => {
							handleEditEditData('aliquota', e)
						}} containerClass="h-[50px] px-2 border border-gray-600 w-full" inputClass="w-full outline-none h-[50px]"/>
					</div>

					<div className="flex flex-col gap-1">
						<h2 className="text-md font-bold text-gray-800">Descrição</h2>
						<textarea className="min-h-[150px] max-h-[250px] px-2 border border-gray-600 p-2" defaultValue={editData.descricao} onBlur={e=> {
							if(e.target.value)
								handleEditEditData(`descricao`, e.target.value)
						}} />
					</div>
				</div>
			</SideMenu>

			<div className="mt-5">
				<div className="flex justify-between mb-5">
					<h2 className="text-lg font-bold text-navy-700 dark:text-white">
						Itens e Serviços
					</h2>
					<button type={"button"} onClick={()=> setOpenNew(true)} className="text-sm ml-auto text-white rounded !font-bold !hover:font-bold hover:text-white bg-blue-500 p-2 flex items-center gap-2"><IoAdd /> Adicionar Item</button>
				</div>

				<FinanceiroServicosTable loading={loading} setLoadingEditData={setLoadingEdit} setEditData={setEditData} setOpenEditData={setOpenEdit} reloadTable={loadData} tableData={data} />
			</div>
		</>
	)
}

export default FinanceiroServicosCard