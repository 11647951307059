import {
	ChakraProvider,
	FormControl,
	FormLabel,
	Input,
	useDisclosure
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { useNavigate, useParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import SalesComissionCard from "./components/SalesComissions/SalesComissionCard";
import Switch from "components/switch";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import CurrencyInput from "react-currency-input-field";
import { PiFloppyDiskBold } from "react-icons/pi";
import ContractComissionCard from "./components/ContractComissions/ContractComissionCard";
import ExpensesCard from "./components/Expenses/ExpensesCard";
import Dropdown from '../components/FuncionariosDropdown'
import { BsThreeDots } from "react-icons/bs";
import Calendar from "react-calendar";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import CustomInput from "components/custom-input";
import RDVCard from "./components/RDV/RDVCard";
import DropdownComponent from "components/dropdown";
import ControleFeriasCard from "./components/ControleFerias/ControleFeriasCard";
import { FeriasType, Operacao } from "types/app";
import { toastError } from "components/toasts/toastError";
import BancosFuncCard from "./components/BancosFunc/BancosFuncCard";
import ComissionsCard from "./components/ComissionsCard";
import { AsyncSelect, GroupBase, OptionBase, OptionsOrGroups, SelectComponentsConfig, chakraComponents } from "chakra-react-select";
import OperacoesCard from "./components/OperacoesCard";

export interface ViewFuncionarioType{
    salary?: number;
	architectId?: number,
    name?: string;
	funcional?: string,
	dataInicioEmpresa?: string,
	dataSaidaEmpresa?: string,
	modalidadeContratacao?: number,
	funcao?: string,
	cbo?: string,
	adiantamento?: boolean,
	percAdiantamento?: number,
	goal?: number,
	salesGoal?: number,
	operacaoId?: number,

	architectComission?: number,
	architectComissionGoalArrived?: number,

	salesComission?: number,
	salesComissionGoalArrived?: number,
	salesComissionSpecial?: number

	presellComission?: number,
	presellComissionGoalArrived?: number

	participantComission?: number,

	contractComission?: number,
	userOperacoes?: number[]
}

const ViewFuncionario = () => {
	const { id } = useParams()
	// const darkmode = useAppSelector((state) => state.layout.darkMode)
	const darkmode = false
	const user = useAppSelector((state) => state.user.user)
	const [searchDate, setSearchDate] = useState(dayjs().startOf('month').toDate())
	const [operacoes, setOperacoes] = useState<Operacao[]>([])

    const [selectedImage, setSelectedImage] = useState<{
        id?: number | string,
        url?: string,
    }>({})

	const { isOpen, onOpen, onClose } = useDisclosure();

    const [data, setData] = useState<ViewFuncionarioType>({
        name: '',
        salary: 0,
		architectId: -1,
		funcional: "",
		dataInicioEmpresa: undefined,
		dataSaidaEmpresa: undefined,
		modalidadeContratacao: -1,
		funcao: "",
		cbo: "",
		adiantamento: false,
		percAdiantamento: 0,
		goal: 0,
		salesGoal: 0,
		operacaoId: 0,

		architectComission: 0,
		architectComissionGoalArrived: 0,

		salesComission: 0,
		salesComissionGoalArrived: 0,
		salesComissionSpecial: 0,

		presellComission: 0,
		presellComissionGoalArrived: 0,

		participantComission: 0,

		contractComission: 0,
		userOperacoes: [],
    })

	useEffect(() => {
		getOperacoes()
		api.get('/funcionarios/view/' + id, {
			headers: {
				token: user?.token
			}
		}).then((res: { data: ViewFuncionarioType }) => {
            setData(res.data)
		})
	}, [])

	const getOperacoes = () => {
		api.get('/operacoes/autocomplete', {
			headers: {
				token: user?.token
			}
		}).then(res => {
			setOperacoes(res.data)
		})
	}

	const loadCBO = () => {
		api.get('/cbos/show/' + data.cbo, {
			headers: {
				token: user?.token
			}
		}).then((res: {data: {code?: number, desc?: string}}) => {
			if(res){
				if(res.data){
					const _data = data
					_data["funcao"] = res.data.desc

					setData({..._data})
				}
			}
		}).catch(err=> {
			toastError(err, darkmode)
		})
	}

	const handleSearchCBO = () => {
		if(data.cbo.length > 0){
			loadCBO()
		}
	}

	const handleChangeInput = (key: keyof (ViewFuncionarioType), val: React.ChangeEvent<HTMLInputElement>) => {
		setData({ ...data, [key]: val.target.value })
	}
	const handleChangeValue = (key: keyof (ViewFuncionarioType), val: any) => {
		const _data: any = data
		_data[key] = val

		setData({ ..._data })
	}

	const handleSaveSalary = () => {
		api.put<ViewFuncionarioType>('/user/salary/' + id, {
			salary: data.salary,
			funcional: data.funcional,
			dataInicioEmpresa: data.dataInicioEmpresa,
			modalidadeContratacao: data.modalidadeContratacao,
			dataSaidaEmpresa: data.dataSaidaEmpresa,
			funcao: data.funcao,
			cbo: data.cbo,
			adiantamento: data.adiantamento,
			percAdiantamento: data.percAdiantamento,
			goal: data.goal,
			salesGoal: data.salesGoal,

			architectComission: data.architectComission,
			architectComissionGoalArrived: data.architectComissionGoalArrived,
			operacaoId: data.operacaoId
		}, {
			headers: {
				token: user?.token
			}
		}).then(() => {
			toast("Dados salvos com sucesso!", {
				type: "success",
				theme: darkmode ? "dark" : "light"
			})

		}).catch((error: ErrorData) => {
			toast(error.response.data.error, {
				type: "error",
				theme: darkmode ? "dark" : "light"
			})
		})
	}

	const asyncComponents: Partial<SelectComponentsConfig<unknown, true, GroupBase<unknown>>> = {
		LoadingIndicator: (props) => (
		  <chakraComponents.LoadingIndicator
			// The color of the main line which makes up the spinner
			// This could be accomplished using `chakraStyles` but it is also available as a custom prop
			color="currentColor" // <-- This default's to your theme's text color (Light mode: gray.700 | Dark mode: whiteAlpha.900)
			// The color of the remaining space that makes up the spinner
			emptyColor="transparent"
			// The `size` prop on the Chakra spinner
			// Defaults to one size smaller than the Select's size
			spinnerSize="md"
			// A CSS <time> variable (s or ms) which determines the time it takes for the spinner to make one full rotation
			speed="0.45s"
			// A CSS size string representing the thickness of the spinner's line
			thickness="2px"
			// Don't forget to forward the props!
			{...props}
		  />
		),
	  };

	interface ColorOption extends OptionBase {
		label: string;
		value: string;
	}

	const colorOptions: ColorOption[] = [
		{
			label: "Red",
			value: "red",
			colorScheme: "red", // This is allowed because of the key in the `OptionBase` type
		},
		{
			label: "Blue",
			value: "blue",
		}
	]

	return (
		<>
		
			<div className={`fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-[10000] invisible ${selectedImage.id != undefined ? "!visible" : ""}`}>
                    <div className="absolute w-[100%] max-w-[1024px] h-[100%] max-h-[800px] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded overflow-hidden">
                        <button className="z-[2] text-red-500 font-bold absolute text-2xl top-2 right-2 w-[40px] h-[40px] bg-gray-500/50 hover:bg-gray-400/50 rounded" onClick={()=> {
                            setSelectedImage({})
                        }}>X</button>
                        <div className="">
                            <img src={selectedImage.url} alt={`ATTACH-${selectedImage.id}`} className="object-cover absolute top-0 bottom-0 left-0 right-0 h-full w-full z-[1]"/>
                        </div>
                    </div>
                </div>
			<div className="mt-5 flex relative w-full p-5">
				<Dropdown className="!absolute top-0 right-0" menus={[
					{
						children: <div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
						<Calendar
							value={searchDate}
							onClickMonth={e=> setSearchDate(dayjs(e).startOf('month').toDate())}
							prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
							nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
							view={"year"}
						/>
					</div>
					}
				]}/>
			</div>
			<form className="h-full justify-center md:justify-start relative flex flex-wrap flex-col gap-5 mt-5 pt-5 h-full w-full bg-white shadow-3xl shadow-shadow-600 border border-gray-200 dark:!bg-navy-800 rounded-xl px-5 py-2 pb-5">
				<div className="flex flex-wrap items-center gap-5">
					<div className="flex w-full md:col-span-2 justify-between absolute top-5 right-5">
						<button onClick={handleSaveSalary} type="button" className="ml-auto flex items-center justify-center cursor-pointer text-lg w-[40px] min-w-[40px] min-h-[40px] h-[40px] rounded-full p-2 bg-orange-500 text-white dark:bg-orange-500">
							<PiFloppyDiskBold />
						</button>
					</div>

					<h2 className="text-lg font-bold text-navy-700 dark:text-white">
						Informações do Funcionário
					</h2>
					<div className="mt-2 flex flex-wrap gap-5">
						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							{/* <Input value={data.salary} onChange={e => handleChangeValue('salary', e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="CNPJ da empresa" /> */}
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Colaborador:
								</p>
								<Input value={data.name} disabled type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2" />
							</div>
						</FormControl>
						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							{/* <Input value={data.salary} onChange={e => handleChangeValue('salary', e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="CNPJ da empresa" /> */}
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Funcional:
								</p>
								<Input value={data.funcional} onChange={e=> handleChangeValue("funcional", e.target.value)} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2" />
							</div>
						</FormControl>
						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							{/* <Input value={data.salary} onChange={e => handleChangeValue('salary', e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="CNPJ da empresa" /> */}
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									CBO:
								</p>
								<Input value={data.cbo} onBlur={()=> {
									handleSearchCBO()
								}} onChange={e=> handleChangeValue("cbo", e.target.value)} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2" />
							</div>
						</FormControl>
						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							{/* <Input value={data.salary} onChange={e => handleChangeValue('salary', e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="CNPJ da empresa" /> */}
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Função:
								</p>
								<Input value={data.funcao || ""} onChange={()=> {}} type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2" />
							</div>
						</FormControl>

						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							{/* <Input value={data.salary} onChange={e => handleChangeValue('salary', e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="CNPJ da empresa" /> */}
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Operação:
								</p>
								<select style={{
									colorScheme: darkmode ? 'dark' : 'light'
								}} value={data.operacaoId} onChange={e=> handleChangeValue("operacaoId", e.target.value)} placeholder="Selecione a Operação" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2">
									<option value="" disabled selected hidden>Escolha a operação</option>
									{operacoes.map(operacao=>(
										<option value={operacao.id}>{operacao.name}</option>
									))}
								</select>
								{/* <ChakraProvider>
									<AsyncSelect
										isMulti
										name="operacoes"
										placeholder="Selecione as operações"
										components={asyncComponents}
										defaultOptions={colorOptions}
										noOptionsMessage={()=> "Nada encontrado"}
										loadingMessage={()=> "Carregando..."}
										loadOptions={(inputValue, callback) => {
											var values: any[] = colorOptions
											setTimeout(()=> {
												callback(values)
											}, 2000)
										}}
									/>
								</ChakraProvider> */}
							</div>
						</FormControl>

						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							{/* <Input value={data.salary} onChange={e => handleChangeValue('salary', e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="CNPJ da empresa" /> */}
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Data de início na empresa:
								</p>


								<DropdownComponent
									button={
										<button
											type="button"
											className={`no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2`}
										>
											{data.dataInicioEmpresa ? dayjs(data.dataInicioEmpresa).format("DD/MM/YYYY") : "Clique para definir"}
										</button>
									}
									extraClassesWrapper="w-full h-10"
									extraClassesButton="w-full h-10"
									animation={"origin-top-right transition-all duration-300 ease-in-out"}
									classNames={`top-11 right-0 w-max `}
									children={
									<div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
										<Calendar
											value={data.dataInicioEmpresa ? dayjs(data.dataInicioEmpresa).toDate() : undefined}
											onClickDay={e=> handleChangeValue("dataInicioEmpresa", dayjs(e).startOf("day"))}
											prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
											nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
											view={"month"}
										/>
									</div>
									}
								/>
							</div>
						</FormControl>
						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							{/* <Input value={data.salary} onChange={e => handleChangeValue('salary', e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="CNPJ da empresa" /> */}
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Data de saída da empresa:
								</p>


								<DropdownComponent
									button={
										<button
											type="button"
											className={`no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2`}
										>
											{data.dataSaidaEmpresa ? dayjs(data.dataSaidaEmpresa).format("DD/MM/YYYY") : "Clique para definir"}
										</button>
									}
									extraClassesWrapper="w-full h-10"
									extraClassesButton="w-full h-10"
									animation={"origin-top-right transition-all duration-300 ease-in-out"}
									classNames={`top-11 right-0 w-max `}
									children={
									<div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
										<Calendar
											value={data.dataSaidaEmpresa ? dayjs(data.dataSaidaEmpresa).toDate() : undefined}
											onClickDay={e=> handleChangeValue("dataSaidaEmpresa", dayjs(e).startOf("day"))}
											prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
											nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
											view={"month"}
										/>
									</div>
									}
								/>
							</div>
						</FormControl>

						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px]">
							{/* <Input value={data.salary} onChange={e => handleChangeValue('salary', e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="CNPJ da empresa" /> */}
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Modalidade de Contratação:
								</p>
								<select style={{
									colorScheme: darkmode ? 'dark' : 'light'
								}} value={data.modalidadeContratacao} onChange={e=> handleChangeValue("modalidadeContratacao", e.target.value)} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2">
									<option value={-1}>Selecione</option>
									<option value={0}>CLT</option>
									<option value={1}>PJ</option>
									<option value={2}>Estágio</option>
								</select>
							</div>
						</FormControl>

						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px] w-full">
							{/* <Input value={data.salary} onChange={e => handleChangeValue('salary', e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="CNPJ da empresa" /> */}
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Salário fixo mensal:
								</p>
								<CustomInput type="currency" containerClass="w-full" inputClass={`no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2`} value={data.salary} setValue={e=> handleChangeValue('salary', e)} suffixClass="dark:text-white"/>
								{/* <CurrencyInput
									placeholder={'R$0,00'}
									value={data.salary}
									allowDecimals={true}


									intlConfig={{locale: "pt-BR", currency: "BRL"}}
									decimalsLimit={2}
									defaultValue={isNaN(data.salary) ? 0 : data.salary}
									onValueChange={(value: string, name?: string, values?: CurrencyInputOnChangeValues)=> handleChangeValue('salary', values.value)}
									className={`no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] w-full p-2`}
								/> */}
							</div>
						</FormControl>

						<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px] w-full">
							{/* <Input value={data.salary} onChange={e => handleChangeValue('salary', e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2" placeholder="CNPJ da empresa" /> */}
							<div className="flex flex-col gap-2 w-full">
								<p className="font-bold text-sm pl-2 dark:text-white">
									Adiantamento?
								</p>
								<div className="flex items-center gap-4">
									<input type="checkbox" checked={data.adiantamento} onChange={e=> handleChangeValue("adiantamento", e.target.checked)}/>
									<CustomInput disabled={!data.adiantamento} containerClass="w-full" inputClass={`no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2 ${!data.adiantamento ? "!bg-gray-100" : ""}`} value={data.percAdiantamento} setValue={e=> handleChangeValue('percAdiantamento', e)} max={100} suffix="%" suffixClass="dark:text-white"/>
								</div>
							</div>
						</FormControl>
					</div>

				</div>

				<div className="flex items-center gap-5">
					<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px] w-full">
						<div className="flex flex-col gap-2 w-full">
							<p className="font-bold text-sm pl-2 dark:text-white">
								Meta de contrato
							</p>
							<CustomInput type="currency" containerClass="w-full" inputClass={`no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2`} value={data.goal} setValue={e=> handleChangeValue('goal', e)} suffixClass="dark:text-white"/>
						</div>
					</FormControl>


					<FormControl className="w-full flex items-end gap-2 min-w-[240px] max-w-[340px] w-full">
						<div className="flex flex-col gap-2 w-full">
							<p className="font-bold text-sm pl-2 dark:text-white">
								Meta de vendas (Rentabilidade)
							</p>
							<CustomInput type="currency" containerClass="w-full" inputClass={`no-arrows text-gray-800 dark:text-white bg-white border border-gray-300 dark:bg-navy-900 rounded-[10px] dark:bg-navy-900 w-full p-2`} value={data.salesGoal} setValue={e=> handleChangeValue('salesGoal', e)} suffixClass="dark:text-white"/>
						</div>
					</FormControl>
				</div>
			</form>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
                <div className="h-fit gap-5  justify-center mt-5 w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-4 shadow-3xl border border-gray-200 shadow-shadow-600 flex items-center w-full">
                    {/* <div className="flex items-center justify-center mt-5 h-full w-full py-2">
                        <button onClick={handleNewContact} className="linear ml-auto flex items-center justify-center rounded-xl bg-green-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
                            Adicionar contato
                        </button>
                    </div> */}
                    <div className="w-full">
                        <ComissionsCard id={id} maxH={300} minH={300} data={{
							contract: {
								comission: data.contractComission,
								setComission: (e)=> handleChangeValue("contractComission", e),
							},

							sales: {
								arrived: data.salesComissionGoalArrived,
								notArrived: data.salesComission,
								special: data.salesComissionSpecial,
								setArrived: (e)=> handleChangeValue("salesComissionGoalArrived", e),
								setNotArrived: (e)=> handleChangeValue("salesComission", e),
								setSpecial: (e)=> handleChangeValue("salesComissionSpecial", e),
							},

							architect: {
								arrived: data.architectComissionGoalArrived,
								notArrived: data.architectComission,
								setArrived: (e)=> handleChangeValue("architectComissionGoalArrived", e),
								setNotArrived: (e)=> handleChangeValue("architectComission", e),
							},

							presell: {
								arrived: data.presellComissionGoalArrived,
								notArrived: data.presellComission,
								setArrived: (e)=> handleChangeValue("presellComissionGoalArrived", e),
								setNotArrived: (e)=> handleChangeValue("presellComission", e),
							},

							participant: {
								comission: data.participantComission,
								setComission: (e)=> handleChangeValue("participantComission", e),
							},
						}}/>
                    </div>
                </div>

                <div className="h-fit gap-5  justify-center mt-5 w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-4 shadow-3xl border border-gray-200 shadow-shadow-600 flex items-center w-full">
                    {/* <div className="flex items-center justify-center mt-5 h-full w-full py-2">
                        <button onClick={handleNewContact} className="linear ml-auto flex items-center justify-center rounded-xl bg-green-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
                            Adicionar contato
                        </button>
                    </div> */}
                    <div className="w-full">
                        <OperacoesCard id={id} maxH={300} minH={300} operacoes={operacoes} userOperacoes={data.userOperacoes} setUserOperacoes={((e)=>{
							handleChangeValue("userOperacoes", e)
						})} />
                    </div>
                </div>

                <div className="h-fit md:col-span-2 justify-center mt-5 w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-4 shadow-3xl border border-gray-200 shadow-shadow-600 flex items-center w-full">
                    {/* <div className="flex items-center justify-center mt-5 h-full w-full py-2">
                        <button onClick={handleNewContact} className="linear ml-auto flex items-center justify-center rounded-xl bg-green-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
                            Adicionar contato
                        </button>
                    </div> */}
                    <div className="w-full">
                        <BancosFuncCard id={id} maxH={300} minH={300}/>
                    </div>
                </div>
                {/* <div className="h-fit md:col-span-2 justify-center mt-5 w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-4 shadow-3xl border border-gray-200 shadow-shadow-600 flex items-center w-full">
                    <div className="w-full">
                        <ControleFeriasCard id={id} maxH={300} minH={300} data={data} />
                    </div>
                </div> */}
                {/* <div className="h-fit md:col-span-2 justify-center mt-5 w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-4 shadow-3xl border border-gray-200 shadow-shadow-600 flex items-center w-full">
                    <div className="w-full">
                        <SalesComissionCard defaultArchitect={data.architectId} searchDate={searchDate} id={id} maxH={300} minH={300} />
                    </div>
                </div> */}

				<div className="flex flex-col mt-5 gap-5">
					{/* <div className="h-fit justify-center w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-4 shadow-3xl border border-gray-200 shadow-shadow-600 flex items-center w-full">
						<div className="w-full">
							<ContractComissionCard id={id} maxH={300} minH={300}/>
						</div>
					</div> */}

					<div className="h-fit justify-center w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-4 shadow-3xl border border-gray-200 shadow-shadow-600 flex items-center w-full">
						{/* <div className="flex items-center justify-center mt-5 h-full w-full py-2">
							<button onClick={handleNewContact} className="linear ml-auto flex items-center justify-center rounded-xl bg-green-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
								Adicionar contato
							</button>
						</div> */}
						<div className="w-full">
							<RDVCard searchDate={searchDate} id={id} maxH={300} minH={300}/>
						</div>
					</div>
				</div>
                <div className="h-fit justify-center mt-5 w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-4 shadow-3xl border border-gray-200 shadow-shadow-600 flex items-center w-full">
                    {/* <div className="flex items-center justify-center mt-5 h-full w-full py-2">
                        <button onClick={handleNewContact} className="linear ml-auto flex items-center justify-center rounded-xl bg-green-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
                            Adicionar contato
                        </button>
                    </div> */}
                    <div className="w-full">
                        <ExpensesCard searchDate={searchDate} id={id} maxH={300} minH={300}/>
                    </div>
                </div>
            </div>

		</>
	)
}

export default ViewFuncionario