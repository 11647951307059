import React, { useEffect, useState } from "react";
import Dropdown from "components/dropdown";
import Calendar from "react-calendar";
import { MdBarChart, MdChevronLeft, MdChevronRight } from "react-icons/md";
import WidgetDropdown from "./WidgetDropdown";

function ButtonWidget({icon, title, subtitle, searchDate, setSearchDate}: {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  searchDate: Date;
  setSearchDate: React.Dispatch<React.SetStateAction<Date>>;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <WidgetDropdown

      open={open}
      setOpen={setOpen}
      button={
        <div className="!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border dark:!bg-navy-800 dark:text-white dark:shadow-none !flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                    <span onClick={() => setOpen(false)} className="cursor-pointer flex items-center text-orange-500 dark:text-white">
                        {icon}
                    </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
            <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                {subtitle}
            </h4>
            </div>
        </div>
      }
      animation={"origin-top-right transition-all duration-300 ease-in-out"}
      classNames={`right-0 top-0 w-max`}
      children={
        <div className="z-50 w-max rounded-xl bg-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <Calendar
            value={searchDate}
            onClickMonth={e=> {
              setSearchDate(e)
            }}
            prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
            nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
            view={"year"}
          />
        </div>
      }
    />
  );
}

export default ButtonWidget;
