import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Tooltip } from "@chakra-ui/react"
import InputField from "components/fields/InputField"
import SelectField from "components/fields/SelectField"
import TooltipHorizon from "components/tooltip"
import { useEffect, useState } from "react"
import { MdDelete } from "react-icons/md"
import ParamsTable from "./components/ParamsTable"
import { ItemParam, OrderCategory, OrderItem, OrderSubcategory, PDFPage } from "types/app"
import api from "services/api"
import { useAppSelector } from "redux/hooks"
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import MoneyInput from "components/fields/MoneyInput"

const Categorys = () => {

    const [data, setData] = useState<OrderCategory[]>([])
    const [pages, setPages] = useState<{content: string, value: number}[]>([])
    const user = useAppSelector((state) => state.user.user)
    const darkmode = useAppSelector((state) => state.layout.darkMode)

    const getPages = () => {
        api.get(`/orders/config/pdf/pages/all`, {
            headers: {
                token: user?.token
            },
        }).then((res: {data: PDFPage[]})=>{
            let newPages: {content: string, value: number}[] = []
            res.data.map(page=> {
                newPages.push({
                    content: page.name,
                    value: page.id
                })
            })

            setPages(newPages)
        })
    }
    const getCategorys = () => {
        api.get("/orders/categorys", {
            headers: {
                token: user?.token
            }
        }).then((res: {data: OrderCategory[]})=> {
            setData(res.data)
        }).catch((error)=> {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const handleNewCategory = () => {
        api.post("/orders/category", {}, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            getCategorys()
            toast("Categoria criada com sucesso", {
                autoClose: 1000,
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }
    const handleEditCategory = (index: number, key: keyof OrderCategory, value: any) => {
        const newData = [...data]
        newData[index] = {
            ...newData[index],
            [key]: value
        }
        setData(newData)
    }
    const handleDeleteCategory = (category: OrderCategory) => {
        Swal.fire({
            title: "Remover Categoria?",
            text: "Você realmente deseja remover a categoria "+category.name+"?",
            icon: "question",
            confirmButtonText: "Sim",
            showCancelButton: true,
            cancelButtonText: "Não",
            background: darkmode ? "rgb(11 20 55)" : "#fff",
            color: darkmode ? "#fff" : "#222",
          }).then(response=> {
            if(response.isConfirmed){
                api.delete('/orders/category/'+category.id, {
                    headers: {
                    token: user?.token
                    }
                }).then(()=>{
                    getCategorys()
                    toast("Categoria removida com sucesso!", {
                        type: "success",
                        theme: darkmode ? "dark" : "light"
                    })
                }).catch((error)=>{
                    toast(error.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                })
            }
        })
    }
    const handleSaveCategory = (index: number, categoryId: number) => {
        api.put("/orders/category/"+categoryId, {
            name: data[index].name,
            /// @ts-ignore
            pageId: data[index].pageId != undefined ? data[index].pageId != 0 ? parseInt(data[index].pageId) : null : undefined
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Categoria salva com sucesso!", {
                autoClose: 1000,
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const handleNewSubcategory = (categoryId: number) => {
        api.post("/orders/subcategory", {
            categoryId
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            getCategorys()
            toast("Subcategoria criada com sucesso", {
                autoClose: 1000,
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }
    const handleRemoveSubcategory = (subcategory: OrderSubcategory) => {
        Swal.fire({
            title: "Remover Subcategoria?",
            text: "Você realmente deseja remover a subcategoria "+subcategory.name+"?",
            icon: "question",
            confirmButtonText: "Sim",
            showCancelButton: true,
            cancelButtonText: "Não",
            background: darkmode ? "rgb(11 20 55)" : "#fff",
            color: darkmode ? "#fff" : "#222",
          }).then(response=> {
            if(response.isConfirmed){
                api.delete('/orders/subcategory/'+subcategory.id, {
                    headers: {
                    token: user?.token
                    }
                }).then(()=>{
                    getCategorys()
                    toast("Subcategoria removida com sucesso!", {
                        type: "success",
                        theme: darkmode ? "dark" : "light"
                    })
                }).catch((error)=>{
                    toast(error.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                })
            }
        })
    }
    const handleSaveSubCategory = (categoryIndex: number, subcategoryIndex: number, subcategoryId: number) => {
        api.put("/orders/subcategory/"+subcategoryId, {
            name: data[categoryIndex].subcategorys[subcategoryIndex].name
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Subcategoria salva com sucesso!", {
                autoClose: 1000,
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }
    const handleEditSubcategory = (categoryIndex: number, subcategoryIndex: number, key: keyof OrderSubcategory, value: any) => {
        const newData = [...data]
        newData[categoryIndex].subcategorys[subcategoryIndex] = {
            ...newData[categoryIndex].subcategorys[subcategoryIndex],
            [key]: value
        }
        setData(newData)
    }

    const handleNewItem = (subcategoryId: number) => {
        api.post("/orders/item", {
            subcategoryId
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            getCategorys()
            toast("Item criado com sucesso", {
                autoClose: 1000,
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }
    const handleSaveItem = (categoryIndex: number, subcategoryIndex: number, itemIndex: number, itemId: number) => {
        api.put("/orders/item/"+itemId, {
            name: data[categoryIndex].subcategorys[subcategoryIndex].items[itemIndex].name,
            type: data[categoryIndex].subcategorys[subcategoryIndex].items[itemIndex].type,
            price: data[categoryIndex].subcategorys[subcategoryIndex].items[itemIndex].price
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Item salvo com sucesso!", {
                autoClose: 1000,
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
        }).catch(error=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }
    const handleEditItem = (categoryIndex: number, subcategoryIndex: number, itemIndex: number, key: keyof OrderItem, value: any) => {
        const newData = [...data]
        newData[categoryIndex].subcategorys[subcategoryIndex].items[itemIndex] = {
            ...newData[categoryIndex].subcategorys[subcategoryIndex].items[itemIndex],
            [key]: value
        }
        setData(newData)
    }

	const handleEditPrice = (categoryIndex: number, subcategoryIndex: number, itemIndex: number, key: keyof OrderItem, value: any) => {
		const newData = [...data]
        newData[categoryIndex].subcategorys[subcategoryIndex].items[itemIndex] = {
            ...newData[categoryIndex].subcategorys[subcategoryIndex].items[itemIndex],
            [key]: value
        }
        setData(newData)
	}
    const handleRemoveItem = (item: OrderItem) => {
        Swal.fire({
            title: "Remover Item?",
            text: "Você realmente deseja remover o item "+item.name+"?",
            icon: "question",
            confirmButtonText: "Sim",
            showCancelButton: true,
            cancelButtonText: "Não",
            background: darkmode ? "rgb(11 20 55)" : "#fff",
            color: darkmode ? "#fff" : "#222",
          }).then(response=> {
            if(response.isConfirmed){
                api.delete('/orders/item/'+item.id, {
                    headers: {
                    token: user?.token
                    }
                }).then(()=>{
                    getCategorys()
                    toast("Item removido com sucesso!", {
                        type: "success",
                        theme: darkmode ? "dark" : "light"
                    })
                }).catch((error)=>{
                    toast(error.response.data.error, {
                        type: "error",
                        theme: darkmode ? "dark" : "light"
                    })
                })
            }
        })
    }

    useEffect(()=> {
        getCategorys()
        getPages()
    }, [])
    return (
        <>
            <div className="flex items-center justify-center mt-10 h-full w-full py-2">
                <button onClick={handleNewCategory} className="linear ml-auto flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-darkorange-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
                    Nova categoria
                </button>
            </div>

            <div className="flex mt-5 flex-col gap-5 w-full py-2">
                {data.map((category, categoryIndex)=>(
                    <Accordion allowMultiple className="text-sm pl-2 bg-white shadow-xl shadow-700 dark:!bg-navy-800 bg-white rounded w-full p-4 border border-gray-200">
                        <AccordionItem className="">
                            <div className="flex items-center">
                                <AccordionButton _expanded={{}} className="text-gray-900 dark:text-white w-full">
                                    <span className="font-bold">{category.name}</span>
                                    <AccordionIcon />
                                </AccordionButton>

                                <TooltipHorizon
                                    content={<span>Remover Categoria</span>}
                                    trigger={<button type="button" className="rounded-full bg-red-500 dark:bg-cyan-900 text-white p-2" onClick={()=> handleDeleteCategory(category)}>
                                        <MdDelete className="ml-auto " />
                                    </button>}
                                    extra=""
                                    placement="top"
                                />
                            </div>

                            <AccordionPanel className="mt pb-10 ">
                                <div className="grid h-full justify-center items-center grid-cols-1 gap-5 md:grid-cols-2 h-full w-full rounded-xl px-5 py-2 " >
                                    <InputField
                                        variant="auth"
                                        extra="mb-3"
                                        label="Nome"
                                        value={category.name}
                                        onChange={(e)=> handleEditCategory(categoryIndex, "name", e.target.value)}
                                        placeholder="Nome da categoria"
                                        id="categoryName"
                                        type="text"
                                    />
                                    <div className="flex gap-5 items-center mt-0 md:mt-5 justify-center md:justify-end ">
                                        <button onClick={() => handleNewSubcategory(category.id)} className="linear w-full md:w-fit flex items-center justify-center rounded-xl bg-cyan-500 p-2 px-3 text-sm font-medium text-white transition duration-200 hover:bg-cyan-600 active:bg-cyan-700 dark:bg-cyan-800 dark:text-white dark:hover:bg-cyan-700 dark:active:bg-cyan-600">
                                            Nova subcategoria
                                        </button>
                                        <button type="button" onClick={()=> handleSaveCategory(categoryIndex, category.id)} className="linear w-full md:w-fit rounded-xl bg-brand-500 p-2 px-3 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                            Salvar categoria
                                        </button>
                                    </div>
                                    {/* <SelectField
                                        extra="mb-3"
                                        label="Página PDF da Categoria"
                                        variant="auth"
                                        placeholder="Tipo da subcategoria"
                                        id="subType"
                                        value={category.pageId}
                                        onChange={(e)=> handleEditCategory(categoryIndex, "pageId", e.target.value)}
                                        options={[{
                                            content: "Sem página",
                                            value: 0
                                        },...pages]}
                                    /> */}
                                </div>
                                {/* SUBCATEGORIA */}
                                {category.subcategorys.map((subcategory, subcategoryIndex)=>(
                                    <Accordion allowMultiple className="mt-5 text-sm pl-2 dark:text-white hover:bg-gray-50 dark:hover:bg-navy-900 rounded w-full p-4 md:px-10">
                                        <AccordionItem className="">
                                            <div className="flex items-center ">
                                                <AccordionButton _expanded={{}} className="text-gray-900 dark:text-white">
                                                    <span className="font-bold">{subcategory.name}</span>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                <TooltipHorizon
                                                    content={<span>Remover Subcategoria</span>}
                                                    trigger={<button type="button" onClick={()=> handleRemoveSubcategory(subcategory)} className="rounded-full bg-gray-900 dark:bg-blue-800 text-white p-2">
                                                        <MdDelete className="" size={12}/>
                                                    </button>}
                                                    extra=""
                                                    placement="top"
                                                />
                                            </div>
                                            <AccordionPanel className="mt pb-10 ">
                                                <div className="grid h-full justify-center items-center grid-cols-1 gap-5 md:grid-cols-2 h-full w-full rounded-xl px-5 py-2 " >
                                                    <InputField
                                                        variant="auth"
                                                        extra="mb-3"
                                                        label="Nome"
                                                        placeholder="Nome da subcategoria"
                                                        id="categoryName"
                                                        value={subcategory.name}
                                                        onChange={e=> handleEditSubcategory(categoryIndex, subcategoryIndex, "name", e.target.value)}
                                                        type="text"
                                                    />
                                                    <div className="flex gap-5 items-center justify-center md:justify-end ">
                                                        <button type="button" onClick={()=> handleNewItem(subcategory.id)} className="linear mt-0 md:mt-5 w-full md:w-fit flex items-center justify-center rounded-xl bg-gray-800 p-2 px-3 text-sm font-medium text-white transition duration-200 hover:bg-gray-700 active:bg-gray-600 dark:bg-navy-700 dark:text-white dark:hover:bg-navy-600 dark:active:bg-navy-800">
                                                            Novo item
                                                        </button>
                                                        <button type="button" onClick={()=> handleSaveSubCategory(categoryIndex, subcategoryIndex, subcategory.id)} className="linear mt-0 md:mt-5 w-full md:w-fit p-2 px-3 rounded-xl bg-gray-900 text-sm font-medium text-white transition duration-200 hover:bg-gray-800 active:bg-gray-900 dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 dark:active:bg-gray-900">
                                                            Salvar subcategoria
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* ITENS */}
                                                {subcategory.items.map((item, itemIndex)=>(
                                                    <Accordion allowMultiple className="mt-5 hover:bg-gray-50 dark:hover:bg-navy-800 text-sm pl-2 dark:text-white rounded w-full p-4 md:px-20">
                                                        <AccordionItem className="">
                                                            <div className="flex items-center ">
                                                                <AccordionButton _expanded={{}} className="text-gray-900 dark:text-white">
                                                                    <span className="font-bold">{item.name}</span>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </div>
                                                            <AccordionPanel className="mt pb-10 ">
                                                                <div className="grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 h-full w-full rounded-xl px-5 py-2 " >
                                                                    <div className="grid grid-cols-1 gap-5">
                                                                        <InputField
                                                                            variant="auth"
                                                                            extra="mb-3"
                                                                            label="Nome"
                                                                            value={item.name}
                                                                            onChange={e=> handleEditItem(categoryIndex, subcategoryIndex, itemIndex, "name", e.target.value)}
                                                                            placeholder="Nome da subcategoria"
                                                                            id="categoryName"
                                                                            type="text"
                                                                        />
                                                                        <SelectField
                                                                            extra=""
                                                                            label="Tipo"
                                                                            variant="auth"
                                                                            placeholder="Tipo do item"
                                                                            value={item.type}
                                                                            onChange={e=> handleEditItem(categoryIndex, subcategoryIndex, itemIndex, "type", e.target.value)}
                                                                            id="itemTyoe"
                                                                            options={[
                                                                                {
                                                                                    content: "Selecione um tipo para o item",
                                                                                    value: ""
                                                                                },
                                                                                {
                                                                                    content: "Quantidade",
                                                                                    value: "number"
                                                                                },
                                                                                {
                                                                                    content: "Lista",
                                                                                    value: "list"
                                                                                },
                                                                                // {
                                                                                //     content: "Sim/Não",
                                                                                //     value: "toogle"
                                                                                // }
                                                                            ]}
                                                                        />
                                                                        <div className="flex gap-5 items-center justify-center md:justify-end ">
                                                                            <button type="button" onClick={()=> handleRemoveItem(item)} className="linear mt-0 md:mt-5 w-full md:w-fit px-5 rounded-xl bg-red-900 p-2 px-3 text-sm font-medium text-white transition duration-200 hover:bg-red-800 active:bg-red-900 dark:bg-red-800 dark:text-white dark:hover:bg-red-800 dark:active:bg-red-900">
                                                                                Remover item
                                                                            </button>
                                                                            <button type="button" onClick={()=> handleSaveItem(categoryIndex, subcategoryIndex, itemIndex, item.id)} className="linear mt-0 md:mt-5 w-full md:w-fit flex items-center justify-center rounded-xl bg-gray-800 p-2 px-3 text-sm font-medium text-white transition duration-200 hover:bg-gray-900 active:bg-gray-800 dark:bg-navy-600 dark:text-white dark:hover:bg-navy-700 dark:active:bg-navy-800">
                                                                                Salvar item
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {item.type && item.type != "" && item.type != "list" && (
                                                                        <MoneyInput
                                                                            variant="auth"
                                                                            extra=""
                                                                            label="Valor"
                                                                            placeholder="Valor do item"
                                                                            id="itemValue"
                                                                            value={item.price}
                                                                            onChange={(e, name, values)=> handleEditPrice(categoryIndex, subcategoryIndex, itemIndex, "price", values.value)}
                                                                        />
                                                                    )}
                                                                    {item.type && item.type != "" && item.type == "list" && (
                                                                        <ParamsTable
                                                                            itemId={item.id}
                                                                            page={1}
                                                                            refresh={getCategorys}
                                                                            tableData={item.params}
                                                                            title="Lista de parâmetros"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    </Accordion>
                                                ))}
                                                {/* END ITENS */}
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                    ))}
                                {/* END SUBCATEGORIAS */}
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                ))}
            </div>
        </>
    )
}


{/* <InputField
    variant="auth"
    extra="mb-3"
    label="Senha*"
    placeholder="******"
    id="password"
    type="password"
/>

<SelectField
    extra="mb-3 w-80p"
    label="Tipo"
    variant="auth"
    placeholder="Tipo da subcategoria"
    id="subType"
    options={[
        {
            content: "Opt 1",
            value: "1"
        },
        {
            content: "Opt 2",
            value: "2"
        }
    ]}
/> */}
export default Categorys