import { Select } from "@chakra-ui/react";
import { toastError } from "components/toasts/toastError";
import { useEffect, useState } from "react"
import { FiSearch } from "react-icons/fi";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ContractType, UserType } from "types/app";
import FuncionariosTable from "./components/FuncionariosTable";
import { useNavigate } from "react-router-dom";

const FuncionariosList = () => {

    const user = useAppSelector(selector => selector.user.user);
    const darkmode = useAppSelector(selector => selector.layout.darkMode)
    const [search, setSearch] = useState('');
    const [statusSearch, setStatusSearch] = useState('-1');
    const [loadingPage, setLoadingPage] = useState(false)
    const [data, setData] = useState<UserType[]>([])
    const navigate = useNavigate()

    const handleNovoContrato = () => {
        navigate('/admin/contracts/new')
    }

    const handleSearch = (e: any) => {
        e.preventDefault()
        loadData()
    }

    const loadData = () => {
        api.get('/funcionarios/list', {
            headers: {
                token: user?.token
            },
            params: {
                search,
            }
        }).then(res=>{
            setData(res.data)
        }).catch(err=> {
            toastError(err, darkmode)
        })
    }

    useEffect(()=> {
        loadData()
    }, [])

    return (
        <div>
            {/* <div className="flex items-center justify-center mt-10 h-full w-full py-2">
                <button onClick={handleNovoContrato} className="linear ml-auto flex items-center justify-center rounded-xl bg-green-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
                    Novo contrato
                </button>
            </div> */}
            <form className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-5 items-center justify-center mt-2 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2" onSubmit={handleSearch}>
                {/* <div className="col-span-1 md:col-span-2 xl:col-span-3 2xl:col-span-5 flex h-12 w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white"> */}
                <div className="col-span-1 md:col-span-1 xl:col-span-3 2xl:col-span-5 flex h-12 w-full items-center flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white">
                    <p className="pl-3 pr-2 text-xl">
                        <FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
                    </p>
					<input
						value={search}
						onChange={e=> setSearch(e.target.value)}
						type="text"
						placeholder="Procurar..."
						className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
					/>
                </div>

                <Select value={statusSearch} onChange={e => setStatusSearch(e.target.value)} icon={<></>} className="no-arrows text-sm col-span-1 flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white px-2">
                    <option value={-1}>Status</option>
                    <option value={1}>Ativo</option>
                    <option value={0}>Inativo</option>
                </Select>
                {/* <p className="block xl:hidden"></p> */}
                <button type="button" onClick={handleSearch} disabled={loadingPage} className="linear md:w-fit md:ml-auto md:p-2 md:px-5 flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Procurar
                </button>
            </form>


			{/* <form className="flex items-center justify-center mt-2 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2 gap-2" onSubmit={handleSearch}>
        <div className="flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            value={search}
            onChange={e=> setSearch(e.target.value)}
            type="text"
            placeholder="Procurar..."
            className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
          />
        </div>
        <button onClick={handleSearch} disabled={loadingPage} className="linear flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Procurar
        </button>
      </form> */}

            <div className="mt-5">
                <FuncionariosTable reloadTable={loadData} loading={loadingPage} title="Lista de Colaboradores" tableData={data} />
            </div>
        </div>
    )
}

export default FuncionariosList