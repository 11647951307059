import { useState } from "react"
import ComissionsTable from "./components/ComissionsTable"

const ComissoesDistribuidor = () => {
	const [naoEncontrados, setNaoEncontrados] = useState('')
	return(
        <div className="mt-5 p-2">
			<h1 className="text-2xl font-bold dark:text-white">Comissões de Distribuidor</h1>
			<div className="flex gap-10 mt-5 px-4">
				<div className="flex flex-col">
					<div className="w-[500px] flex flex-col gap-2">
						<span className="">Clique no botão abaixo para subir o arquivo do Distribuidor</span>
						<input type="file" id="comissoesFile" name="comissoesFile" hidden/>
						<label htmlFor="comissoesFile" className="rounded cursor-pointer p-2 bg-blue-500 w-fit text-white hover:bg-blue-600 transition">Subir arquivo</label>
						<span className="mt-5">Comissões encontradas</span>
						<div className="w-full h-[250px]">
							<ComissionsTable tableData={[]}/>
						</div>
					</div>
				</div>
				<div className="w-[240px] h-[450px] flex items-center justify-center">
					<button className="mt-5 rounded p-2 bg-blue-500 w-fit text-white hover:bg-blue-600 transition">Salvar comissões</button>
				</div>
				<div className="flex flex-col h-[380px] w-[500px]">
					<p>Comissões não encontrados</p>
					<ComissionsTable tableData={[]}/>
				</div>
			</div>
		</div>
	)
}

export default ComissoesDistribuidor