import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, FormControl, FormLabel, Input, Select, Textarea } from "@chakra-ui/react"
import { cnpjMask, cnpjMaskToNumber } from "masks/cnpjMask"
import { phoneMask, phoneMaskToNumber } from "masks/phoneMask";
import InputMask from 'react-input-mask';
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ErrorData } from "types/fetch";
import { useNavigate, useParams } from "react-router-dom";
import { ContentType, PDFPage } from "types/app";
import { PDFViewer } from "@react-pdf/renderer";
import PDFPageViewer from "./components/PDFPage";
import TooltipHorizon from "components/tooltip";
import SelectField from "components/fields/SelectField";

interface DataType extends PDFPage{

}


const EditPDFPage = () => {
    const { id } = useParams()

    const [data, setData] = useState<DataType>({
		content: [],
		id: null,
		isContent: false,
		name: "",
		order: null,
		pagePath: null,
		categoryId: null,
		subcategoryId: -1,
		itemId: -1
	})
    const [contents, setContents] = useState<ContentType[]>([])
    const [show, setShow] = useState(false)
    const [categorys, setCategorys] = useState<{id: number, name: string}[]>([])
    const [subcategorys, setSubcategorys] = useState<{id: number, name: string}[]>([])
    const [items, setItems] = useState<{id: number, name: string}[]>([])
	const [loaded, setLoaded] = useState(false)
    const darkmode = useAppSelector((state) => state.layout.darkMode)
    const user = useAppSelector((state) => state.user.user)

    const navigate = useNavigate()

    const variables = [
        {desc: "Carrinho de compras (Dimensões recomendadas  X: 60  Y: 120  Largura: 475  Tamanho da fonte: 12)", variable: "{{cart}}"},
        {desc: "Escopo de serviços (Dimensões recomendadas  X: 85  Y: 115  Largura: 425  Tamanho da fonte: 12)", variable: "{{scopes}}"},
        {desc: "D [de] MMMM [de] YYYY", variable: "{{LL}}"},
        {desc: "Razão Social do cliente", variable: "{{clientName}}"},
        {desc: "Inscrição estadual do cliente", variable: "{{clientIE}}"},
        {desc: "CNPJ do cliente", variable: "{{clientCNPJ}}"},
        {desc: "Nome do funcionário que fez a proposta", variable: "{{funcName}}"},
        {desc: "Email do funcionário que fez a proposta", variable: "{{funcEmail}}"},
        {desc: "Telefone do funcionário que fez a proposta", variable: "{{funcPhone}}"},
        {desc: "Valor total da proposta", variable: "{{total}}"},
        {desc: "Responsável por assinar", variable: "{{signature}}"},
        {desc: "Método de pagamento", variable: "{{payment}}"},
        {desc: "Tempo estimado de entrega", variable: "{{estimatedTime}}"},
        {desc: "Informações da Proposta (Dimensões recomendadas  X: 85  Y: 130  Largura: 430  Altura: 600  Tamanho da fonte: 12)", variable: "{{orderInfo}}"},
        {desc: "Fonte normal(no meio do texto)", variable: "$n"},
        {desc: "Fonte em negrito(no meio do texto)", variable: "$b"},
        {desc: "Cor normal(no meio do texto)", variable: "$c1"},
        {desc: "Cor alternativa(no meio do texto)", variable: "$c2"},
    ]

    const handleSubmit = (e: any) => {
        e.preventDefault()

        api.put('/orders/config/pdf/edit/page/'+id, {
            name: data.name,
            order: data.order,
            isContent: contents.length > 0,
            category: data.categoryId,
			subcategory: data.subcategoryId != -1 ? data.subcategoryId : null,
			item: data.itemId != -1 ? data.itemId : null
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            toast("Página editada com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            // navigate('/admin/pdf')
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })

    }

    const handleChangeImage = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }

        const bodyFormData = new FormData()
        bodyFormData.append("image", e.target.files[0]);
        /// @ts-ignore
        bodyFormData.append("order", data.order ? parseInt(data.order) : null)

        api.put('/orders/config/pdf/edit/page/'+id, bodyFormData, {
            headers: {
                "Content-Type": "multipart/form-data",
                token: user?.token
            }
        }).then((res)=> {
            toast("Imagem adicionada com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
            setData(res.data)
        }).catch((error: ErrorData) => {
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const changeData = (key: keyof(DataType), value: any) => {
        setData({
            ...data,
            [key]: value
        })
    }

    useEffect(()=>{
        api.get('/orders/config/pdf/page/view/'+id, {
            headers: {
                token: user?.token
            }
        }).then((res: {data: PDFPage})=> {
            setData(res.data)
            setContents(res.data.content)
            setShow(res.data.order ? true : false)
			setLoaded(true)
        })

        api.get('/orders/categorys/names', {
            headers: {
                token: user?.token
            }
        }).then((res: {data: any})=> {
            setCategorys(res.data)
        })
    }, [])

	useEffect(()=> {
		if(!loaded) return;
		setItems([])
		if(!data.categoryId){
			setSubcategorys([])
			changeData('subcategoryId', -1)
		}else{
			api.get('/orders/subcategorys/names/'+data.categoryId, {
				headers: {
					token: user?.token
				}
			}).then((res: {data: any})=> {
				setSubcategorys(res.data)
			}).catch(()=> {
				setSubcategorys([])
			})
		}
	}, [data.categoryId])

	useEffect(()=> {
		if(!loaded) return;
		if(!data.subcategoryId){
			setItems([])
			changeData('itemId', -1)
		}else{
			api.get('/orders/items/names/'+data.subcategoryId, {
				headers: {
					token: user?.token
				}
			}).then((res: {data: any})=> {
				setItems(res.data)
			}).catch(()=> {
				setItems([])
			})
		}
	}, [data.subcategoryId])

    const editContent = (id: number, key: keyof(ContentType), newValue: any) => {
        const editedContents = [...contents]
        editedContents[id] = {
            ...editedContents[id],
            [key]: newValue
        }
        setContents(editedContents)
    }

    const handleAddContent = () => {
        api.post('/orders/config/pdf/new/content', {
            pageId: parseInt(id)
        }, {
            headers: {
                token: user?.token
            }
        }).then(res=>{
            toast("Elemento criado com sucesso", {
                type: "success",
                autoClose: 1000,
                theme: darkmode ? "dark" : "light"
            })
            setContents([...contents, res.data])
        }).catch((error: ErrorData)=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const handleDeleteContent = (id: number) => {
        api.delete('/orders/config/pdf/delete/content/'+id, {
            headers: {
                token: user?.token
            }
        }).then(()=>{
            toast("Elemento deletado com sucesso", {
                type: "success",
                autoClose: 1000,
                theme: darkmode ? "dark" : "light"
            })
            const newContents = [...contents].filter(content=> content.id !== id)
            setContents(newContents)

        }).catch((error: ErrorData)=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const handleSaveContent = (id:number, index: number) => {
        const { x, y, width, height, content, fontSize, textAlign, fontFace, textIndent } = contents[index]
        api.put('/orders/config/pdf/edit/content/'+id, {
            x,
            y,
            width,
            height,
            content,
            fontSize,
            textAlign,
			fontFace,
			textIndent
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=>{
            toast("Elemento salvo com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
        }).catch((error: ErrorData)=>{
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const addToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
        toast("Variável "+text+ " adicionada na área de transferência", {
            type: "info",
            autoClose: 1000,
            theme: darkmode ? "dark" : "light"
        })
    }

    const Variables = (): JSX.Element => {
        return(
            <div className="grid grid-cols-2 text-sm font-base cursor-pointer">
                {variables.map(variable=>(
                    <TooltipHorizon
                        content={<span>{variable.desc}</span>}
                        trigger={<span onClick={()=> addToClipboard(variable.variable)} className="text-gray-700">{variable.variable}</span>}
                        extra=""
                        placement="top"
                    />
                ))}
            </div>
        )
    }

    return(
        <>
            <form className="grid h-full justify-center items-start grid-cols-1 gap-5 md:grid-cols-2 mt-10 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">
                <p></p>
                <button type="button" onClick={handleSubmit} className="linear h-10 mt-5 w-full md:w-30p xl:w-20p ml-auto flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-darkorange-400 dark:text-white dark:hover:bg-darkorange-300 dark:active:bg-darkorange-200">
                    Salvar
                </button>
                <div className="left flex-col gap-5">
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 mt-5">
                        <FormControl className="flex flex-col gap-1 w-full">
                            <FormLabel className="font-bold text-sm pl-2 dark:text-white">Nome<b className="text-red-500">*</b></FormLabel>
                            <Input value={data?.name} onChange={e=> changeData("name", e.target.value)} required type="text" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Nome da página" />
                        </FormControl>

                        <div className="border-white w-full xl:w-60p ml-auto">
                            <input onChange={handleChangeImage} type="file" accept=".jpg, .png" id="photo" className="hidden-input"/>
                            <label className="linear h-10 mt-6 w-full cursor-pointer flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-darkblue-400 dark:text-white dark:hover:bg-darkblue-300 dark:active:bg-darkblue-200" htmlFor="photo">
                                Carregar foto
                            </label>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
                        <FormControl className="flex flex-col gap-1 w-full">
                            <FormLabel className="font-bold text-sm pl-2 dark:text-white">Ordem</FormLabel>
                            <Input value={data?.order} onChange={e=> changeData("order", e.target.valueAsNumber)} required type="number" className="text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Posição da página" />
                        </FormControl>

                        <FormControl className="flex flex-col gap-1 w-full">
                            <FormLabel className="font-bold text-sm pl-2 dark:text-white">Categoria da página</FormLabel>
                            <Select value={data?.categoryId ? data.categoryId : null} icon={<></>} onChange={e=> {
								setData({
									...data,
									///@ts-ignore
									categoryId: e.target.value,
									subcategoryId: -1
								})
							}} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
                                <option value={null}>Sem categoria</option>
                                {categorys.map(category=>(
                                    <option value={category.id}>{category.name}</option>
                                ))}
                            </Select>
                        </FormControl>
						<FormControl className="flex flex-col gap-1 w-full">
							<FormLabel className="font-bold text-sm pl-2 dark:text-white">Subcategoria da página</FormLabel>
							<Select value={data?.subcategoryId ? data.subcategoryId : null} icon={<></>} onChange={e=> changeData("subcategoryId", e.target.value)} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
								<option value={-1}>Sem subcategoria</option>
								{subcategorys.map(subcategory=>(
									<option value={subcategory.id}>{subcategory.name}</option>
								))}
							</Select>
						</FormControl>
						<FormControl className="flex flex-col gap-1 w-full">
							<FormLabel className="font-bold text-sm pl-2 dark:text-white">Item da página</FormLabel>
							<Select value={data?.itemId ? data.itemId : null} icon={<></>} onChange={e=> changeData("itemId", e.target.value)} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
								<option value={-1}>Sem item</option>
								{items.map(item=>(
									<option value={item.id}>{item.name}</option>
								))}
							</Select>
						</FormControl>
                    </div>

                    <Accordion allowMultiple className="mt-5 text-sm pl-2 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
                        <AccordionItem>
                            <h2>
                                <AccordionButton _expanded={{}}>
                                    Elementos do PDF
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>

                            <AccordionPanel className="mt-5 pb-10">
                                <button onClick={handleAddContent} type="button" className="linear flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-blue-400 dark:text-white dark:hover:bg-blue-300 dark:active:bg-blue-200">
                                    Adicionar elemento
                                </button>

                                {contents.map((el, index)=>(
                                    <Accordion allowMultiple className="mt-5 text-sm pl-2 dark:text-white bg-white dark:bg-navy-700 rounded w-full border border-gray-100 shadow-md shadow-shadow-700">
                                        <AccordionItem className="">
                                            <AccordionButton _expanded={{}} className="text-gray-900 dark:text-white shadow-md shadow-shadow-700 p-2">
                                                <span className="text-bold">{el.content}</span>
                                                <AccordionIcon />
                                            </AccordionButton>

                                            <AccordionPanel className="mt pb-10 ">
                                                <div className="grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 h-full w-full rounded-xl px-5 py-2 " >
                                                    <FormControl className="flex flex-col gap-1 w-full mt-5">
                                                        <FormLabel className="font-bold text-sm pl-2 dark:text-white">Conteúdo</FormLabel>
                                                        <Textarea value={el.content} minH={300} h={300} maxH={300} onChange={e=> editContent(index, "content", e.target.value)} className="no-arrows text-gray-900 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Conteúdo do elemento" />
                                                        <span><b className="text-gray-900 dark:text-white">Variáveis:</b> <Variables /></span>
                                                    </FormControl>

                                                    <div className="grid h-10 grid-cols-1 gap-2 mt-5">
														<div className="grid grid-cols-2 gap-5">
															<FormControl className="flex flex-col gap-1 w-full">
																<FormLabel className="font-bold text-sm pl-2 dark:text-white">X</FormLabel>
																<Input value={el.x} onChange={e=> editContent(index, "x", e.target.valueAsNumber)} required type="number" className="text-gray-900 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Dist. Vertical" />
															</FormControl>
															<FormControl className="flex flex-col gap-1 w-full">
																<FormLabel className="font-bold text-sm pl-2 dark:text-white">Y</FormLabel>
																<Input value={el.y} onChange={e=> editContent(index, "y", e.target.valueAsNumber)} required type="number" className="text-gray-900 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Dist. Horizontal" />
															</FormControl>
														</div>

														<div className="grid grid-cols-2 gap-5">
															<FormControl className="flex flex-col gap-1 w-full">
																<FormLabel className="font-bold text-sm pl-2 dark:text-white">Largura</FormLabel>
																<Input value={el.width} onChange={e=> editContent(index, "width", e.target.valueAsNumber)} required type="number" className="text-gray-900 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Largura" />
															</FormControl>
															<FormControl className="flex flex-col gap-1 w-full">
																<FormLabel className="font-bold text-sm pl-2 dark:text-white">Altura</FormLabel>
																<Input value={el.height} onChange={e=> editContent(index, "height", e.target.valueAsNumber)} required type="number" className="text-gray-900 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Altura" />
															</FormControl>
														</div>

														<div className="grid grid-cols-2 gap-5">
															<FormControl className="flex flex-col gap-1 w-full">
																<FormLabel className="font-bold text-sm pl-2 dark:text-white">Alinhar Texto</FormLabel>
																<Select value={el.textAlign} icon={<></>} onChange={(e)=> editContent(index, "textAlign", e.target.value)} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
																	<option value="left">Esquerda</option>
																	<option value="right">Direita</option>
																	<option value="center">Centro</option>
																	<option value="justify">Justificar</option>
																</Select>
															</FormControl>
															<FormControl className="flex flex-col gap-1 w-full">
																<FormLabel className="font-bold text-sm pl-2 dark:text-white">Tamanho da fonte</FormLabel>
																<Input value={el.fontSize} onChange={e=> editContent(index, "fontSize", e.target.valueAsNumber)} required type="number" className="text-gray-900 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Tam. Fonte" />
															</FormControl>
														</div>

														<div className="grid grid-cols-2 gap-5">
															<FormControl className="flex flex-col gap-1 w-full col-span-2">
																<FormLabel className="font-bold text-sm pl-2 dark:text-white">Espaçamento do parágrafo</FormLabel>
																<Input value={el.textIndent} onChange={e=> editContent(index, "textIndent", e.target.valueAsNumber)} required type="number" className="text-gray-900 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Espaçamento" />
															</FormControl>
														</div>

														<div className="grid grid-cols-2 gap-5">
															<FormControl className="flex flex-col gap-1 w-full">
																<FormLabel className="font-bold text-sm pl-2 dark:text-white">Tipo da fonte</FormLabel>
																<Select value={el.fontFace} icon={<></>} onChange={(e)=> editContent(index, "fontFace", parseInt(e.target.value))} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2">
																	<option value={0}>Normal</option>
																	<option value={1}>Negrito</option>
																</Select>
															</FormControl>

															<FormControl className="flex flex-col gap-1 w-full">
																<FormLabel className="font-bold text-sm pl-2 dark:text-white">Ghost</FormLabel>
																<Input value={el.ghost} onChange={e=> editContent(index, "ghost", e.target.value)} required type="color" className="text-gray-900 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2 h-10" placeholder="Tam. Fonte" />
															</FormControl>
														</div>

														<div className="grid grid-cols-2 gap-5">
															<button onClick={()=> handleDeleteContent(el.id)} type="button" className="linear flex h-10 self-end items-center justify-center rounded-xl bg-red-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200">
																Remover
															</button>
															<button onClick={()=> handleSaveContent(el.id, index)} type="button" className="linear flex items-center justify-center rounded-xl bg-blue-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-blue-400 dark:text-white dark:hover:bg-blue-300 dark:active:bg-blue-200">
																Salvar
															</button>
														</div>
                                                    </div>
                                                </div>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                ))}
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
                <div className="right flex-col gal-5">
                    <PDFViewer className="w-full" style={{height: 1100}}>
                        <PDFPageViewer page={data} contents={contents} />
                    </PDFViewer>
                </div>
            </form>
        </>
    )
}

export default EditPDFPage