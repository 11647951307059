import React from "react";

function useOutsideAlerter(ref: any, setX: any): void {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    // function handleClickOutside(event: React.MouseEvent<HTMLElement>) {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setX(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setX]);
}

const WidgetDropdown = (props: {
  button: JSX.Element;
  children: JSX.Element;
  classNames: string;
  animation?: string;
  setOpen?:   React.Dispatch<React.SetStateAction<boolean>>;
  open?:      boolean;
}) => {
  const { button, children, classNames, animation } = props;
  const wrapperRef = React.useRef(null);
  const [openWrapper, setOpenWrapper] = React.useState(false);
  useOutsideAlerter(wrapperRef, props.setOpen ? props.setOpen : setOpenWrapper);

  return (
    <div ref={wrapperRef} className="!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-xl shadow-shadow-600 dark:!bg-navy-800 border border-gray-100 dark:text-white dark:shadow-none !flex-row flex-grow items-center rounded-[20px]">
      <div className="flex" >
        {button}
      </div>
      <div
        className={`${classNames} absolute z-10 ${
          animation
            ? animation
            : "origin-top-right transition-all duration-300 ease-in-out"
        } ${props.open ? props.open : openWrapper ? "scale-100" : "scale-0"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default WidgetDropdown;
