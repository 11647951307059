import { ChakraProvider, FormControl, FormLabel, Input, Select, Textarea, useDisclosure } from "@chakra-ui/react"
import CompanyAutoComplete from "components/companyAutoComplete/CompanyAutoComplete"
import { getMonth, months } from "helpers/months"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useAppSelector } from "redux/hooks"
import api from "services/api"
import { ClientType, ContractType, NFItemCodigo, SelectType, UserType } from "types/app"
import CurrencyInput from "./components/CurrencyInput"
import { toastError } from "components/toasts/toastError"
import { ErrorData } from "types/fetch"
import { useNavigate } from "react-router-dom"
import { replaceSpecialChars, stringToFloat, stringToInteger } from "helpers/conversions"
import Dropdown from "components/dropdown"
import Calendar from "react-calendar"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import { Select as SelectWithText } from 'chakra-react-select'
import dayjs from "dayjs"
import { tiposContratos } from "variables/contratos"
import AsyncSelect from 'react-select/async'
import { StylesConfig } from "react-select";

const selectStyle: StylesConfig = {
	singleValue: (styles, { isDisabled }) => ({
		...styles,
		color: isDisabled ? "rgb(112 126 174 / 1)" : undefined
	}),
	input: (styles, { isDisabled }) => ({
		...styles,
		paddingLeft: 2,
		paddingRight: 2,
		height: 42,

	}),
	option: (styles, { isSelected, isFocused, isDisabled}) => ({
		...styles,
		background: isSelected ? "#2d394d" : isFocused ? "#edf2f7" : styles.background,
		':active': {
			...styles[':active'],
			backgroundColor: !isDisabled
			? "#cbd5e0" : undefined,
		},
	}),
	control: (styles, {isDisabled}) => ({
		...styles,
		borderColor: "#a3aed0",
		borderRadius: 0,
		background: isDisabled ? "#f8f9fa" : "white",
		cursor: isDisabled ? "default" : "text"
	})
}

interface DataType {
    id?: number
    description?: string
    active?: boolean
    note?: string
	descricaoNota?: string,
    value?: number
    birthday?: number
    paymentMethod?: number
    clientId?: number
    createdById?: number
    ownerId?: number
    createdAt?: Date
    startDate?: Date
    nocSendDate?: Date
    billingDay?: number,
	preVendasId?: number,
	dataAssinatura?: string,
	tipoContratoId?: number,

	nfEmail?: string,
	nfItemId?: number,
	nfItem?: NFItemCodigo
}

const ContractsNew = () => {

    const user = useAppSelector(selector => selector.user.user)
    const darkmode = useAppSelector(selector => selector.layout.darkMode)
    const [data, setData] = useState<DataType>({
    })
    const [companyNameTimeout, setCompanyNameTimeout] = useState<any>()
    const [companyName, setCompanyName] = useState('')
    const [loadingCompanys, setLoadingCompanys] = useState(false)
    const [companySelect, setCompanySelect] = useState<SelectType[]>([]);

    const [users, setUsers] = useState<UserType[]>([])

    const { isOpen, onOpen, onClose } = useDisclosure()
	const [itensNF, setItensNF] = useState<NFItemCodigo[]>([])

    const navigate = useNavigate()

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if(!data.clientId){
            toast("Selecione um cliente", {
                theme: darkmode ? "dark" : "light",
                type: "error"
            })
            return;
        }
        if(!data.value){
            toast("Digite um valor para o contrato", {
                theme: darkmode ? "dark" : "light",
                type: "error"
            })
            return;
        }

        if(!data.billingDay || data.billingDay == -1){
            toast("Selecione uma data de faturamento", {
                theme: darkmode ? "dark" : "light",
                type: "error"
            })
            return;
        }

        api.post<any, any, DataType>("/contracts/new", {
            description: data.description,
            active: data.active,
            note: data.note,
            value: stringToFloat(data.value.toString().replace(/[a-zA-Z]\$|\./gi, '').replace(/\,/gi, '.')),
            birthday: stringToInteger(data.birthday),
            paymentMethod: stringToInteger(data.paymentMethod),
            clientId: stringToInteger(data.clientId),
            createdById: data.createdById,
            ownerId: stringToInteger(data.ownerId),
            createdAt: data.createdAt,
            startDate: data.startDate,
            nocSendDate: data.nocSendDate,
            billingDay: data.billingDay,
			preVendasId: data.preVendasId,
			descricaoNota: data.descricaoNota,
			dataAssinatura: data.dataAssinatura,
			tipoContratoId: stringToInteger(data.tipoContratoId),
			nfEmail: data.nfEmail,
			nfItemId: data.nfItemId
        }, {
            headers: {
                token: user?.token
            }
        }).then(()=> {
            navigate('/admin/contracts/list')
            toast("Contrato criado com sucesso!", {
                type: "success",
                theme: darkmode ? "dark" : "light"
            })
        }).catch((err: ErrorData)=>{
            toastError(err, darkmode)
        })
    }

    const handleChangeInput = (key: keyof (DataType), val: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [key]: val.target.value })
    }
    const handleChangeValue = (key: keyof (DataType), val: any) => {
        setData({ ...data, [key]: val })
    }


    const getClients = (_search?: string) => {
        setLoadingCompanys(true)
        api.get("/clients/all/autocomplete", {
            headers: {
                token: user?.token
            },
            params: {
                search: _search ? _search : ""
            }
        }).then((res: { data: ClientType[] }) => {
            setLoadingCompanys(false)
            var _newClients: SelectType[] = []
            res.data.map(client => {
                _newClients.push({
                    content: client.name ? client.name : "Sem nome",
                    value: client.id.toString()
                })
            })
            setCompanySelect(_newClients)
        }).catch((error) => {
            setLoadingCompanys(false)
            toast(error.response.data.error, {
                type: "error",
                theme: darkmode ? "dark" : "light"
            })
        })
    }

    const handleSelectClient = (newClient: string) => {
        const _companyId = isNaN(parseInt(newClient)) ? '-1' : newClient
        handleChangeValue("clientId", _companyId)
    }

    const handleChangeClient = (companyName: string) => {
        setCompanyName(companyName)
        if (companyNameTimeout) {
            clearInterval(companyNameTimeout)
        }
        setCompanyNameTimeout(setTimeout(() => {
            getClients(companyName)
            setCompanyNameTimeout(null)
        }, 500))
    }

    const getUsers = () => {
        api.get('/users/select', {
            headers: {
                token: user?.token
            }
        }).then((res: {data: UserType[]}) => {
            setUsers(res.data)
        }).catch((error: ErrorData)=> {
            toastError(error, darkmode)
        })
    }

	const getNFItens = () => {
		api.get('/nfsitens/autocomplete', {
            headers: {
                token: user?.token
            }
        }).then((res: {data: NFItemCodigo[]}) => {
            setItensNF(res.data)
        }).catch((error: ErrorData)=> {
            toastError(error, darkmode)
        })
	}

    useEffect(()=> {
        getClients()
        getUsers()
		getNFItens()
    }, [])

    return(
        <>
            <form onSubmit={handleSubmit} className="grid h-full justify-center grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 mt-10 h-full w-full bg-white dark:!bg-navy-800 rounded-xl px-5 py-2">
				<FormControl className="w-full flex gap-1 flex-col col-span-2">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Cliente</FormLabel>
                    <CompanyAutoComplete
                        loading={loadingCompanys}
                        extra=""
                        className="md:col-span-1 2xl:col-span-4"
                        inputClass="h-[50px] px-2 border border-gray-600 w-full"
                        label=""
                        variant="auth"
                        placeholder=""
                        id="orderClient"
                        onChange={handleChangeClient}
                        onSelect={handleSelectClient}
                        value={companyName}
                        options={companySelect}
                    />
                </FormControl>

				<FormControl className="w-full flex gap-1 flex-col">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Data de criação</FormLabel>
					<Dropdown
						button={
						<button
							type="button"
							onClick={() => onOpen()}
							className={`h-[50px] px-2 border border-gray-600 w-full`}
						>
							{data.createdAt ? dayjs(data.createdAt).format("DD/MM/YYYY") : "Clique para definir"}
						</button>
						}
						extraClassesWrapper="w-full h-10"
						extraClassesButton="w-full h-10"
						animation={"origin-top-right transition-all duration-300 ease-in-out"}
						classNames={`top-11 right-0 w-max `}
						children={
						<div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
							<Calendar
								value={data.createdAt}
								onClickDay={e=> handleChangeValue("createdAt", e)}
								prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
								nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
								view={"month"}
							/>
						</div>
						}
					/>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Data de assinatura</FormLabel>
					<Dropdown
						button={
							<button
								type="button"
								onClick={() => onOpen()}
								className={`h-[50px] px-2 border border-gray-600 w-full`}
							>
								{data.dataAssinatura ? dayjs(data.dataAssinatura).format("DD/MM/YYYY") : "Clique para definir"}
							</button>
						}
						extraClassesWrapper="w-full h-10"
						extraClassesButton="w-full h-10"
						animation={"origin-top-right transition-all duration-300 ease-in-out"}
						classNames={`top-11 right-0 w-max `}
						children={
						<div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
							<Calendar
								value={data.dataAssinatura ? dayjs(data.dataAssinatura).toDate() : undefined}
								onClickDay={e=> handleChangeValue("dataAssinatura", e)}
								prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
								nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
								view={"month"}
							/>
						</div>
						}
					/>
				</FormControl>

                <FormControl className="w-full flex gap-1 flex-col">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Data de início</FormLabel>
                    <Dropdown
                        button={
                        <button
                            type="button"
                            onClick={() => onOpen()}
                            className={`h-[50px] px-2 border border-gray-600 w-full`}
                        >
                            {data.startDate ? dayjs(data.startDate).format("DD/MM/YYYY") : "Clique para definir"}
                        </button>
                        }
                        extraClassesWrapper="w-full h-10"
                        extraClassesButton="w-full h-10"
                        animation={"origin-top-right transition-all duration-300 ease-in-out"}
                        classNames={`top-11 right-0 w-max `}
                        children={
                        <div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                            <Calendar
                                value={data.startDate}
                                onClickDay={e=> handleChangeValue("startDate", e)}
                                prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
                                nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
                                view={"month"}
                            />
                        </div>
                        }
                    />
                </FormControl>

				<FormControl className="w-full flex gap-1 flex-col">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Dia de Faturamento</FormLabel>
					<Select value={data.billingDay} icon={<></>} onChange={e=> handleChangeValue("billingDay", e.target.value)} className="h-[50px] px-2 border border-gray-600 w-full">
						<option value={-1}>Nenhum</option>
						{[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28].map(val=>(
							<option key={"DAY-"+val} value={val}>{val}</option>
						))}
					</Select>
				</FormControl>

                <FormControl className="w-full flex gap-1 flex-col">
                    <FormLabel className="font-bold text-sm pl-2 dark:text-white">Valor do contrato</FormLabel>
                    <CurrencyInput value={data.value} onChange={e=> handleChangeValue("value", e.target.value)} className="h-[50px] px-2 border border-gray-600 w-full" placeholder="R$0,00" />
                </FormControl>

				<FormControl className="w-full flex gap-1 flex-col col-span-1">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Vendedor responsável</FormLabel>
					<AsyncSelect
						defaultOptions={users
							.map((func) => {
								let _func: any = {};
								_func.value = func.id;
								_func.label = func.name;
								return _func;
							})
							.slice(0, 9)}
						cacheOptions
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = users
									.filter((func) =>
										replaceSpecialChars(
											func.name.toLowerCase()
										).includes(replaceSpecialChars(inputstring.toLowerCase()))
									)
									.map((func) => {
										let _func: any = {};
										_func.value = func.id;
										_func.label = func.name;
										return _func;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.ownerId,
							label: users?.find(
								(c) =>
									c.id.toString() ==
									data.ownerId?.toString()
							)?.name,
						}}
						onChange={(e: any) => {
							handleChangeValue("ownerId", e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col col-span-1">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Pré vendas</FormLabel>
					<AsyncSelect
						defaultOptions={users
							.map((func) => {
								let _func: any = {};
								_func.value = func.id;
								_func.label = func.name;
								return _func;
							})}
						cacheOptions
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = users
									.filter((func) =>
										replaceSpecialChars(
											func.name.toLowerCase()
										).includes(replaceSpecialChars(inputstring.toLowerCase()))
									)
									.map((func) => {
										let _func: any = {};
										_func.value = func.id;
										_func.label = func.name;
										return _func;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.preVendasId,
							label: users?.find(
								(c) =>
									c.id.toString() ==
									data.preVendasId?.toString()
							)?.name,
						}}
						onChange={(e: any) => {
							handleChangeValue("preVendasId", e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">O contrato está ativo?</FormLabel>
					<Select value={data.active ? 1 : 0} icon={<></>} onChange={e=> handleChangeValue("active", e.target.value == '0' ? false : true)} className="h-[50px] px-2 border border-gray-600 w-full">
						<option value={0}>Não</option>
						<option value={1}>Sim</option>
					</Select>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Método de pagamento</FormLabel>
					<Select value={data.paymentMethod} icon={<></>} onChange={e=> handleChangeValue("paymentMethod", e.target.value)} className="h-[50px] px-2 border border-gray-600 w-full">
						<option value={0}>Boleto</option>
						<option value={1}>TED</option>
					</Select>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Tipo de contrato</FormLabel>
					<Select value={data.tipoContratoId} icon={<></>} placeholder="Selecione um" onChange={e=> handleChangeValue("tipoContratoId", e.target.value)} className="h-[50px] px-2 border border-gray-600 w-full">
						{tiposContratos.map(t=> (
							<option key={"TIPO-"+t.id} value={t.id}>{t.nome}</option>
						))}
					</Select>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col col-span-1">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Código NF</FormLabel>
					<AsyncSelect
						defaultOptions={itensNF
							.map((item) => {
								let _item: any = {};
								_item.value = item.id;
								_item.label = item.item_lista_servico + "/" + item.codigo;
								return _item;
							})}
						cacheOptions
						menuPlacement="auto"
						loadOptions={(inputstring) => {
							return new Promise((resolve, reject) => {
								const options = itensNF
									.filter((func) =>
										replaceSpecialChars(
											func.item_lista_servico.replace(/\./gi, "").toLowerCase()
										).includes(replaceSpecialChars(inputstring.replace(/\./gi, "").toLowerCase()))
									)
									.map((item) => {
										let _item: any = {};
										_item.value = item.id;
										_item.label = item.item_lista_servico + "/" + item.codigo;
										return _item;
									})
									.slice(0, 9);

								setTimeout(() => {
									resolve(options);
								}, 250);
							});
						}}
						value={{
							value: data.nfItemId,
							label: data.nfItemId ? (itensNF?.find(
								(c) =>
									c.id.toString() ==
									data.nfItemId?.toString()
							)?.item_lista_servico + "/" + itensNF?.find(
								(c) =>
									c.id.toString() ==
									data.nfItemId?.toString()
							)?.codigo) : "Selecione um",
						}}
						onChange={(e: any) => {
							handleChangeValue("nfItemId", e.value);
						}}
						noOptionsMessage={() => "Sem dados"}
						placeholder={"Selecione um"}
						styles={selectStyle}
					/>
				</FormControl>

				<FormControl className="w-full flex gap-1 flex-col">
					<FormLabel className="font-bold text-sm pl-2 dark:text-white">Email nota fiscal</FormLabel>
					<Input type="email" value={data.nfEmail} onChange={e=> handleChangeValue("nfEmail", e.target.value)} className="h-[50px] px-2 border border-gray-600 w-full" placeholder="email@nf.com.br" />
				</FormControl>

				<div className="grid grid-cols-6 col-span-6 gap-5">
					<FormControl className="w-full flex gap-1 flex-col lg:col-span-2">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Descrição do contrato</FormLabel>
						<Textarea minH={200} value={data.description} onChange={e=> handleChangeValue("description", e.target.value)} placeholder="Digite a descrição do contrato" className="h-[50px] p-2 border border-gray-600 w-full" />
					</FormControl>
					<FormControl className="w-full flex gap-1 flex-col lg:col-span-2">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Anotações</FormLabel>
						<Textarea minH={200} value={data.note} onChange={e=> handleChangeValue("note", e.target.value)} placeholder="Campo para anotações referentes ao contrato" className="h-[50px] p-2 border border-gray-600 w-full" />
					</FormControl>
					<FormControl className="w-full flex gap-1 flex-col lg:col-span-2">
						<FormLabel className="font-bold text-sm pl-2 dark:text-white">Descritivo para a nota fiscal</FormLabel>
						<Textarea minH={200} value={data.descricaoNota} onChange={e=> handleChangeValue("descricaoNota", e.target.value)} placeholder="Campo para anotações para serem inseridas na NF do contrato" className="h-[50px] p-2 border border-gray-600 w-full" />
					</FormControl>
				</div>


				<div className="col-span-6">
					<button type="submit" className="linear h-10 mt-5 ml-auto flex items-center justify-center rounded-xl bg-green-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
						Atualizar contrato
					</button>
				</div>
            </form>
        </>
    )
}

export default ContractsNew