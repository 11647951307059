import { useNavigate } from "react-router-dom"
import links from "./data/links"
import { useAppSelector } from "redux/hooks"

const QuickLinks = () => {
    const user = useAppSelector(selector => selector.user.user)

    const handleOpenLink = (link: string) => {
        window.open(link)
    }

    return(
        <div className="mt-5 w-full p-5 flex flex-col gap-10">
            
            {Object.values(links).map((cats, index) => {
                let canRender = true
                if(cats.permission != undefined){
                    canRender = false
                    if(user?.permissions){
                        if(user?.admin || user?.permissions[cats.permission] == true){
                            canRender = true
                        }
                    }
                }
                if(canRender)
                    return (
                        <div key={`link-category-${index}`}>
                            <h1 className="font-bold text-2xl dark:text-white text-sm">{cats.label}</h1>
                            <div className="mt-5 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-7 2xl:grid-cols-7 4xl:grid-cols-10 gap-5 items-center justify-center">
                                {cats.links.map(({link, name, icon, image}, index)=>(
                                    <div title={link} onClick={()=> handleOpenLink(link)} className="flex text-center p-2 shadow-lg shadow-shadow-700 hover:shadow-2xl hover:shadow-shadow-800 dark:hover:!bg-navy-500 transition duration-200 flex-col text-gray-800 dark:text-white items-center justify-center h-[120px] w-full cursor-pointer rounded-xl border-2 dark:!border-navy-600 border-gray-100" key={`LINKS-${index}`}>
                                        {icon && icon}
                                        <h2 className="text-md mt-5 font-light text-xs">{name}</h2>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )   
            })}
        </div>
    )
}

export default QuickLinks