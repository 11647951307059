import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { TicketsType } from "types/app";

const RankingTecnicosChart = ({ searchDate, rankingData }: {searchDate: Date, rankingData: TicketsType["ranking"]}) => {
  const darkmode = useAppSelector((state) => state.layout.darkMode)
  const user = useAppSelector((state) => state.user.user)

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "10px",
        fontFamily: undefined,
        backgroundColor: "#000000"
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "light",
      // y: {
      //   formatter: (val: any) => Intl.NumberFormat("pt-BR", {style: "currency", currency: "BRL"}).format(val)
      // }
    },
    xaxis: {
      categories: [""],
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#CBD5E0",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: "#4318FF",
              opacity: 1,
            },
            {
              offset: 100,
              color: "rgba(67, 24, 255, 1)",
              opacity: 0.28,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40px",
      },
    },
  })

  const [chartData, setChartData] = useState([])

  useEffect(()=> {
    if(rankingData){
      setChartData([...rankingData.data])
      setChartOptions({
        ...chartOptions,
        xaxis: {
          categories: rankingData.names
        }
      })
    }
    // api.get('/dashboards/rankingvendedores', {
    //   headers: {
    //     token: user?.token
    //   },
    //   params: {
    //     month: searchDate
    //   }
    // }).then(res=>{
    //     setChartData(res.data.ranking)
    //     setChartOptions({
    //       ...chartOptions,
    //       xaxis: {
    //         categories: res.data.names
    //       }
    //     })
    // }).catch(()=>{
    //   toast('Erro ao baixar dados do gráfico 001.', {
    //     theme: darkmode ? 'dark' : 'light'
    //   });
    // })
}, [rankingData])

  return (
    <Chart
      options={chartOptions}
      series={chartData}
      type="bar"
      width="100%"
      height="100%"
    />
  );
}

export default RankingTecnicosChart;
